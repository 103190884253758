import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import classes from "./EditLoadingData.module.scss";
import { Form, Input, DatePicker, Select, notification, Checkbox, Modal, Divider, Space, Upload } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import moment from "moment";
import constantsArr from "../../../../constants/global-constants";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
const { TextArea } = Input;

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const EditLoadingData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const loadId = params?.id ?? "";
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [loadingForm] = Form.useForm();
    const [vehicleForm] = Form.useForm();
    const [loadDetails, setLoadDetails] = useState("");
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [vehicleLists, setVehicleLists] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [loadingAgencyDropDown, setLoadingAgencyDropDown] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const [netWeight, setNetWeight] = useState(0);
    const [tareWeight, setTareWeight] = useState(0);
    const [reviewedBy, setReviewedBy] = useState("");
    const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem('userInfo'))
    const [reasonForCancellation, setReasonForCancellation] = useState(null)
    const [challanNumberDisabled, setChallanNumberDisabled] = useState(true)
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const dateFormat = "DD-MM-YYYY";
    const [newVehicleRegisterPopOpen, setNewVehicleRegisterPopOpen] = useState(false)
    const [vehicleDropDownOpen, setVehicleDropDownOpen] = useState(false)
    const [isSubmittingCreateVehicleResponse, setIsSubmittingCreateVehicleResponse] = useState(false); // New state variable
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const allowedMimeTypes = constantsArr.ALLOWED_MIME_TYPES
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [viewPdf, setViewPdf] = useState(null);
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const [vehicleNoValue, setVehicleNoValue] = useState("")
    const [vehicleFetchInfo, setVehicleFetchInfo] = useState({})
    const [isForcefullyValidated, setIsForceFullyValidated] = useState(false)
    const [validatedThroughAPI, setValidatedThroughAPI] = useState(false)
    const [isVehicleInfoModelOpen, setIsVehicleInfoModelOpen] = useState(false)

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const getLoadingDetails = async () => {
        const response = await AppHelper.getLoadingById(loadId);
        setChallanNumberDisabled(response?.data?.attributes?.Cancelled)
        loadingForm.setFieldsValue({
            Challan_Number: response?.data?.attributes?.Challan_Number,
            Vehicle_Number: response?.data?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number,
            Owner: response?.data?.attributes?.Vehicle_Number?.data?.attributes?.Owner,
            Date_of_Loading: dayjs(response?.data?.attributes?.Date_of_Loading),
            Loading_Agency: response?.data?.attributes?.Loading_Agency?.data?.attributes?.Company_name,
            Transporter_Agency: response?.data?.attributes?.Transporter_Agency?.data?.attributes?.Company_name,
            Thermal_Plant_Book_no: response?.data?.attributes?.Thermal_Plant_Book_no,
            Net_Weight: response?.data?.attributes?.Net_Weight,
            Gross_Weight: response?.data?.attributes?.Gross_Weight,
            Kanta_Slip_No: response?.data?.attributes?.Kanta_Slip_No,
            Tare_Weight: response?.data?.attributes?.Tare_Weight,
            Loading_by_Employee: response?.data?.attributes?.Loading_by_Employee,
            Reviewed_By: response?.data?.attributes?.Reviewed_By,
            Remarks: response?.data?.attributes?.Remarks,
            Cancelled: response?.data?.attributes?.Cancelled,
            Reason_For_Cancellation: response?.data?.attributes?.Reason_For_Cancellation,
            Created_By: response?.data?.attributes?.Created_By,
            DO_No: response?.data?.attributes?.DO_No
        })

        let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";
        if (uploadedPictures) {
            uploadedPictures = JSON.parse(uploadedPictures)
        }
        let updatedPictures = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
            ...image,
            url: image?.url,
            uid: image?.id,
            name: image?.name,
            type: image?.mime,
            status: "done",
        })) : [];
        setNetWeight(response?.data?.attributes?.Net_Weight && response?.data?.attributes?.Net_Weight !== "" ? response?.data?.attributes?.Net_Weight : 0)
        setTareWeight(response?.data?.attributes?.Tare_Weight && response?.data?.attributes?.Tare_Weight !== "" ? response?.data?.attributes?.Tare_Weight : 0)
        setLoadDetails(response.data);
        setFileList(updatedPictures);
        setUploadedFileList(updatedPictures)
    };

    const handleModelCancel = () => setNewVehicleRegisterPopOpen(false)

    const disableDates = (currentDate) => {
        return currentDate > moment().endOf('day')
    }

    const onCheckboxChange = (e) => setReviewedBy(e.target.checked)

    const putloadData = async (formValues) => {
        setIsSubmittingResponse(true);

        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        if (uploadImageResponse?.error) {
            notification.error({
                message: "Error",
                description: uploadImageResponse?.error,
                duration: 3,
            });
            return false
        } else {
            const dateLoading = new Date(dayjs(formValues?.Date_of_Loading).format("YYYY-MM-DD"))
            const dateUnloading = new Date(dayjs(loadDetails?.attributes?.Unloading?.data?.attributes.Date_of_Unloading).format("YYYY-MM-DD"))
            if (dateLoading > dateUnloading) {
                notification.error({
                    message: "Error",
                    description: "Date of Loading can not be greater than unloading date",
                    duration: 3,
                });
                setIsSubmittingResponse(false);
                return false
            }
            const isLoadingCancelled = loadDetails?.attributes?.Cancelled
            if (isLoadingCancelled === true) {
                if (formValues?.Reason_For_Cancellation.trim().length === 0) {
                    notification.error({
                        message: `Reason cannot be empty`,
                        duration: 3,
                    });
                    return false
                }
                setIsSubmittingResponse(false);
            }

            formValues['Date_of_Loading'] = dayjs(formValues?.Date_of_Loading).format("YYYY-MM-DD")
            const loadingAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Loading_Agency && item?.attributes?.Project?.data?.id === globalTpProjectValues?.projectGlobal)
            const transportationAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Transporter_Agency && item?.attributes?.Project?.data?.id === globalTpProjectValues?.projectGlobal)
            const vehicleDetailsByNumber = vehicleLists?.find((obj) => obj?.Vehicle_Number === formValues?.Vehicle_Number);
            const thermalPlantObject = { 'id': globalTpProjectValues ? globalTpProjectValues.thermalPlantGlobal : null }
            const ProjectObject = { 'id': globalTpProjectValues ? globalTpProjectValues.projectGlobal : null }
            formValues['Loading_Agency'] = loadingAgency?.id
            formValues['Transporter_Agency'] = transportationAgency?.id
            formValues['Vehicle_Number'] = vehicleDetailsByNumber?.id
            formValues['Thermal_Plant'] = thermalPlantObject
            formValues['Project'] = ProjectObject
            formValues['Uploaded_Pictures'] = { 'id': uploadImageResponse?.data?.id ?? "" }
            formValues['Reviewed_By'] = reviewedBy === true ? sessionUserInfo?.userID : null
            formValues['Updated_By'] = sessionUserInfo?.userID ?? null
            // Send the updated data to the API
            const payload = {
                data: { ...formValues }
            };
            const response = await AppHelper.updateLoading(loadId, payload);
            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: response?.error?.message,
                    duration: 3,
                });
            } else {
                // check if any unloading data is created for loading and if yes than update common fields value in unloading and hsd details
                if (loadDetails?.attributes?.Unloading?.data?.attributes?.Challan_Number) {
                    const unloadingData = loadDetails?.attributes?.Unloading?.data?.attributes
                    // unloading data exists and need to be updated
                    unloadingData['Transporting_Agency'] = formValues?.Transporter_Agency
                    unloadingData['Vehicle_Number'] = formValues?.Vehicle_Number
                    unloadingData['Thermal_Plant_Book_no'] = formValues?.Thermal_Plant_Book_no
                    unloadingData['Challan_Number'] = formValues?.Challan_Number
                    unloadingData['Project'] = ProjectObject
                    const payload = { data: { ...unloadingData } };
                    await AppHelper.updateUnloading(loadDetails?.attributes?.Unloading?.data?.id, payload);
                }
                notification.success({
                    message: "Success",
                    description: "Loading has been updated successfully.",
                    duration: 3,
                });
                setIsSubmittingResponse(false);
                sessionStorage.removeItem('loadings_schema_current_sort_order_value')
                navigate(`/loadings?pageSize=${pageSize}&sort=updatedAt:desc`);
            }
        }
        setIsSubmittingResponse(false);
    };

    const handleCreateVehiclePopup = () => {
        setVehicleDropDownOpen(false)
        setNewVehicleRegisterPopOpen(true)
    }

    const onChangeCheckbox = (e) => {
        setIsForceFullyValidated(e.target.checked)
    }

    const handleCancelVehicleModelOpen = () => {
        setIsVehicleInfoModelOpen(false);
    };

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        console.log(file, 'file')
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, and png.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }

    const onFinishCreateVehicle = async (values) => {
        setIsSubmittingCreateVehicleResponse(true);
        
        values.Vehicle_Information = vehicleFetchInfo ?? {}
        values.Gross_Weight = vehicleFetchInfo?.grossVehicleWeight ?? 0
        values.Vehicle_Category = vehicleFetchInfo?.vehicleCategory ?? ""
        values.Forcefully_Validated = isForcefullyValidated ?? false
        values.Validated_Through_API = validatedThroughAPI ?? false

        const vehicle = await AppHelper.postVehicle(values);
        // Optionally, you can show a success message
        if (vehicle?.error) {
            notification.error({
                message: "Error",
                description: vehicle?.error?.message,
                duration: 1.5,
            });
        } else {
            notification.success({
                message: "Success",
                description: "Vehicle created successfully.",
                duration: 1.5,
            });
            setNewVehicleRegisterPopOpen(false)
            // add newly created vehicle no on top of drop down menu
            setVehicleDropDown([{ 'value': values?.Vehicle_Number, 'label': values?.Vehicle_Number }, ...vehicleDropDown])
            // add newly added vehicle no in main loaded vehicle list 
            setVehicleLists([
                {
                    "id": vehicle?.data?.id,
                    "Vehicle_Number": vehicle?.data?.attributes?.Vehicle_Number,
                    "Owner": vehicle?.data?.attributes?.Owner,
                    "No_of_Tyres": vehicle?.data?.attributes?.No_of_Tyres,
                },
                ...vehicleLists
            ])
            setVehicleDropDownOpen(false)
            // set newly created vehicle no to vehicle number field automatically
            loadingForm.setFieldsValue({
                Vehicle_Number: values?.Vehicle_Number,
                Owner: values?.Owner
            })

        }
        setIsSubmittingCreateVehicleResponse(false);
    }

    const handleChange = (values) => {
        setIsSubmittingResponse(false);
        const fileStatus = values?.file?.status
        let fileList = values?.fileList
        if (fileStatus !== 'removed') {
            fileList = values?.fileList
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
            if (values?.file?.type === 'application/pdf' && values?.file?.status !== 'done') {
                const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    getUploadedFile.thumbUrl = URL.createObjectURL(e.target.result)
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
            setFileList(fileList)
        }
        setFileList(values?.fileList);
    };

    const loadVehicleDetails = (e) => {
        const vehicleDetailsById = vehicleLists?.find((obj) => obj?.Vehicle_Number === e);
        loadingForm.setFieldsValue({
            Owner: vehicleDetailsById?.Owner,
        });
    };

    const handleInputChange = (e) => {
        setIsSubmittingResponse(false);
        const { value, id } = e.target; // Assuming 'Date_of_Unloading' is the name of your date field
        if (id === "Net_Weight") {
            setNetWeight(parseFloat(value ?? 0) || 0)
            loadingForm.setFieldsValue({ Gross_Weight: ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString() });
        } else if (id === "Tare_Weight") {
            setTareWeight(parseFloat(value ?? 0) || 0)
            loadingForm.setFieldsValue({ Gross_Weight: ((parseFloat(netWeight) + parseFloat(tareWeight)).toFixed(2)).toString() });
        }
    };

    const backToLoadingsList = (e) => {
        e.preventDefault();
        navigate(`/loadings?currentPage=${pageNo}&pageSize=${pageSize}`);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleKeyDown = (event) => {
        // Check if the pressed key is an alphabet character
        if (/[a-zA-Z]/.test(event.key) && event.key !== 'Backspace') {
            event.preventDefault(); // Prevent input of alphabets
        }
    };

    const validateVehicleNumber = async () => {
        if (!vehicleNoValue || vehicleNoValue === '') {
            notification.error({
                message: "Error",
                description: "Please enter vehicle number",
                duration: 3,
            });
            return false
        }
        const getVehicleInformation = await AppHelper.validateVehicleNo(vehicleNoValue)
        if (getVehicleInformation?.error) {
            notification.error({
                message: "Error",
                description: getVehicleInformation?.error?.message,
                duration: 3,
            });
        } else {
            setValidatedThroughAPI(true)
            setVehicleFetchInfo(getVehicleInformation?.result ?? getVehicleInformation ?? {})
            setIsVehicleInfoModelOpen(true)
        }
    };

    const handleVehicleChange = (e) => {
        setVehicleNoValue(e.target.value)
    }

    useEffect(() => {
        getLoadingDetails();
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response?.data);
            setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(response?.data));
        });
        AppHelper.getAgenciesByProject(globalTpProjectValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Loading', globalTpProjectValues));
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', globalTpProjectValues));
        });

        vehicleForm.setFieldsValue({
            No_of_Tyres: 0
        })
    }, []);

    useEffect(() => {
        document.title = "Edit Loading"
        const updatedGrossWeight = ((parseFloat(netWeight ?? 0) + parseFloat(tareWeight ?? 0)).toFixed(2)).toString();
        loadingForm.setFieldsValue({ Gross_Weight: updatedGrossWeight });
    }, [tareWeight, netWeight]);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["edit-loading-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Edit Loading Details (Challan No: {loadDetails?.attributes?.Cancelled === true ? <span className={classes['cancelled-loading-challan-no']}>{loadDetails?.attributes?.Challan_Number}</span> : loadDetails?.attributes?.Challan_Number})
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        variant="contained"
                        size="small"
                        onClick={backToLoadingsList}
                        className={classes["add-new-entry"]}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Loadings
                    </UIButton>
                </Grid>
            </Grid>

            <div className={classes["agency-details-margin"]}>
                <Form
                    layout="vertical"
                    form={loadingForm}
                    onFinish={putloadData}
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item
                                messageVariables=""
                                label="Challan No."
                                name="Challan_Number"
                                rules={[
                                    { required: true, message: "Please enter the Challan No." },
                                ]}
                            >
                                <Input
                                    type="number"
                                    onChange={handleInputChange}
                                    disabled={challanNumberDisabled}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Vehicle Number" name="Vehicle_Number"
                                rules={[
                                    { required: true, message: "Please enter the Vehicle No." },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    style={{ width: "100%" }}
                                    onChange={loadVehicleDetails}
                                    placeholder="Select"
                                    open={vehicleDropDownOpen}
                                    onDropdownVisibleChange={(visible) => setVehicleDropDownOpen(visible)}
                                    dropdownRender={(menu) => (
                                        <>
                                            {menu}
                                            <Divider
                                                style={{
                                                    margin: '8px 0',
                                                }}
                                            />
                                            <Space
                                                style={{
                                                    padding: '0 8px 4px',
                                                }}
                                            >
                                                <UIButton type="text" icon={<PlusOutlined />} className={classes["add-new-vehicle-dropdown"]} onClick={handleCreateVehiclePopup}>
                                                    Add New Vehicle Number
                                                </UIButton>
                                            </Space>
                                        </>
                                    )}
                                    options={vehicleDropDown}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Loading Agency" name="Loading_Agency"
                                rules={[
                                    { required: true, message: "Please enter the Loading Agency" },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={loadingAgencyDropDown}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Transporting Agency" name="Transporter_Agency"
                                rules={[
                                    { required: true, message: "Please enter the Transporting Agency" },
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={transportationAgencyDropDown}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Net Weight at Loading (Kg)" name="Net_Weight">
                                <Input
                                    onChange={handleInputChange}
                                    id="Net_Weight"
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    type="number"
                                    readOnly={false}
                                    step="any"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Gross Weight at Loading (Kg)" name="Gross_Weight">
                                <Input
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    type="number"
                                    onChange={handleInputChange}
                                    readOnly={true}
                                    step="any"
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>

                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Date of Loading" name="Date_of_Loading"
                                rules={[
                                    { required: true, message: "Please select Date of Loading" },
                                ]}>
                                <DatePicker style={{ width: "100%" }} disabledDate={disableDates} picker="date" format={dateFormat} />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Owner" name="Owner">
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={true}
                                    disabled
                                    className={classes['disabled-input']}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Thermal Plant Book No." name="Thermal_Plant_Book_no"
                                rules={[
                                    { required: true, message: "Please enter the Thermal Plant Book No." },
                                ]}>
                                <Input
                                    onKeyDown={handleKeyDown}
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Kanta Slip No. at Loading" name="Kanta_Slip_No">
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Tare Weight at Loading (Kg)" name="Tare_Weight">
                                <Input
                                    onKeyDown={AppHelper.preventMinus}
                                    min={0}
                                    type="number"
                                    onChange={handleInputChange}
                                    readOnly={false}
                                    step="any"
                                />
                            </Form.Item>
                            <Form.Item
                                name="DO_No"
                                label="D/O No."
                                wrapperCol={{ span: 24 }}
                                className={classes['ant-form-item']}
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the D/O Number"
                                    },
                                    {
                                        validator: async (_, value) => {
                                            if (!value) return;
                                            if (/^[a-zA-Z0-9]+$/.test(value)) {
                                                return Promise.resolve();
                                            } else {
                                                return Promise.reject(new Error(`D/O No must be alphanumeric only`));
                                            }
                                        },
                                    }
                                ]}
                            >
                                <Input onChange={handleInputChange} />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Employee at Loading Point" name="Loading_by_Employee">
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Remarks" name="Remarks">
                                <Input
                                    onChange={handleInputChange}
                                    readOnly={false}
                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>

                            {loadingForm.getFieldValue('Cancelled') && <Form.Item
                                messageVariables=""
                                label="Reason for Cancellation"
                                name="Reason_For_Cancellation"
                                rules={[
                                    { required: true, message: "Reason cannot be empty" },
                                ]}
                            >
                                <TextArea
                                    rows={4}
                                    maxLength={4000}
                                    // readOnly={false}
                                    value={reasonForCancellation}
                                    name="Reason_For_Cancellation"
                                    onChange={(e) => setReasonForCancellation(e.target.value)}
                                />
                            </Form.Item>}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <Upload
                                customRequest={customRequest}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChange}
                                name="Unloading_Images"
                                withCredentials={true}
                                beforeUpload={beforeUpload}
                                accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                width={800}
                                styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                                onCancel={handleCancel}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes["grid-position"]}
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            {
                                loadDetails?.attributes?.Created_By?.data?.id
                                    ?
                                    loadDetails?.attributes?.Created_By?.data?.id !== sessionUserInfo?.userID
                                        ?
                                        loadDetails?.attributes?.Reviewed_By?.data?.id
                                            ?
                                            <Form.Item name="Reviewed_By">
                                                <Checkbox value={true} checked disabled>
                                                    Review Done?
                                                </Checkbox>
                                            </Form.Item>
                                            :
                                            <Form.Item name="Reviewed_By">
                                                <Checkbox value={true} onChange={onCheckboxChange}>
                                                    Review Done?
                                                </Checkbox>
                                            </Form.Item>
                                        :
                                        ""
                                    :
                                    ""
                            }
                        </Grid>
                    </Grid>
                    <div className={classes["submit-button-area"]}>
                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                            Save
                        </UIButton>
                        <UIButton
                            variant="outlined"
                            color="error"
                            onClick={backToLoadingsList}
                        >
                            Cancel
                        </UIButton>
                    </div>
                </Form>
            </div>

            {
                newVehicleRegisterPopOpen
                    ?
                    <Modal
                        centered
                        open={newVehicleRegisterPopOpen}
                        onCancel={handleModelCancel}
                        width={'50%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Create New Vehicle</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <Form layout="vertical" form={vehicleForm} onFinish={onFinishCreateVehicle}>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="wrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={6} md={6} xs={12} position="relative">
                                            <Form.Item
                                                label="Vehicle Number"
                                                name="Vehicle_Number"
                                                rules={[
                                                    {
                                                        required: true, message: "Please enter the vehicle number"
                                                    },
                                                    {
                                                        pattern: new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/i),
                                                        message: "Please enter valid vehicle number"
                                                    }
                                                ]}
                                            >
                                                <Input className={classes['width88']} onChange={handleVehicleChange} />
                                            </Form.Item>
                                            <div className={classes["input-field-challan-button-validate"]}>
                                                <Typography className={classes["stop-missing-icon"]} onClick={validateVehicleNumber}>Validate</Typography>
                                            </div>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item
                                                label="Owner"
                                                name="Owner"
                                                rules={[
                                                    {
                                                        required: true, message: "Please enter the Owner Name"
                                                    }
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="wrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item name="No_of_Tyres" label="No of Tyres"
                                                rules={[
                                                    {
                                                        pattern: new RegExp(/^[0-9]+$/),
                                                        message: "No of Tyres should be integer only"
                                                    }
                                                ]}>
                                                <Input type="number" min={0} max={20} />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={12}>
                                            <Form.Item name="Remarks" label="Remarks">
                                                <Input type="text" />
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        alignItems="center"
                                        alignContent="center"
                                        wrap="nowrap"
                                        className={classes["grid-space"]}
                                    >
                                        <Grid item lg={12} md={12} xs={12}>
                                            <Form.Item
                                                messageVariables=""
                                                wrapperCol={{ span: 30 }}
                                                labelCol={{ span: 30 }}
                                                className={classes['ant-form-item-radio']}
                                            >
                                                <Checkbox value={true} onChange={onChangeCheckbox}>Forcefully Validated</Checkbox>
                                            </Form.Item>
                                        </Grid>
                                    </Grid>
                                    <div className={classes["submit-button-area"]}>
                                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingCreateVehicleResponse}>
                                            Save
                                        </UIButton>
                                        <UIButton
                                            variant="outlined"
                                            color="error"
                                            onClick={handleModelCancel}
                                        >
                                            Cancel
                                        </UIButton>
                                    </div>
                                </Form >
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }

            <Modal open={isVehicleInfoModelOpen} footer={false} onCancel={handleCancelVehicleModelOpen} centered className={classes["model-missing-challan"]} width={1000}>
                {
                    Object.keys(vehicleFetchInfo)?.length > 0
                        ?
                        <>
                            <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>Vehicle Details</Typography>
                            <div className={classes["missing-tags"]} style={{ height: 400, overflowY: 'scroll' }}>
                                {
                                    Object.entries(vehicleFetchInfo).filter(item => item?.[0] !== 'splitPresentAddress' && item?.[0] !== 'splitPermanentAddress').map(([value, key], i) => {
                                        return <>
                                            <div className={classes['vehicle-info-row']} style={{ width: constantsArr.LONG_LABELS_VEHICLE_INFO_TABLE.includes(value) ? '100%' : '49%' }}>
                                                <span className={classes['label']}>
                                                    {AppHelper.convertStringToLabel(value)}:
                                                </span>
                                                {(!key || key === '') ? 'NA' : key}
                                            </div>
                                            {++i % 2 === 0 ? <div className={classes['clear-both']}></div> : ""}
                                        </>
                                    })
                                }
                            </div>

                            <div className={classes["flex-items-missing"]}>
                                <Typography variant="body2" color="initial" className={classes["description-missing-challan"]}>

                                </Typography>
                            </div>
                        </>
                        :
                        <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>No Vehicle information is available</Typography>
                }
            </Modal>
        </>
    );
};
