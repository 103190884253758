import React, { useEffect, useState } from "react";
import { Checkbox, notification, Spin, Table } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import classes from "./UnloadingExcavatorBilled.module.scss";
import { Button, Grid } from "@mui/material";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'
import constantsArr from "../../../../../../constants/global-constants";
import AppHelper from "../../../../../../helpers/AppHelper";
import dayjs from "dayjs";

const columnsUnBilled = [
    {
        title: "S. no.",
        dataIndex: "s_no",
    },
    {
        title: "Bill Amount",
        dataIndex: "bill_amount",
    },
    {
        title: "GST",
        dataIndex: "gst",
    },
    {
        title: "Total",
        dataIndex: "total",
    },
    {
        title: "HSD Deduction Amount",
        dataIndex: "hsd_deduction_amount",
    },
    {
        title: "TDS Deduction",
        dataIndex: "tds_deduction",
    },
    {
        title: "Other Deduction",
        dataIndex: "other_deduction",
    },
    {
        title: "Unbilled Payable",
        dataIndex: "unbilled_payable",
    },
];

export const UnloadingExcavatorBilled = (props) => {

    const [selectedRowKeys, setSelectedRowKeysUnloading] = useState([]);
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT];
    const [showLoadingBar, setShowLoadingBar] = useState(true)
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const [withHeldGSTRecordIdsUnloading, setWithHeldGSTRecordIdsUnloading] = useState([])
    const [tableDataUnLoading, setTableDataUnLoading] = useState(false)
    const [unbilledTableDataUnLoading, setTableUnBilledDataUnloading] = useState(false)

    const columnsBilledUnloading = [
        {
            title: "Hard Copy Receive",
            dataIndex: "hard_copy_receive",
            render: (text, record) => (
                <div>
                    <CopyOutlined className={classes["copy-icon"]} />
                </div>
            ),
            width: "10%",
        },
        {
            title: "S. No.",
            dataIndex: "s_no",
            width: "8%",
        },
        {
            title: "Bill Date",
            dataIndex: "bill_date",
            width: "11%",
        },
        {
            title: "Last Date Of Entry",
            dataIndex: "last_date_of_entry",
            width: "12%",
        },
        {
            title: "Bill No. Prefix",
            dataIndex: "bill_no_prefix",
            width: "12%",
        },
        {
            title: "Bill Amount",
            dataIndex: "bill_amount",
            width: "10%",
        },
        {
            title: "GST",
            dataIndex: "gst",
            width: "10%",
        },
        {
            title: "Total",
            dataIndex: "total",
            width: "12%",
        },
        {
            title: "HSD Deduction Amount",
            dataIndex: "hsd_deduction_amount",
            width: "15%",
        },
        {
            title: "TDS Deduction",
            dataIndex: "tds_deduction",
            width: "12%",
        },
        {
            title: "Other Deduction",
            dataIndex: "other_deduction",
            width: "12%",
        },
        {
            title: "Billed Payable",
            dataIndex: "bill_balance",
            width: "12%",
        },
        {
            title: "With Held Gst",
            dataIndex: "with_held_gst",
            render: (text, record) => (
                <div>
                    <Checkbox checked={checkWithHeldGSTTrueToRecordCheckboxUnloading(record?.key)} onChange={(e) => selectRowsWithHeldGSTUnloading(record?.key, e.target.checked)} /> &nbsp; &#x20b9; {text}
                </div>
            ),
            width: "20%",
        },
    ];

    const checkWithHeldGSTTrueToRecordCheckboxUnloading = (recordId) => {
        return withHeldGSTRecordIdsUnloading?.includes(recordId)
    }

    const saveUnloadingBill = () => {
        saveHardCopyReceivedUnloading()
        saveHeldWithGSTUnloading()
        notification.success({
            message: 'Success',
            description: 'Selected records status has been updated successfully',
            duration: 3
        })
    }

    const addWidgetInfoToExport = (workBook) => {
        const workSheetBilledDetailAllBills = workBook.addWorksheet("All Bills")
        const workSheetBilledDetailHardCopyReceived = workBook.addWorksheet("Hard Copy Received")
        const workSheetBilledDetailPendingHardCopy = workBook.addWorksheet("Pending Hard Copy")
        const workSheetBilledDetailWithHeldGST = workBook.addWorksheet("With Held GST")

        // prepare sheet columns / headers
        workSheetBilledDetailAllBills.columns = workSheetBilledDetailHardCopyReceived.columns = workSheetBilledDetailPendingHardCopy.columns = workSheetBilledDetailWithHeldGST.columns = [
            { header: 'Bill Amount', key: "first_value", width: 25 },
            { header: props?.widgetContentInfoAllBills?.data?.attributes?.sumBillAmount ?? 0, key: "second_value", width: 20 },
        ]

        // prepare sheet rows to display
        workSheetBilledDetailAllBills.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "GST", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumGST ?? 0 ?? '-' }
        )



        workSheetBilledDetailAllBills.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Total", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumTotal ?? 0 ?? '-' }
        )




        workSheetBilledDetailAllBills.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })
        workSheetBilledDetailHardCopyReceived.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })
        workSheetBilledDetailPendingHardCopy.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })
        workSheetBilledDetailWithHeldGST.addRow({
            first_value: "Hsd Deduction", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumHSDAmount ?? 0 ?? '-'
        })





        workSheetBilledDetailAllBills.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Tds Deduction", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumTdsAmount ?? 0 ?? 0 }
        )





        workSheetBilledDetailAllBills.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Other Deduction", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumOtherDeductionAmount ?? 0 ?? 0 }
        )





        workSheetBilledDetailAllBills.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Billed Payable", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumTotalPayableAmount ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "With Held GST", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumWithHeldAmount ?? 0 ?? 0 }
        )



        workSheetBilledDetailAllBills.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Already Paid", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.sumAlreadyPaidAmount ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Billed Balance", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.totalBilledBalance ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Unbilled Balance", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.totalUnBilledBalance ?? 0 ?? 0 }
        )




        workSheetBilledDetailAllBills.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoAllBills?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailHardCopyReceived.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoHardCopyReceived?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailPendingHardCopy.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoPendingHardCopy?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )
        workSheetBilledDetailWithHeldGST.addRow(
            { first_value: "Eff Balance", second_value: props?.widgetContentInfoWithHeldGST?.data?.attributes?.totalEffectiveBalance ?? 0 ?? 0 }
        )

    }

    const getTableDataUnloadingBilled = (records) => {

        const data = [];

        for (let i = 0; i < records?.data?.attributes?.results?.length; i++) {

            const element = records?.data?.attributes?.results[i];

            let billedBalance

            if (element?.with_held_gst_payment) {
                billedBalance = parseFloat(element?.work_amount)
            } else {
                billedBalance = parseFloat(element?.work_amount) + parseFloat(element?.gst)
            }

            billedBalance = ((parseFloat(billedBalance) - ((parseFloat(element?.hsd_amount) + parseFloat(element?.less_tds) + parseFloat(element?.other_deduction)))))

            data.push({
                key: element?.id,
                hard_copy_receive: "",
                s_no: ((parseInt(currentPage) === 1) ? i + 1 : (((parseInt(i) + 1) + ((parseInt(currentPage) - 1)) * parseInt(pageSize)))),
                bill_date: dayjs(element?.date_of_billing).format(dateFormat),
                bill_no_prefix: element?.billing_prefix,
                last_date_of_entry: dayjs(element?.lastDateOfEntry).format(dateFormat),
                bill_amount: element?.work_amount.toLocaleString('en-in'),
                gst: element?.gst.toLocaleString('en-in') ?? 0,
                total: (parseFloat(element?.work_amount) + parseFloat(element?.gst)).toLocaleString('en-in'),
                hsd_deduction_amount: parseFloat(element?.hsd_amount).toLocaleString('en-in'),
                tds_deduction: parseFloat(element?.less_tds).toLocaleString('en-in'),
                other_deduction: parseFloat(element?.other_deduction).toLocaleString('en-in'),
                bill_balance: parseFloat(billedBalance).toLocaleString('en-in'),
                with_held_gst: parseFloat(element?.gst).toLocaleString('en-in'),
            });
        }

        return data
    };

    const onSelectChangeCheckboxesUnLoading = (newSelectedRowKeysLoading) => setSelectedRowKeysUnloading(newSelectedRowKeysLoading);

    const rowSelectionUnloading = {
        selectedRowKeys,
        onChange: onSelectChangeCheckboxesUnLoading,
        fixed: "left",
        preserveSelectedRowKeys: true
    };

    const getRecordIdsInBillToAutoSelectForWithHeldGSTHardCopyRecievedUnloading = (loadings) => {
        const recordIdsForWithHeldGSTSelected = loadings?.length > 0 ? loadings?.filter(item => item?.with_held_gst_payment === true).map(item => item?.id) : []
        setWithHeldGSTRecordIdsUnloading(recordIdsForWithHeldGSTSelected)

        const recordIdsForHardCopyReceivedSelected = loadings?.length > 0 ? loadings?.filter(item => item?.hard_copy_received_payment === true).map(item => item?.id) : []
        setSelectedRowKeysUnloading(recordIdsForHardCopyReceivedSelected)
    }

    const getBilledRecordsUnloading = (pagination, currentPage, pageSize, agencyType, agencyId) => {
        setTableDataUnLoading([])
        AppHelper.getBilledRecordsBilledDetails(pagination, currentPage, pageSize, agencyType, agencyId).then(response => {
            const getTableDataContents = getTableDataUnloadingBilled(response)
            setTableDataUnLoading(getTableDataContents)
        }).catch(err => {
            console.log(err)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const selectRowsWithHeldGSTUnloading = (recordId, checked = false) => {
        if (checked) {
            setWithHeldGSTRecordIdsUnloading([...withHeldGSTRecordIdsUnloading, recordId])
        } else {
            const currentwithHeldGSTArr = [...withHeldGSTRecordIdsUnloading]
            const recordIndex = currentwithHeldGSTArr.indexOf(recordId)
            currentwithHeldGSTArr.splice(recordIndex, 1)
            setWithHeldGSTRecordIdsUnloading(currentwithHeldGSTArr)
        }
    }

    const saveHeldWithGSTUnloading = () => {
        AppHelper.saveWithHeldGST(withHeldGSTRecordIdsUnloading, 'unloading', props?.currentSelectedAgencyId).then(response => {
            getBilledRecordsUnloading(true, 1, exportPageSize, 'unloading', props?.currentSelectedAgencyId)
            props?.fetchWidgetContentInfoWithHeldGST()
        }).catch(err => {
            notification.error({
                message: 'Error',
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const saveHardCopyReceivedUnloading = () => {
        AppHelper.saveHardCopyReceivedTransport(selectedRowKeys, 'unloading', props?.currentSelectedAgencyId).then(response => {
            props?.fetchWidgetContentInfoAllBills()
            props?.fetchWidgetContentInfoHardCopyReceived()
            props?.fetchWidgetContentInfoPendingHardCopy()
        }).catch(err => {
            notification.error({
                message: 'Error',
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const getTableDataUnBilledExcavator = (records) => {
        let data = []

        if (records?.data?.attributes?.totalBilledAmount > 0 || records?.data?.attributes?.excavatorDeductionAmount > 0) {
            data = [{
                key: 1,
                bill_amount: records?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                s_no: 1,
                gst: '-',
                total: records?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                hsd_deduction_amount: records?.data?.attributes?.excavatorDeductionAmount.toLocaleString('en-in') ?? 0,
                tds_deduction: '-',
                other_deduction: '-',
                unbilled_payable: (parseFloat(records?.data?.attributes?.totalBilledAmount) - parseFloat(records?.data?.attributes?.excavatorDeductionAmount)).toLocaleString('en-in')
            }];
        }

        return data
    };


    const getUnBilledRecordsUnloading = (agencyType, agencyId) => {
        AppHelper.getUnBilledRecordsBilledDetails(agencyType, agencyId).then(response => {
            setShowLoadingBar(false)
            const tableContent = getTableDataUnBilledExcavator(response)
            setTableUnBilledDataUnloading(tableContent)
        }).catch(err => {
            console.log(err)
            setShowLoadingBar(false)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const exportPaymentSummaryUnloadingExcavator = async () => {

        setIsExportButtonDisabled(true)

        const exportResponse = await AppHelper.getBilledRecordsBilledDetails(true, currentPage, exportPageSize, 'unloading', props?.currentSelectedAgencyId)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Unloading Excavator Billed", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: "S. No.", key: "s_no", width: 10 },
                { header: "Bill Date", key: "bill_date", width: 15 },
                { header: "Last Date Of Entry", key: "last_date_of_entry", width: 25 },
                { header: "Bill No. Prefix", key: "bill_no_prefix", width: 20 },
                { header: "Bill Amount", key: "bill_amount", width: 15 },
                { header: "GST", key: "gst", width: 10 },
                { header: "Total", key: "total", width: 12 },
                { header: "HSD Deduction Amount", key: "hsd_deduction_amount", width: 25 },
                { header: "TDS Deduction", key: "tds_deduction", width: 20 },
                { header: "Other Deduction", key: "other_deduction", width: 20 },
                { header: "Billed Balance", key: "bill_balance", width: 15 },
                { header: "With Held GST", key: "with_held_gst", width: 15 }
            ]

            for (let i = 0; i < exportResponse?.data?.attributes?.results?.length; i++) {

                const element = exportResponse?.data?.attributes?.results[i];

                let billedBalance = parseFloat(element?.total_payable_amount)

                // subtract gst from bill balance if withheld gst is appied to bill record
                const withHeldGSTAmountRecordExists = withHeldGSTRecordIdsUnloading?.includes(element?.id)

                if (withHeldGSTAmountRecordExists)
                    billedBalance = billedBalance - parseFloat(element?.gst)

                let exportData = {}
                exportData.key = element?.id
                exportData.s_no = i + 1
                exportData.hard_copy_receive = ""
                exportData.bill_date = dayjs(element?.date_of_billing).format(dateFormat)
                exportData.bill_no_prefix = element?.billing_prefix
                exportData.last_date_of_entry = dayjs(element?.lastDateOfEntry).format(dateFormat)
                exportData.bill_amount = element?.work_amount
                exportData.gst = element?.gst ?? 0
                exportData.total = (parseFloat(element?.work_amount) + parseFloat(element?.gst))
                exportData.hsd_deduction_amount = parseFloat(element?.hsd_amount)
                exportData.tds_deduction = parseFloat(element?.less_tds)
                exportData.other_deduction = parseFloat(element?.other_deduction)
                exportData.bill_balance = parseFloat(billedBalance)
                exportData.with_held_gst = element?.with_held_gst_payment ? 'Yes' : 'No'
                workSheetPendingBills.addRow(exportData)
            }

            // preapare sheet for global counts
            const workSheetBilledDetailUnBilled = workBook.addWorksheet("Unoading Excavator Unbilled")
            workSheetBilledDetailUnBilled.columns = [
                { header: "S. no.", key: "s_no", width: 10 },
                { header: "Bill Amount", key: "bill_amount", width: 25 },
                { header: "GST", key: "gst", width: 25 },
                { header: "Total", key: "total", width: 25 },
                { header: "HSD Deduction Amount", key: "hsd_deduction_amount", width: 25 },
                { header: "TDS Deduction", key: "tds_deduction", width: 25 },
                { header: "Other Deduction", key: "other_deduction", width: 25 },
                { header: "Unbilled Payable", key: "unbilled_payable", width: 25 },
            ]

            const exportResponseUnBilled = await AppHelper.getUnBilledRecordsBilledDetails('unloading', props?.currentSelectedAgencyId)
            if (exportResponseUnBilled?.data?.attributes?.totalBilledAmount > 0 || exportResponseUnBilled?.data?.attributes?.hsdDeductionAmount > 0 || exportResponseUnBilled?.data?.attributes?.excavatorDeductionAmount > 0) {
                workSheetBilledDetailUnBilled.addRow({
                    key: 1,
                    bill_amount: exportResponseUnBilled?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                    s_no: 1,
                    gst: '-',
                    total: exportResponseUnBilled?.data?.attributes?.totalBilledAmount.toLocaleString('en-in') ?? 0,
                    hsd_deduction_amount: exportResponseUnBilled?.data?.attributes?.excavatorDeductionAmount.toLocaleString('en-in') ?? 0,
                    tds_deduction: '-',
                    other_deduction: '-',
                    unbilled_payable: (parseFloat(exportResponseUnBilled?.data?.attributes?.totalBilledAmount) - parseFloat(exportResponseUnBilled?.data?.attributes?.excavatorDeductionAmount)).toLocaleString('en-in')
                })
            }

            addWidgetInfoToExport(workBook)

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `payment_billed_detail_unloading_excavator_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Payment detail unloading excavator data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    useEffect(() => {
        if (props?.currentSelectedAgencyId) {
            getUnBilledRecordsUnloading('unloading', props?.currentSelectedAgencyId)
            getRecordIdsInBillToAutoSelectForWithHeldGSTHardCopyRecievedUnloading(props?.unloadingbilledRecordData?.data?.attributes?.results)
            const getTableDataContents = getTableDataUnloadingBilled(props?.unloadingbilledRecordData ?? [])
            setTableDataUnLoading(getTableDataContents)
        }
    }, [props?.currentSelectedAgencyId])


    return (
        <>
            {
                props?.currentSelectedAgencyId ?
                    props?.transporterActiveTab === "billed" ? (
                        <>
                            {
                                showLoadingBar === true ?
                                    <div className={classes['summary-table-spin-element']}><Spin /></div>
                                    :
                                    <div className={classes["loading-bar"]}>
                                        <Table
                                            columns={columnsBilledUnloading}
                                            dataSource={tableDataUnLoading}
                                            bordered={true}
                                            scroll={{ x: 2000 }}
                                            pagination={{
                                                position: ['bottomCenter']
                                            }}
                                            rowSelection={rowSelectionUnloading}
                                            rowKey={(record) => record?.key}
                                        />
                                    </div>
                            }
                        </>
                    ) : (
                        <>
                            {
                                showLoadingBar === true ?
                                    <div className={classes['summary-table-spin-element']}><Spin /></div>
                                    :
                                    <div className={classes["loading-bar"]}>
                                        <Table
                                            columns={columnsUnBilled}
                                            dataSource={unbilledTableDataUnLoading}
                                            bordered={true}
                                            scroll={{ x: 1500 }}
                                            pagination={false}
                                        />
                                    </div>
                            }
                        </>
                    )
                    :
                    ""
            }
            <Grid className={classes["export-submit-button"]}>
                <Button
                    variant="outlined"
                    className={`${classes["filter-new-entry"]} ${classes["export-button"]} ${isExportButtonDisabled ? classes['button-disabled'] : ''}`}
                    onClick={exportPaymentSummaryUnloadingExcavator}
                    disabled={isExportButtonDisabled}>
                    Export
                </Button>
                <Button variant="contained" className={classes["submit-button"]} onClick={saveUnloadingBill}>
                    Save
                </Button>
            </Grid>
        </>
    );
};
