import React, { useEffect, useState } from "react";
import classes from "./Summary.module.scss";
import { Box, Grid, Typography } from "@mui/material";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { LoadingSummary } from "./LoadingSummary/LoadingSummary";
import { UnloadingSummary } from "./UnloadingSummary/UnloadingSummary";
import { TransportationSummary } from "./TransportationSummary/TransportationSummary";
import AppHelper from "../../../helpers/AppHelper";

const TabElementData = (props) => {
    const { value, children, index, ...other } = props
    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className='agency-tab-content'
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        <Box sx={{ pt: 3 }}>
            {children}
        </Box>
    </div>
}

export const Summary = () => {

    const [tabsValue, setTabsValue] = useState(0)
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));

    const onTabChange = (e, newVal) => {
        setTabsValue(newVal)
    }

    useEffect(() => {
        document.title = "Summary"
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["summary-padding"]}
            >
                <Typography variant="h5" color="initial">
                    Transaction Summary
                </Typography>
            </Grid>
            <Grid className={classes["summary-padding-left-right"]}>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs TabIndicatorProps={{ style: { backgroundColor: 'rgb(68, 76, 247)' } }} value={tabsValue} aria-label="basic tabs example" onChange={onTabChange}>
                            <Tab key="loading" label="Loading" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                '&.Mui-selected': {
                                    color: 'rgb(68, 76, 247)',
                                }
                            }} />
                            <Tab key="unloading" label="Unloading" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                '&.Mui-selected': {
                                    color: 'rgb(68, 76, 247)',
                                }
                            }} />
                            <Tab key="transportation" label="Transporting" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                '&.Mui-selected': {
                                    color: 'rgb(68, 76, 247)',
                                }
                            }} />
                        </Tabs>
                    </Box>
                </Box>
                {
                    tabsValue === 0
                        ?
                        <TabElementData value={tabsValue} index={0} key="loading-details">
                            <LoadingSummary tabsValue={tabsValue} sessionGlobalValues={sessionGlobalValues} />
                        </TabElementData>
                        :
                        ""
                }
                {
                    tabsValue === 1
                        ?
                        <TabElementData value={tabsValue} index={1} key="unloading-details">
                            <UnloadingSummary tabsValue={tabsValue} sessionGlobalValues={sessionGlobalValues} />
                        </TabElementData> :
                        ""
                }
                {
                    tabsValue === 2
                        ?
                        <TabElementData value={tabsValue} index={2} key="transporting-details">
                            <TransportationSummary tabsValue={tabsValue} sessionGlobalValues={sessionGlobalValues} />
                        </TabElementData> :
                        ""
                }
            </Grid>
        </>
    );
};
