import { Grid, Typography } from "@mui/material"
import totalCountImg from "../../../../assets/images/HeaderIcons/totalcount.svg"
import activeVehicleImg from "../../../../assets/images/HeaderIcons/activevehicle.svg";
import inActiveVehicleImg from "../../../../assets/images/HeaderIcons/inactivevehicle.svg";
import holdVehicleImg from "../../../../assets/images/HeaderIcons/holdvehicle.svg";
import { Tooltip } from "antd";
import AppHelper from "../../../../helpers/AppHelper";

export const TotalWidget = ({ grandTotal, billedTotal, unbilledTotal, summaryGrandTotalTableData, totalNetWeightAllAgencies, classes }) => {
    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                marginTop="20px"
            >
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    spacing={1.5}
                    padding="10px 0"
                    className={classes["header-scroll-horizontal"]}
                >
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-first"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={totalCountImg}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={grandTotal.toLocaleString('en-in')}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(grandTotal).toLocaleString('en-in')}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Grand Total
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={activeVehicleImg}
                                        alt="active vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={billedTotal.toLocaleString('en-in')}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(billedTotal).toLocaleString('en-in')}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Billed
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-third"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={inActiveVehicleImg}
                                        alt="In active vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Tooltip title={unbilledTotal.toLocaleString('en-in')}>
                                        <Typography
                                            variant="body"
                                            color="white"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            {AppHelper.formatNumber(unbilledTotal).toLocaleString('en-in')}
                                        </Typography>
                                    </Tooltip>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Unbilled
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={holdVehicleImg}
                                        alt="Hold vehicle"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {!isFinite(parseFloat(((summaryGrandTotalTableData[0]?.unloaded_wt / totalNetWeightAllAgencies) * 100)).toFixed(2)) ? 0 : parseFloat(((summaryGrandTotalTableData[0]?.unloaded_wt / totalNetWeightAllAgencies) * 100).toFixed(2))}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Work Percent
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};