import React from "react";
import ReactApexChart from "react-apexcharts";
import classes from "../TripDaysGraph/TripDaysGraph.module.scss";

export const AvgWTDaysGraph = ({ avg15DaysTripWeightLineChart }) => {

    const seriesArray = []

    if (avg15DaysTripWeightLineChart?.WithoutAgency?.avgWeightRecordsWithoutAgency?.length > 0) {
        seriesArray.push({
            data: avg15DaysTripWeightLineChart?.WithoutAgency?.avgWeightRecordsWithoutAgency.map((item) => { return item?.value ?? 0 })
        })
    }

    if (avg15DaysTripWeightLineChart?.withAgency?.avgWeightRecordsWithAgency?.length > 0) {
        seriesArray.push({
            data: avg15DaysTripWeightLineChart?.withAgency?.avgWeightRecordsWithAgency.map((item) => { return item?.value ?? 0 })
        })
    }

    let colors = []

    if (seriesArray?.length > 1) {
        colors = ["#E1E1E1", "#2CAFFE"]
    } else {
        colors = ["#2CAFFE"]
    }

    const options7 = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: "smooth"
        },
        grid: {
            borderColor: "#efefef",
            row: {
                colors: ["#ffffff", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.2,
            },
        },
        markers: {
            size: 1,
        },
        xaxis: {
            categories: avg15DaysTripWeightLineChart?.WithoutAgency?.avgWeightRecordsWithoutAgency?.length > 0 ? avg15DaysTripWeightLineChart?.WithoutAgency?.avgWeightRecordsWithoutAgency.map((item) => { return item?.date }) : [],
            title: {
                text: "Month",
            },
        },
        yaxis: {
            title: {
                text: "Average Weight",
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
        },
    };

    const series7 = seriesArray;

    return (
        <>

            {
                avg15DaysTripWeightLineChart?.WithoutAgency?.avgWeightRecordsWithoutAgency?.length > 0
                    ?
                    <ReactApexChart
                        options={options7}
                        series={series7}
                        type="line"
                        height={400}
                    />
                    :
                    <div className={classes['graphArea']}>
                        <div className={classes['spinStyle']}>No Data Found</div>
                    </div>
            }
        </>
    );
};
