import React, { useState, useEffect } from "react";
import { Form, Input, message, notification } from "antd";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./ChangePassword.module.scss";
import AppHelper from "../../helpers/AppHelper";
import { useNavigate } from "react-router-dom";

export const ChangePassword = () => {
  const navigate = useNavigate();
  const [getStaffUsersDetails, setGetStaffUsersDetails] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem('userInfo'))
  const loadId = sessionUserInfo?.userID ?? "";
  const validateOldPassword = (_, value) => {
    if (value && value === sessionUserInfo?.userPassword) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("The old password is incorrect."));
    }
  };

  const getStaffUserByID = async () => {
    const response = await AppHelper.getUserDetailsById(loadId);
    setGetStaffUsersDetails(response?.data);
  };

  const handleInputChangePassword = (e) => {
    setNewPassword(e.target.value);
  };

  const putChangePassword = async () => {
    const newPayload = { ...getStaffUsersDetails?.attributes };
    newPayload.Password = newPassword;
    const response = await AppHelper.updateGetStaffUsers(loadId, { data: newPayload });
    if (response?.error) {
      console.log(response?.error);
      message.error(`Error updating password: ${response?.error}`);
    } else {
      message.success("Staff user's password updated successfully");
      sessionStorage.removeItem("userInfo");
      sessionStorage.removeItem("globalValues");
      setTimeout(() => {
        navigate("/");
      }, 500)
      notification.success({
        message: "Success",
        description: "Please login again with updated password",
      });
    }
  };

  useEffect(() => {
    document.title = "Change Password"
    getStaffUserByID();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
        className={classes["header-change-password-fixed"]}
      >
        <Typography
          variant="h5"
          color="initial"
          fontWeight="bold"
          className={classes["grid-space"]}
        >
          Change Password
        </Typography>
      </Grid>
      <Form layout="vertical" onFinish={putChangePassword} className={classes["header-change-password-fixed"]}>
        <Grid
          container
          spacing={1}
          direction="row"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item lg={6} md={6} xs={12}>
            <Form.Item
              name="OPassword"
              label="Old Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
                {
                  validator: validateOldPassword,
                },
              ]}
              hasFeedback
              className={classes['space-between-rows']}
            >
              <Input.Password />
            </Form.Item>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Form.Item
              name="Password"
              label="New Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
              className={classes['space-between-rows']}
            >
              <Input.Password onChange={handleInputChangePassword} />
            </Form.Item>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["Password"]}
              hasFeedback
              className={classes['space-between-rows']}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("Password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("The new password that you entered do not match!")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Grid>
        </Grid>
        <div className={classes["submit-button-area"]}>
          <UIButton variant="contained" color="primary" type="submit">
            Save
          </UIButton>
        </div>
      </Form>
    </>
  );
};
