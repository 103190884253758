import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./ViewTransactionClientBillingSummary.module.scss";
import billingPageImg from "../../../../assets/images/BillsIcons/billingpage.svg";
import transportGrayImg from "../../../../assets/images/BillsIcons/clientbilllocationtruck.svg";
import { Tabs, Table } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import { notification } from "antd";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";

const { TabPane } = Tabs;

const columns = [
    {
        title: "S.No.",
        dataIndex: "s_no",
        width: "70px",
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: "140px",
    },
    {
        title: "Loading Agency",
        dataIndex: "Loading_Agency",
        width: "170px",
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: "110px",
    },
    {
        title: "Unloading Agency",
        dataIndex: "Unloading_Agency",
        width: "180px",
    },
    {
        title: "Vehicle Number",
        dataIndex: "Vehicle_Number",
        width: "140px",
    },
    {
        title: "Challan No.",
        dataIndex: "Challan_Number",
        width: "120px",
    },
    {
        title: "D/O No.",
        dataIndex: "DO_No",
        width: "110px",
    },
    {
        title: "HSD LTR",
        dataIndex: "HSD_LTR",
        width: "110px",
    },
    {
        title: "Transporter Agency",
        dataIndex: "Transporter_Agency",
        width: "180px",
    },
    {
        title: "Thermal Plant Book No.",
        dataIndex: "Thernal_Plant_Book_No",
        width: "110px",
    },
    {
        title: "Net weight (UL) (MT)",
        dataIndex: "Net_Weight",
        width: "110px",
    },
    {
        title: "Gross weight (MT)",
        dataIndex: "Gross_weight",
        width: "130px",
    },
    {
        title: "Tare weight (MT)",
        dataIndex: "Tare_weight",
        width: "110px",
    },
    {
        title: "Kanta Slip No. UL",
        dataIndex: "Kanta_slip_no",
        width: "110px",
    },
    {
        title: "Unloading Location",
        dataIndex: "Unloading_location",
        width: "170px",
    },
];

export const ViewTransactionClientBillingSummary = () => {
    const navigate = useNavigate();
    const [billDetails, setBillDetails] = useState({})
    const [vehicleList, setVehicleList] = useState([])
    const params = useParams();
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const redirectBackToAllGeneratedBill = () => {
        navigate("/bills/client-bill/generated-client-bill");
    };

    const setTableData = (responseData) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.loading?.id,
                s_no: i + 1,
                Challan_Number: responseData[i]?.loading?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.loading?.Date_of_Loading) ? dayjs(responseData[i]?.loading?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.loading?.Unloading?.Date_of_Unloading) ? dayjs(responseData[i]?.loading?.Unloading?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.loading?.Vehicle_Number?.Vehicle_Number ?? "",
                Kanta_slip_no: responseData[i]?.loading?.Unloading?.Kanta_Slip_No ?? '-',
                Net_Weight: (responseData[i]?.loading?.Unloading?.Net_Weight && responseData[i]?.loading?.Unloading?.Net_Weight !== "") ? parseFloat(responseData[i]?.loading?.Unloading?.Net_Weight / 1000).toFixed(2) : 0,
                Work_Rate: responseData[i]?.loading?.Work_Rate,
                Work_Amount: parseFloat(responseData[i]?.loading?.Work_Amount).toFixed(2) ?? 0,
                DO_No: responseData[i]?.loading?.DO_No,
                HSD_LTR: responseData[i]?.loading?.hsd_detail?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.loading?.hsd_detail?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.loading?.hsd_detail?.HSD_Amt ?? 0,
                Loading_Agency: responseData[i]?.loading?.Loading_Agency ? responseData[i]?.loading?.Loading_Agency?.Company_name : "",
                Unloading_Agency: responseData[i]?.loading?.Unloading?.Unloading_Agency?.Company_name ? responseData[i]?.loading?.Unloading?.Unloading_Agency?.Company_name : "",
                Transporter_Agency: responseData[i]?.loading?.Transporter_Agency?.Company_name ? responseData[i]?.loading?.Transporter_Agency?.Company_name : "",
                Thernal_Plant_Book_No: responseData[i]?.loading?.Unloading?.Thermal_Plant_Book_no ? responseData[i]?.loading?.Unloading?.Thermal_Plant_Book_no : "",
                Gross_weight: ((responseData[i]?.loading?.Unloading?.Gross_Weight && responseData[i]?.loading?.Unloading?.Gross_Weight !== "") ? parseFloat(responseData[i]?.loading?.Unloading?.Gross_Weight / 1000).toFixed(2) : 0),
                Tare_weight: ((responseData[i]?.loading?.Unloading?.Tare_Weight && responseData[i]?.loading?.Unloading?.Tare_Weight !== "") ? parseFloat(responseData[i]?.loading?.Unloading?.Tare_Weight / 1000).toFixed(2) : 0),
                Unloading_location: responseData[i]?.loading?.Unloading?.Unloading_Location ? responseData[i]?.loading?.Unloading?.Unloading_Location : "",
            });
        }

        return pendingBillsTabledata
    }

    useEffect(() => {
        AppHelper.getClientBillDetails(params?.id).then(response => {
            setBillDetails(response)
            const getTableData = setTableData(response?.loading_ids)
            setVehicleList(getTableData)
        }).catch(err => {
            notification.error({
                message: "Unable to get client bill details",
                description: '',
                duration: 3
            })
        })
        document.title = "Client Bills Transaction Summary"
    }, [])
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                display="flex"
                justifyContent="flex-end"
                className={classes["transaction-summary-padding"]}
            >
                <UIButton
                    className={classes["generate-new-entry"]}
                    startIcon={<ArrowLeftOutlined />}
                    onClick={redirectBackToAllGeneratedBill}
                >
                    All Generated Bills
                </UIButton>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["transaction-summary-padding"]}
            >
                <Grid className={classes["transaction-summary-border"]}>
                    <img
                        src={transportGrayImg}
                        alt=""
                        className={classes["transaction-transport-img"]}
                    />
                    <Grid className={classes["first-boundary-transaction"]}>
                        <Typography
                            variant="body1"
                            color="#fff"
                            fontWeight="bold"
                            letterSpacing="1px"
                            fontSize="1.3rem"
                        >
                            {billDetails?.Project?.Name_of_project}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            display="flex"
                            fontSize="1.3rem"
                        >
                            <img src={billingPageImg} alt="Billing Page" /> Bill No.:&nbsp;
                            <strong>{billDetails?.bill_no}</strong>
                        </Typography>
                    </Grid>

                    <Tabs defaultActiveKey="1" className={classes["tab-transaction"]}>
                        <TabPane tab="Transaction Summary" key="1">
                            <Grid
                                className={classes["summary-list-padding"]}
                                container
                                spacing={3}
                            >
                                <Grid item lg={6} md={12} xs={12}>
                                    <Grid
                                        container
                                        spacing={0}
                                        className={classes["work-type-object-first"]}
                                    >
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["normal-para"]}
                                            >
                                                Trip Count :
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                color="#682a01"
                                                fontWeight="bold"
                                                textAlign="center"
                                                textTransform="capitalize"
                                                className={classes["transport-para"]}
                                            >
                                                {billDetails?.trip_count}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                        className={classes["work-type-object-second"]}
                                    >
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["normal-para"]}
                                            >
                                                Start date of Unloading :
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["bold-para"]}
                                            >
                                                {dayjs(billDetails?.unloading_starting_date).format(dateFormat)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                        className={classes["work-type-object-first"]}
                                    >
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["normal-para"]}
                                            >
                                                End date of Unloading :
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["bold-para"]}
                                            >
                                                {dayjs(billDetails?.unloading_end_date).format(dateFormat)}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                        className={classes["work-type-object-first"]}
                                    >
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["normal-para"]}
                                            >
                                                Billing date :
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["bold-para"]}
                                            >
                                                {dayjs(billDetails?.bill_date).format(dateFormat)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item lg={6} md={12} xs={12}>
                                    <Grid
                                        container
                                        spacing={0}
                                        className={classes["work-type-object-third"]}
                                    >
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="h5"
                                                className={classes["normal-para"]}
                                            >
                                                CUM
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography variant="h4" className={classes["bold-para"]}>
                                                {billDetails?.cum}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        container
                                        spacing={0}
                                        className={classes["work-type-object-second"]}
                                    >
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["normal-para"]}
                                            >
                                                Billing Amount :
                                            </Typography>
                                        </Grid>
                                        <Grid item lg={6} md={6} xs={6}>
                                            <Typography
                                                variant="body1"
                                                className={classes["bold-para"]}
                                            >
                                                &#8377; {parseFloat(billDetails?.specificBillAmount ?? 0).toLocaleString('en-IN') ?? 0}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </TabPane>
                        <TabPane tab="Vehicle List" key="2">
                            <Table
                                columns={columns}
                                dataSource={vehicleList}
                                bordered={true}
                                scroll={{ y: 800 }}
                                pagination={{
                                    pageSize: constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT,
                                    showSizeChanger: false
                                }}
                            />
                        </TabPane>
                    </Tabs>
                </Grid>
            </Grid>
        </>
    );
};
