import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, MinusCircleOutlined } from "@ant-design/icons";
import classes from "./EditPetrolPump.module.scss";
import { Card, Form, Input, Space, DatePicker, notification, Modal } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import constantsArr from "../../../../constants/global-constants";

export const EditPetrolPump = () => {
    const navigate = useNavigate();
    const params = useParams();
    const loadId = params?.id ?? "";
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [petrolPumpDetails, setPetrolPumpDetails] = useState("");
    const [formInputFields, setFormInputFields] = useState([])
    const [petrolPumpForm] = Form.useForm()
    const [validationPetrolPumpRates, setValidationPetrolPumpRates] = useState([])
    const [validationModelOpen, setValidationModelOpen] = useState(false)
    const [petrolPumpRateElement, setPetrolPumpRateElement] = useState([
        {
            Start_Date: "",
            Rate: "",
        },
    ]);
    const dateFormat = "DD-MM-YYYY";
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);

    const handleOk = () => setValidationModelOpen(false)

    const validatePetrolPumpRates = (ratesArray) => {
        if (!ratesArray || ratesArray.length === 0) return false

        let errorsArray = []
        for (let index = 0; index < ratesArray.length; index++) {
            if ((!ratesArray[index]['Start_Date'] || ratesArray[index]['Start_Date'] === "") && (!ratesArray[index]['Rate'] || ratesArray[index]['Rate'] === "")) {
                errorsArray.push(`Row no ${index + 1} - Please provide both the start date and rate.`)
            } else if ((!ratesArray[index]['Start_Date'] || ratesArray[index]['Start_Date'] === "") && (ratesArray[index]['Rate'] && ratesArray[index]['Rate'] !== "")) {
                errorsArray.push(`Row no ${index + 1} - Please provide both the start date and rate.`)
            } else if ((ratesArray[index]['Start_Date'] && ratesArray[index]['Start_Date'] !== "") && (!ratesArray[index]['Rate'] || ratesArray[index]['Rate'] === "")) {
                errorsArray.push(`Row no ${index + 1} - Please provide both the start date and rate.`)
            }
        }
        if (errorsArray.length > 0) {
            setValidationModelOpen(true);
            setValidationPetrolPumpRates(errorsArray)
        } else {
            setValidationModelOpen(false);
            setValidationPetrolPumpRates([])
        }
        return errorsArray;
    }

    const backToPetrolPumpList = (e) => {
        e.preventDefault();
        navigate(`/petrol-pumps?currentPage=${pageNo}&pageSize=${pageSize}`);
    };

    const getPetrolPumpDetails = async () => {
        const response = await AppHelper.getPetrolPumpById(loadId);
        setPetrolPumpDetails(response?.data);
        let formFieldsValue = {
            Petrolpump_Name: response?.data?.attributes?.Petrolpump_Name,
            Contact_Number: response?.data?.attributes?.Contact_Number,
            Contact_person: response?.data?.attributes?.Contact_person,
            Location: response?.data?.attributes?.Location,
            Remarks: response?.data?.attributes?.Remarks,
        }
        petrolPumpForm.setFieldsValue(formFieldsValue)
        setFormInputFields(formFieldsValue)
        let rateElementArrayFromDB = response?.data?.attributes?.Rate;
        if (rateElementArrayFromDB.length > 0 && rateElementArrayFromDB[0]?.id !== "") {
            rateElementArrayFromDB = rateElementArrayFromDB.sort((firstItem, secondItem) => new Date(firstItem?.Start_Date) - new Date(secondItem?.Start_Date))
            setPetrolPumpRateElement([...rateElementArrayFromDB]);
            setFormInputFields({ ...formInputFields, 'Rate': rateElementArrayFromDB })
        } else {
            setPetrolPumpRateElement([...petrolPumpRateElement]);
            setFormInputFields({ ...formInputFields, 'Rate': petrolPumpRateElement })
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const checkRateForInputtedDateAlreadyExist = (ratesArray) => {
        if (!ratesArray.length) return false
        let duplicatesArray = []
        for (let index = 0; index < ratesArray.length; index++) {
            if (!duplicatesArray.includes(ratesArray[index]['Start_Date'])) {
                duplicatesArray.push(ratesArray[index]['Start_Date']);
            } else {
                return ratesArray[index]['Start_Date']
            }
        }
        return false
    }

    const preventNegativeValue = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    const updatePetrolPumpDetails = async (values) => {
        setIsSubmittingResponse(true);
        //validate each and every petrol pump rate row and throw error for any invalid data
        const validationRateErrors = validatePetrolPumpRates(formInputFields?.Rate);
        if (validationRateErrors.length === 0) {
            let duplicateRateExistsForDate = checkRateForInputtedDateAlreadyExist(formInputFields?.Rate)
            if (duplicateRateExistsForDate) {
                notification.error({
                    message: "Error",
                    description: `Rate for Start Date ${formatDate(duplicateRateExistsForDate)} already exists`,
                    duration: 3,
                });
                return false
            }

            // find out duplicate dates in rates array and restrict if found
            const findDuplicatesInRates = findDuplicateDateInRatesArray(petrolPumpRateElement)
            if (findDuplicatesInRates) {
                notification.error({
                    message: "Error",
                    description: `Rate for Start Date ${formatDate(findDuplicatesInRates)} already exists`,
                    duration: 3,
                });
                return false
            }
            const updatedPayload = { ...values, "Rate": petrolPumpRateElement }
            const payload = { data: updatedPayload };
            const response = await AppHelper.updatePetrolPump(loadId, payload);
            //await AppHelper.updateExcavatorDataAsOnPetrolPumpRateChange(loadId, payload);
            if (response?.error) {
                notification.error({
                    message: "Error",
                    description: response?.error?.message,
                    duration: 3,
                });
            } else {
                notification.success({
                    message: "Success",
                    description: "Petrol Pump Updated Successfully.",
                    duration: 3,
                });
                navigate(`/petrol-pumps`);
            }
        }
        setIsSubmittingResponse(false);
    };

    const findDuplicateDateInRatesArray = (ratesArray) => {
        if (!ratesArray.length) return false
        let duplicatesArray = []
        for (let index = 0; index < ratesArray.length; index++) {
            if (!duplicatesArray[ratesArray[index]['Start_Date']]) {
                duplicatesArray[ratesArray[index]['Start_Date']] = ratesArray[index]['Rate']
            } else {
                return ratesArray[index]['Start_Date']
            }
        }
    }

    const handleDateChange = (date, dateString, index) => {
        // Splitting the dateString into parts and formatting it as required
        if (!date && !dateString) return false
        const parts = dateString.split('-');
        const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;

        setPetrolPumpRateElement((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = { ...newDetails[index], Start_Date: formattedDate };
            // Update the petrolPumpDetails state with the modified rate details
            setFormInputFields((prevDetails) => {
                const updatedDetails = { ...prevDetails, Rate: newDetails };
                return updatedDetails;
            });
            return newDetails;
        });
    };

    const handleRateChange = (e, index) => {
        const { name, value } = e.target;
        setPetrolPumpRateElement((prevDetails) => {
            const newDetails = [...prevDetails];
            newDetails[index] = {
                ...newDetails[index],
                [name]: value,
            };
            // Update the petrolPumpDetails state with the modified rate details
            setFormInputFields({ ...formInputFields, 'Rate': newDetails });
            return newDetails;
        });
    };

    const addMorePetrolPumpRate = () => {
        setPetrolPumpRateElement([
            ...petrolPumpRateElement,
            {
                Start_Date: "",
                Rate: "",
            },
        ]);
        setFormInputFields({
            ...formInputFields, 'Rate': [...petrolPumpRateElement, {
                Start_Date: "",
                Rate: "",
            }]
        })
    };

    const removePetrolPumpRate = (index) => {
        let currentPetrolPumpRateElement = [...petrolPumpRateElement];
        currentPetrolPumpRateElement.splice(index, 1);
        setPetrolPumpRateElement(currentPetrolPumpRateElement);
        setFormInputFields({ ...formInputFields, 'Rate': currentPetrolPumpRateElement })
    };

    const handleInputChange = (e) => {
        setFormInputFields({ ...formInputFields, [e.target.id]: e.target.value })
    };

    useEffect(() => {
        document.title = "Edit Petrol Pump"
        getPetrolPumpDetails();
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["edit-petrol-pump-padding"]}
            >
                <Grid item lg={9.5} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Edit Petrol Pump Details
                    </Typography>
                </Grid>
                <Grid item lg={2.5} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        variant="contained"
                        size="small"
                        onClick={backToPetrolPumpList}
                        className={classes["add-new-entry"]}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Petrol Pumps
                    </UIButton>
                </Grid>
            </Grid>

            <div className={classes["agency-details-margin"]}>
                <Form
                    onFinish={updatePetrolPumpDetails}
                    form={petrolPumpForm}
                    layout="vertical"
                    initialValues={{
                        items: [{}],
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item messageVariables="" label="Petrol Pump Name" name="Petrolpump_Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Petrol Pump Name.",
                                    }
                                ]}>
                                <Input
                                    readOnly={false}
                                    id="Petrolpump_Name"
                                    onChange={handleInputChange}

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Contact Person" name="Contact_person">
                                <Input
                                    readOnly={false}
                                    id="Contact_person"
                                    onChange={handleInputChange}

                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item
                                messageVariables=""
                                label="Contact Number"
                                name="Contact_Number"
                                rules={[
                                    {
                                        min: 10,
                                        message: "Contact no should be of 10 digits only"
                                    },
                                    {
                                        max: 10,
                                        message: "Contact no should be of 10 digits only"
                                    }
                                ]}>
                                <Input
                                    readOnly={false}
                                    type="number"
                                    id="Contact_Number"
                                    onChange={handleInputChange}

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Location" name="Location"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter the Location.",
                                    }
                                ]}>
                                <Input
                                    readOnly={false}
                                    id="Location"
                                    onChange={handleInputChange}

                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item
                                messageVariables=""
                                label="Remarks"
                                name="Remarks"
                            >
                                <Input
                                    readOnly={false}
                                    id="Remarks"

                                    onChange={handleInputChange}
                                />
                            </Form.Item>
                        </Grid>
                    </Grid>
                    <Grid>
                        <Form.List name="items">
                            {(fields, { add, remove }) => (
                                <div
                                    style={{
                                        display: "flex",
                                        rowGap: 16,
                                        flexDirection: "column",
                                        width: '100%'
                                    }}
                                >
                                    <Card title="Rates">
                                        <Form.Item>
                                            <Form.List name="items" initialValue={[{}]}>
                                                {(fields, { add, remove }) => (
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            rowGap: 16,
                                                            flexDirection: "column",
                                                            padding: 18,
                                                        }}
                                                    >
                                                        <Space style={{ display: "block", width: '100%' }}>
                                                            {petrolPumpRateElement?.map((rate, rateIndex) => (
                                                                <Space key={rateIndex}>
                                                                    <Form.Item
                                                                        label="Start Date">
                                                                        <DatePicker
                                                                            size="large"
                                                                            disabled={petrolPumpDetails?.attributes?.Rate[rateIndex]?.Start_Date ? true : false}
                                                                            readOnly={false}
                                                                            value={rate.Start_Date ? dayjs(rate.Start_Date) : ""}
                                                                            onChange={(date, dateString) =>
                                                                                handleDateChange(
                                                                                    date,
                                                                                    dateString,
                                                                                    rateIndex
                                                                                )
                                                                            }
                                                                            format={dateFormat}
                                                                        />
                                                                    </Form.Item>
                                                                    <Form.Item label="Rate">
                                                                        <Input
                                                                            size="large"
                                                                            readOnly={false}
                                                                            value={rate.Rate ?? ""}
                                                                            name="Rate"
                                                                            min={0}
                                                                            type="number"
                                                                            step={0.01}
                                                                            onKeyPress={preventNegativeValue}
                                                                            onChange={(e) =>
                                                                                handleRateChange(e, rateIndex)
                                                                            }
                                                                        />
                                                                    </Form.Item>
                                                                    {!petrolPumpDetails?.attributes?.Rate[rateIndex]?.Start_Date && (
                                                                        <MinusCircleOutlined
                                                                            onClick={() =>
                                                                                removePetrolPumpRate(rateIndex)
                                                                            }
                                                                        />
                                                                    )}
                                                                </Space>
                                                            ))}
                                                        </Space>
                                                        <UIButton
                                                            onClick={() => addMorePetrolPumpRate()}
                                                            block
                                                        >
                                                            + Add Rate
                                                        </UIButton>
                                                    </div>
                                                )}
                                            </Form.List>
                                        </Form.Item>
                                    </Card>
                                </div>
                            )}
                        </Form.List>
                    </Grid>
                    <Grid>
                        <div className={classes["submit-button-area"]}>
                            <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                                Save
                            </UIButton>
                            <UIButton
                                variant="outlined"
                                color="error"
                                onClick={backToPetrolPumpList}
                            >
                                Cancel
                            </UIButton>
                        </div>
                    </Grid>
                </Form>
            </div>


            {
                validationModelOpen === true &&
                    validationPetrolPumpRates.length > 0
                    ?
                    <Modal
                        open={validationModelOpen}
                        centered
                        className={classes['error-agency-modal']}
                        title=""
                        width={1024}
                        onCancel={handleOk}
                        footer=""
                    >
                        <>
                            <h3 className={classes['error-agency-modal-header']}>Please check below errors in petrol pump</h3>
                            <div className={classes['error-agency-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width="100%" className={classes['agency-errors-table']}>
                                    <thead>
                                        <tr>
                                            <th width="10%" className={classes['agency-errors-table-first-td']}>SL No.</th>
                                            <th width="90%">Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            validationPetrolPumpRates.map((value, key) => {
                                                return <tr key={key}>
                                                    <td className={classes['agency-errors-table-first-td']}>{key + 1}</td>
                                                    <td className={classes['error-message-row']}>{value}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};
