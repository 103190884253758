import React from "react";
import { Grid, Typography } from "@mui/material";
import classes from "./LineWrapper.module.scss";

export const LineWrapper = ({ summaryText }) => {
    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                marginTop="20px"
            >
                <Grid
                    item
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    container
                    spacing={1}
                    className={classes["summary-wrapper"]}
                >
                    {
                        summaryText === 'Grand Total (Billed + Unbilled)'
                            ?
                            <>
                                <Grid item lg={3.2} md={3.3} sm={4.3} xs={12}>
                                    <Typography variant="body1" className={classes["summary-text"]}>
                                        {summaryText}
                                    </Typography>
                                </Grid>
                                <Grid item lg={8.8} md={8.7} sm={7.6} xs={12}>
                                    <Grid className={classes["line1"]}></Grid>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item lg={1} md={1} sm={2} xs={12}>
                                    <Typography variant="body1" className={classes["summary-text"]}>
                                        {summaryText}
                                    </Typography>
                                </Grid>
                                <Grid item lg={11} md={11} sm={10} xs={12}>
                                    <Grid className={classes["line1"]}></Grid>
                                </Grid>
                            </>

                    }
                </Grid>
            </Grid>
        </>
    );
};
