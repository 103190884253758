import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton, Button } from "@mui/material";
import classes from "./GenerateLoadingEditBill.module.scss";
import { ArrowLeftOutlined, DeleteOutlined } from "@ant-design/icons";
import { Form, Input, Modal, Spin, Table, notification } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import { BreadCrumbLoading } from "../BreadCrumbLoading/BreadCrumbLoading";

const pendingBillsTablecolumns = [
    {
        title: "SL No",
        dataIndex: "sl_no",
        width: '7%'
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: '12%'
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: '10%'
    },
    {
        title: "Ch. No.",
        dataIndex: "Challan_Number",
        width: '12%'
    },
    {
        title: "Vehicle No.",
        dataIndex: "Vehicle_Number",
        width: '12%'
    },
    {
        title: "Kanta Slip No",
        dataIndex: "Kanta_Slip_No",
        width: '10%'
    },
    {
        title: "Net wt. UL (MT)",
        dataIndex: "Net_Weight",
        width: '10%'
    },
    {
        title: "Work Rate",
        dataIndex: "Work_Rate",
        width: '8%'
    },
    {
        title: "Work Amt.",
        dataIndex: "Work_Amount",
        width: '8%'
    },
    {
        title: "D/O No",
        dataIndex: "DO_No",
        width: '6%'
    },
    {
        title: "HSD (LTR)",
        dataIndex: "HSD_LTR",
        width: '8%'
    },
    {
        title: "HSD Rate",
        dataIndex: "Rate_INR",
        width: '8%'
    },
    {
        title: "HSD Amt.",
        dataIndex: "HSD_Amt",
        width: '8%'
    },
];

export const GenerateLoadingEditBill = () => {
    const navigate = useNavigate();
    const params = useParams();
    const activeButton = "generate_bill"; // Set the default active button
    const [generateForm] = Form.useForm()
    const [roundOffWeightRateElement, setRoundOffWeightRateElement] = useState([{ Quantity: "", Rate: "" }]);
    const [roundOffHSDRateElement, setRoundOffHSDRateElement] = useState([{ Quantity: "", Rate: "" }]);
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [totalUnloadingWeight, setTotalUnloadingWeight] = useState(0)
    const [workEffectiveRate, setWorkEffectiveRate] = useState(0)
    const [workAmount, setWorkAmount] = useState(0)
    const [updatedHSD, setUpdatedHSD] = useState(0)
    const [HSDAmount, setHSDAmount] = useState(0)
    const [dieselAmountRatio, setDieselAmountRatio] = useState(0)
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE];
    const [pendingBillsTabledata, setPendingBillsTabledata] = useState([])
    const [adjustedWeightRateArray, setAdjustedWeightRateArray] = useState({})
    const [adjustedWeightHSDRateArray, setAdjustedWeightHSDRateArray] = useState({})
    const [finalAdjustedNetWeight, setFinalAdjustedWeight] = useState(0)
    const [finalAdjustedWeightRateAmount, setFinalAdjustedWeightRateAmount] = useState(0)
    const [finalAdjustedHSDWeight, setFinalAdjustedHSDWeight] = useState(0)
    const [finalAdjustedHSDAmount, setFinalAdjustedHSDAmount] = useState(0)
    const [isSyncButtonDisabled, setIsSyncButtonDisabled] = useState(true)
    const [adjustedWeightHSDRateValidationErrors, setAdjustedWeightHSDRateValidationErrors] = useState([])
    const [validationModelOpen, setValidationModelOpen] = useState(false)
    const [adjustedWeightInputFields, setAdjustedWeightInputFields] = useState({
        RoundOffWeight: {
            Rates: [{
                Quantity: '',
                Rate: ''
            }]
        }
    })
    const [adjustedWeightHSDRateInputFields, setAdjustedWeightHSDRateInputFields] = useState({
        RoundOffHSDRate: {
            Rates: [{
                Quantity: '',
                Rate: ''
            }]
        }
    })
    const selectedPendingBillIds = AppHelper.decryptText(sessionStorage.getItem(`loading_edit_pending_bills_${params?.id}_to_generate_ids`))
    const selectedAgencyName = AppHelper.decryptText(sessionStorage.getItem(`loading_edit_pending_bills_${params?.id}_selected_agency`))
    const adjustedWeightRateValuesSession = AppHelper.decryptText(sessionStorage.getItem(`adjustedWeightRateValuesEditLoading_${params?.id}`))
    const adjustedHSDRateValuesSession = AppHelper.decryptText(sessionStorage.getItem(`adjustedHSDRateValuesEditLoading_${params?.id}`))
    const generateBillCountItemsObject = AppHelper.decryptText(sessionStorage.getItem(`generateEditLoadingBillObject_${params?.id}`))
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [updatedHSDEffectiveRate, setUpdatedHSDEffectiveRate] = useState(0)
    const [minimumLoadingDate, setMinimumLoadingDate] = useState("")
    const [maximumLoadingDate, setMaximumLoadingDate] = useState("")

    const addMoreRoundWeightRateElement = () => {
        setRoundOffWeightRateElement([...roundOffWeightRateElement, { Quantity: "", Rate: "" }]);
        const currentAdjustedWeightInputFields = { ...adjustedWeightInputFields }
        currentAdjustedWeightInputFields[`RoundOffWeight`][`Rates`] = [
            ...adjustedWeightInputFields[`RoundOffWeight`][`Rates`],
            { 'Quantity': '', 'Rate': '' }
        ]
        setAdjustedWeightInputFields(currentAdjustedWeightInputFields)
        setIsSyncButtonDisabled(false)
    };

    const removeRoundWeightRateElement = (childIndex) => {
        let formInputValuesToChange = {}
        const currentRoundOffWeightRateElement = [...roundOffWeightRateElement];
        currentRoundOffWeightRateElement.splice(childIndex, 1);
        setRoundOffWeightRateElement(currentRoundOffWeightRateElement);

        // empty the input values after deletion
        formInputValuesToChange[`RoundOffWeight.Rates.${childIndex}.Quantity`] = null
        formInputValuesToChange[`RoundOffWeight.Rates.${childIndex}.Rate`] = null
        generateForm.setFieldsValue(formInputValuesToChange)

        const currentAdjustedWeightInputFields = { ...adjustedWeightInputFields }
        currentAdjustedWeightInputFields[`RoundOffWeight`][`Rates`].splice(childIndex, 1)
        setAdjustedWeightInputFields(currentAdjustedWeightInputFields)

        // remove object from maine element array
        delete adjustedWeightRateArray?.[`RoundOffWeight.Rates.${childIndex}.Quantity`]
        delete adjustedWeightRateArray?.[`RoundOffWeight.Rates.${childIndex}.Rate`]
        setIsSyncButtonDisabled(false)
    };

    const addMoreRoundHSDRateElement = () => {
        setRoundOffHSDRateElement([...roundOffHSDRateElement, { Quantity: "", Rate: "" }]);
        const currentAdjustedHSDRateInputFields = { ...adjustedWeightHSDRateInputFields }
        currentAdjustedHSDRateInputFields[`RoundOffHSDRate`][`Rates`] = [
            ...adjustedWeightHSDRateInputFields[`RoundOffHSDRate`][`Rates`],
            { 'Quantity': '', 'Rate': '' }
        ]
        setAdjustedWeightHSDRateInputFields(currentAdjustedHSDRateInputFields)
        setIsSyncButtonDisabled(false)
    };

    const removeRoundHSDRateElement = (childIndex) => {
        let formInputValuesToChange = {}
        const currentRoundOffHSDRateElement = [...roundOffHSDRateElement];
        currentRoundOffHSDRateElement.splice(childIndex, 1);
        setRoundOffHSDRateElement(currentRoundOffHSDRateElement)

        // empty the input values after deletion
        formInputValuesToChange[`RoundOffHSDRate.Rates.${childIndex}.Quantity`] = null
        formInputValuesToChange[`RoundOffHSDRate.Rates.${childIndex}.Rate`] = null
        generateForm.setFieldsValue(formInputValuesToChange)

        const currentAdjustedHSDRateInputFields = { ...adjustedWeightHSDRateInputFields }
        currentAdjustedHSDRateInputFields[`RoundOffHSDRate`][`Rates`].splice(childIndex, 1)
        setAdjustedWeightHSDRateInputFields(currentAdjustedHSDRateInputFields)

        // remove object from main element array
        delete adjustedWeightHSDRateArray?.[`RoundOffHSDRate.Rates.${childIndex}.Quantity`]
        delete adjustedWeightHSDRateArray?.[`RoundOffHSDRate.Rates.${childIndex}.Quantity`]
        setIsSyncButtonDisabled(false)
    }

    const backToPendingBill = () => {
        navigate(`/bills/loading/edit-loading-bill/${params?.id}`);
    };

    const redirectToFinalCalculation = () => {
        // add current settings into session storage

        let hsdEffectiveRate = ((parseFloat(HSDAmount) + parseFloat(finalAdjustedHSDAmount)) / ((parseFloat(updatedHSD) + parseFloat(finalAdjustedHSDWeight)))).toFixed(2)
        hsdEffectiveRate = !isNaN(hsdEffectiveRate) ? hsdEffectiveRate : 0
        hsdEffectiveRate = isFinite(hsdEffectiveRate) ? hsdEffectiveRate : 0

        const generatedBillObject = {
            trip_count: totalTripCount,
            unloading_weight: parseFloat(totalUnloadingWeight).toFixed(2),
            round_off_weight: parseFloat(finalAdjustedNetWeight).toFixed(2),
            updated_quantity_weight: (parseFloat(totalUnloadingWeight) + parseFloat(finalAdjustedNetWeight)).toFixed(2),
            work_effective_rate: parseFloat(workEffectiveRate).toFixed(2),
            work_amount: parseFloat(workAmount).toFixed(2),
            finalAdjustedWeightRateAmount: parseFloat(finalAdjustedWeightRateAmount).toFixed(2),
            hsd_litre: parseFloat(updatedHSD).toFixed(2),
            round_off_hsd: parseFloat(finalAdjustedHSDWeight).toFixed(2),
            updated_quantity_litre: (parseFloat(updatedHSD) + parseFloat(finalAdjustedHSDWeight)).toFixed(2),
            hsd_effective_rate: hsdEffectiveRate,
            hsd_amount: (parseFloat(HSDAmount)).toFixed(2),
            totalRoundOffHSDAmount: (parseFloat(finalAdjustedHSDAmount)).toFixed(2),
            updated_diesel_amount_ratio: dieselAmountRatio,
            loading_id_list: selectedPendingBillIds,
            total_payable_amount: totalPayableAmount,
            total_billed_amount: ((parseFloat(totalUnloadingWeight) + parseFloat(finalAdjustedNetWeight)) * parseFloat(workEffectiveRate)).toFixed(2),
            minimumLoadingDate: minimumLoadingDate,
            maximumLoadingDate: maximumLoadingDate
        }
        sessionStorage.setItem(`generateEditLoadingBillObject_${params?.id}`, AppHelper.encryptText(generatedBillObject))
        sessionStorage.setItem(`adjustedWeightRateValuesEditLoading_${params?.id}`, AppHelper.encryptText(adjustedWeightInputFields))
        sessionStorage.setItem(`adjustedHSDRateValuesEditLoading_${params?.id}`, AppHelper.encryptText(adjustedWeightHSDRateInputFields))
        navigate(`/bills/loading/edit-loading-bill-final-calculation/${params?.id}`);
    };

    const handleOk = () => setValidationModelOpen(false)

    const preventNegativeValue = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    const loadAgencyPendingBillsData = (responseData) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.id,
                sl_no: i + 1,
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading) ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.attributes?.Date_of_Unloading && responseData[i]?.attributes?.Date_of_Unloading !== '-') ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number,
                Kanta_Slip_No: responseData[i]?.attributes?.Kanta_Slip_No ?? '-',
                Net_Weight: responseData[i]?.attributes?.Net_Weight ?? 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: responseData[i]?.attributes?.Work_Amount,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
            });
        }
        return pendingBillsTabledata
    }

    const handleAdjustedWeightRate = (e) => {
        setAdjustedWeightRateArray({ ...adjustedWeightRateArray, [e.target.id]: e.target.value })
        setIsSyncButtonDisabled(false)
    }

    const handleAdjustedHSDWeightRate = (e) => {
        setAdjustedWeightHSDRateArray({ ...adjustedWeightHSDRateArray, [e.target.id]: e.target.value })
        setIsSyncButtonDisabled(false)
    }

    const validateRoundOffWeightRateHSDBeforeSubmit = (updatedAdjustedWeightRateArray, updatedAdjustedHSDRateArray) => {
        let errorRows = []
        // validate adjusted weight and rate first
        updatedAdjustedWeightRateArray?.RoundOffWeight?.Rates?.forEach((value, key) => {
            if ((value?.Quantity && value?.Quantity !== "") && (!value?.Rate || value?.Rate === "")) {
                errorRows.push(`In Round off Weight - (Row no ${key + 1} please provide both the quantity and rate.`)
            } else if ((!value?.Quantity || value?.Quantity === "") && (value?.Rate && value?.Rate !== "")) {
                errorRows.push(`In Round off Weight - (Row no ${key + 1} please provide both the quantity and rate.`)
            } else {
                if (updatedAdjustedWeightRateArray?.RoundOffWeight?.Rates?.length > 1) {
                    if ((!value?.Quantity || value?.Quantity === "") && (!value?.Rate || value?.Rate === "")) {
                        errorRows.push(`In Round off Weight - (Row no ${key + 1} please provide both the quantity and rate.`)
                    }
                }
            }
        })
        updatedAdjustedHSDRateArray?.RoundOffHSDRate?.Rates?.forEach((valueHSD, keyHSD) => {
            if ((valueHSD?.Quantity && valueHSD?.Quantity !== "") && (!valueHSD?.Rate || valueHSD?.Rate === "")) {
                errorRows.push(`In Round off HSD (LTR) - (Row no ${keyHSD + 1} please provide both the quantity and rate.`)
            } else if ((!valueHSD?.Quantity || valueHSD?.Quantity === "") && (valueHSD?.Rate && valueHSD?.Rate !== "")) {
                errorRows.push(`In Round off HSD (LTR) - (Row no ${keyHSD + 1} please provide both the quantity and rate.`)
            } else {
                if (updatedAdjustedHSDRateArray?.RoundOffHSDRate?.Rates?.length > 1) {
                    if ((!valueHSD?.Quantity || valueHSD?.Quantity === "") && (!valueHSD?.Rate || valueHSD?.Rate === "")) {
                        errorRows.push(`In Round off HSD (LTR) - (Row no ${keyHSD + 1} please provide both the quantity and rate.`)
                    }
                }
            }
        })

        return errorRows;
    }

    const syncAdjustedWeightRateOnSubmit = () => {
        const updatedAdjustedWeightRateArray = AppHelper.updateRatesObjectRoundOff(adjustedWeightRateArray, adjustedWeightInputFields, 'WeightRate')
        const updatedAdjustedHSDRateArray = AppHelper.updateRatesObjectRoundOff(adjustedWeightHSDRateArray, adjustedWeightHSDRateInputFields, 'HSDRate')
        const validationErrors = validateRoundOffWeightRateHSDBeforeSubmit(updatedAdjustedWeightRateArray, updatedAdjustedHSDRateArray)

        if (validationErrors.length === 0) {
            // take both adjusted weight and rate values and add them in global counts
            let totalRoundOffWeight = 0
            let totalRoundOffRate = 0
            let totalRoundOffWeightAmount = 0
            let totalRoundOffHSDLTR = 0
            let totalRoundOffHSDRate = 0
            let totalRoundOffHSDAmount = 0
            let dieselAmountRatio = 0

            // calculate round off 
            updatedAdjustedWeightRateArray?.RoundOffWeight?.Rates?.forEach((value, key) => {
                totalRoundOffWeight = totalRoundOffWeight + parseFloat((value?.Quantity && value?.Quantity !== "") ? value?.Quantity : 0)
                totalRoundOffRate = totalRoundOffRate + parseFloat((value?.Rate && value?.Rate !== "") ? value?.Rate : 0)
                totalRoundOffWeightAmount = (totalRoundOffWeightAmount + (parseFloat(parseFloat((value?.Quantity && value?.Quantity !== "") ? value?.Quantity : 0)) * parseFloat(parseFloat((value?.Rate && value?.Rate !== "") ? value?.Rate : 0))))
            })
            const updatedWorkEffectiveRate = (((parseFloat(workAmount) + parseFloat(totalRoundOffWeightAmount)).toFixed(2)) / (parseFloat(totalUnloadingWeight) + parseFloat(totalRoundOffWeight)).toFixed(2)).toFixed(2)
            setWorkEffectiveRate(updatedWorkEffectiveRate)
            setFinalAdjustedWeight(parseFloat(totalRoundOffWeight).toFixed(2))
            setFinalAdjustedWeightRateAmount(parseFloat(totalRoundOffWeightAmount).toFixed(2))

            updatedAdjustedHSDRateArray?.RoundOffHSDRate?.Rates?.forEach((value, key) => {
                totalRoundOffHSDLTR = totalRoundOffHSDLTR + parseFloat((value?.Quantity && value?.Quantity !== "") ? value?.Quantity : 0)
                totalRoundOffHSDRate = totalRoundOffHSDRate + parseFloat((value?.Rate && value?.Rate !== "") ? value?.Rate : 0)
                totalRoundOffHSDAmount = (totalRoundOffHSDAmount + (parseFloat(parseFloat((value?.Quantity && value?.Quantity !== "") ? value?.Quantity : 0)) * parseFloat(parseFloat((value?.Rate && value?.Rate !== "") ? value?.Rate : 0))))
            })
            const updatedHSDEffectiveRate = (((parseFloat(HSDAmount) + parseFloat(totalRoundOffHSDAmount)).toFixed(2)) / (parseFloat(updatedHSD) + parseFloat(totalRoundOffHSDRate)).toFixed(2)).toFixed(2)
            setUpdatedHSDEffectiveRate(updatedHSDEffectiveRate)
            setFinalAdjustedHSDWeight(parseFloat(totalRoundOffHSDLTR).toFixed(2))
            setFinalAdjustedHSDAmount(parseFloat(totalRoundOffHSDAmount).toFixed(2))

            dieselAmountRatio = (((parseFloat(HSDAmount) + parseFloat(totalRoundOffHSDAmount)).toFixed(2) * 100) / ((parseFloat(workAmount) + parseFloat(totalRoundOffWeightAmount)).toFixed(2)))
            dieselAmountRatio = isFinite(dieselAmountRatio) ? dieselAmountRatio.toFixed(2) : 0.00
            setDieselAmountRatio(dieselAmountRatio)

            const finalTotalPayableAmount = ((parseFloat(workAmount) + parseFloat(totalRoundOffWeightAmount)) - (parseFloat(HSDAmount) + parseFloat(totalRoundOffHSDAmount))).toFixed(2)
            setTotalPayableAmount(finalTotalPayableAmount)
            setIsSyncButtonDisabled(true)
        } else {
            setValidationModelOpen(true)
            setAdjustedWeightHSDRateValidationErrors(validationErrors)
        }
    }

    useEffect(() => {
        // fetch records by selected pending bills in previous screen
        AppHelper.getLoadingPendingBillsById(selectedPendingBillIds, true, currentPage, pageSize).then(response => {
            const responseData = response?.data
            const responseMeta = response?.meta
            const getTableData = loadAgencyPendingBillsData(responseData)
            setPendingBillsTabledata(getTableData)
            setShowTableSpinner(false)
            setTotalUnloadingWeight(responseMeta.unloadingWeight ?? 0)
            setWorkEffectiveRate(responseMeta.workEffectiveRate ?? 0)
            setWorkAmount(responseMeta.workAmount ?? 0)
            setHSDAmount(responseMeta.hsdAmount ?? 0)
            setUpdatedHSD(responseMeta.updatedHsdLitre ?? 0)
            setDieselAmountRatio(responseMeta?.dieselAmountRatio ?? 0)
            setTotalPayableAmount(responseMeta.totalPayableAmount ?? 0)
            setTotalTripCount(responseMeta.tripCount ?? 0)
            setMinimumLoadingDate(responseMeta?.minLoadingDate ?? '')
            setMaximumLoadingDate(responseMeta?.maxLoadingDate ?? '')

            // take both adjusted weight and rate values and add them in global counts
            let totalRoundOffWeight = 0
            let totalRoundOffRate = 0
            let totalRoundOffWeightAmount = 0
            let totalRoundOffHSDLTR = 0
            let totalRoundOffHSDRate = 0
            let totalRoundOffHSDAmount = 0
            let dieselAmountRatio = 0

            AppHelper.getGeneratedBillDetails(params?.id).then((response) => {
                // display adjusted weight rate and HSD rate elements if stored in session storage
                let weightRateElements = {}
                if (adjustedWeightRateValuesSession && adjustedWeightRateValuesSession !== "") {
                    if (adjustedWeightRateValuesSession?.RoundOffWeight?.Rates && adjustedWeightRateValuesSession?.RoundOffWeight?.Rates?.length > 0 && adjustedWeightRateValuesSession?.RoundOffWeight?.Rates?.[0]?.Quantity) {
                        weightRateElements = adjustedWeightRateValuesSession
                    } else if (adjustedWeightRateValuesSession?.Rates?.length > 0) {
                        weightRateElements.RoundOffWeight = adjustedWeightRateValuesSession
                    }
                } else if (response?.data?.attributes?.RoundOffWeight?.Rates?.length > 0) {
                    weightRateElements.RoundOffWeight = response?.data?.attributes?.RoundOffWeight
                }

                let HSDRateElements = {}
                if (adjustedHSDRateValuesSession && adjustedHSDRateValuesSession !== "") {
                    if (adjustedHSDRateValuesSession?.RoundOffHSDRate?.Rates?.length > 0 && adjustedHSDRateValuesSession?.RoundOffHSDRate?.Rates?.[0]?.Quantity) {
                        HSDRateElements = adjustedHSDRateValuesSession
                    } else if (adjustedHSDRateValuesSession?.Rates?.length > 0) {
                        HSDRateElements.RoundOffHSDRate = adjustedHSDRateValuesSession
                    }
                } else if (response?.data?.attributes?.RoundOffHSDRate?.Rates?.length > 0 && response?.data?.attributes?.RoundOffHSDRate?.Rates?.[0]?.Quantity) {
                    HSDRateElements.RoundOffHSDRate = response?.data?.attributes?.RoundOffHSDRate
                }

                if (weightRateElements?.RoundOffWeight || HSDRateElements?.RoundOffHSDRate) {
                    let formInputDefaultValues = {}
                    if (weightRateElements?.RoundOffWeight?.Rates && weightRateElements?.RoundOffWeight?.Rates?.length > 0) {
                        setAdjustedWeightInputFields(weightRateElements)
                        let adjustedWeightRateElementsArray = []
                        weightRateElements?.RoundOffWeight?.Rates?.forEach((item, index) => {
                            formInputDefaultValues[`RoundOffWeight.Rates.${index}.Quantity`] = item?.Quantity
                            formInputDefaultValues[`RoundOffWeight.Rates.${index}.Rate`] = item?.Rate
                            adjustedWeightRateElementsArray.push({ 'Quantity': '', 'Rate': '' })

                            totalRoundOffWeight = totalRoundOffWeight + parseFloat((item?.Quantity && item?.Quantity !== "") ? item?.Quantity : 0)
                            totalRoundOffRate = totalRoundOffRate + parseFloat((item?.Rate && item?.Rate !== "") ? item?.Rate : 0)
                            totalRoundOffWeightAmount = (totalRoundOffWeightAmount + (parseFloat(parseFloat((item?.Quantity && item?.Quantity !== "") ? item?.Quantity : 0)) * parseFloat(parseFloat((item?.Rate && item?.Rate !== "") ? item?.Rate : 0))))
                        })
                        setAdjustedWeightRateArray(formInputDefaultValues)
                        setRoundOffWeightRateElement(adjustedWeightRateElementsArray)
                    } else {
                        setRoundOffWeightRateElement([{ 'Quantity': '', 'Rate': '' }])
                        setAdjustedWeightInputFields({
                            RoundOffWeight: {
                                Rates: [{ 'Quantity': '', 'Rate': '' }]
                            }
                        })
                    }

                    if (HSDRateElements?.RoundOffHSDRate?.Rates && HSDRateElements?.RoundOffHSDRate?.Rates?.length > 0) {
                        setAdjustedWeightHSDRateInputFields(HSDRateElements)
                        let adjustedHSDRateElementsArray = []
                        HSDRateElements?.RoundOffHSDRate?.Rates?.forEach((item, index) => {
                            formInputDefaultValues[`RoundOffHSDRate.Rates.${index}.Quantity`] = item?.Quantity
                            formInputDefaultValues[`RoundOffHSDRate.Rates.${index}.Rate`] = item?.Rate
                            adjustedHSDRateElementsArray.push({ 'Quantity': '', 'Rate': '' })

                            totalRoundOffHSDLTR = totalRoundOffHSDLTR + parseFloat((item?.Quantity && item?.Quantity !== "") ? item?.Quantity : 0)
                            totalRoundOffHSDRate = totalRoundOffHSDRate + parseFloat((item?.Rate && item?.Rate !== "") ? item?.Rate : 0)
                            totalRoundOffHSDAmount = (totalRoundOffHSDAmount + (parseFloat(parseFloat((item?.Quantity && item?.Quantity !== "") ? item?.Quantity : 0)) * parseFloat(parseFloat((item?.Rate && item?.Rate !== "") ? item?.Rate : 0))))
                        })
                        setAdjustedWeightHSDRateArray(formInputDefaultValues)
                        setRoundOffHSDRateElement(adjustedHSDRateElementsArray)
                    } else {
                        setRoundOffHSDRateElement([{ 'Quantity': '', 'Rate': '' }])
                        setAdjustedWeightHSDRateInputFields({
                            RoundOffHSDRate: {
                                Rates: [{ 'Quantity': '', 'Rate': '' }]
                            }
                        })
                    }
                    const updatedWorkEffectiveRate = (((parseFloat(responseMeta.workAmount) + parseFloat(totalRoundOffWeightAmount)).toFixed(2)) / (parseFloat(responseMeta.unloadingWeight ?? 0) + parseFloat(totalRoundOffWeight)).toFixed(2)).toFixed(2)
                    setWorkEffectiveRate(updatedWorkEffectiveRate)
                    setFinalAdjustedWeight(parseFloat(totalRoundOffWeight).toFixed(2))
                    setFinalAdjustedWeightRateAmount(parseFloat(totalRoundOffWeightAmount).toFixed(2))

                    const updatedHSDEffectiveRate = (((parseFloat(responseMeta.hsdAmount) + parseFloat(totalRoundOffHSDAmount)).toFixed(2)) / (parseFloat(responseMeta.updatedHsdLitre) + parseFloat(totalRoundOffHSDRate)).toFixed(2)).toFixed(2)
                    setUpdatedHSDEffectiveRate(updatedHSDEffectiveRate)
                    setFinalAdjustedHSDWeight(parseFloat(totalRoundOffHSDLTR).toFixed(2))
                    setFinalAdjustedHSDAmount(parseFloat(totalRoundOffHSDAmount).toFixed(2))

                    dieselAmountRatio = (((parseFloat(responseMeta.hsdAmount) + parseFloat(totalRoundOffHSDAmount)).toFixed(2) * 100) / ((parseFloat(responseMeta.workAmount) + parseFloat(totalRoundOffWeightAmount)).toFixed(2)))
                    dieselAmountRatio = isFinite(dieselAmountRatio) ? dieselAmountRatio.toFixed(2) : 0.00
                    setDieselAmountRatio(dieselAmountRatio)

                    const finalTotalPayableAmount = ((parseFloat(responseMeta.workAmount) + parseFloat(totalRoundOffWeightAmount)) - (parseFloat(responseMeta.hsdAmount) + parseFloat(totalRoundOffHSDAmount))).toFixed(2)
                    setTotalPayableAmount(finalTotalPayableAmount)

                    if (formInputDefaultValues !== "") {
                        generateForm.setFieldsValue(formInputDefaultValues)
                    }
                    if (generateBillCountItemsObject) {
                        setTotalUnloadingWeight(generateBillCountItemsObject?.unloading_weight ?? 0)
                        setFinalAdjustedWeight(generateBillCountItemsObject?.round_off_weight ?? 0)
                        setWorkEffectiveRate(generateBillCountItemsObject?.work_effective_rate ?? 0)
                        setWorkAmount(generateBillCountItemsObject?.work_amount ?? 0)
                        setFinalAdjustedWeightRateAmount(generateBillCountItemsObject?.finalAdjustedWeightRateAmount ?? 0)
                        setUpdatedHSD(generateBillCountItemsObject?.hsd_litre ?? 0)
                        setFinalAdjustedHSDWeight(generateBillCountItemsObject?.round_off_hsd ?? 0)
                        setHSDAmount(generateBillCountItemsObject?.hsd_amount ?? 0)
                        setFinalAdjustedHSDAmount(generateBillCountItemsObject?.totalRoundOffHSDAmount ?? 0)
                        setDieselAmountRatio(generateBillCountItemsObject?.updated_diesel_amount_ratio ?? 0)
                        setTotalPayableAmount(generateBillCountItemsObject?.total_payable_amount ?? 0)
                    }
                    setTotalTripCount(responseMeta?.tripCount ?? 0)
                }
            }).catch(err => {
                console.log(`Error while loading generated bill details`, err);
                notification.error({
                    message: `Error while loading generated bill details`, err,
                    description: err?.message,
                    duration: 3
                })
            })
        }).catch(err => {
            console.log(`Error while getting bill records: ${err}`);
        })

        document.title = "Edit Loading Agency Generate Bill"
    }, [])
    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generate-transport-padding"]}
            >
                <Grid item lg={10} md={8} sm={8} xs={12}>
                    <BreadCrumbLoading activeButton={activeButton} page="loadingEdit" params={params} />
                </Grid>

                <Grid item lg={2} md={4} sm={4} xs={12} className={classes['right-align']}>
                    <UIButton
                        variant="contained"
                        className={`${classes["back-new-entry"]}`}
                        startIcon={<ArrowLeftOutlined />}
                        onClick={backToPendingBill}
                    >
                        Pending Bills
                    </UIButton>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generate-transport-padding"]}
            >
                <Grid className={classes["middle-boundary-transport"]} container>
                    <Grid>
                        {/* Will change in future dynamically */}
                        <Typography
                            variant="h5"
                            color="#fff"
                            className={classes["text-boundary-tranport"]}
                        >
                            {selectedAgencyName}
                        </Typography>
                    </Grid>
                    <Grid className={classes["total-amount-transport"]}>
                        <Typography variant="h6" fontWeight="bold" color="#fff">
                            Total Payable Amount
                        </Typography>
                        <Typography variant="h6" color="#fff" fontWeight="bold">
                            = &nbsp; &#8377; {totalPayableAmount.toLocaleString('en-IN')}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Form layout="vertical" form={generateForm}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["generate-transport-padding-wrapper"]}
                >
                    <Grid
                        container
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        className={classes["add-more-weight-rate-area"]}
                    >
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xs={12}
                            className={`${classes["border-right"]} ${classes["add-more-weight-rate-area-grid"]}`}
                        >
                            <Grid container item lg={12} md={12} xs={12}>
                                <Grid container item lg={9} md={10} xs={12}>
                                    <p>Round off Weight (MT)</p>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    lg={3}
                                    md={2}
                                    xs={12}
                                    className={classes["right-align"]}
                                >
                                    <Button
                                        size="small"
                                        className={classes["add_more_rate_weight"]}
                                        onClick={addMoreRoundWeightRateElement}
                                    >
                                        + Add More
                                    </Button>
                                </Grid>
                            </Grid>
                            {roundOffWeightRateElement.map((value, index) => (
                                <>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                        className={classes["weight_rate_add_more_area"]}
                                        key={index}
                                    >
                                        <Grid item lg={4} md={4} xs={12}>
                                            <Form.Item
                                                name={`RoundOffWeight.Rates.${index}.Quantity`}
                                                label={index === 0 ? "Quantity" : false}
                                            >
                                                <Input
                                                    type="number"
                                                    id={`RoundOffWeight.Rates.${index}.Quantity`}
                                                    min={0}
                                                    max={99999}
                                                    onChange={handleAdjustedWeightRate}
                                                />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={12}>
                                            <Form.Item
                                                name={`RoundOffWeight.Rates.${index}.Rate`}
                                                label={index === 0 ? "Rate" : false}
                                            >
                                                <Input
                                                    type="number"
                                                    min={0}
                                                    max={99999}
                                                    onKeyPress={preventNegativeValue}
                                                    id={`RoundOffWeight.Rates.${index}.Rate`}
                                                    step={0.1}
                                                    onChange={handleAdjustedWeightRate}
                                                />
                                            </Form.Item>
                                        </Grid>
                                        {index > 0 && (index === roundOffWeightRateElement.length - 1) ? (
                                            <Grid item lg={4} md={4} xs={12}>
                                                <UIButton
                                                    onClick={() => removeRoundWeightRateElement(index)}
                                                    style={{ padding: "0" }}
                                                >
                                                    <DeleteOutlined
                                                        style={{
                                                            border: "1px solid #ff0000",
                                                            padding: "7px",
                                                            borderRadius: "5px",
                                                            color: "#ff0000",
                                                        }}
                                                    />
                                                </UIButton>
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                        <Grid
                            item
                            lg={6}
                            md={6}
                            xs={12}
                            className={`${classes["add-more-weight-rate-area-grid"]}`}
                        >
                            <Grid container item lg={12} md={12} xs={12}>
                                <Grid container item lg={9} md={10} xs={12}>
                                    <p>Round off HSD (LTR)</p>
                                </Grid>
                                <Grid
                                    container
                                    item
                                    lg={3}
                                    md={2}
                                    xs={12}
                                    className={classes["right-align"]}
                                >
                                    <Button
                                        size="small"
                                        className={classes["add_more_rate_weight"]}
                                        onClick={addMoreRoundHSDRateElement}
                                    >
                                        + Add More
                                    </Button>
                                </Grid>
                            </Grid>
                            {roundOffHSDRateElement.map((value, index) => (
                                <>
                                    <Grid
                                        container
                                        spacing={1}
                                        direction="row"
                                        justify="center"
                                        item
                                        lg={12}
                                        md={12}
                                        xs={12}
                                        className={classes["weight_rate_add_more_area"]}
                                        key={index}
                                    >
                                        <Grid item lg={4} md={4} xs={12}>
                                            <Form.Item
                                                name={`RoundOffHSDRate.Rates.${index}.Quantity`}
                                                label={index === 0 ? "Quantity" : false}
                                            >
                                                <Input
                                                    type="number"
                                                    min={0}
                                                    max={99999}
                                                    value={value?.Quantity ?? ''}
                                                    id={`RoundOffHSDRate.Rates.${index}.Quantity`}
                                                    onChange={handleAdjustedHSDWeightRate}
                                                />
                                            </Form.Item>
                                        </Grid>
                                        <Grid item lg={4} md={4} xs={12}>
                                            <Form.Item
                                                name={`RoundOffHSDRate.Rates.${index}.Rate`}
                                                label={index === 0 ? "Rate" : false}
                                            >
                                                <Input
                                                    type="number"
                                                    min={0}
                                                    max={99999}
                                                    onKeyPress={preventNegativeValue}
                                                    id={`RoundOffHSDRate.Rates.${index}.Rate`}
                                                    step={0.1}
                                                    onChange={handleAdjustedHSDWeightRate}
                                                />
                                            </Form.Item>
                                        </Grid>
                                        {index > 0 && (index === roundOffHSDRateElement.length - 1) ? (
                                            <Grid item lg={4} md={4} xs={12}>
                                                <UIButton
                                                    onClick={() => removeRoundHSDRateElement(index)}
                                                    style={{ padding: "0" }}
                                                >
                                                    <DeleteOutlined
                                                        style={{
                                                            border: "1px solid #ff0000",
                                                            padding: "7px",
                                                            borderRadius: "5px",
                                                            color: "#ff0000",
                                                        }}
                                                    />
                                                </UIButton>
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        className={classes["add-more-weight-rate-area-submit"]}
                    >
                        <Grid container item lg={12} md={12} xs={12}>
                            <Grid item lg={10} md={10} xs={12}></Grid>
                            <Grid
                                item
                                lg={2}
                                md={2}
                                xs={12}
                                className={classes["right-align"]}
                            >
                                <Button
                                    size="small"
                                    variant="contained"
                                    className={(isSyncButtonDisabled === true ? `${classes["submit-button-disabled"]} ${classes["add-new-entry"]}` : `${classes["add-new-entry"]}`)}
                                    disabled={isSyncButtonDisabled}
                                    onClick={syncAdjustedWeightRateOnSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Form>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["generate-transport-padding"]}
            >
                <Grid className={classes["bottom-transport-counter"]} container>
                    <Grid className={classes["bottom-transport-first-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>
                            {totalTripCount}
                        </Typography>
                        <Typography variant="body" color="#fff" className={classes["para"]}>
                            Trip Count
                        </Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-second-counter"]}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#fff"
                            className={classes["h6"]}
                        >
                            <span title={(parseFloat(totalUnloadingWeight) + parseFloat(finalAdjustedNetWeight)).toFixed(2)}>{AppHelper.formatNumber((parseFloat(totalUnloadingWeight) + parseFloat(finalAdjustedNetWeight)).toFixed(2))}</span>
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            className={classes["para"]}
                        >
                            Total Unloading Wt (MT)
                        </Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-third-counter"]}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#fff"
                            className={classes["h6"]}
                        >
                            {parseFloat(workEffectiveRate)}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            className={classes["para"]}
                        >
                            Work Effective Rate (Rs.)
                        </Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-fourth-counter"]}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#fff"
                            className={classes["h6"]}
                        >
                            <span title={(parseFloat(workAmount) + parseFloat(finalAdjustedWeightRateAmount)).toFixed(2)}>{AppHelper.formatNumber((parseFloat(workAmount) + parseFloat(finalAdjustedWeightRateAmount)).toFixed(2))}</span>
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            className={classes["para"]}
                        >
                            Work Amount (Rs.)
                        </Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-fivth-counter"]}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#fff"
                            className={classes["h6"]}
                        >
                            {(parseFloat(updatedHSD) + parseFloat(finalAdjustedHSDWeight)).toFixed(2)}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            className={classes["para"]}
                        >
                            Updated HSD (LTR)
                        </Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-sixth-counter"]}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#fff"
                            className={classes["h6"]}
                        >
                            <span title={parseFloat(HSDAmount) + parseFloat(finalAdjustedHSDAmount)}>
                                {AppHelper.formatNumber((parseFloat(HSDAmount) + parseFloat(finalAdjustedHSDAmount)).toFixed(2))}
                            </span>
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            className={classes["para"]}
                        >
                            HSD Amount (Rs.)
                        </Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-seventh-counter"]}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            color="#fff"
                            className={classes["h6"]}
                        >
                            {dieselAmountRatio}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            className={classes["para"]}
                        >
                            Diesel Amount Ratio
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Table
                className={classes["pending-bill-table"]}
                columns={pendingBillsTablecolumns}
                dataSource={pendingBillsTabledata}
                bordered={true}
                scroll={{ y: 1600 }}
                loading={{ indicator: <div><Spin /></div>, spinning: showTableSpinner }}
                pagination={{
                    pageSize: constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT,
                    showSizeChanger: false
                }}
            />

            <div className={classes["submit-button-area"]}>
                <UIButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes["submit-button"]}
                    onClick={redirectToFinalCalculation}
                >
                    Final Calculation
                </UIButton>
            </div>

            {
                validationModelOpen === true &&
                    adjustedWeightHSDRateValidationErrors.length > 0
                    ?
                    <Modal
                        open={validationModelOpen}
                        onOk={handleOk}
                        centered
                        className={classes['error-agency-modal']}
                        title=""
                        width={1024}
                        onCancel={handleOk}
                        footer=""
                    >
                        <>
                            <h3 className={classes['error-agency-modal-header']}>Please check below errors while adjusting weight and rate</h3>
                            <div className={classes['error-agency-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width="100%" className={classes['agency-errors-table']}>
                                    <thead>
                                        <tr>
                                            <th width="10%" className={classes['agency-errors-table-first-td']}>SL No.</th>
                                            <th width="90%">Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            adjustedWeightHSDRateValidationErrors.map((value, key) => {
                                                return <tr key={key}>
                                                    <td className={classes['agency-errors-table-first-td']}>{key + 1}</td>
                                                    <td className={classes['error-message-row']}>{value}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};
