import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { Grid, Typography, Button as UIButton } from '@mui/material'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import AppHelper from '../../../../helpers/AppHelper'
import { Form, Input, Select, Skeleton, Spin, Upload, notification, Modal } from 'antd'
import classes from '../../HSDDetails/CreateHsdDetails/HSDDetails.module.scss'
import moment from 'moment'
import constantsArr from '../../../../constants/global-constants'
import dayjs from 'dayjs'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const EditHsdDetails = () => {
    const navigate = useNavigate()
    const params = useParams();
    const hsdId = params?.id ?? ""
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [hsdForm] = Form.useForm()
    const [loadingDetails, setLoadingDetails] = useState([])
    const [skeleteonLoading, setSekeletonLoading] = useState(true)
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState([])
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const [vehicleLists, setVehicleLists] = useState([]);
    const [petrolPumpsValues, setPetrolPumpsValues] = useState([])
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [agencies, setAgencies] = useState([]);
    const [formInputFields, setFormInputFields] = useState({
        Challan_Number: "",
        Vehicle_Number: "",
        Date_of_Loading: "",
        Transporter_Name: "",
        Petrolpump_Name: "",
        Owner: "",
        Rate_INR: 0,
        DO_No: "",
        HSD_LTR: 0,
        Remarks: "",
        HSD_Amt: "",
    })
    const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const { search } = useLocation();
    const paramsBack = new URLSearchParams(search);
    const redirectbackto = paramsBack.get('redirectbackto');
    const backButtonLabel = redirectbackto ? 'Loadings' : 'Hsd Details';
    const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const allowedMimeTypes = constantsArr.ALLOWED_MIME_TYPES
    const [viewPdf, setViewPdf] = useState(null);
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const [uploadedFileList, setUploadedFileList] = useState([]);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const backToHsdDetailsList = (e) => {
        e.preventDefault();
        if (redirectbackto === "true") {
            navigate(`/loadings?currentPage=${pageNo}&pageSize=${pageSize}`);
        } else {
            navigate(`/hsd-details?currentPage=${pageNo}&pageSize=${pageSize}`)
        }
    }
    const setFormDefaultValues = (values) => {
        const defaultValues = {
            Challan_Number: values?.data?.attributes?.Loading?.data?.attributes?.Challan_Number ?? "",
            Date_of_Loading: moment(values?.data?.attributes?.Date_of_Loading).format("DD-MM-YYYY"),
            Vehicle_Number: values?.data?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number ?? "",
            Vehicle_Number_Id: values?.data?.attributes?.Vehicle_Number?.data?.id ?? "",
            Owner: values?.data?.attributes?.Vehicle_Number?.data?.attributes?.Owner ?? "",
            Transporter_Name: values?.data?.attributes?.Transporter_Name?.data?.attributes?.Company_name ?? "",
            Petrolpump_Name: values?.data?.attributes?.Petrolpump_Name?.data?.attributes?.Petrolpump_Name ?? "",
            DO_No: values?.data?.attributes?.Loading?.data?.attributes?.DO_No ?? "",
            Rate_INR: values?.data?.attributes?.Rate_INR ?? "0",
            HSD_LTR: values?.data?.attributes?.HSD_LTR ?? "0",
            HSD_Amt: values?.data?.attributes?.HSD_Amt ?? "0",
            Remarks: values?.data?.attributes?.Remarks ?? "0",
            Petrolpump_Name_Id: values?.data?.attributes?.Petrolpump_Name?.data?.id ?? 0,
        }
        hsdForm.setFieldsValue(defaultValues)
        setFormInputFields(defaultValues)
    }

    const hsdHSDDetails = (e) => {
        setIsSubmittingResponse(false);
        const checkPetrolPumpIsInteger = AppHelper.checkIsInteger(e)
        let petrolPumpObject = petrolPumpsValues.find((ele) => checkPetrolPumpIsInteger ? ele?.id === e : ele?.attributes?.Petrolpump_Name === e)
        const selectedDate = dayjs(hsdForm.getFieldValue("Date_of_Loading"), "DD-MM-YYYY").format("YYYY-MM-DD");
        if (!selectedDate) {
            notification.error({
                message: "Error",
                description: "Please select date first to fetch the petrol pump rate",
                duration: 3,
            });
            hsdForm.setFieldsValue({
                Petrol_Pumps: null
            })
            return false
        }

        const hsdDetailsById = petrolPumpsValues.find((obj) => obj.id === petrolPumpObject?.id);
        const petrolPumpRateArrayObject = hsdDetailsById?.attributes?.Rate;
        const sortPetrolPumpRates = AppHelper.makePetrolPumpRatesDescendingOrder(petrolPumpRateArrayObject)

        // find rate by petrol pump and date
        const petrolPumpRate = AppHelper.findPetrolPumpRatebyDateAndPetrolPumpValue(selectedDate, sortPetrolPumpRates)
        hsdForm.setFieldsValue({
            Rate_INR: petrolPumpRate.toString(),
            Petrolpump_Name_Id: hsdDetailsById?.id,
            HSD_Amt: ((parseFloat(formInputFields.HSD_LTR) * parseFloat(petrolPumpRate)).toFixed(2)).toString(),
        })
        setFormInputFields({ ...formInputFields, 'Rate_INR': petrolPumpRate })
    };


    const submitForm = async (formValues) => {
        setIsSubmittingResponse(true);
        const transportationAgency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Transporter_Name)
        const vehicle = vehicleLists?.data.find((item) => item?.Vehicle_Number === formValues?.Vehicle_Number)
        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        const payLoadData = {
            ...formInputFields,
            ...formValues,
            'Petrolpump_Name': formValues.Petrolpump_Name_Id,
            'Vehicle_Number': vehicle?.id ?? null,
            'Transporter_Name': transportationAgency?.id,
            'Updated_By': sessionUserInfo?.userID ?? null,
            'Date_of_Loading': (formValues.Date_of_Loading) ? moment(formValues.Date_of_Loading, "DD-MM-YYYY").format("YYYY-MM-DD") : null,
            'Uploaded_Pictures': { 'id': uploadImageResponse?.data?.id ?? "" }
        }
        const hsdEditResponse = await AppHelper.updateHSDDetails(payLoadData, hsdId)
        if (hsdEditResponse?.error) {
            notification.error({
                message: "Error",
                description: `Error: ${hsdEditResponse?.error.status} - ${hsdEditResponse?.error.message}`,
                duration: 3,
            });
        } else {
            // update hsd done to true in loading schema to set that hsd has been done for particular loading
            if (loadingDetails?.data?.attributes?.Loading?.data?.attributes?.Challan_Number) {
                const loadingDetailsToBeUpdated = loadingDetails?.data?.attributes?.Loading?.data?.attributes;
                loadingDetailsToBeUpdated['HSD_DONE'] = true
                const payload = { data: loadingDetailsToBeUpdated }
                await AppHelper.updateLoading(loadingDetails?.data?.attributes?.Loading?.data?.id, payload);
            }
            notification.success({
                message: "Success",
                description: "HSD Details updated successfully.",
                duration: 3,
            });
            navigate(`/hsd-details?sort=updatedAt:desc`)
        }
        setIsSubmittingResponse(false);
    }

    const handleChange = (e) => {
        setIsSubmittingResponse(false);
        // calculate rate on basis of petrol pump drop down value and set to form input field values
        if (e.target.id === 'HSD_LTR') {
            let hsdLTRVal = (!e.currentTarget.value || e.currentTarget.value === "") ? 0 : e.currentTarget.value
            hsdLTRVal = parseFloat(hsdLTRVal)
            hsdForm.setFieldsValue({
                HSD_Amt: ((parseFloat(hsdLTRVal) * parseFloat(formInputFields.Rate_INR)).toFixed(2)).toString(),
            })
            setFormInputFields({ ...formInputFields, 'HSD_LTR': e.target.value })
        } else {
            setFormInputFields({ ...formInputFields, [e.target.id]: e.target.value })
        }
    }

    const loadVehicleDetails = (e) => {
        const vehicleDetailsById = vehicleLists?.data?.find((obj) => obj?.Vehicle_Number === e);
        hsdForm.setFieldsValue({
            Owner: vehicleDetailsById?.Owner,
        });
    };

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };


    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        console.log(file, 'file')
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, and png.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }


    const handleChangePictures = (values) => {
        setIsSubmittingResponse(false);
        const fileStatus = values?.file?.status
        let fileList = values?.fileList
        if (fileStatus !== 'removed') {
            fileList = values?.fileList
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
            if (values?.file?.type === 'application/pdf' && values?.file?.status !== 'done') {
                const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                const fileReader = new FileReader();
                fileReader.onload = (e) => {
                    getUploadedFile.thumbUrl = URL.createObjectURL(e.target.result)
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
            setFileList(fileList)
        }
        setFileList(values?.fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    useEffect(() => {
        <>
            {

                hsdId ?
                    AppHelper.getHSDDetailsById(hsdId).then(response => {
                        let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";
                        if (uploadedPictures) {
                            uploadedPictures = JSON.parse(uploadedPictures)
                        }
                        let updatedPictures = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
                            ...image,
                            url: image?.url,
                            uid: image?.id,
                            name: image?.name,
                            type: image?.mime,
                            status: "done",
                        })) : [];
                        setFileList(updatedPictures);
                        setUploadedFileList(updatedPictures)
                        setLoadingDetails(response)
                        setSekeletonLoading(false)
                        setFormDefaultValues(response)
                    })
                    :
                    ""
            }
        </>
        document.title = "Edit HSD Details"
        AppHelper.getPetrolPumps().then(response => {
            setPetrolPumpsValues(response)
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpsDropDown(response))
        })
        // Fetch vehicle and agency data
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response);
            setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(response?.data));
        });
        AppHelper.getAgenciesByProject(globalTpProjectValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', globalTpProjectValues));
        });
    }, [])

    return (
        <>
            <Skeleton active loading={skeleteonLoading}>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["hsd-details-grid-padding"]}
                >
                    <Grid item lg={10} md={9} xs={12}>
                        <Typography variant="h1" fontSize="1.5rem" fontWeight="bold" className="title-un-loading-text">
                            Edit HSD Details (Challan No: {
                                loadingDetails?.data?.attributes?.Loading?.data?.attributes?.Cancelled === true ?
                                    <span className={classes["cancelled-loading-challan-no"]}>
                                        {loadingDetails?.data?.attributes?.Loading?.data?.attributes?.Challan_Number ?? "-"}
                                    </span>
                                    :
                                    loadingDetails?.data?.attributes?.Loading?.data?.attributes?.Challan_Number ?? "-"
                            })
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                        <UIButton size="small" variant="contained" onClick={backToHsdDetailsList} className={classes['add-new-entry']}>
                            <ArrowLeftOutlined />&nbsp;{backButtonLabel}
                        </UIButton>
                    </Grid>
                </Grid>
                <div className={classes['agency-details-margin']}>
                    <Form layout='vertical' form={hsdForm} onFinish={submitForm}>
                        <Form.Item noStyle name="Petrolpump_Name_Id">
                            <Input type='hidden' />
                        </Form.Item>
                        <Form.Item noStyle name="Vehicle_Number_Id">
                            <Input type='hidden' />
                        </Form.Item>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    name="Challan_Number"
                                    label="Challan Number"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter challan number'
                                        }
                                    ]}
                                >
                                    <Input readOnly onChange={handleChange} disabled />
                                </Form.Item>
                                <Form.Item
                                    name="Vehicle_Number"
                                    label="Vehicle No."
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        onChange={loadVehicleDetails}
                                        options={vehicleDropDown}
                                        placeholder="Select"
                                        disabled
                                    />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    name="Date_of_Loading"
                                    label="Date of Loading"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Input readOnly onChange={handleChange} disabled />
                                </Form.Item>
                                <Form.Item
                                    name="Owner"
                                    label="Owner"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Input readOnly onChange={handleChange} disabled />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    name="Transporter_Name"
                                    label="Transporting Agency"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        style={{ width: "100%" }}
                                        options={transportationAgencyDropDown}
                                        placeholder="Select"
                                        disabled
                                    />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="D/O No."
                                    name="DO_No"
                                    wrapperCol={{ span: 30 }}>
                                    <Input readOnly onChange={handleChange} disabled />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    name="Petrolpump_Name"
                                    label="Petrol Pump Name"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please select petrol pump"
                                        }
                                    ]}
                                >
                                    <Select
                                        showSearch
                                        options={petrolPumpsDropDownValues}
                                        placeholder="Please Select"
                                        id='Petrolpump_Name'
                                        filterOption={filterOption}
                                        onChange={(e) => {
                                            hsdHSDDetails(e)
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="Rate_INR"
                                    label="Rate"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Input id='Rate_INR' readOnly onChange={handleChange} disabled className={classes['disabled-input']} />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    name="HSD_LTR"
                                    label="HSD LTR"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter the HSD Litre."
                                        }
                                    ]}
                                >
                                    <Input type="number" onChange={handleChange}
                                        min={0}
                                        step="any"
                                        onKeyDown={AppHelper.preventMinus}
                                    />
                                </Form.Item>
                                <Form.Item
                                    name="Remarks"
                                    label="Remarks"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Input onChange={handleChange} />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    name='HSD_Amt'
                                    label="HSD Amount"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    <Input readOnly disabled className={classes['disabled-input']} />
                                </Form.Item>
                                <Form.Item
                                    label="&nbsp;"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                >
                                    &nbsp;
                                </Form.Item>
                            </Grid>

                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Upload
                                    customRequest={customRequest}
                                    multiple
                                    listType="picture-card"
                                    fileList={fileList}
                                    onPreview={handlePreview}
                                    onChange={handleChangePictures}
                                    name="Unloading_Images"
                                    withCredentials={true}
                                    beforeUpload={beforeUpload}
                                    accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                                >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                                </Upload>
                                <Modal
                                    open={previewOpen}
                                    title={previewTitle}
                                    footer={null}
                                    width={800}
                                    styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                                    onCancel={handleCancel}
                                >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                            </Grid>
                        </Grid>
                        <div className={classes['submit-button-area']}>
                            <UIButton variant="contained" color="primary" type='submit' className={classes['submit-button']} disabled={isSubmittingResponse}>
                                Save
                            </UIButton>
                            <UIButton variant="outlined" color="error" onClick={backToHsdDetailsList}>
                                Cancel
                            </UIButton>
                        </div>
                    </Form>
                </div>
            </Skeleton>
        </>
    )
}

export default EditHsdDetails