import React, { useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./GeneratePetrolPumpBill.module.scss";
import { BreadCrumbPetrolPump } from "../BreadCrumbPetrolPump/BreadCrumbPetrolPump";
import { Form, Table, notification, Spin, Tooltip, Pagination } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const columns = [
  {
    title: "S.No.",
    dataIndex: "S_No",
    // width: "4%",
  },
  {
    title: "Date of Loading",
    dataIndex: "Date_Of_Loading",
    // width: "10%",
  },
  {
    title: "Date of UL",
    dataIndex: "Date_Of_Unloading",
    // width: "8%",
  },
  {
    title: "Vehicle No.",
    dataIndex: "vehicle_number",
    // width: "6%",
  },
  {
    title: "Challan No.",
    dataIndex: "Challan_Number",
    // width: "6%",
  },
  {
    title: "Agency Name",
    dataIndex: "agency_name",
    // width: "7%",
  },
  {
    title: "D/o No.",
    dataIndex: "Do_No",
    // width: "5%",
  },
  {
    title: "HSD LTR",
    dataIndex: "hsd_ltr",
    // width: "5%",
  },
  {
    title: "Rate",
    dataIndex: "rate",
    // width: "5%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    // width: "5%",
  },
];
const data = [];
const avgWeight = 5;
for (let i = 1; i < 11; i++) {
  data.push({
    key: i,
    S_No: `${i}`,
    vehicle_number: `RJ${i} CA 00${i}`,
    Date_Of_Loading: `0${i}-05-2025`,
    Date_Of_Unloading: `0${i}-05-2024`,
    Challan_Number: "200" + `${i}`,
    agency_name: "Hire service",
    Do_No: `${i}`,
    hsd_ltr: `${i}` * `${avgWeight}`,
    rate: `${i}` ** `${avgWeight}`,
    amount: `${i}` + `${avgWeight}`,
  });
}

export const GeneratePetrolPumpBill = () => {
  const navigate = useNavigate();
  const activeButton = "generate_bill";
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
  };

  const backToPendingBill = () => {
    navigate("/bills/petrol-pump/pending-petrol-pump-bill");
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generate-petrol-pump-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={9} md={8} sm={4} xs={12}>
            <BreadCrumbPetrolPump
              activeButton={activeButton}
              page="petrolpump"
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            sm={8}
            xs={12}
            className={classes["back-button-position"]}
          >
            <UIButton
              variant="contained"
              className={`${classes["back-new-entry"]}`}
              startIcon={<ArrowLeftOutlined />}
              onClick={backToPendingBill}
            >
              Pending Bills
            </UIButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generate-petrol-pump-padding"]}
      >
        <Grid className={classes["middle-boundary-transport"]} container>
          <Grid>
            {/* Will change in future dynamically */}
            <Typography
              variant="h5"
              color="#fff"
              className={classes["text-boundary-tranport"]}
            >
              Hira Service Section
            </Typography>
          </Grid>
          <Grid className={classes["total-amount-transport"]}>
            <Typography variant="h6" fontWeight="bold" color="#fff">
              Total Payable Amount
            </Typography>
            <Typography variant="h6" color="#fff" fontWeight="bold">
              = 39483033
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generate-petrol-pump-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                450
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Trip Count
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-second-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Total Excavator Count
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-third-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD LTR In Vehicle
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-fourth-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD LTR In Excavator
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-fivth-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Total HSD LTR
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-sixth-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD Effective Rate
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={4} sm={12} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-seventh-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD Effective Rate
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes["pagination-box"]}>
        <Pagination defaultCurrent={1} total={50} />
      </div>
      <Table
        className={classes["pending-petrol-pump-bill-padding"]}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        bordered={true}
        tableLayout="fixed"
        scroll={{ x: 1500 }}
        pagination={false}
      />
      <div className={classes["pagination-box"]}>
        <Pagination defaultCurrent={1} total={50} />
      </div>

      <Grid className={classes["export-submit-button"]}>
        <UIButton variant="contained" className={classes["submit-button"]}>
          Final Calculation
        </UIButton>
      </Grid>
    </>
  );
};
