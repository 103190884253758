import React, { useEffect, useState } from "react";
import { DatePicker, Drawer, Form, Input, Table, Select, notification, Spin, Tooltip, Pagination, Row, Col, Checkbox } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./PendingTransportBills.module.scss";
import { useNavigate } from "react-router-dom";
import transportAgency from "../../../../assets/images/BillsIcons/transportageny.svg";
import constantsArr from "../../../../constants/global-constants";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import { BreadCrumbTransport } from "../BreadCrumbTransport/BreadCrumbTransport";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

const pendingBillsTablecolumns = [
    {
        title: "S.No.",
        dataIndex: "sl_no",
        width: '6%'
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: '10%'
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: '10%'
    },
    {
        title: "Ch. No.",
        dataIndex: "Challan_Number",
        width: '6%'
    },
    {
        title: "Vehicle No.",
        dataIndex: "Vehicle_Number",
        width: '10%'
    },
    {
        title: "Kanta Slip No",
        dataIndex: "Kanta_Slip_No",
        width: '10%'
    },
    {
        title: "Net wt. UL (MT)",
        dataIndex: "Net_Weight",
        width: '10%'
    },
    {
        title: "Work Rate",
        dataIndex: "Work_Rate",
        width: '8%'
    },
    {
        title: "Work Amt.",
        dataIndex: "Work_Amount",
        width: '8%'
    },
    {
        title: "D/O No",
        dataIndex: "DO_No",
        width: '6%'
    },
    {
        title: "HSD (LTR)",
        dataIndex: "HSD_LTR",
        width: '8%'
    },
    {
        title: "HSD Rate",
        dataIndex: "Rate_INR",
        width: '8%'
    },
    {
        title: "HSD Amt.",
        dataIndex: "HSD_Amt",
        width: '10%'
    },
];

export const PendingTransportBills = () => {
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [filterForm] = Form.useForm()
    const [generateForm] = Form.useForm()
    const [billNo, setBillNo] = useState("NA")
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [totalUnloadingWeight, setTotalUnloadingWeight] = useState(0)
    const [workEffectiveRate, setWorkEffectiveRate] = useState(0)
    const [workAmount, setWorkAmount] = useState(0)
    const [updatedHSD, setUpdatedHSD] = useState(0)
    const [HSDAmount, setHSDAmount] = useState(0)
    const [dieselAmountRatio, setDieselAmountRatio] = useState(0)
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT];
    const [pendingBillsTabledata, setPendingBillsTabledata] = useState([])
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([])
    const [agencies, setAgencies] = useState([]);
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [rangePickerLoadingStartDateDefaultValue, setRangePickerLoadingStartDateDefaultValue] = useState("")
    const [rangePickerLoadingEndDateDefaultValue, setRangePickerLoadingEndDateDefaultValue] = useState("")
    const [rangePickerUnLoadingStartDateDefaultValue, setRangePickerUnLoadingStartDateDefaultValue] = useState("")
    const [rangePickerUnLoadingEndDateDefaultValue, setRangePickerUnLoadingEndDateDefaultValue] = useState("")
    const [rangePickerStartDateValueUnloading, setRangePickerStartDateValueUnloading] = useState("")
    const [rangePickerEndDateValueUnloading, setRangePickerEndDateValueUnloading] = useState("")
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const activeButton = 'pending_bills'
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const [showPagination, setShowPagination] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT);
    const transportPendingBillsSelectedAgency = AppHelper.decryptText(sessionStorage.getItem('transport_pending_bills_selected_agency'))
    const transportPendingBillsActiveSortOrder = AppHelper.decryptText(sessionStorage.getItem('transport_pending_bills_active_sort_order'))
    const transportPendingBillsScreenFilterValues = AppHelper.decryptText(sessionStorage.getItem('transport_pending_bills_filter_values'))
    const transportPendingBillsGeneratedIDs = AppHelper.decryptText(sessionStorage.getItem('transport_pending_bills_to_generate_ids'))
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const [cancelledWithHsdTakenCheckbox, setCancelledWithHsdTakenCheckbox] = useState(false)

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadPendingBills(agencies, transportPendingBillsActiveSortOrder, transportPendingBillsSelectedAgency, true, currentPage, pageSize, transportPendingBillsScreenFilterValues)
    };

    const changeSortOrder = (e) => {
        sessionStorage.setItem("transport_pending_bills_active_sort_order", AppHelper.encryptText(e.target.value))
        setCurrentPagePagination(1)
        loadPendingBills(agencies, e.target.value, transportPendingBillsSelectedAgency, true, 1, pageSize, transportPendingBillsScreenFilterValues)
    }

    const redirectToGenerateBillScreen = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({
                message: "Please select records to generate bill",
                duration: 3
            })
            return false
        } else {
            sessionStorage.setItem('transport_pending_bills_to_generate_ids', AppHelper.encryptText(selectedRowKeys))
            sessionStorage.setItem('bill_no', AppHelper.encryptText(billNo))
            navigate('/bills/transport/generate-bill')
        }
    }

    const onSearch = (value) => console.log("search:", value);

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        sessionStorage.setItem('transport_pending_bills_to_generate_ids', AppHelper.encryptText(newSelectedRowKeys))
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        fixed: "left",
        preserveSelectedRowKeys: true
    };

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
            setRangePickerLoadingStartDateDefaultValue("")
            setRangePickerLoadingEndDateDefaultValue("")
        }
    }

    const handleRangePickerUnloading = (e) => {
        if (e) {
            setRangePickerStartDateValueUnloading(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValueUnloading(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValueUnloading("")
            setRangePickerEndDateValueUnloading("")
            setRangePickerUnLoadingStartDateDefaultValue("")
            setRangePickerUnLoadingEndDateDefaultValue("")
        }
    }

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const handleChangeCheckbox = (e) => {
        setCancelledWithHsdTakenCheckbox(e.target.checked)
    }

    const handleChangeAgency = (agency) => {
        if ((agency && agency !== "") || (transportPendingBillsSelectedAgency && transportPendingBillsSelectedAgency !== "" && transportPendingBillsSelectedAgency !== agency)) {
            sessionStorage.removeItem('adjustedWeightRateValuesGenerateTransport')
            sessionStorage.removeItem('adjustedHSDRateValuesGenerateTransport')
            sessionStorage.removeItem('generateBillObjectGenerateTransport')
            sessionStorage.removeItem('transport_pending_bills_to_generate_ids')
            setSelectedRowKeys([])
        }
        // put selected agency in session storage to keep record intact
        sessionStorage.setItem('transport_pending_bills_selected_agency', agency ? AppHelper.encryptText(agency) : '')

        // find out id of agency by name
        setCurrentPagePagination(1)
        loadPendingBills(agencies, transportPendingBillsActiveSortOrder, agency, true, 1, pageSize, transportPendingBillsScreenFilterValues)
        // remove any stored values in local storage related to transport agency bill because agency is going to change
        sessionStorage.removeItem('adjustedWeightRateValues')
        sessionStorage.removeItem('adjustedHSDRateValues')
        sessionStorage.removeItem('bill_no')
    }

    const loadPendingBills = (agenciesArr, sortOrder, agency, pagination, currentPage, pageSize, filterArgs = {}) => {
        setShowTableSpinner(true)
        const agencyByName = AppHelper.findAgencyIdByNameProject(agenciesArr, agency, sessionGlobalValues?.projectGlobal)
        AppHelper.getPendingBillsTransportingAgency(sortOrder, agencyByName?.id, pagination, currentPage, pageSize, filterArgs).then(response => {
            setGlobalCountItems(response, currentPage)
            setShowPagination(true)
            setShowTableSpinner(false)
            setBillNo(response?.meta?.billNumber ?? "NA")
        }).catch(err => {
            setShowPagination(false)
            notification.error({
                message: "Unable to filter pending bills data",
                description: '',
                duration: 3
            })
        })
    }

    const setGlobalCountItems = (response, currentPage) => {
        setTotalTripCount(response?.meta?.tripCount ?? 0)
        setTotalUnloadingWeight(response?.meta?.unloadingWeight ?? 0)
        setWorkEffectiveRate(response?.meta?.workEffectiveRate ?? 0)
        setWorkAmount(response?.meta?.workAmount ?? 0)
        setUpdatedHSD(response?.meta?.updatedHsdLitre ?? 0)
        setHSDAmount(response?.meta?.hsdAmount ?? 0)
        setDieselAmountRatio(isFinite(response?.meta?.dieselAmountRatio) ? response?.meta?.dieselAmountRatio : 0)
        setTotalPayableAmount(response?.meta?.totalPayableAmount ?? 0)

        // set default loading date filter form range values
        if (transportPendingBillsScreenFilterValues && transportPendingBillsScreenFilterValues?.Date_of_Loading !== "") {
            const breakDateOfLoadingFilterStorage = transportPendingBillsScreenFilterValues?.Date_of_Loading.split("~")
            if (breakDateOfLoadingFilterStorage[0] && breakDateOfLoadingFilterStorage[0] !== "") {
                const startDateRangeLoadingDateDefault = breakDateOfLoadingFilterStorage[0]
                const endDateRangeLoadingDateDefault = breakDateOfLoadingFilterStorage[1]
                setRangePickerLoadingStartDateDefaultValue(startDateRangeLoadingDateDefault ? dayjs(dayjs(startDateRangeLoadingDateDefault, dateFormatDayjsConversion)) : null)
                setRangePickerLoadingEndDateDefaultValue(endDateRangeLoadingDateDefault ? dayjs(dayjs(endDateRangeLoadingDateDefault, dateFormatDayjsConversion)) : null)
            } else {
                setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
                setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
            }
        } else {
            setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
            setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
        }

        // set default unloading date filter form range values
        if (transportPendingBillsScreenFilterValues && transportPendingBillsScreenFilterValues?.Date_of_Unloading !== "") {
            const breakDateOfUnLoadingFilterStorage = transportPendingBillsScreenFilterValues?.Date_of_Unloading.split("~")
            if (breakDateOfUnLoadingFilterStorage[0] && breakDateOfUnLoadingFilterStorage[0] !== "") {
                const startDateRangeUnLoadingDateDefault = breakDateOfUnLoadingFilterStorage[0]
                const endDateRangeUnLoadingDateDefault = breakDateOfUnLoadingFilterStorage[1]
                setRangePickerUnLoadingStartDateDefaultValue(startDateRangeUnLoadingDateDefault ? dayjs(dayjs(startDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
                setRangePickerUnLoadingEndDateDefaultValue(endDateRangeUnLoadingDateDefault ? dayjs(dayjs(endDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
            } else {
                setRangePickerUnLoadingStartDateDefaultValue(response?.meta?.minUnloadingDate ? dayjs(response?.meta?.minUnloadingDate, dateFormatDayjsConversion) : null)
                setRangePickerUnLoadingEndDateDefaultValue(response?.meta?.maxUnloadingDate ? dayjs(response?.meta?.maxUnloadingDate, dateFormatDayjsConversion) : null)
            }
        } else {
            setRangePickerUnLoadingStartDateDefaultValue(response?.meta?.minUnloadingDate ? dayjs(response?.meta?.minUnloadingDate, dateFormatDayjsConversion) : null)
            setRangePickerUnLoadingEndDateDefaultValue(response?.meta?.maxUnloadingDate ? dayjs(response?.meta?.maxUnloadingDate, dateFormatDayjsConversion) : null)
        }
        const getTableData = loadAgencyPendingBillsData(response?.data, currentPage)
        setPendingBillsTabledata(getTableData)
    }

    const filterResults = (formValues) => {
        formValues.Date_of_Loading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        formValues.Date_of_Unloading = `${rangePickerStartDateValueUnloading}~${rangePickerEndDateValueUnloading}`
        if (cancelledWithHsdTakenCheckbox)
            formValues.cancelledWithHsdTakenCheckbox = cancelledWithHsdTakenCheckbox

        sessionStorage.setItem('transport_pending_bills_filter_values', AppHelper.encryptText(formValues))
        loadPendingBills(agencies, transportPendingBillsActiveSortOrder, transportPendingBillsSelectedAgency, true, 1, pageSize, formValues)
        setOpen(false);
        setCurrentPagePagination(1)
        setSelectedRowKeys([])
        sessionStorage.setItem('transport_pending_bills_to_generate_ids', '')
    }

    const exportTransportPendingBills = async () => {

        setIsExportButtonDisabled(true)

        const agencyByName = agencies.find((item) => item?.attributes?.Company_name === transportPendingBillsSelectedAgency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const exportResponse = await AppHelper.exportPendingTransportBills(((transportPendingBillsActiveSortOrder && transportPendingBillsActiveSortOrder !== "") ? transportPendingBillsActiveSortOrder : 'Date_of_Loading:asc'), agencyByName?.id, true, 1, exportPageSize, transportPendingBillsScreenFilterValues, false, transportPendingBillsGeneratedIDs)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Pending Bills", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Date of Unloading', key: "Date_of_Unloading", width: 20 },
                { header: 'Challan No.', key: "Challan_Number", width: 15 },
                { header: 'Vehicle No.', key: "Vehicle_Number", width: 15 },
                { header: 'Kanta Slip No', key: "Kanta_Slip_No", width: 15 },
                { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 15 },
                { header: 'Work Rate', key: "Work_Rate", width: 15 },
                { header: 'Work Amt.', key: "Work_Amount", width: 15 },
                { header: 'D/O No', key: "DO_No", width: 15 },
                { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                { header: 'HSD Rate', key: "Rate_INR", width: 15 },
                { header: 'HSD Amt', key: "HSD_Amt", width: 15 },
            ]

            exportResponse?.pendingBillsRecord.forEach(billData => {
                billData.Date_of_Loading = (billData.Date_of_Loading && billData.Date_of_Loading !== "" && billData.Date_of_Loading !== '-') ? dayjs(billData.Date_of_Loading).format(dateFormat) : '-'
                billData.Date_of_Unloading = (billData.Date_of_Unloading && billData.Date_of_Unloading !== "" && billData.Date_of_Unloading !== '-') ? dayjs(billData.Date_of_Unloading).format(dateFormat) : '-'
                billData.Challan_Number = billData.Challan_Number && !isNaN(parseInt(billData.Challan_Number)) ? parseInt(billData.Challan_Number) : "-";
                billData.Kanta_Slip_No = billData.Kanta_Slip_No && !isNaN(parseInt(billData.Kanta_Slip_No)) ? parseInt(billData.Kanta_Slip_No) : "-";
                workSheetPendingBills.addRow(billData)
            })

            // preapare sheet for global counts

            const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Count Items")
            // prepare sheet columns / headers
            workSheetPendingBillGlobalCounts.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: exportResponse?.totalHeaderCountDetails?.thermalPlantName, key: "second_value", width: 20 },
            ]

            // prepare sheet rows to display
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Project", second_value: exportResponse?.totalHeaderCountDetails?.projectName ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Agency", second_value: exportResponse?.totalHeaderCountDetails?.agencyName ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow({
                first_value: "Bill No.", second_value: exportResponse?.totalHeaderCountDetails?.billNumber ?? ''
            })

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Trip Count", second_value: exportResponse?.totalHeaderCountDetails?.tripCount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total Unloading Wt (MT)", second_value: exportResponse?.totalHeaderCountDetails?.unloadingWeight ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Effective Rate (Rs.)", second_value: exportResponse?.totalHeaderCountDetails?.workEffectiveRate ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Amount (Rs.)", second_value: exportResponse?.totalHeaderCountDetails?.workAmount ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Updated HSD (LTR)", second_value: exportResponse?.totalHeaderCountDetails?.updatedQuantityLitre ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Amount (Rs.)", second_value: exportResponse?.totalHeaderCountDetails?.hsdAmount ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Diesel Amount Ratio", second_value: exportResponse?.totalHeaderCountDetails?.updatedDieselAmountRatio ?? '' }
            )

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `transport_pending_bills_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Transporting pending bills data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setRangePickerStartDateValueUnloading("")
        setRangePickerEndDateValueUnloading("")
        setRangePickerLoadingStartDateDefaultValue("")
        setRangePickerLoadingEndDateDefaultValue("")
        setRangePickerUnLoadingStartDateDefaultValue("")
        setRangePickerUnLoadingEndDateDefaultValue("")
        setCancelledWithHsdTakenCheckbox(false)
        setSelectedRowKeys([])
        sessionStorage.setItem('transport_pending_bills_to_generate_ids', '')
    }

    const preventNegativeValue = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    const loadAgencyPendingBillsData = (responseData, currentPage) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.attributes?.loadingId,
                sl_no: ((parseInt(currentPage) === 1) ? i + 1 : (((parseInt(i) + 1) + ((parseInt(currentPage) - 1)) * parseInt(pageSize)))),
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading) ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.attributes?.Date_of_Unloading) ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number,
                Kanta_Slip_No: responseData[i]?.attributes?.Kanta_Slip_No ?? '-',
                Net_Weight: responseData[i]?.attributes?.Net_Weight ?? 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: parseFloat(responseData[i]?.attributes?.Work_Amount).toFixed(2) ?? 0,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
                Cancelled: responseData[i]?.attributes?.Cancelled ?? false,
            });
        }
        return pendingBillsTabledata
    }

    useEffect(() => {
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));
            // get all the details of all agencies within project
            loadPendingBills(response, transportPendingBillsActiveSortOrder, transportPendingBillsSelectedAgency, true, currentPage, pageSize, transportPendingBillsScreenFilterValues)
        }).catch(err => {
            console.log(`Error while fetching agencies`, err);
            notification.error({
                message: `Error while fetching agencies`, err,
                description: "",
                duration: 3
            })
        });

        if (transportPendingBillsScreenFilterValues && transportPendingBillsScreenFilterValues !== "") {
            filterForm.setFieldsValue({
                Challan_No: transportPendingBillsScreenFilterValues?.Challan_No ?? "",
                D_O_No: transportPendingBillsScreenFilterValues?.D_O_No ?? "",
                Kanta_Slip_No: transportPendingBillsScreenFilterValues?.Kanta_Slip_No ?? ""
            })
            setCancelledWithHsdTakenCheckbox(transportPendingBillsScreenFilterValues?.cancelledWithHsdTakenCheckbox)
        }

        if (transportPendingBillsGeneratedIDs && transportPendingBillsGeneratedIDs !== "") {
            setSelectedRowKeys(transportPendingBillsGeneratedIDs)
        }

        document.title = "Transporting Pending Bills"
    }, [])

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid
                    item
                    lg={8}
                    md={7}
                    sm={12}
                    xs={12}
                    className={classes["gridmarginBottom20"]}
                >
                    <BreadCrumbTransport activeButton={activeButton} page="transportation" />
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    lg={4}
                    md={5}
                    sm={12}
                    xs={12}
                    className={classes["gridmargintop20"]}
                >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Form>
                            <Form.Item
                                label="Sort By"
                                className={classes["select-new-entry"]}
                            >
                                <Select defaultValue={(transportPendingBillsActiveSortOrder && transportPendingBillsActiveSortOrder !== "") ? transportPendingBillsActiveSortOrder : "Date_of_Loading:asc"} className={classes["select-button-height"]} id="sortOrder" onChange={(e) => changeSortOrder({ target: { id: "sortOrder", value: e } })}>
                                    <Select.Option value="Challan_Number:asc">Challan No. (asc)</Select.Option>
                                    <Select.Option value="Challan_Number:desc">Challan No. (desc)</Select.Option>
                                    <Select.Option value="Date_of_Loading:asc">Date Of Loading (asc)</Select.Option>
                                    <Select.Option value="Date_of_Loading:desc">Date Of Loading (desc)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} className={classes['right-align']}>
                        <UIButton
                            variant="outlined"
                            className={classes["filter-new-entry"]}
                            onClick={showDrawer}
                            startIcon={<FilterOutlined />}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={filterForm} onFinish={filterResults}>
                                <Form.Item label="Challan No" name="Challan_No">
                                    <Input
                                        type="number"
                                        min={0}
                                        max={999999}
                                        step={1}
                                        placeholder="Enter Challan No"
                                        onKeyPress={preventNegativeValue}
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Date of Loading" name="Date_of_Loading">
                                    <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} defaultValue={[rangePickerLoadingStartDateDefaultValue, rangePickerLoadingEndDateDefaultValue]} />
                                </Form.Item>
                                <Form.Item label="Date of Unloading" name="Date_of_Unloading">
                                    <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePickerUnloading} format={dateFormat} defaultValue={[rangePickerUnLoadingStartDateDefaultValue, rangePickerUnLoadingEndDateDefaultValue]} />
                                </Form.Item>
                                <Form.Item label="DO No." name="D_O_No">
                                    <Input
                                        placeholder="Enter DO No."
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Kanta Slip No" name="Kanta_Slip_No">
                                    <Input
                                        placeholder="Enter Kanta Slip No"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item-radio']}
                                            name="cancelledWithHsdTaken"
                                        >
                                            <Checkbox value={true} checked={cancelledWithHsdTakenCheckbox} onChange={handleChangeCheckbox} >Cancelled with HSD Taken</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["submit-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid className={classes["transport-agency-bill-number-wrapper"]} container>
                    <Grid className={classes["transport-agency-wrappper"]}>
                        <img src={transportAgency} alt="Transporting agency" />
                        <Typography variant="h6" color="#000" fontWeight="bold">
                            Transporting Agency
                        </Typography>
                        <Form>
                            <Select
                                showSearch
                                allowClear
                                placeholder="All"
                                optionFilterProp="children"
                                defaultValue={transportPendingBillsSelectedAgency && transportPendingBillsSelectedAgency !== "" ? transportPendingBillsSelectedAgency : null}
                                onChange={(e) => handleChangeAgency(e)}
                                onSearch={onSearch}
                                filterOption={filterOption}
                                className={classes["select-new-entry"]}
                                options={transportationAgencyDropDown}
                            />
                        </Form>
                    </Grid>
                    <Grid className={classes["transport-agency-bill-number"]}>
                        <Typography variant="h6" color="#000" fontWeight="bold">Bill Number</Typography>
                        <Typography variant="body" color="#353535" className={classes["hr-transport-box"]}>{billNo}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid className={classes["middle-boundary-transport"]} container>
                    <Grid>
                        {/* Will change in future dynamically */}
                        <Typography variant="h5" color="#fff" className={classes["text-boundary-tranport"]}>{transportPendingBillsSelectedAgency}</Typography>
                    </Grid>
                    <Grid className={classes["total-amount-transport"]}>
                        <Typography variant="h6" fontWeight="bold" color="#fff">Total Payable Amount</Typography>
                        <Typography variant="h6" color="#fff" fontWeight="bold">= &nbsp; &#8377; {totalPayableAmount.toLocaleString('en-IN')}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid className={classes["bottom-transport-counter"]} container>
                    <Grid className={classes["bottom-transport-first-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{totalTripCount}</Typography>
                        <Typography variant="body1" color="#fff" className={classes["para"]}>Trip Count</Typography>
                    </Grid>
                    <Tooltip title={totalUnloadingWeight + " MT"}>
                        <Grid className={classes["bottom-transport-second-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}><span title={totalUnloadingWeight}>{AppHelper.formatNumber(totalUnloadingWeight)}</span></Typography>
                            <Typography variant="body1" color="#fff" className={classes["para"]}>Total Unloading Wt (MT)</Typography>
                        </Grid>
                    </Tooltip>
                    <Grid className={classes["bottom-transport-third-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{workEffectiveRate}</Typography>
                        <Typography variant="body1" color="#fff" className={classes["para"]}>Work Effective Rate (Rs.)</Typography>
                    </Grid>
                    <Tooltip title={"₹ " + workAmount}>
                        <Grid className={classes["bottom-transport-fourth-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}><span title={workAmount}>{AppHelper.formatNumber(workAmount)}</span></Typography>
                            <Typography variant="body1" color="#fff" className={classes["para"]}>Work Amount (Rs.)</Typography>
                        </Grid>
                    </Tooltip>
                    <Grid className={classes["bottom-transport-fivth-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{updatedHSD}</Typography>
                        <Typography variant="body1" color="#fff" className={classes["para"]}>Updated HSD (LTR)</Typography>
                    </Grid>
                    <Tooltip title={"₹ " + HSDAmount}>
                        <Grid className={classes["bottom-transport-sixth-counter"]}>
                            <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}><span title={HSDAmount}>{AppHelper.formatNumber(HSDAmount)}</span></Typography>
                            <Typography variant="body1" color="#fff" className={classes["para"]}>HSD Amount (Rs.)</Typography>
                        </Grid>
                    </Tooltip>
                    <Grid className={classes["bottom-transport-seventh-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{dieselAmountRatio}</Typography>
                        <Typography variant="body1" color="#fff" className={classes["para"]}>Diesel Amount Ratio</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Form layout="vertical" form={generateForm}>
                <>
                    {
                        showPagination === true && totalTripCount > 0 ? (
                            <div className={classes["pagination-box"]}>
                                <Pagination
                                    current={currentPagePagination}
                                    showSizeChanger={false}
                                    defaultCurrent={currentPage}
                                    defaultPageSize={currentPageSizePagination}
                                    showTotal={(total) => `Total ${totalTripCount} items`}
                                    responsive
                                    pageSize={currentPageSizePagination}
                                    onChange={changePageNumber}
                                    total={totalTripCount}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }
                </>
                <Table
                    className={classes["pending-bill-table"]}
                    rowSelection={transportPendingBillsSelectedAgency && transportPendingBillsSelectedAgency !== "" ? rowSelection : false}
                    rowClassName={(record) => record?.Cancelled === true ? classes['table-row-cancelled'] : ''}
                    columns={pendingBillsTablecolumns}
                    dataSource={pendingBillsTabledata}
                    rowKey={(record) => record?.key}
                    bordered={true}
                    scroll={{ x: 1600 }}
                    loading={{ indicator: <div><Spin /></div>, spinning: showTableSpinner }}
                    pagination={false}
                />
                <>
                    {
                        showPagination === true && totalTripCount > 0 ? (
                            <div className={classes["pagination-box"]}>
                                <Pagination
                                    current={currentPagePagination}
                                    showSizeChanger={false}
                                    defaultCurrent={currentPage}
                                    defaultPageSize={currentPageSizePagination}
                                    showTotal={(total) => `Total ${totalTripCount} items`}
                                    responsive
                                    pageSize={currentPageSizePagination}
                                    onChange={changePageNumber}
                                    total={totalTripCount}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }
                </>
                {
                    transportPendingBillsSelectedAgency ?
                        <div className={classes["submit-button-area"]}>
                            <UIButton
                                variant="outlined"
                                className={classes["submit-outline-button"]}
                                onClick={exportTransportPendingBills}
                                disabled={isExportButtonDisabled}
                            >
                                Export
                            </UIButton>
                            <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} onClick={redirectToGenerateBillScreen}>
                                Generate Bill
                            </UIButton>
                        </div>
                        :
                        ""
                }
            </Form>
        </>
    );
};
