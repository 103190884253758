import React from "react";
import classes from "./TripDaysGraph.module.scss";
import ReactApexChart from "react-apexcharts";

export const TripDaysGraph = ({ avg15DaysTripWeightLineChart }) => {

    const seriesArray = []

    if (avg15DaysTripWeightLineChart?.WithoutAgency?.tripRecordsWithoutAgency?.length > 0) {
        seriesArray.push({
            data: avg15DaysTripWeightLineChart?.WithoutAgency?.tripRecordsWithoutAgency.map((item) => { return item?.value ?? 0 })
        })
    }

    if (avg15DaysTripWeightLineChart?.withAgency?.tripRecordsWithAgency?.length > 0) {
        seriesArray.push({
            data: avg15DaysTripWeightLineChart?.withAgency?.tripRecordsWithAgency.map((item) => { return item?.value ?? 0 })
        })
    }

    let colors = []

    if (seriesArray?.length > 1) {
        colors = ["#E1E1E1", "#2CAFFE"]
    } else {
        colors = ["#2CAFFE"]
    }

    const options6 = {
        chart: {
            height: 350,
            type: "line",
            zoom: {
                enabled: false,
            },
            toolbar: {
                show: false,
            },
        },
        colors: colors,
        dataLabels: {
            enabled: true,
        },
        stroke: {
            curve: "smooth",
        },
        grid: {
            borderColor: "#efefef",
            row: {
                colors: ["#ffffff", "transparent"], // takes an array which will be repeated on columns
                opacity: 0.1,
            },
        },
        markers: {
            size: 1,
        },
        xaxis: {
            categories: avg15DaysTripWeightLineChart?.WithoutAgency?.tripRecordsWithoutAgency?.length > 0 ? avg15DaysTripWeightLineChart?.WithoutAgency?.tripRecordsWithoutAgency.map((item) => { return item?.date }) : [],
            title: {
                text: "Month",
            },
        },
        yaxis: {
            title: {
                text: "No. of Trip",
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
            floating: true,
            offsetY: -25,
            offsetX: -5,
        },
    };

    const series6 = seriesArray

    return (
        <>
            {
                avg15DaysTripWeightLineChart?.WithoutAgency?.tripRecordsWithoutAgency?.length > 0
                    ?
                    <ReactApexChart
                        options={options6}
                        series={series6}
                        type="line"
                        height={400}
                    />
                    :
                    <div className={classes['graphArea']}>
                        <div className={classes['spinStyle']}>No Data Found</div>
                    </div>
            }
        </>
    );
};
