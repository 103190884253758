import React, { useEffect, useState } from "react";
import classes from "./Payment.module.scss";
import { Grid, Box } from "@mui/material";
import { PaymentSummary } from "./PaymentSummary/PaymentSummary";
import { BilledDetail } from "./BilledDetail/BilledDetail";
import { PaymentDetail } from "./PaymentDetail/PaymentDetail";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import AppHelper from "../../../helpers/AppHelper";

const TabElementData = (props) => {
    const { value, children, index, ...other } = props
    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className='agency-tab-content'
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        <Box sx={{ pt: 3 }}>
            {children}
        </Box>
    </div>
}

export const Payment = () => {

    const [tabsValue, setTabsValue] = useState(0)

    const sessionActiveTabValue = AppHelper.decryptText(sessionStorage.getItem('payment_screen_active_tab'))

    const onTabChange = (e, newVal) => {
        setTabsValue(newVal)
        sessionStorage.setItem('payment_screen_active_tab', AppHelper.encryptText(newVal))
    }

    useEffect(() => {
        document.title = "Payment "
        if(sessionActiveTabValue && sessionActiveTabValue !== "") {
            setTabsValue(sessionActiveTabValue)
        }
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["payment-padding"]}
            >

                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        {
                            sessionActiveTabValue && sessionActiveTabValue !== ""
                                ?
                                <Tabs TabIndicatorProps={{ style: { backgroundColor: 'rgb(68, 76, 247)' } }} value={sessionActiveTabValue} aria-label="basic tabs example" onChange={onTabChange}>
                                    <Tab key="payment_summary" label="Payment Summary" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                        '&.Mui-selected': {
                                            color: 'rgb(68, 76, 247)',
                                        }
                                    }} />
                                    <Tab key="billed_detail" label="Billed Detail" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                        '&.Mui-selected': {
                                            color: 'rgb(68, 76, 247)',
                                        }
                                    }} />
                                    <Tab key="payment_detail" label="Payment Detail" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                        '&.Mui-selected': {
                                            color: 'rgb(68, 76, 247)',
                                        }
                                    }} />
                                </Tabs>
                                :
                                <Tabs TabIndicatorProps={{ style: { backgroundColor: 'rgb(68, 76, 247)' } }} value={tabsValue} aria-label="basic tabs example" onChange={onTabChange}>
                                    <Tab key="payment_summary" label="Payment Summary" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                        '&.Mui-selected': {
                                            color: 'rgb(68, 76, 247)',
                                        }
                                    }} />
                                    <Tab key="billed_detail" label="Billed Detail" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                        '&.Mui-selected': {
                                            color: 'rgb(68, 76, 247)',
                                        }
                                    }} />
                                    <Tab key="payment_detail" label="Payment Detail" style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '18px' }} sx={{
                                        '&.Mui-selected': {
                                            color: 'rgb(68, 76, 247)',
                                        }
                                    }} />
                                </Tabs>
                        }

                    </Box>
                </Box>

                {
                    sessionActiveTabValue && sessionActiveTabValue !== ""
                        ?
                        sessionActiveTabValue === 0
                            ?
                            <TabElementData value={0} index={0} key="payment_summary" style={{ width: '100%' }}>
                                <PaymentSummary />
                            </TabElementData>
                            :
                            sessionActiveTabValue === 1
                                ?
                                <TabElementData value={1} index={1} key="billed_detail" style={{ width: '100%' }}>
                                    <BilledDetail />
                                </TabElementData>
                                :
                                sessionActiveTabValue === 2
                                    ?
                                    <TabElementData value={2} index={2} key="payment_detail" style={{ width: '100%' }}>
                                        <PaymentDetail />
                                    </TabElementData>
                                    :
                                    ""
                        :
                        tabsValue === 0
                            ?
                            <TabElementData value={0} index={0} key="payment_summary" style={{ width: '100%' }}>
                                <PaymentSummary />
                            </TabElementData>
                            :
                            tabsValue === 1
                                ?
                                <TabElementData value={1} index={1} key="billed_detail" style={{ width: '100%' }}>
                                    <PaymentSummary />
                                </TabElementData>
                                :
                                tabsValue === 2
                                    ?
                                    <TabElementData value={2} index={2} key="payment_detail" style={{ width: '100%' }}>
                                        <PaymentDetail />
                                    </TabElementData>
                                    :
                                    ""
                }
            </Grid >
        </>
    );
};
