import { ArrowLeftOutlined } from '@ant-design/icons';
import { Box, Button, Grid, Typography } from '@mui/material'
import { Form, Input, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classes from "./AgencyData.module.scss";
import AppHelper from '../../../helpers/AppHelper.js'
import constantsArr from '../../../constants/global-constants.js';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import dayjs from 'dayjs';

const TabElementData = (props) => {
    const { value, children, index, ...other } = props
    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className='agency-tab-content'
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        <Box sx={{ pt: 3 }}>
            {children}
        </Box>
    </div>
}

function AgencyDetails() {
    const params = useParams();
    const navigate = useNavigate();
    const agencyId = params?.id ?? ""
    const [agencyDetails, setAgencyDetails] = useState("");
    const [skeletonLoading, setSkeletonLoading] = useState(false)
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [projectName, setProjectName] = useState('');
    const [tabsValue, setTabsValue] = useState(0)
    const [agencyForm] = Form.useForm()

    const backToAgenciesList = (e) => {
        e.preventDefault();
        navigate(`/agencies?currentPage=${pageNo}&pageSize=${pageSize}`)
    }

    const getAgencyDetails = async () => {
        setSkeletonLoading(true)
        AppHelper.getAgencyById(agencyId).then((response) => {
            let formInputDefaultValues = {
                Type_of_agency: response?.data?.attributes?.Type_of_agency,
                Location: response?.data?.attributes?.Location,
                Company_name: response?.data?.attributes?.Company_name,
                Contact_person: response?.data?.attributes?.Contact_person,
                Mobile_Number: response?.data?.attributes?.Mobile_Number,
                Remarks: response?.data?.attributes?.Remarks,
            }

            // set default values for loading rate block
            //const [loadingRateElement, setLoadingRateElement] = useState([{ parent: 0, data: [{ 'start_date': '', 'rate': '' }] }])
            if (response?.data?.attributes?.Loading_Rate) {
                let totalLoadingRateBlocksArr = []
                if (response?.data?.attributes?.Loading_Rate?.Rates.length > 0) {
                    response?.data?.attributes?.Loading_Rate?.Rates.sort((a, b) => new Date(a.Start_Date) - new Date(b.Start_Date));
                    response?.data?.attributes?.Loading_Rate?.Rates.forEach((item, index) => {
                        formInputDefaultValues[`Loading_Rate.Rates.${index}.Start_Date`] = dayjs(item?.Start_Date).format("DD-MM-YYYY")
                        let getAgencyRateType = constantsArr.AGENCY_RATE_TYPES.find((itemInner) => itemInner?.value === item?.Rate_Type)
                        formInputDefaultValues[`Loading_Rate.Rates.${index}.Rate_Type`] = getAgencyRateType?.label ?? 'Per (MT)'
                        formInputDefaultValues[`Loading_Rate.Rates.${index}.Rate`] = item?.Rate
                        totalLoadingRateBlocksArr.push({ 'Start_Date': '', 'Rate_Type': '', 'Rate': '', 'Mode': 'Edit' })
                    })
                }
            }

            // set default values for unloading rate block
            if (response?.data?.attributes?.Unloading_Rates) {
                let totalUnloadingRateBlocksArr = []
                if (response?.data?.attributes?.Unloading_Rates?.Rates.length > 0) {
                    response?.data?.attributes?.Unloading_Rates?.Rates.sort((a, b) => new Date(a.Start_Date) - new Date(b.Start_Date));
                    response?.data?.attributes?.Unloading_Rates?.Rates.forEach((childItem, childIndex) => {
                        formInputDefaultValues[`Unloading_Rates.Rates.${childIndex}.Start_Date`] = dayjs(childItem?.Start_Date).format("DD-MM-YYYY")
                        let getAgencyRateType = constantsArr.AGENCY_RATE_TYPES.find((itemInner) => itemInner?.value === childItem?.Rate_Type)
                        formInputDefaultValues[`Unloading_Rates.Rates.${childIndex}.Rate_Type`] = getAgencyRateType?.label ?? 'Per (MT)'
                        formInputDefaultValues[`Unloading_Rates.Rates.${childIndex}.Rate`] = childItem?.Rate
                        totalUnloadingRateBlocksArr.push({ 'Start_Date': '', 'Rate_Type': '', 'Rate': '', 'Mode': 'Edit' })
                    })
                }
            }

            // set default values for transportation rate block
            if (response?.data?.attributes?.Transportation_Rate) {
                let totalTransportationRateBlocksArr = []
                if (response?.data?.attributes?.Transportation_Rate?.Rates.length > 0) {
                    response?.data?.attributes?.Transportation_Rate?.Rates.sort((a, b) => new Date(a.Start_Date) - new Date(b.Start_Date));
                    response?.data?.attributes?.Transportation_Rate?.Rates.forEach((childItem, childIndex) => {
                        formInputDefaultValues[`Transportation_Rate.Rates.${childIndex}.Start_Date`] = dayjs(childItem?.Start_Date).format("DD-MM-YYYY")
                        let getAgencyRateType = constantsArr.AGENCY_RATE_TYPES.find((itemInner) => itemInner?.value === childItem?.Rate_Type)
                        formInputDefaultValues[`Transportation_Rate.Rates.${childIndex}.Rate_Type`] = getAgencyRateType?.label ?? 'Per (MT)'
                        formInputDefaultValues[`Transportation_Rate.Rates.${childIndex}.Rate`] = childItem?.Rate
                        totalTransportationRateBlocksArr.push({ 'Start_Date': '', 'Rate_Type': '', 'Rate': '', 'Mode': 'Edit' })
                    })
                }
            }

            agencyForm.setFieldsValue(formInputDefaultValues)
            setAgencyDetails(response?.data)
            setSkeletonLoading(false)
        }).catch(err => {
            console.log(err.response.data);
        })
    }

    const onTabChange = (e, newVal) => {
        setTabsValue(newVal)
    }

    const agencyValueIndexInDropDown = (agencyName) => {
        return agencyDetails?.attributes?.Type_of_agency.indexOf(agencyName)
    }


    useEffect(() => {
        document.title = "View Agency"
        getAgencyDetails();

        async function fetchProjectName() {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
            const thermalPlantId = globalTpProjectValues?.thermalPlantGlobal;
            const globalProjectId = globalTpProjectValues?.projectGlobal;
            const projects = await AppHelper.getProjectsByThermalPlants(thermalPlantId);

            let projectNameFromStorage = sessionStorage.getItem('projectName');
            if (!projectNameFromStorage) {
                projects.forEach((value) => {
                    if (value.id === globalProjectId) {
                        projectNameFromStorage = value.Name_of_project;
                        sessionStorage.setItem('projectName', projectNameFromStorage);
                    }
                });
            } else {
                // If projectNameFromStorage exists and the current project id is different from the stored project id,
                // Update the project name in the session storage
                const currentProject = projects.find(project => project.id === globalProjectId);
                if (currentProject && currentProject.Name_of_project !== projectNameFromStorage) {
                    projectNameFromStorage = currentProject.Name_of_project;
                    sessionStorage.setItem('projectName', projectNameFromStorage);
                }
            }

            setProjectName(projectNameFromStorage);
        }

        fetchProjectName();
    }, [])

    return (
        <>
            <Skeleton active loading={skeletonLoading} className={classes["create-agency-padding"]}>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["create-agency-padding"]}
                >
                    <Grid item lg={11} md={11} xs={12}>
                        <Typography variant="h1" fontSize="1.5rem" fontWeight="bold" className="title-un-loading-text">
                            View Agency Details
                        </Typography>
                    </Grid>

                    <Grid item lg={1} md={1} xs={12} container className={classes['action-button']}>
                        <Button size="small" variant="contained" onClick={backToAgenciesList} className={classes['add-new-entry']}>
                            <ArrowLeftOutlined />&nbsp;Agencies
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes['agency-details-margin']}>
                    <Form layout='vertical' form={agencyForm}>
                        <div className={classes['sub-headings']}>Basic Information</div>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid item lg={4} md={4} sm={12}>
                                <Form.Item
                                    messageVariables=""
                                    label="Company Name"
                                    wrapperCol={{ span: 24 }}
                                    name="Company_name"
                                    className={classes['ant-form-item']}>
                                    <Input readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Type of Agency"
                                    name="Type_of_agency"
                                    wrapperCol={{ span: 30 }}>
                                    <Input readOnly />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12}>
                                <Form.Item
                                    messageVariables=""
                                    label="Contact Person"
                                    name='Contact_person'
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}>
                                    <Input readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Location"
                                    wrapperCol={{ span: 24 }}
                                    name="Location">
                                    <Input readOnly />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={4} md={4} sm={12}>
                                <Form.Item
                                    messageVariables=""
                                    name='Mobile_Number'
                                    label="Mobile Number"
                                    wrapperCol={{ span: 30 }}
                                    labelCol={{ span: 30 }}
                                    className={classes['ant-form-item']}>
                                    <Input readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Remarks"
                                    name="Remarks"
                                    wrapperCol={{ span: 24 }}>
                                    <Input readOnly />
                                </Form.Item>
                            </Grid>
                        </Grid>

                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs TabIndicatorProps={{ style: { backgroundColor: 'rgb(68, 76, 247)' } }} value={tabsValue} onChange={onTabChange} aria-label="basic tabs example">
                                    {
                                        agencyDetails?.attributes?.Type_of_agency?.map((agencyName) => (
                                            <Tab key={agencyName} label={agencyName} style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '16px' }} sx={{
                                                '&.Mui-selected': {
                                                    color: 'rgb(68, 76, 247)',
                                                },

                                            }} />
                                        ))
                                    }
                                </Tabs>
                            </Box>
                        </Box>
                        {
                            agencyDetails?.attributes?.Type_of_agency.includes('Loading') &&
                            <TabElementData value={tabsValue} index={agencyValueIndexInDropDown('Loading')} key="loading-details">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    className={classes["tab-content-heading"]}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography variant="body2" color="initial">
                                            Project: <strong>{projectName ?? ''}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    style={{ scrollBehavior: "smooth", overflowY: "scroll", width: "100%", marginTop: "20px", marginLeft: "2px", backgroundColor: "#CAE4FF", padding: "10px", borderRadius: "5px", boxShadow: "0px 1px 10px #00000054" }}
                                >
                                    {/* Render additional form fields based on the additionalFields state */}
                                    {
                                        agencyDetails?.attributes?.Loading_Rate?.Rates?.map((value, key) => (
                                            <Grid container spacing={1} direction="row" justify="center" alignItems="center" alignContent="center" wrap="wrap" key={key}>
                                                <Grid item lg={4} md={4} xs={4}>
                                                    <Form.Item
                                                        messageVariables=""
                                                        label={`Start Date`}
                                                        name={`${`Loading_Rate.Rates.${key}.Start_Date`}`}
                                                    >
                                                        <Input readOnly id={`${`Loading_Rate.Rates.${key}.Start_Date`}`} style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={4}>
                                                    <Form.Item
                                                        messageVariables=""
                                                        label={`Rate Type`}
                                                        name={`${`Loading_Rate.Rates.${key}.Rate_Type`}`}
                                                    >
                                                        <Input readOnly id={`${`Loading_Rate.Rates.${key}.Rate_Type`}`} style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={4}>
                                                    <Form.Item
                                                        messageVariables=""
                                                        label={`Rate`}
                                                        wrapperCol={{ span: 24 }}
                                                        className={classes['ant-form-item']}
                                                        name={`${`Loading_Rate.Rates.${key}.Rate`}`}
                                                    >
                                                        <Input type='number' readOnly id={`${`Loading_Rate.Rates.${key}.Rate`}`} min={0} style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Grid>
                                            </Grid>
                                        ))}

                                </Grid>
                            </TabElementData>
                        }
                        {
                            agencyDetails?.attributes?.Type_of_agency.includes('Unloading') &&
                            <TabElementData value={tabsValue} index={agencyValueIndexInDropDown('Unloading')} key="unloading-details">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    className={classes["tab-content-heading"]}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography variant="body2" color="initial">
                                            Project: <strong>{projectName ?? ''}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    style={{ scrollBehavior: "smooth", overflowY: "scroll", maxHeight: "750px", width: "100%", marginTop: "20px", marginLeft: "2px", backgroundColor: "#D5E3CD", padding: "10px", borderRadius: "5px", boxShadow: "0px 1px 10px #00000054" }}
                                >
                                    {/* Render additional form fields based on the additionalFields state */}
                                    {agencyDetails?.attributes?.Unloading_Rates?.Rates?.map((value, key) => (
                                        <Grid container spacing={1} direction="row" justify="center" alignItems="center" alignContent="center" wrap="wrap" key={key}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Start Date`}
                                                    name={`${`Unloading_Rates.Rates.${key}.Start_Date`}`}>
                                                    <Input readOnly placeholder='Pick a date' format="DD-MM-YYYY" style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Rate Type`}
                                                    name={`${`Unloading_Rates.Rates.${key}.Rate_Type`}`}
                                                >
                                                    <Input readOnly id={`${`Unloading_Rates.Rates.${key}.Rate_Type`}`} style={{ width: "100%" }} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                    name={`${`Unloading_Rates.Rates.${key}.Rate`}`}
                                                >
                                                    <Input type='number' readOnly id={`${`Unloading_Rates.Rates.${key}.Rate`}`} min={0} />
                                                </Form.Item>
                                            </Grid>
                                        </Grid>
                                    ))}

                                </Grid>
                            </TabElementData>
                        }
                        {
                            agencyDetails?.attributes?.Type_of_agency.includes('Transportation') &&
                            <TabElementData value={tabsValue} index={agencyValueIndexInDropDown('Transportation')} key="transportation-details">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    className={classes["tab-content-heading"]}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography variant="body2" color="initial">
                                            Project: <strong>{projectName ?? ''}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    style={{ scrollBehavior: "smooth", overflowY: "scroll", maxHeight: "750px", width: "100%", marginTop: "20px", marginLeft: "2px", backgroundColor: "#F9EAE0", padding: "10px", borderRadius: "5px", boxShadow: "0px 1px 10px #00000054" }}
                                >
                                    {/* Render additional form fields based on the additionalFields state */}
                                    {
                                        agencyDetails?.attributes?.Transportation_Rate?.Rates?.map((value, key) => (
                                            <Grid container spacing={1} direction="row" justify="center" alignItems="center" alignContent="center" wrap="wrap" key={key}>
                                                <Grid item lg={4} md={4} xs={4}>
                                                    <Form.Item
                                                        messageVariables=""
                                                        label={`Start Date`}
                                                        name={`${`Transportation_Rate.Rates.${key}.Start_Date`}`}
                                                    >
                                                        <Input readOnly format="DD-MM-YYYY" id={`${`Transportation_Rate.Rates.${key}.Start_Date`}`} placeholder='Pick a date' style={{ width: "100%" }} name={`${`Transportation_Rate.Rates.${key}.Start_Date`}`} />
                                                    </Form.Item>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={4}>
                                                    <Form.Item
                                                        messageVariables=""
                                                        label={`Rate Type`}
                                                        name={`${`Transportation_Rate.Rates.${key}.Rate_Type`}`}
                                                    >
                                                        <Input readOnly id={`${`Transportation_Rate.Rates.${key}.Rate_Type`}`} style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </Grid>
                                                <Grid item lg={4} md={4} xs={4}>
                                                    <Form.Item
                                                        messageVariables=""
                                                        label={`Rate`}
                                                        wrapperCol={{ span: 24 }}
                                                        className={classes['ant-form-item']}
                                                        name={`${`Transportation_Rate.Rates.${key}.Rate`}`}
                                                    >
                                                        <Input type='number' readOnly id={`${`Transportation_Rate.Rates.${key}.Rate`}`} min={0} />
                                                    </Form.Item>
                                                </Grid>
                                            </Grid>
                                        ))}

                                </Grid>
                            </TabElementData>
                        }
                    </Form>
                </div >
            </Skeleton>
        </>
    )
}

export default AgencyDetails