import React, { useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./GeneratedPetrolPumpBill.module.scss";
import petrolPumpImg from "../../../../assets/images/BillsIcons/petrolpumpimg.svg";
import {
  Form,
  Select,
  DatePicker,
  Drawer,
  Input,
  Tooltip,
  Popconfirm,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  ExportOutlined,
  FilterOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";

export const GeneratedPetrolPumpBill = () => {
  const [open, setOpen] = useState(false);
  const showDrawer = () => setOpen(true);
  const { RangePicker } = DatePicker;

  const onClose = () => setOpen(false);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generated-petrol-pump-bill-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid className={classes["petrol-pump-bill-number-wrapper"]}>
              <img src={petrolPumpImg} alt="petrol pump agency" />
              <Typography variant="h6" color="#000" fontWeight="bold">
                Petrol Pump
              </Typography>{" "}
              <Form>
                <Select
                  showSearch
                  allowClear
                  placeholder="All"
                  optionFilterProp="children"
                  filterOption={filterOption}
                  className={classes["select-new-entry"]}
                />
              </Form>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid className={classes["petrol-pump-bill-select-filter-wrapper"]}>
              <div>
                <Form>
                  <Form.Item
                    label="Sort By"
                    className={classes["select-new-entry"]}
                  >
                    <Select className={classes["select-button-height"]}>
                      <Select.Option value="Challan_Number:asc">
                        Challan No. (asc)
                      </Select.Option>
                      <Select.Option value="Challan_Number:desc">
                        Challan No. (desc)
                      </Select.Option>
                      <Select.Option value="Date_of_Loading:asc">
                        Date Of Loading (asc)
                      </Select.Option>
                      <Select.Option value="Date_of_Loading:desc">
                        Date Of Loading (desc)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              </div>

              <div>
                <UIButton
                  variant="outlined"
                  className={classes["filter-new-entry"]}
                  onClick={showDrawer}
                  startIcon={<FilterOutlined />}
                >
                  Filters
                </UIButton>
                <Drawer title="Filters" onClose={onClose} open={open}>
                  <Form layout="vertical">
                    <Form.Item label="Bill Date Range" name="bill-date">
                      <RangePicker style={{ width: "100%" }} picker="date" />
                    </Form.Item>
                    <Form.Item label="Bill No." name="bill_number">
                      <Input
                        placeholder="Enter Bill No."
                        addonBefore={<SearchOutlined />}
                      />
                    </Form.Item>
                    <div className={classes["search-button-area"]}>
                      <UIButton size="medium" variant="outlined" color="error">
                        Reset
                      </UIButton>
                      <UIButton
                        size="medium"
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes["submit-button"]}
                      >
                        Apply
                      </UIButton>
                    </div>
                  </Form>
                </Drawer>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generated-petrol-pump-bill-padding"]}
      >
        <Grid className={classes["middle-boundary-transport"]} container>
          <Grid>
            {/* Will change in future dynamically */}
            <Typography
              variant="h5"
              color="#fff"
              className={classes["text-boundary-tranport"]}
            >
              Hira Service Section
            </Typography>
          </Grid>
          <Grid className={classes["total-amount-transport"]}>
            <Typography variant="h6" fontWeight="bold" color="#fff">
              Total Payable Amount
            </Typography>
            <Typography variant="h6" color="#fff" fontWeight="bold">
              = 39483033
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generated-petrol-pump-bill-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                450
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Trip Count
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Total Excavator Count
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD LTR In Vehicle
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD LTR In Excavator
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Total HSD LTR
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={2} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD Effective Rate
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generated-petrol-pump-bill-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={9.5} md={8.5} sm={12} xs={12}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              spacing={2}
              textAlign="center"
            >
              <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <Typography variant="body1" color="initial" fontWeight="bold">
                  Bill Prefix
                </Typography>
              </Grid>
              <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <Typography variant="body1" color="initial" fontWeight="bold">
                  Bill No.
                </Typography>
              </Grid>
              <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <Typography variant="body1" color="initial" fontWeight="bold">
                  Pump Name
                </Typography>
              </Grid>
              <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <Typography variant="body1" color="initial" fontWeight="bold">
                  Bill Date (From - To)
                </Typography>
              </Grid>
              <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                <Typography variant="body1" color="initial" fontWeight="bold">
                  Net Payable Amount
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={2.5} md={3.5} sm={12} xs={12}>
            <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                &nbsp;
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                &nbsp;
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                &nbsp;
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["generated-petrol-pump-bill-padding"]}
      >
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          spacing={2}
          className={classes["bill-detail-padding"]}
          marginBottom="10px"
        >
          <Grid item lg={9.5} md={8.5} sm={12} xs={12}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              spacing={2}
              className={classes["petrol-pump-view-detail"]}
            >
              <Link className={classes["view-list-flex-link"]} to="/bills/petrol-pump/view-transaction-summary">
                <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                  <Typography variant="body1" color="#555555" fontWeight="bold">
                    PT
                  </Typography>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                  <Typography variant="body1" color="#555555" fontWeight="bold">
                    08
                  </Typography>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                  <Typography variant="body1" color="#555555" fontWeight="bold">
                    Hire Station Station
                  </Typography>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                  <Typography variant="body1" color="#555555" fontWeight="bold">
                    23/Dec/2024 - 30/Apr/2024
                  </Typography>
                </Grid>
                <Grid item lg={2.4} md={2.4} sm={2.4} xs={2.4}>
                  <Typography variant="body1" color="#555555" fontWeight="bold">
                    8347783
                  </Typography>
                </Grid>
              </Link>
            </Grid>
          </Grid>
          <Grid item lg={2.5} md={3.5} sm={12} xs={12} textAlign="right">
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              container
              spacing={2}
              marginTop="-12px"
            >
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Tooltip title="Export">
                  <Link className={classes["bill-numbers-icons"]}>
                    <ExportOutlined style={{ color: "#555555" }} />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Tooltip title="Edit">
                  <Link className={classes["bill-numbers-icons"]} to={"/bills/petrol-pump/edit-pending-petrol-pump-bill"}>
                    <EditOutlined style={{ color: "#555555" }} />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item lg={4} md={4} sm={4} xs={4}>
                <Popconfirm
                  title="Delete the task"
                  description="Are you sure you want to delete this Bill? The vehicle list will be moved to Pending bills."
                  okText="Yes"
                  cancelText="No"
                >
                  <Link className={classes["bill-numbers-icons"]}>
                    <DeleteOutlined style={{ color: "#ff0000" }} />
                  </Link>
                </Popconfirm>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
