import React, { useEffect, useState } from "react";
import classes from "./PendingClientBillingGenerate.module.scss";
import { Typography, Button as UIButton, Grid } from "@mui/material";
import { BreadCrumbClientBilling } from "../BreadCrumbClientBilling/BreadCrumbClientBilling";
import { ArrowLeftOutlined, FilterOutlined, SearchOutlined } from "@ant-design/icons";
import {
    DatePicker,
    Drawer,
    Form,
    Input,
    Table,
    Select,
    Spin,
    notification,
    Pagination,
} from "antd";
import CBTripCount from "../../../../assets/images/BillsIcons/CBTripCount.svg";
import CBStartDate from "../../../../assets/images/BillsIcons/CBstartdate.svg";
import CBLastDate from "../../../../assets/images/BillsIcons/CBlastdate.svg";
import CBNetWeight from "../../../../assets/images/BillsIcons/CBnetweight.svg";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const pendingBillsTablecolumns = [
    {
        title: "S.No.",
        dataIndex: "s_no",
        width: "70px",
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: "140px",
    },
    {
        title: "Loading Agency",
        dataIndex: "Loading_Agency",
        width: "170px",
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: "110px",
    },
    {
        title: "Unloading Agency",
        dataIndex: "Unloading_Agency",
        width: "180px",
    },
    {
        title: "Vehicle Number",
        dataIndex: "Vehicle_Number",
        width: "140px",
    },
    {
        title: "Challan No.",
        dataIndex: "Challan_Number",
        width: "120px",
    },
    {
        title: "D/O No.",
        dataIndex: "DO_No",
        width: "110px",
    },
    {
        title: "HSD LTR",
        dataIndex: "HSD_LTR",
        width: "110px",
    },
    {
        title: "Transporter Agency",
        dataIndex: "Transporter_Agency",
        width: "180px",
    },
    {
        title: "Thermal Plant Book No.",
        dataIndex: "Thernal_Plant_Book_No",
        width: "110px",
    },
    {
        title: "Net weight (UL) (MT)",
        dataIndex: "Net_Weight",
        width: "110px",
    },
    {
        title: "Gross weight (MT)",
        dataIndex: "Gross_weight",
        width: "130px",
    },
    {
        title: "Tare weight (MT)",
        dataIndex: "Tare_weight",
        width: "110px",
    },
    {
        title: "Kanta Slip No. UL",
        dataIndex: "Kanta_slip_no",
        width: "110px",
    },
    {
        title: "Unloading Location",
        dataIndex: "Unloading_location",
        width: "170px",
    },
];

export const PendingClientBillingGenerate = () => {
    const activeButton = "pending_client_bills_generate";
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const navigate = useNavigate()
    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [pendingBillsTabledata, setPendingBillsTabledata] = useState([])
    const sortOrder = "Date_of_Unloading:asc"
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT];
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const selectedLoadingIds = AppHelper.decryptText(sessionStorage.getItem('client_pending_bills_selected_loading_ids'))
    const selectedBillDate = AppHelper.decryptText(sessionStorage.getItem('selected_bill_date'))
    const [billNo, setBillNo] = useState("NA")
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [minUnloadingDate, setMinUnloadingDate] = useState(0)
    const [maxUnloadDate, setMaxUnloadingDate] = useState(0)
    const [totalNetWeight, setTotalNetWeight] = useState(0)
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [filterForm] = Form.useForm()
    const [billDateOnChange, setBillDateOnChange] = useState("")
    const [filterFormValues, setFilterFormValues] = useState({})
    const currentDate = dayjs().format("YYYY-MM-DD")
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT);
    const [showPagination, setShowPagination] = useState(false);

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadPendingBillsSelected(sortOrder, currentPage, pageSize, true, filterFormValues, selectedLoadingIds)
    };


    const changeSortOrder = (e) => {
        loadPendingBillsSelected(e.target.value, 1, pageSize, true, filterFormValues, selectedLoadingIds)
    }

    const changeBillDate = (e) => {
        setBillDateOnChange(e.target.value)
        const getBillStorageData = AppHelper.decryptText(sessionStorage.getItem("billDataStorage"))
        getBillStorageData.bill_date = e.target.value
        sessionStorage.setItem("billDataStorage", AppHelper.encryptText(getBillStorageData))
    }

    const backToPendingBill = () => {
        navigate('/bills/client-bill/pending-client-bill')
    }

    const redirectToFinalCalculation = () => {
        navigate('/bills/client-bill/final-calculation')
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
    }
    const setGlobalCountItems = (response) => {
        setTotalTripCount(response?.meta?.totalLoading ?? 0)
        setMinUnloadingDate(response?.meta?.start_unloading_date && response?.meta?.start_unloading_date !== "" ? dayjs(response?.meta?.start_unloading_date).format("DD-MM-YYYY") : '-')
        setMaxUnloadingDate(response?.meta?.end_unloading_date && response?.meta?.end_unloading_date !== "" ? dayjs(response?.meta?.end_unloading_date).format("DD-MM-YYYY") : '-')
        setTotalNetWeight(response?.meta?.total_net_weight ?? 0)
        setBillNo(response?.meta?.billNumber ?? "NA")
        const getTableData = loadAgencyPendingBillsData(response?.data)
        setPendingBillsTabledata(getTableData)
        const billDataStorage = {
            start_unloading_date: response?.meta?.start_unloading_date,
            end_unloading_date: response?.meta?.end_unloading_date,
            trip_count: response?.meta?.totalLoading,
            bill_no: response?.meta?.billNumber,
            bill_date: ((billDateOnChange && billDateOnChange !== "") ? billDateOnChange : selectedBillDate),
            net_weight: response?.meta?.total_net_weight
        }
        sessionStorage.setItem("billDataStorage", AppHelper.encryptText(billDataStorage))
    }

    const loadAgencyPendingBillsData = (responseData) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.id,
                s_no: ((parseInt(currentPage) === 1) ? i + 1 : (((parseInt(i) + 1) + ((parseInt(currentPage) - 1)) * parseInt(pageSize)))),
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading) ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.attributes?.Date_of_Unloading) ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number ?? "",
                Kanta_slip_no: responseData[i]?.attributes?.Kanta_Slip_No ?? '-',
                Net_Weight: responseData[i]?.attributes?.Net_Weight ? parseFloat((responseData[i]?.attributes?.Net_Weight).toFixed(2)) : 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: parseFloat(responseData[i]?.attributes?.Work_Amount).toFixed(2) ?? 0,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
                Loading_Agency: responseData[i]?.attributes?.Loading_Agency ? responseData[i]?.attributes?.Loading_Agency : "",
                Unloading_Agency: responseData[i]?.attributes?.Unloading_Agency ? responseData[i]?.attributes?.Unloading_Agency : "",
                Transporter_Agency: responseData[i]?.attributes?.Transporter_Agency ? responseData[i]?.attributes?.Transporter_Agency : "",
                Thernal_Plant_Book_No: responseData[i]?.attributes?.Thermal_Plant_Book_no ? responseData[i]?.attributes?.Thermal_Plant_Book_no : "",
                Gross_weight: parseFloat((responseData[i]?.attributes?.Gross_weight).toFixed(2)) ?? 0,
                Tare_weight: parseFloat((responseData[i]?.attributes?.Tare_weight).toFixed(2)) ?? 0,
                Unloading_location: responseData[i]?.attributes?.Unloading_Location ? responseData[i]?.attributes?.Unloading_Location : "",
            });
        }
        return pendingBillsTabledata
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
        }
    }

    const filterResults = (formValues) => {
        formValues.Date_of_Unloading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        loadPendingBillsSelected(sortOrder, currentPage, pageSize, true, formValues, selectedLoadingIds)
        setOpen(false);
        setFilterFormValues(formValues)
    }

    const loadPendingBillsSelected = (sortOrder, currentPage, pageSize, pagination, formValues, selectedLoadingIds) => {
        setShowTableSpinner(true)
        AppHelper.getSelectedLoadingPendingClientBills(sortOrder, currentPage, pageSize, pagination, formValues, selectedLoadingIds).then(response => {
            setGlobalCountItems(response)
            setShowTableSpinner(false)
            setShowPagination(true)
            setBillNo(response?.meta?.billNumber ?? billNo)
        }).catch(err => {
            setShowPagination(false)
            console.log(`Error while getting pending bills results`, err);
            notification.error({
                message: `Error while getting pending bills results`, err,
                description: "",
                duration: 3
            })
            setShowTableSpinner(false)
        })
    }

    useEffect(() => {
        loadPendingBillsSelected(sortOrder, currentPage, pageSize, true, filterFormValues, selectedLoadingIds)
        document.title = "Pending Client Bills Generate"
    }, [])
    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid
                    item
                    lg={7}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes["gridmarginBottom20"]}
                >
                    <BreadCrumbClientBilling
                        activeButton={activeButton}
                        page="clientBill"
                    />
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    lg={5}
                    md={6}
                    sm={12}
                    xs={12}
                    className={classes["gridmargintop20"]}
                >
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Form>
                            <Form.Item
                                label="Sort By"
                                className={classes["select-new-entry"]}
                            >
                                <Select defaultValue="Date_of_Unloading:asc" className={classes["select-button-height"]} id="sortOrder" onChange={(e) => changeSortOrder({ target: { id: "sortOrder", value: e } })}>
                                    <Select.Option value="Challan_Number:asc">Challan No. (asc)</Select.Option>
                                    <Select.Option value="Date_of_Unloading:asc">Date Unloading (asc)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} className={classes['right-align']}>
                        <UIButton
                            variant="contained"
                            className={`${classes["back-new-entry"]}`}
                            startIcon={<ArrowLeftOutlined />}
                            onClick={backToPendingBill}
                        >
                            Pending Bills
                        </UIButton>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} className={classes['right-align']}>
                    <UIButton
                        onClick={showDrawer}
                        variant="outlined"
                        startIcon={<FilterOutlined />}
                        className={classes["filter-new-entry"]}
                    >
                        Filters
                    </UIButton>
                    <Drawer title="Filters" onClose={onClose} open={open}>
                        <Form layout="vertical" form={filterForm} onFinish={filterResults}>
                            <Form.Item label="Date of Unloading" name="Date">
                                <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} />
                            </Form.Item>
                            <Form.Item label="Challan No" name="Challan_Number">
                                <Input
                                    type="number"
                                    min={0}
                                    max={999999}
                                    step={1}
                                    placeholder="Enter Challan No"
                                    addonBefore={<SearchOutlined />}
                                />
                            </Form.Item>
                            <Form.Item label="Thermal Plant Book No." name="Thermal_Plant_Book_no">
                                <Input
                                    placeholder="Enter Thermal Plant Book No."
                                    addonBefore={<SearchOutlined />}
                                />
                            </Form.Item>
                            <Form.Item label="Kanta Slip No. UL" name="Kanta_Slip_No">
                                <Input
                                    placeholder="Enter Kanta Slip No. UL"
                                    addonBefore={<SearchOutlined />}
                                />
                            </Form.Item>
                            <div className={classes["search-button-area"]}>
                                <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                    Reset
                                </UIButton>
                                <UIButton
                                    size="medium"
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    className={classes["submit-button"]}
                                >
                                    Apply
                                </UIButton>
                            </div>
                        </Form>
                    </Drawer>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid className={classes["client-bill-number-wrapper"]} container>
                    <Grid className={classes["client-bill-wrappper"]}>
                        <Typography variant="h6" color="#000" fontWeight="bold">
                            Bill Date
                        </Typography>
                        <Form>
                            <DatePicker style={{ width: "100%" }} defaultValue={dayjs(selectedBillDate)} onChange={(e) => changeBillDate({ target: { id: "BllDate", value: e } })} format={dateFormat} maxDate={dayjs(currentDate)} />
                        </Form>
                    </Grid>
                    <Grid className={classes["client-bill-number"]}>
                        <Typography variant="h6" color="#000" fontWeight="bold">
                            Bill Number
                        </Typography>
                        <Typography
                            variant="body"
                            color="#353535"
                            className={classes["hr-client-box"]}
                        >
                            {billNo}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    container
                    spacing={0}
                    className={classes["headerMarginTop"]}
                >
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-first"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBTripCount}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {totalTripCount}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Trip Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBStartDate}
                                        alt="Starting Date (UL)"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {minUnloadingDate}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Starting Date (UL)
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-third"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBLastDate}
                                        alt="End Date (UL)"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {maxUnloadDate}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        End Date (UL)
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBNetWeight}
                                        alt="Net Weight (UL)"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {totalNetWeight}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Net Weight (UL)
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <>
                {
                    showPagination === true && totalTripCount > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${totalTripCount} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={totalTripCount}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>

            <Form>
                <Table
                    className={classes["pending-client-bill"]}
                    rowSelection={false}
                    columns={pendingBillsTablecolumns}
                    dataSource={pendingBillsTabledata}
                    rowKey={(record) => record?.key}
                    bordered={true}
                    scroll={{ y: 800 }}
                    loading={{ indicator: <div><Spin /></div>, spinning: showTableSpinner }}
                    pagination={false}
                />
            </Form>

            <>
                {
                    showPagination === true && totalTripCount > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${totalTripCount} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={totalTripCount}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>

            <div className={classes["submit-button-area"]}>
                <UIButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes["submit-button"]}
                    onClick={redirectToFinalCalculation}
                >
                    Final Calculation
                </UIButton>
            </div>
        </>
    );
};
