import React from "react";
import ReactApexChart from "react-apexcharts";
import moment from "moment";

export const ProjectTimelineGraph = ({ timelineChartInfo, projectDetails }) => {

    // Initialize the Gantt module
    // HighchartsGantt(Highcharts);

    // Define the chart options
    // const options = {
    //     chart: {
    //         type: "gantt",
    //     },
    //     yAxis: {
    //         uniqueNames: true,
    //     },
    //     xAxis: {
    //         type: 'datetime'
    //     },
    //     scrollbar: {
    //         enabled: false,
    //     },
    //     lang: {
    //         accessibility: {
    //             axis: {
    //                 xAxisDescriptionPlural:
    //                     "The chart has a two-part X axis " +
    //                     "showing time in both week numbers and days.",
    //                 yAxisDescriptionPlural:
    //                     "The chart has one Y axis showing " + "task categories.",
    //             },
    //         },
    //     },
    //     series: [
    //         {
    //             name: "Project 1",
    //             data: [
    //                 {
    //                     start: Date.UTC(2017, 11, 1),
    //                     end: Date.UTC(2018, 1, 2),
    //                     completed: {
    //                         amount: 0.95,
    //                     },
    //                     name: "Total days as per PO",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 1, 2),
    //                     end: Date.UTC(2018, 11, 5),
    //                     completed: {
    //                         amount: 0.5,
    //                     },
    //                     name: "Working till today",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 11, 8),
    //                     end: Date.UTC(2018, 11, 9),
    //                     completed: {
    //                         amount: 0.15,
    //                     },
    //                     name: "Remaining Days",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 11, 9),
    //                     end: Date.UTC(2018, 11, 19),
    //                     completed: {
    //                         amount: 0.3,
    //                         fill: "#fa0",
    //                     },
    //                     name: "Tentative Days",
    //                 },
    //                 {
    //                     start: Date.UTC(2018, 11, 10),
    //                     end: Date.UTC(2018, 11, 23),
    //                     name: "Difference",
    //                 }
    //             ],
    //         },
    //     ],
    // };

    const getDaysBetweenDates = (startDate, endDate) => {
        const startDateObj = moment(startDate)

        const endDateObj = moment(endDate)

        let dateDifference = Math.abs(endDateObj.diff(startDateObj, 'days'))

        // increase by 1 for valid counting of days
        dateDifference = dateDifference + 1

        return Math.floor(dateDifference);
    }

    let remainingDaysArr = []

    if (timelineChartInfo?.isProjectCompletedBeforeTime || timelineChartInfo?.isProjectCompleted) {
        remainingDaysArr = [
            new Date(projectDetails?.Start_Date).getTime(),
            new Date(projectDetails?.Project_Completion_Date).getTime(),
        ]
    } else {
        remainingDaysArr = [
            new Date(timelineChartInfo?.remainingDaysDateRange?.startDate).getTime(),
            new Date(timelineChartInfo?.remainingDaysDateRange?.endDate).getTime()
        ]
    }

    const series = [
        {
            data: [
                {
                    x: 'Total days as per PO',
                    y: [
                        new Date(timelineChartInfo?.totalDaysAsPerPODateRange?.startDate).getTime(),
                        new Date(timelineChartInfo?.totalDaysAsPerPODateRange?.endDate).getTime()
                    ],
                    fillColor: '#0067b6'
                },
                {
                    x: 'Working till today',
                    y: [
                        new Date(timelineChartInfo?.workingTillTodayDateRange?.startDate).getTime(),
                        new Date(timelineChartInfo?.workingTillTodayDateRange?.endDate).getTime()
                    ],
                    fillColor: '#50d3ff'
                },
                {
                    x: `Remaining days`,
                    y: remainingDaysArr,
                    fillColor: timelineChartInfo?.isProjectCompletedBeforeTime ? '#479036' : ((timelineChartInfo?.isProjectCompleted) ? '#444cf7' : '#625dca')
                },
                {
                    x: 'Tentative days',
                    y: [
                        new Date(timelineChartInfo?.tentativeDaysDateRange?.startDate).getTime(),
                        new Date(timelineChartInfo?.tentativeDaysDateRange?.endDate).getTime()
                    ],
                    fillColor: '#dfa803'
                },
                {
                    x: 'Difference',
                    y: [
                        new Date(timelineChartInfo?.differenceDateRange?.startDate).getTime(),
                        new Date(timelineChartInfo?.differenceDateRange?.endDate).getTime()
                    ],
                    fillColor: timelineChartInfo?.differenceCount < 0 ? '#e93838' : "#479036"
                }
            ]
        }
    ]

    const options = {
        chart: {
            height: 350,
            type: 'rangeBar',
            zoom: {
                enabled: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                distributed: true,
                dataLabels: {
                    hideOverflowingLabels: false
                },
                barHeight: '45%',
                borderRadius: 10
            }
        },
        dataLabels: {
            enabled: true,
            hideOverflowingLabels: false,
            formatter: function (val, opts) {
                var diff = getDaysBetweenDates(val[0], val[1])
                if (opts?.dataPointIndex === 2) {
                    return timelineChartInfo?.isProjectCompletedBeforeTime ? 'Project Completed Before Time' : ((timelineChartInfo?.isProjectCompleted) ? 'Project Completed' : diff + (diff > 1 ? ' days' : ' day'))
                } else {
                    return diff + (diff > 1 ? ' days' : ' day')
                }
            },
            style: {
                colors: ['#f3f4f5', '#fff'],
                fontSize: '16px'
            }
        },
        xaxis: {
            type: 'datetime',
            position: 'top',
            labels: {
                format: 'dd-MM-yyyy',
            }
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy' // Change this format to your desired one
            }
        },
        yaxis: {
            show: true,
            labels: {
                style: {
                    fontSize: '14px',
                    width: 500
                }
            }
        },
        grid: {
            padding: {
                left: 50,
                right: 10
            },
            row: {
                colors: ['#f3f4f5', '#fff'],
                opacity: 1
            }
        }
    }

    return (
        <>
            {/* <HighchartsReact
                highcharts={Highcharts}
                constructorType={"ganttChart"}
                options={options}
            /> */}
            < ReactApexChart options={options} series={series} type="rangeBar" height={350} />
        </>
    );
};
