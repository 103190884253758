import { Typography, Grid, Button as UIButton } from "@mui/material";
import {
    Card,
    DatePicker,
    Drawer,
    Form,
    Input,
    Modal,
    Pagination,
    Select,
    Spin,
    Upload,
    notification,
    Row,
    Col,
    Popconfirm,
    Tooltip,
    Checkbox
} from "antd";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import classes from "./ExcavatorData.module.scss";
import AppHelper from "../../../helpers/AppHelper";
import {
    DeleteOutlined,
    EditOutlined,
    EyeOutlined,
    FilterOutlined,
    ImportOutlined,
    SearchOutlined,
    UploadOutlined,
    LoadingOutlined
} from "@ant-design/icons";
import dayjs from "dayjs";
import constants from "../../../constants/agency-constant";
import constantsArr from "../../../constants/global-constants";
import importExcavatorSampleFile from '../../../assets/import-samples/excavator_import_data.xlsx';
import totalCountImg from "../../../assets/images/HeaderIcons/totalcount.svg"
import totalLitresImg from "../../../assets/images/HeaderIcons/totalLitres.svg"
import totalAmountImg from "../../../assets/images/HeaderIcons/totalamount.svg"

export const ExcavatorData = () => {
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const params = new URLSearchParams(document.location.search);
    const [hsdDetailsData, setHSDDetailsData] = useState(true);
    const [showPagination, setShowPagination] = useState(false);
    const [currentPagePagination, setCurrentPagePagination] = useState(params.get('currentPage') ?? 1);
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE);
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [excavatorFilterForm] = Form.useForm()
    const [currentSetFilterValues, setCurrentSetFilterValues] = useState({})
    const [currentPage, pageSize] = [params.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE, params.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE];
    const [agencyDataDown, setagencyDataDown] = useState([]);
    const [loadingAgencyLists, setloadingAgencyLists] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState([]);
    const [excelImportErrors, setExcelImportErrors] = useState({})
    const [isModalOpenExcelErrors, setIsModalOpenExcelErrors] = useState(false)
    const [uploadedFile, setUploadedFile] = useState({})
    const maxUploadedImportFileSize = constantsArr.MAX_IMPORT_EXCEL_FILE_SIZE_MB * 1024 * 1024
    const allowedImportFileTypes = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
    const [hsdLtrStartFilter, setHSDStartFilter] = useState("")
    const [hsdLtrEndFilter, setHSDEndFilter] = useState("")
    const [sortValue, setSortValue] = useState(params.get('sort') ?? 'updatedAt:desc'); // Default sorting
    const [sortSpinning, setSortSpinning] = useState(false);
    const dateFormat = "DD-MM-YYYY";
    const [hideDeleteIcon, setHideDeleteIcon] = useState(false); // State to manage delete icon visibility
    const [isGeneratedEntriesModalOpen, setGeneratedEntriesIsModalOpen] = useState(false);
    const [modelItem, setModelItem] = useState({});
    const [notBilledInVendorBills, setNotBilledInVendorBills] = useState(false)

    const props = {
        name: 'file',
        action: false,
        beforeUpload: file => checkBeforeUpload(file),
        maxCount: 1,
        onRemove: () => { setUploadedFile({}) },
        uploadedFile
    };

    const redirectToCreateExcavator = () => navigate("/excavators/create");

    const changePageNumber = (currentPage, pageSize) => {
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadHSDDetails(sortValue, currentPage, pageSize, currentSetFilterValues);
    };

    const loadHSDDetails = async (sortOrder, currentPage, pageSize, filterArgs = {}) => {
        setCurrentPagePagination(currentPage);
        setSortSpinning(true);
        return AppHelper.getExcavatorList(sortOrder, currentPage, pageSize, true, filterArgs).then((response) => {
            setSortSpinning(false);
            setLoading(false);
            setShowPagination(true);
            if (response?.error) {
                console.log(response?.error);
            } else {
                setHSDDetailsData(response);
                setShowPagination(true);
            }
        });
    };

    const showGeneratedEntriesModel = (e, item) => {
        setGeneratedEntriesIsModalOpen(true);
        setModelItem(item)
    }

    const onCancelGeneratedEntriesModel = () => {
        setGeneratedEntriesIsModalOpen(false);
    }

    const checkBeforeUpload = (file) => {
        const fileType = file?.type ?? ""
        const fileSize = file?.size ?? 0
        setUploadedFile({ ...uploadedFile, file })
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" })
            return false
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            return false
        }
        return false
    }

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const showModalPopUp = () => {
        setIsModalOpen(true)
        setHideDeleteIcon(false);
    }

    const handleModelCancel = () => setIsModalOpen(false);

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const populateAgencyDropDown = (e) => {
        excavatorFilterForm.setFieldsValue({
            agency_id: null
        })
        setagencyDataDown([]);
        setagencyDataDown(AppHelper.getAgencyDropDown(loadingAgencyLists, e, sessionGlobalValues));
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        }
    }

    const handleDeleteExcavator = async (loadingId) => {
        const response = await AppHelper.deleteExcavator(loadingId);
        if (response?.error) {
            console.error('Error response:', response); // Log the response for debugging
            notification.error({
                message: 'Error: Failed to delete excavator',
                description: response?.error?.message || 'Unknown error',
                duration: 3,
            });
        } else {
            notification.success({
                message: 'Success',
                description: 'Excavator has been deleted successfully.',
                duration: 3,
            });
            loadHSDDetails(sortValue, currentPagePagination, pageSize, currentSetFilterValues);
        }
    };

    const resetFilterForm = (e) => {
        e.preventDefault()
        excavatorFilterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setHSDStartFilter("")
        setHSDEndFilter("")
        setNotBilledInVendorBills(false)
    }

    const handleChange = (e) => {
        console.log(e.target.name, 'e.target.name')
        if (e.target.name === 'HSD_LTR_start') {
            setHSDStartFilter(e.target.value)
        } else if (e.target.name === 'HSD_LTR_end') {
            setHSDEndFilter(e.target.value)
        }
    }

    const handleChangeCheckbox = (e) => {
        console.log(e.target.checked, 'e.target.checked');
        setNotBilledInVendorBills(e.target.checked)
    }

    const filterExcavators = async (formValues) => {
        const agency = loadingAgencyLists.find((item) => item?.attributes?.Company_name === formValues?.agency_id && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        formValues.agency_id = agency?.id ?? ""
        formValues.Date = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        formValues.agency_id = agency?.id ?? ""
        formValues.hsdLTR = `${hsdLtrStartFilter}~${hsdLtrEndFilter}`
        console.log(notBilledInVendorBills, 'notBilledInVendorBills')
        if (notBilledInVendorBills)
            formValues.notBilledInVendorBills = notBilledInVendorBills

        loadHSDDetails(sortValue, currentPage, pageSize, formValues);
        setOpen(false)
        setCurrentSetFilterValues(formValues)
    }

    const handleUploadFiles = async () => {
        if (Object.keys(uploadedFile).length === 0) {
            notification.error({ message: "Error", description: "Please upload file to import" })
            return false
        }
        // Set loading to true when upload starts
        setLoading(true);
        setHideDeleteIcon(true); // Hide delete icon when upload button is clicked
        const fileType = uploadedFile?.file?.type ?? ""
        const fileSize = uploadedFile?.file?.size ?? 0
        if (!fileType) {
            notification.error({ message: "Error", description: "Invalid file detected" });
            setLoading(false); // Set loading to false when upload finishes
            return false;
        }

        if (allowedImportFileTypes.includes(fileType)) {
            // check size of uploaded file should not be too big
            if (fileSize > maxUploadedImportFileSize) {
                notification.error({ message: "Error", description: "The uploaded file exceeds the maximum allowable size of 8 MB." })
                setLoading(false);
                return false
            }
        } else {
            // wrong file type detected throw error
            notification.error({ message: "Error", description: "The uploaded file must be in either the xls or xlsx format." })
            setLoading(false);
            return false
        }
        // call to an api to import
        const fileImportResponse = await AppHelper.importExcavatorsExcel(uploadedFile)
        if (fileImportResponse && Object.keys(fileImportResponse).length > 0) {
            setExcelImportErrors(fileImportResponse);
            setIsModalOpenExcelErrors(true);
            setIsModalOpen(false);
            setLoading(false);
        } else {
            // Check if fileImportResponse is undefined or null
            if (fileImportResponse === undefined || fileImportResponse === null || fileImportResponse === "ERR_NETWORK") {
                // Show error message if fileImportResponse is undefined or null
                notification.error({
                    message: "Error",
                    description: "Failed to import data. Please try again later.",
                });
                setLoading(false);
            } else {
                setIsModalOpenExcelErrors(false)
                setIsModalOpen(false)
                loadHSDDetails(sortValue, currentPage, pageSize, currentSetFilterValues);
                setLoading(false);
                notification.success({
                    message: "Success",
                    description: "Excavators data imported successfully!",
                    duration: 3,
                });
            }
        }
    };

    const handleModelExcelErrorsCancel = () => setIsModalOpenExcelErrors(false)

    const handleSortChange = (value) => {
        setSortValue(value);
        setCurrentPagePagination(1);
        loadHSDDetails(value, 1, pageSize, currentSetFilterValues);
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    // Function to show a notification when attempting to close modal during uploading
    const showCannotCloseNotification = () => {
        notification.warning({
            message: 'Uploading in Progress',
            description: 'Please wait for the file upload to complete before closing the modal.',
            duration: 3,
        });
    };


    useEffect(() => {
        document.title = "Excavators List";
        loadHSDDetails(sortValue, currentPage, pageSize, currentSetFilterValues);
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setloadingAgencyLists(response);
        });
        AppHelper.getPetrolPumps().then((response) => {
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpNameDropDown(response));
        });
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes['header-fixed-excavator']}
            >
                <Grid item lg={6} md={2} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Excavators
                    </Typography>
                </Grid>
                <Grid item lg={6} md={10} xs={12} container spacing={2} className={classes["gridmargintop10"]}>
                    <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-search"]}>
                        {/* <Search placeholder="input search text" className={classes["search-new-entry"]} /> */}
                    </Grid>
                    <Grid item lg={3} md={3} xs={6} className={classes["form-select-div-select"]}>
                        <Form>
                            <Form.Item label="Sort By">
                                <Select className={classes["select-new-entry"]} defaultValue={sortValue ?? "Date:desc"} onChange={handleSortChange}>
                                    <Select.Option value="updatedAt:desc" >Updated Date</Select.Option>
                                    <Select.Option value="Date:asc">Date Ascending</Select.Option>
                                    <Select.Option value="Date:desc">Date Descending</Select.Option>
                                    <Select.Option value="HSD_LTR:asc">HSD Ltr. Ascending</Select.Option>
                                    <Select.Option value="HSD_LTR:desc">HSD Ltr. Descending</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={2} md={2} xs={3}>
                        <UIButton
                            type="primary"
                            onClick={showModalPopUp}
                            variant="outlined"
                            startIcon={<ImportOutlined />}
                            className={classes["import-new-entry"]}
                        >
                            Import
                        </UIButton>
                    </Grid>
                    <Grid item lg={2} md={2} xs={3}>
                        <UIButton
                            onClick={showDrawer}
                            variant="outlined"
                            startIcon={<FilterOutlined />}
                            className={classes["filter-new-entry"]}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={excavatorFilterForm} onFinish={filterExcavators}>
                                <Form.Item label="Type of Agency" name="Agency_Type">
                                    <Select id='Type_of_agency' allowClear placeholder="Please select" options={constants.AGENCY_TYPES} onChange={(e) => populateAgencyDropDown(e)} />
                                </Form.Item>
                                <Form.Item label="Date" name="Date">
                                    <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} />
                                </Form.Item>
                                <Form.Item label="Agency Name" name="agency_id">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        options={agencyDataDown}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Form.Item label="D/O No." name="DO_No">
                                    <Input
                                        placeholder="Enter D/O No."
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Petrol Pump" name="Petrol_Pumps">
                                    <Select
                                        showSearch
                                        optionFilterProp="children"
                                        filterOption={filterOption}
                                        options={petrolPumpsDropDownValues}
                                        placeholder="Select"
                                    />
                                </Form.Item>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            label="HSD in Ltr"
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item']}
                                        >
                                            <Row gutter={[24, 24]} wrap={true}>
                                                <Col span={12}>
                                                    <Input name="HSD_LTR_start" placeholder="From" type="number" addonBefore={<SearchOutlined />} value={hsdLtrStartFilter} onChange={handleChange} />
                                                </Col>
                                                <Col span={12}>
                                                    <Input name="HSD_LTR_end" placeholder="To" type="number" addonBefore={<SearchOutlined />} value={hsdLtrEndFilter} onChange={handleChange} />
                                                </Col>
                                            </Row>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} wrap={true}>
                                    <Col span={24}>
                                        <Form.Item
                                            messageVariables=""
                                            wrapperCol={{ span: 30 }}
                                            labelCol={{ span: 30 }}
                                            className={classes['ant-form-item-radio']}
                                            name="notBilledVendorBills"
                                        >
                                            <Checkbox value={true} onChange={handleChangeCheckbox}>Not Billed In Vendor Bills</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["upload-file-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                    <Grid item lg={2} md={2} xs={3}>
                        <UIButton
                            size="small"
                            variant="contained"
                            className={classes["add-new-entry"]}
                            onClick={redirectToCreateExcavator}
                        >
                            + Add New
                        </UIButton>
                    </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12} container spacing={1} className={classes["headerMarginTop"]}>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-first"]}`} fontSize={14}>
                            <div className={classes["header-grid-flex"]}>
                                <div><img src={totalCountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                <div className={classes["divPadding"]}>
                                    <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={hsdDetailsData?.meta?.totalEntries ?? 0}>
                                        {AppHelper.formatNumber(hsdDetailsData?.meta?.totalEntries ?? 0)}
                                    </Typography><br></br>
                                    <Typography variant="body" color="white">
                                        Total Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Tooltip title={hsdDetailsData?.meta?.totalLitres}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-second"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalLitresImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div className={classes["divPadding"]}>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={hsdDetailsData?.meta?.totalLitres ?? 0}>
                                            {AppHelper.formatNumber(hsdDetailsData?.meta?.totalLitres ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total LTR
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Tooltip title={`${hsdDetailsData?.meta?.totalAmount}`}>
                            <Typography variant="body1" className={`${classes["total-items"]} ${classes["total-item-third"]}`} fontSize={14}>
                                <div className={classes["header-grid-flex"]}>
                                    <div><img src={totalAmountImg} alt="Total count" className={classes["header-grid-image"]} /></div>
                                    <div className={classes["divPadding"]}>
                                        <Typography variant="body" color="white" className={classes["header-text-fontSize"]} title={hsdDetailsData?.meta?.totalAmount ?? 0}>
                                            {AppHelper.formatNumber(hsdDetailsData?.meta?.totalAmount ?? 0)}
                                        </Typography><br></br>
                                        <Typography variant="body" color="white">
                                            Total Amount
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid container spacing={0} className={classes["marginTop20"]}>
                {!hsdDetailsData?.data?.attributes?.results || sortSpinning ? (
                    <Spin size="large" tip="Please Wait ...">
                        <div className={classes["content"]} />
                    </Spin>
                ) : hsdDetailsData?.data?.attributes?.results?.length === 0 ? (
                    // Show message when data is not available
                    <Typography variant="body1">
                        No excavator record is available
                    </Typography>
                ) : (hsdDetailsData?.data?.attributes?.results?.map((item) => (
                    <Grid key={item.id} item lg={4} md={6} xs={12}>
                        <Card bordered={true} className={classes["card-box"]}>
                            <div className={classes["AgencyDetails"]}>
                                <Typography variant="body2" gutterBottom>
                                    Vehicle Number :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        EXCAVATOR
                                    </span>
                                </Typography>
                                <Typography gutterBottom variant="body2">
                                    Date :
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {formatDate(item?.Date ?? "-")}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Petrol Pump Agency:
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Petrol_Pumps?.[0]?.Petrolpump_Name.length > 10 ? <span title={item?.Petrol_Pumps?.[0]?.Petrolpump_Name}>{item?.Petrol_Pumps?.[0]?.Petrolpump_Name.substring(0, 12)}....</span> : item?.Petrol_Pumps?.[0]?.Petrolpump_Name ?? "-"}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    Type of Agency:
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.Agency_Type}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    HSD LTR:
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.HSD_LTR ?? 0}
                                    </span>
                                </Typography>
                                <Typography variant="body2" gutterBottom>
                                    D/O No:
                                    <span className={classes["AgencyDetailsValue"]}>
                                        {item?.DO_No ?? "-"}
                                    </span>
                                </Typography>
                            </div>

                            <div className={classes["card-button"]}>
                                <ul className={classes["action-items"]}>
                                    <li>
                                        <Link
                                            to={`/excavators/view/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}
                                            title="View Agency Details"
                                        >
                                            <EyeOutlined className={classes["card-icons"]} />
                                        </Link>
                                    </li>
                                    {
                                        (
                                            item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                            ||
                                            (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                            ?
                                            (
                                                <li>
                                                    <Link>
                                                        <EditOutlined
                                                            onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                            className={classes["card-icons"]}
                                                            style={{ color: "#000" }}
                                                        />
                                                    </Link>
                                                </li>
                                            ) : (
                                                <li>
                                                    <Link to={`/excavators/edit/${item?.id}?currentPage=${currentPagePagination}&pageSize=${pageSize}`}>
                                                        <EditOutlined
                                                            className={classes["card-icons"]}
                                                            style={{ color: "#000" }}
                                                        />
                                                    </Link>
                                                </li>
                                            )
                                    }
                                    {
                                        (
                                            item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                            ||
                                            (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "")
                                            ?
                                            (
                                                <li>
                                                    <Link>
                                                        <DeleteOutlined
                                                            onClick={(e) => showGeneratedEntriesModel(e, item)}
                                                            className={classes["card-icons"]}
                                                            style={{ color: "#000" }}
                                                        />
                                                    </Link>
                                                </li>
                                            ) : (
                                                <li>
                                                    <Popconfirm
                                                        okText="Yes"
                                                        cancelText="No"
                                                        title="Delete the Excavator"
                                                        description="Are you sure, you want to delete this excavator record?"
                                                        onConfirm={e => handleDeleteExcavator(item?.id)}
                                                        icon={
                                                            <DeleteOutlined style={
                                                                {
                                                                    color: "#ff4d4f"
                                                                }
                                                            } />
                                                        }
                                                    >
                                                        <DeleteOutlined
                                                            className={`${classes['card-icons']}`}
                                                            style={{ color: '#ff0000' }}
                                                        />
                                                    </Popconfirm>
                                                </li>
                                            )
                                    }

                                </ul>
                                <div className={
                                    (
                                        item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                        ||
                                        (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "") ? `${classes["generated-bill-ribbon"]}` : ""
                                }>
                                    <Typography variant="body2">
                                        {(
                                            item?.is_loading_billed && item?.Loading_Bill?.id && item?.Loading_Bill?.id !== "")
                                            ||
                                            (item?.is_unloading_billed && item?.Unloading_Bill?.id && item?.Unloading_Bill?.id !== "") ? "Generated" : ""}
                                    </Typography>
                                </div>
                                <>
                                    <Modal open={isGeneratedEntriesModalOpen} onCancel={onCancelGeneratedEntriesModel} centered footer={null} width={700}>
                                        <Grid className={classes["alert-bottom-border"]}>
                                            <Typography variant="h5" color="initial" className={classes["alert-text"]}>Alert !</Typography>
                                        </Grid>
                                        <Grid>
                                            <Typography variant="body1" color="#555555" className={classes["alert-description"]}>Editing this entry is only allowed after it's been removed from the generated bills</Typography>
                                        </Grid>
                                        <Grid
                                            direction="row"
                                            justify="center"
                                            alignItems="center"
                                            alignContent="center"
                                            className={classes["generated-entries-modal-table"]}
                                            container
                                        >

                                            <Grid className={classes["generated-entries-model-heading"]}>
                                                <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="#908f8f" paddingLeft="15px">Bill Type</Typography></Grid>
                                                <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="#908f8f">Agency Name</Typography></Grid>
                                                <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="#908f8f">Bill No.</Typography></Grid>
                                            </Grid>
                                            {

                                                modelItem?.is_loading_billed && modelItem?.Loading_Bill?.id && modelItem?.Loading_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Loading Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Loading_Bill?.agency?.Company_name}</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Loading_Bill?.bill_number}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                            {
                                                modelItem?.is_unloading_billed && modelItem?.Unloading_Bill?.id && modelItem?.Unloading_Bill?.id !== ""
                                                    ?
                                                    <Grid container spacing={0} className={classes["generated-entries-model-description"]}>
                                                        <Grid item lg={4} md={4} sm={4} xs={4}><Typography variant="body1" color="intial" paddingLeft="15px">Unloading Bill</Typography></Grid>
                                                        <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5}><Typography variant="body1" color="intial">{modelItem?.Unloading_Bill?.agency?.Company_name}</Typography></Grid>
                                                        <Grid item lg={2.5} md={2.5} sm={2.5} xs={2.5}><Typography variant="body1" color="intial">{modelItem?.Unloading_Bill?.bill_number}</Typography></Grid>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                        </Grid>
                                    </Modal>
                                </>
                            </div>
                        </Card>
                    </Grid>
                ))
                )}

            </Grid>
            {showPagination === true && hsdDetailsData?.data?.attributes?.results?.length > 0 ? (
                <div className={classes["pagination-box"]}>
                    <Pagination
                        current={currentPagePagination}
                        defaultCurrent={currentPage}
                        defaultPageSize={currentPageSizePagination}
                        showTotal={(total) => `Total ${total} items`}
                        responsive
                        pageSize={currentPageSizePagination}
                        onChange={changePageNumber}
                        showSizeChanger={false}
                        total={hsdDetailsData?.data?.attributes?.pagination?.total ?? 0}
                    />
                </div>
            ) : (
                ""
            )}
            <Modal
                centered
                open={isModalOpen}
                onCancel={loading ? showCannotCloseNotification : handleModelCancel}
                footer={(_) => (
                    <>
                        <UIButton
                            onClick={loading ? showCannotCloseNotification : handleModelCancel}
                            variant="outlined"
                            color="error"
                            className={classes['model-buttons']}
                        >
                            Cancel
                        </UIButton>
                        <UIButton
                            onClick={handleUploadFiles}
                            variant="contained"
                            className={classes["add-new-filter-entry"]}
                            disabled={loading} // Disable the Upload button while loading
                        >
                            {loading ? <Spin indicator={<LoadingOutlined style={{ color: "#fff" }} />} /> : 'Upload'} {/* Show spinner while loading */}
                        </UIButton>
                    </>
                )}
            >

                <Upload {...props}>
                    <UIButton
                        startIcon={<UploadOutlined />}
                        className={classes['upload-file-entry']}
                        variant="outlined"
                    >
                        Browse File
                    </UIButton>
                </Upload>
                <h4>Please choose file .xls and .xlsx only. <a href={importExcavatorSampleFile} download={true}>Click here</a> to download the sample file.</h4>
                <style>{`
         & .ant-upload-list-item-actions .ant-btn {
          display: ${hideDeleteIcon ? 'none' : 'inline-block'};
        }
      `}</style>
            </Modal>
            {/**
       * Modal popup for excel import errors display
       */}
            {
                excelImportErrors && Object.keys(excelImportErrors).length > 0
                    ?
                    <Modal
                        centered
                        open={isModalOpenExcelErrors}
                        onCancel={handleModelExcelErrorsCancel}
                        width={'60%'}
                        className={classes['error-import-modal']}
                        footer=""
                    >
                        <>
                            <h3 className={classes["error-import-modal-heading"]}>Please find errors in Excavtors import excel data as below:</h3>
                            <div className={classes['error-import-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width={'100%'}>
                                    <thead>
                                        <tr>
                                            <th width={'8%'} align="center">Row No</th>
                                            <th width={'92%'}>Errors</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Object.entries(excelImportErrors).map(([key, value]) => {
                                                return <tr key={key}>
                                                    <td align="center"><strong>{key}</strong></td>
                                                    <td>
                                                        <table border={0} cellPadding={0} cellSpacing={0} key={key} width={'100%'}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>
                                                                        <ul>
                                                                            {
                                                                                value?.map((item, key) => {
                                                                                    return <li key={key}>
                                                                                        <p>{item}</p>
                                                                                    </li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    );
};
