import { Grid } from "@mui/material";
import classes from "./IndustruyImgComponent.module.scss"

export const IndustryImgComponent = () => {
  return (
    <>
      <Grid item md={6} xs={12} className={classes["wrapper-section-one"]}>
        <div className={classes["industry-img"]}></div>
      </Grid>
    </>
  );
};
