import React, { useState, useEffect } from "react";
import classes from "./ViewUnloadingTransactionSummary.module.scss";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import billingPageImg from "../../../../assets/images/BillsIcons/billingpage.svg";
import loadingBlueImg from "../../../../assets/images/BillsIcons/loadingBluebigger.svg";
import { Tabs, Table, Spin } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";

const { TabPane } = Tabs;

const generatedBillsTableColumns = [
    {
        title: "S.NO.",
        dataIndex: "sl_no",
        width: "6%",
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: "12%",
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: "10%",
    },
    {
        title: "Ch. No.",
        dataIndex: "Challan_Number",
        width: "10%",
    },
    {
        title: "Vehicle",
        dataIndex: "Vehicle_Number",
        width: "10%",
    },
    {
        title: "Kanta Slip No. UL",
        dataIndex: "Kanta_Slip_No",
        width: "10%",
    },
    {
        title: "Net wt. UL (MT)",
        dataIndex: "Net_Weight",
        width: "10%",
    },
    {
        title: "Work Rate",
        dataIndex: "Work_Rate",
        width: "8%",
    },
    {
        title: "Work Amt.",
        dataIndex: "Work_Amount",
        width: "8%",
    },
    {
        title: "D/O No",
        dataIndex: "DO_No",
        width: "6%",
    },
    {
        title: "HSD (LTR)",
        dataIndex: "HSD_LTR",
        width: "8%",
    },
    {
        title: "HSD Rate",
        dataIndex: "Rate_INR",
        width: "8%",
    },
    {
        title: "HSD Amt.",
        dataIndex: "HSD_Amt",
        width: "10%",
    },
];

export const ViewUnloadingTransactionSummary = () => {
    const navigate = useNavigate();
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE];
    const params = useParams();
    const selectedPendingBillIds = [params?.id ?? ""];
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT;
    const [workType, setWorkType] = useState("");
    const [dateOfBilling, setDateOfBilling] = useState("");
    const [tripCount, setTripCount] = useState(0);
    const [unloadingWeight, setUnloadingWeight] = useState(0);
    const [roundOffWeight, setRoundOffWeight] = useState(0);
    const [updatedQtyMT, setUpdatedQtyMT] = useState(0);
    const [workEffectiveRate, setWorkEffectiveRate] = useState(0);
    const [workAmount, setWorkAmount] = useState(0);
    const [gst, setGst] = useState(0);
    const [otherDeduction, setOtherDeduction] = useState(0);
    const [lessTds, setLessTds] = useState(0);
    const [hsdLtr, setHsdLtr] = useState(0);
    const [hsdRoundOff, setHsdRoundOff] = useState(0);
    const [updatedQtyLTR, setUpdatedQtyLTR] = useState(0);
    const [hsdEffectiveRate, setHsdEffectiveRate] = useState(0);
    const [hsdAmount, setHsdAmount] = useState(0);
    const [updatedDieselAmountRatio, setUpdatedDieselAmountRatio] = useState(0);
    const [totalPayableAmount, setTotalPayableAmount] = useState(0);
    const parts = dateOfBilling.split("-");
    const formattedDate = `${parts[2]}-${parts[1]}-${parts[0]}`;
    const [loadingAgencyName, setLoadingAgencyName] = useState("");
    const [billNumber, setBillNumber] = useState("");
    const [billPrefix, setBillPrefix] = useState("");
    const [generatedBillsTableData, setGeneratedBillsTableData] = useState([]);
    const [spinner, setSpinner] = useState(false);
    const [remarkForDeduction, setRemarkForDedcution] = useState("");
    const [minimumLoadingDate, setMinimumLoadingDate] = useState("")
    const [maximumLoadingDate, setMaximumLoadingDate] = useState("")

    const setGlobalCountItems = (response) => {
        setWorkType(response?.meta?.billResults?.agency_type ?? "N/A");
        setDateOfBilling(response?.meta?.billResults?.date_of_billing ?? "N/A");
        setTripCount(response?.meta?.tripCount ?? 0);
        setUnloadingWeight(response?.meta?.billResults?.unloading_weight ?? 0);
        setRoundOffWeight(response?.meta?.billResults?.round_off_weight ?? 0);
        setUpdatedQtyMT(response?.meta?.billResults?.updated_quantity_weight ?? 0);
        setWorkEffectiveRate(response?.meta?.billResults?.work_effective_rate ?? 0);
        setWorkAmount(response?.meta?.billResults?.work_amount ?? 0);
        setGst(response?.meta?.billResults?.gst ?? 0);
        setOtherDeduction(response?.meta?.billResults?.other_deduction ?? 0);
        setLessTds(response?.meta?.billResults?.less_tds ?? 0);
        setHsdLtr(response?.meta?.billResults?.hsd_litre ?? 0);
        setHsdRoundOff(response?.meta?.billResults?.round_off_hsd ?? 0);
        setUpdatedQtyLTR(response?.meta?.billResults?.updated_quantity_litre ?? 0);
        setHsdEffectiveRate(response?.meta?.billResults?.hsd_effective_rate ?? 0);
        setHsdAmount(response?.meta?.billResults?.hsd_amount ?? 0);
        setUpdatedDieselAmountRatio(response?.meta?.billResults?.updated_diesel_amount_ratio ?? 0);
        setTotalPayableAmount(response?.meta?.billResults?.total_payable_amount ?? 0);
        setLoadingAgencyName(response?.meta?.billResults?.agency?.Company_name ?? "");
        setBillNumber(response?.meta?.billResults?.bill_number ?? "");
        setBillPrefix(response?.meta?.billResults?.billing_prefix ?? "")
        setRemarkForDedcution(response?.meta?.billResults?.remark_for_deduction)
        setMinimumLoadingDate(response?.meta?.minUnloadingDate ?? 0)
        setMaximumLoadingDate(response?.meta?.maxUnloadingDate ?? 0)
        const getTableData = loadAgencyGeneratedLoadingBillsData(response?.data ?? "");
        setGeneratedBillsTableData(getTableData);
    };

    const loadAgencyGeneratedLoadingBillsData = (responseData) => {
        let generatedBillsTableData = [];
        for (let i = 0; i < responseData.length; i++) {
            generatedBillsTableData.push({
                key: responseData[i]?.attributes?.loadingId,
                sl_no: i + 1,
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading && responseData[i]?.attributes?.Date_of_Loading != '-') ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : "-"),
                Date_of_Unloading: responseData[i]?.attributes?.Date_of_Unloading ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : "-",
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number,
                Kanta_Slip_No: responseData[i]?.attributes?.Kanta_Slip_No ?? "-",
                Net_Weight: responseData[i]?.attributes?.Net_Weight ?? 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: responseData[i]?.attributes?.Work_Amount ?? 0,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
            });
        }
        return generatedBillsTableData;
    };

    const onChange = () => {
        console.log("hello");
    };

    const redirectBackToAllGeneratedBill = () => {
        navigate("/bills/unloading/generated-unloading-bills");
    };

    useEffect(() => {
        setSpinner(true);
        // fetch records by selected pending bills in previous screen
        AppHelper.getGeneratedUnloadingAgencyById(
            selectedPendingBillIds,
            true,
            currentPage,
            pageSize
        )
            .then((response) => {
                setGlobalCountItems(response);
                setSpinner(false);
            })
            .catch((err) => {
                console.log(`Error while getting bill records: ${err}`);
            });
    }, []);
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                display="flex"
                justifyContent="flex-end"
                className={classes["transaction-summary-padding"]}
            >
                <UIButton
                    className={classes["generate-new-entry"]}
                    startIcon={<ArrowLeftOutlined />}
                    onClick={redirectBackToAllGeneratedBill}
                >
                    All Generated Bills
                </UIButton>
            </Grid>

            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["transaction-summary-padding"]}
            >
                <Grid className={classes["transaction-summary-border"]}>
                    <img
                        src={loadingBlueImg}
                        alt=""
                        className={classes["transaction-transport-img"]}
                    />
                    <Grid className={classes["first-boundary-transaction"]}>
                        <Typography
                            variant="body1"
                            color="#fff"
                            fontWeight="bold"
                            letterSpacing="1px"
                            fontSize="1.3rem"
                        >
                            {loadingAgencyName}
                        </Typography>
                        <Typography
                            variant="body1"
                            color="#fff"
                            display="flex"
                            fontSize="1.3rem"
                        >
                            <img src={billingPageImg} alt="Billing Page" /> Bill No.:&nbsp;
                            <strong>{billNumber}</strong>
                        </Typography>
                    </Grid>

                    {spinner === true ? (
                        <div className={classes["spinner-area"]}>
                            <Spin size="large" />
                        </div>
                    ) : (
                        <Tabs
                            defaultActiveKey="1"
                            onChange={onChange}
                            className={classes["tab-transaction"]}
                        >
                            <TabPane tab="Transaction Summary" key="1">
                                <Grid
                                    className={classes["summary-list-padding"]}
                                    container
                                    spacing={3}
                                >
                                    <Grid item lg={6} md={12} xs={12}>
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Work Type :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    color="#479036"
                                                    fontWeight="bold"
                                                    textAlign="center"
                                                    className={classes["transport-para"]}
                                                >
                                                    Unloading
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Date :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {formattedDate}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Trip Count :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {tripCount}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-second"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography variant="body1" className={classes["normal-para"]}>
                                                    Period :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography variant="body1" className={classes["bold-para"]}>
                                                    {dayjs(minimumLoadingDate).format(dateFormat)} - {dayjs(maximumLoadingDate).format(dateFormat)}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-second"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Unloading Wt (MT) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {unloadingWeight}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Round off Wt (MT) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {roundOffWeight}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Updated Qty (MT) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {updatedQtyMT}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Work Effective Rate (Rs.) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {workEffectiveRate}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Work Amount (Rs.) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {workAmount}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-second"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    GST :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {gst}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Other Deduction :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {otherDeduction}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Remark for Deduction:
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {remarkForDeduction}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Less TDS :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {lessTds}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item lg={6} md={12} xs={12}>
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Billing Prefix :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {billPrefix}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    HSD LTR :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {hsdLtr}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    HSD Round off :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {hsdRoundOff}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Updated Qty (LTR) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {updatedQtyLTR}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    HSD Effective Rate :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {hsdEffectiveRate}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    HSD Amount (Rs.) :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {hsdAmount}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-first"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["normal-para"]}
                                                >
                                                    Updated Diesel Amount Ratio :
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="body1"
                                                    className={classes["bold-para"]}
                                                >
                                                    {updatedDieselAmountRatio}
                                                </Typography>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            container
                                            spacing={0}
                                            className={classes["work-type-object-third"]}
                                        >
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="h5"
                                                    className={classes["normal-para"]}
                                                >
                                                    Total Payable Amount
                                                </Typography>
                                            </Grid>
                                            <Grid item lg={6} md={6} xs={6}>
                                                <Typography
                                                    variant="h4"
                                                    className={classes["bold-para"]}
                                                >
                                                    &#8377; {totalPayableAmount}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </TabPane>
                            <TabPane tab="Vehicle List" key="2">
                                <Table
                                    columns={generatedBillsTableColumns}
                                    dataSource={generatedBillsTableData}
                                    bordered={true}
                                    scroll={{ y: 1000, x: 1500 }}
                                    loading={{ indicator: <div><Spin /></div>, spinning: spinner }}
                                    pagination={{
                                        pageSize: constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT,
                                        showSizeChanger: false
                                    }}
                                />
                            </TabPane>
                        </Tabs>
                    )}
                </Grid>
            </Grid>
        </>
    );
};
