import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./TransportEditBillFinalCalculation.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, DatePicker, Select, notification } from "antd";
import { BreadCrumbTransport } from "../BreadCrumbTransport/BreadCrumbTransport";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import TextArea from "antd/es/input/TextArea";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

export const TransportEditBillFinalCalculation = () => {
    const navigate = new useNavigate();
    const params = useParams()
    const activeButton = 'final_calculation'; // Set the default active button
    const generatedBillObject = AppHelper.decryptText(sessionStorage.getItem(`generateBillObjectEditGenerateTransport_${params?.id}`))
    const globalTPProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const selectedAgencyBill = AppHelper.decryptText(sessionStorage.getItem(`transport_pending_bills_${params?.id}_selected_agency`))
    const selectedLoadingIdsToGenerateBill = AppHelper.decryptText(sessionStorage.getItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`))
    const adjustedWeightRateValues = AppHelper.decryptText(sessionStorage.getItem(`adjustedWeightRateValuesEditGenerateTransport_${params?.id}`))
    const adjustedHSDRateValues = AppHelper.decryptText(sessionStorage.getItem(`adjustedHSDRateValuesEditGenerateTransport_${params?.id}`))
    const currentDate = dayjs().format("YYYY-MM-DD")
    const [agencies, setAgencies] = useState([])
    const [finalForm] = Form.useForm()
    const [totalBilledAmount, setTotalBilledAmount] = useState(0)
    const [totalWorkAmount, setTotalWorkAmount] = useState(0)
    const [totalHSDAmount, setTotalHSDAmount] = useState(0)
    const [gstAmount, setGSTAmount] = useState(0)
    const [otherDeductionAmount, setOtherDeductionAmount] = useState(0)
    const [lessTDSAmount, setLessTDSAmount] = useState(0)
    const [diableSubmitButton, setDisableSubmitButton] = useState(false)
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT;

    const onChange = (e) => {
        if (e.target.id === 'GST') {
            // calculate gst on payable amount
            const gstAmount = ((parseFloat(totalBilledAmount) * e.target.value) / 100).toFixed(2)
            setGSTAmount(gstAmount)
        } else if (e.target.id === 'Other_Deduction') {
            setOtherDeductionAmount((e.target.value && e.target.value !== "") ? e.target.value : 0)
        } else if (e.target.id === 'Less_TDS') {
            // calculate percentage of total payable amount for less tds
            const lessTDSAmount = ((parseFloat(totalBilledAmount) * e.target.value) / 100).toFixed(2)
            setLessTDSAmount(lessTDSAmount)
        }
    };

    /**
     * Function to generate final bill with calculation with validation
     */
    const generateBillWithFinalCalculation = async (formValues) => {
        setDisableSubmitButton(true)
        const selectedAgencyFindByName = agencies.find((item) => item?.attributes?.Company_name === selectedAgencyBill && item?.attributes?.Project?.data?.id === globalTPProjectValues?.projectGlobal)
        const billPendingIds = makeSelectedIdsToBillSubmitFormat(selectedLoadingIdsToGenerateBill)
        const sortedBilledLoadingIds = billPendingIds.sort((first, second) => first?.loading?.data?.id - second?.loading?.data?.id)

        let adjustedWeightRateElement = []
        let adjustedHSDRateElement = []
        if (adjustedWeightRateValues?.RoundOffWeight?.Rates.length > 0) {
            adjustedWeightRateValues?.RoundOffWeight?.Rates?.forEach((value, key) => {
                if ((value?.Quantity && value?.Quantity !== '') && (value?.Rate && value?.Rate !== '')) {
                    adjustedWeightRateElement.push({
                        Quantity: parseFloat(value?.Quantity).toFixed(2),
                        Rate: parseFloat(value?.Rate).toFixed(2)
                    })
                }
            })
        }
        if (adjustedHSDRateValues?.RoundOffHSDRate?.Rates.length > 0) {
            adjustedHSDRateValues?.RoundOffHSDRate?.Rates?.forEach((value, key) => {
                if ((value?.Quantity && value?.Quantity !== '') && (value?.Rate && value?.Rate !== '')) {
                    adjustedHSDRateElement.push({
                        Quantity: parseFloat(value?.Quantity).toFixed(2),
                        Rate: parseFloat(value?.Rate).toFixed(2)
                    })
                }
            })
        }

        const finalBillData = {
            "trip_count": generatedBillObject?.trip_count ?? 0,
            "unloading_weight": generatedBillObject?.unloading_weight ?? 0,
            "round_off_weight": generatedBillObject?.round_off_weight ?? 0,
            "updated_quantity_weight": generatedBillObject?.updated_quantity_weight ?? 0,
            "work_effective_rate": generatedBillObject?.work_effective_rate ?? 0,
            "work_amount": ((parseFloat(generatedBillObject?.work_amount)) + (parseFloat(generatedBillObject?.finalAdjustedWeightRateAmount))).toFixed(2) ?? 0,
            "hsd_litre": generatedBillObject?.hsd_litre ?? 0,
            "round_off_hsd": generatedBillObject?.round_off_hsd ?? 0,
            "updated_quantity_litre": generatedBillObject?.updated_quantity_litre ?? 0,
            "hsd_effective_rate": generatedBillObject?.hsd_effective_rate ?? 0,
            "hsd_amount": (parseFloat(generatedBillObject?.hsd_amount) + parseFloat(generatedBillObject?.totalRoundOffHSDAmount)).toFixed(2) ?? 0,
            "updated_diesel_amount_ratio": generatedBillObject?.updated_diesel_amount_ratio ?? 0,
            "billing_prefix": formValues?.Billing_Prefix ?? '',
            "bill_number": formValues?.Bill_No ?? '',
            "date_of_billing": dayjs(formValues?.Date).format('YYYY-MM-DD'),
            "gst": ((parseFloat(totalWorkAmount) * formValues?.GST) / 100).toFixed(2),
            "gst_percentage": parseInt(formValues?.GST ?? 0),
            "other_deduction": parseFloat(otherDeductionAmount ?? 0).toFixed(2),
            "remark_for_deduction": formValues?.Remark_for_Deduction ?? '',
            "less_tds": ((parseFloat(totalWorkAmount) * formValues?.Less_TDS) / 100).toFixed(2),
            "less_tds_percentage": parseInt(formValues?.Less_TDS ?? 0),
            "total_payable_amount": (parseFloat(totalWorkAmount) + parseFloat(gstAmount) - (parseFloat(totalHSDAmount) + parseFloat(otherDeductionAmount) + parseFloat(lessTDSAmount))).toFixed(2),
            "total_billed_amount": (parseFloat(totalWorkAmount)).toFixed(2),
            "is_bill_updated": true,
            "agency_type": "transporting",
            "is_bill_final_settled": false,
            "Project": globalTPProjectValues?.projectGlobal,
            "agency": selectedAgencyFindByName?.id ?? null,
            "loading_id_list": sortedBilledLoadingIds,
            "round_off_work_effective_rate": generatedBillObject?.finalAdjustedRate ?? 0,
            "updated_work_effective_rate": (parseFloat(generatedBillObject?.work_effective_rate) + parseFloat(generatedBillObject?.finalAdjustedRate)).toFixed(2) ?? 0,
            "round_off_hsd_rate": '',
            "updated_hsd_rate": '',
            "RoundOffWeight": adjustedWeightRateElement.length > 0 ? { "Rates": adjustedWeightRateValues?.RoundOffWeight?.Rates ?? [] } : {},
            "RoundOffHSDRate": adjustedHSDRateElement.length > 0 ? { "Rates": adjustedHSDRateValues?.RoundOffHSDRate?.Rates ?? [] } : {}
        }

        const response = await AppHelper.updateFinalGeneratedBillTransport(finalBillData, params?.id)
        if (response?.code === "ERR_NETWORK") {
            notification.error({
                message: "Network Error",
                description: response?.error?.message,
                duration: 3,
            });
            setDisableSubmitButton(false)
        } else if (response?.error) {
            notification.error({
                message: "Error",
                description: response?.error?.message,
                duration: 3,
            });
            setDisableSubmitButton(false)
        } else {
            const exportResponse = await AppHelper.getGeneratedTransportAgencyById(response?.id)
            if (exportResponse?.error) {
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            } else {
                // export xlsx code goes here
                const workBook = new exceljs.Workbook()
                const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                    views: [{ ySplit: 1, state: 'frozen' }]
                })
                workSheetPendingBills.columns = [
                    { header: 'S.NO.', key: "sl_no", width: 8 },
                    { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                    { header: 'Date of Unloading', key: "Date_of_Unloading", width: 20 },
                    { header: 'Challan No.', key: "Challan_Number", width: 15 },
                    { header: 'Vehicle No.', key: "Vehicle_Number", width: 15 },
                    { header: 'Kanta Slip No', key: "Kanta_Slip_No", width: 15 },
                    { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 15 },
                    { header: 'Work Rate', key: "Work_Rate", width: 15 },
                    { header: 'Work Amt.', key: "Work_Amount", width: 15 },
                    { header: 'D/O No', key: "DO_No", width: 15 },
                    { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                    { header: 'HSD Rate', key: "Rate_INR", width: 15 },
                    { header: 'HSD Amt', key: "HSD_Amt", width: 15 },
                ]

                exportResponse?.data?.attributes?.loading_id_list.forEach((billData, key) => {
                    billData.sl_no = key + 1
                    billData.Date_of_Loading = (billData.Date_of_Loading && billData.Date_of_Loading !== "" && billData.Date_of_Loading !== '-') ? dayjs(billData.Date_of_Loading).format(dateFormat) : '-'
                    billData.Date_of_Unloading = ((billData.Cancelled === true) ? 'CANCELLED' : (billData.Date_of_Unloading && billData.Date_of_Unloading !== "" && billData.Date_of_Unloading !== '-') ? dayjs(billData.Date_of_Unloading).format(dateFormat) : '-')
                    workSheetPendingBills.addRow(billData)
                })

                // preapare sheet for global counts

                const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Bill Details")
                // prepare sheet columns / headers
                workSheetPendingBillGlobalCounts.columns = [
                    { header: 'TP', key: "first_value", width: 25 },
                    { header: exportResponse?.data?.attributes?.Project?.data?.attributes?.Thermal_Plant?.data?.attributes?.Name, key: "second_value", width: 25 },
                ]

                // prepare sheet rows to display
                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Project", second_value: exportResponse?.data?.attributes?.Project?.data?.attributes?.Name_of_project ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Agency", second_value: exportResponse?.data?.attributes?.agency?.data?.attributes?.Company_name ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Work Type", second_value: 'Transporting' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Bill Date", second_value: exportResponse?.data?.attributes?.date_of_billing ? dayjs(exportResponse?.data?.attributes?.date_of_billing).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Period", second_value: `${dayjs(exportResponse?.data?.attributes?.minLoadingDate).format(dateFormat)} - ${dayjs(exportResponse?.data?.attributes?.maxLoadingDate).format(dateFormat)}` }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Billing Prefix", second_value: formValues?.Billing_Prefix ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Bill No.", second_value: exportResponse?.data?.attributes?.bill_number ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Trip Count", second_value: exportResponse?.data?.attributes?.trip_count ?? 0 }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Unloading Wt (MT)", second_value: exportResponse?.data?.attributes?.unloading_weight ?? 0 }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Round off (WT)", second_value: exportResponse?.data?.attributes?.round_off_weight ?? 0 }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Total Unload wt (MT)", second_value: exportResponse?.data?.attributes?.updated_quantity_weight ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Work Amount (Rs.)", second_value: exportResponse?.data?.attributes?.work_amount ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Work Effective Rate (Rs.)", second_value: exportResponse?.data?.attributes?.work_effective_rate ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "GST", second_value: exportResponse?.data?.attributes?.gst ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "HSD Ltr", second_value: exportResponse?.data?.attributes?.hsd_litre ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "HSD Ltr Round off", second_value: exportResponse?.data?.attributes?.round_off_hsd ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Total HSD Wt (Ltr)", second_value: exportResponse?.data?.attributes?.updated_quantity_litre ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "HSD Effective Rate (Rs.)", second_value: exportResponse?.data?.attributes?.hsd_effective_rate ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "HSD Amount (Rs.)", second_value: exportResponse?.data?.attributes?.hsd_amount ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Updated Diesel Amount Ratio", second_value: exportResponse?.data?.attributes?.updated_diesel_amount_ratio ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Other Deduction", second_value: exportResponse?.data?.attributes?.other_deduction ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "TDS", second_value: exportResponse?.data?.attributes?.less_tds ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Remarks for Deduction", second_value: exportResponse?.data?.attributes?.remark_for_deduction ?? '' }
                )

                const totalPayableAmountExport = (parseFloat(totalWorkAmount) + parseFloat(gstAmount) - ((parseFloat(totalHSDAmount) + parseFloat(otherDeductionAmount) + parseFloat(lessTDSAmount)))).toFixed(2)
                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "BILL AMOUNT PAYABLE", second_value: totalPayableAmountExport ?? '' }
                )

                // set header to export as excel file
                try {
                    const buffer = await workBook.xlsx.writeBuffer()
                    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                    const blob = new Blob([buffer], { type: fileType })
                    FileSaver.saveAs(blob, `transport_agency_export_bill_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                } catch (error) {
                    console.log(error, 'error');
                    notification.error({
                        message: "Error",
                        description: exportResponse?.error?.message,
                        duration: 3
                    })
                }
            }

            notification.success({
                message: "Success",
                description: "Bill for transporting agency updated successfully",
                duration: 3,
            });

            // clear session storage items related to bills
            sessionStorage.removeItem(`generateBillObjectEditGenerateTransport_${params?.id}`)
            sessionStorage.removeItem(`transport_pending_bills_${params?.id}_selected_agency`)
            sessionStorage.removeItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`)
            sessionStorage.removeItem(`adjustedWeightRateValuesEditGenerateTransport_${params?.id}`)
            sessionStorage.removeItem(`adjustedHSDRateValuesEditGenerateTransport_${params?.id}`)
            sessionStorage.removeItem(`adjustedWeightRateValues_${params?.id}`)
            sessionStorage.removeItem(`adjustedHSDRateValues_${params?.id}`)
            sessionStorage.removeItem('bill_no')
            setDisableSubmitButton(false)
            navigate(`/bills/transport/generated-transport-bills?agency=${selectedAgencyBill}`);
        }
    }

    const onSearch = (value) => {
        console.log("search:", value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const makeSelectedIdsToBillSubmitFormat = (loadingIds) => {
        if (loadingIds.length === 0) return []
        let finalIdsArray = []
        for (let index = 0; index < loadingIds.length; index++) {
            finalIdsArray.push(
                {
                    "id": index,
                    "loading": loadingIds[index]
                }
            )
        }

        return finalIdsArray
    }

    const backToGenerateBill = () => {
        navigate(`/bills/transport/edit-transport-bill-generate/${params?.id}`)
    }

    useEffect(() => {
        setTotalWorkAmount(parseFloat(generatedBillObject?.work_amount ?? 0) + parseFloat(generatedBillObject?.finalAdjustedWeightRateAmount ?? 0))
        setTotalHSDAmount(parseFloat(generatedBillObject?.hsd_amount ?? 0) + parseFloat(generatedBillObject?.totalRoundOffHSDAmount ?? 0))
        setTotalBilledAmount(generatedBillObject?.total_billed_amount ?? 0)
        AppHelper.getAgenciesByProject(globalTPProjectValues?.projectGlobal).then((response) => {
            setAgencies(response)
        });

        AppHelper.getGeneratedBillDetails(params?.id).then((response) => {
            finalForm.setFieldsValue({
                Billing_Prefix: response?.data?.attributes?.billing_prefix ?? "",
                Bill_No: response?.data?.attributes?.bill_number ?? "",
                Date: response?.data?.attributes?.date_of_billing ? dayjs(response?.data?.attributes?.date_of_billing) : "",
                GST: response?.data?.attributes?.gst_percentage ?? "",
                Other_Deduction: response?.data?.attributes?.other_deduction ?? "",
                Less_TDS: response?.data?.attributes?.less_tds_percentage ?? "",
                Remark_for_Deduction: response?.data?.attributes?.remark_for_deduction ?? "",
            })

            const totalWorkAmountForGST = (parseFloat(generatedBillObject?.work_amount) + parseFloat(generatedBillObject?.finalAdjustedWeightRateAmount ?? 0))
            setGSTAmount(((parseFloat(totalWorkAmountForGST) * response?.data?.attributes?.gst_percentage) / 100).toFixed(2))
            setLessTDSAmount(((parseFloat(totalWorkAmountForGST) * response?.data?.attributes?.less_tds_percentage) / 100).toFixed(2))
            setOtherDeductionAmount(response?.data?.attributes?.other_deduction ?? 0)
        }).catch(err => {
            console.log(`Error while loading generated bill details`, err);
            notification.error({
                message: `Error while loading generated bill details`, err,
                description: "",
                duration: 3
            })
        })

        document.title = "Transport Agency Bill Final Calculation"
    }, [])
    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["transport-final-calculation"]}
            >
                <Grid item lg={10} md={8} sm={8} xs={12}>
                    <BreadCrumbTransport activeButton={activeButton} page="transportationEdit" params={params} />
                </Grid>
                <Grid item lg={2} md={4} sm={4} xs={12} className={classes['right-align']}>
                    <UIButton
                        variant="contained"
                        className={classes["back-new-entry"]}
                        startIcon={<ArrowLeftOutlined />}
                        onClick={backToGenerateBill}
                    >
                        Generate Bill
                    </UIButton>
                </Grid>
            </Grid>
            <Form layout="vertical" form={finalForm} onFinish={generateBillWithFinalCalculation}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["transport-final-calculation-padding"]}
                >
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid className={classes["final-track-first"]}>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-foremost"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Agency :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {selectedAgencyBill ?? ''}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-first"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Work Type :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        Transporting
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-second"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Trip Count :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.trip_count ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-second"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Period :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {dayjs(generatedBillObject?.minimumLoadingDate).format(dateFormat)} - {dayjs(generatedBillObject?.maximumLoadingDate).format(dateFormat)}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-third"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Unloading Wt (MT) :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.unloading_weight ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Round Off Wt (MT) :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.round_off_weight ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Updated Qty (MT) :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.updated_quantity_weight ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Work Effective Rate (Rs.) :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.work_effective_rate ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Work Amount (Rs.):
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {(parseFloat(generatedBillObject?.work_amount) + parseFloat(generatedBillObject?.finalAdjustedWeightRateAmount)).toLocaleString('en-IN')}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fivth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        HSD LTR :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.hsd_litre ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-sixth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        HSD Round Off :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.round_off_hsd ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-sixth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Updated Qty (LTR) :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.updated_quantity_litre ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-sixth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        HSD Effective Rate :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.hsd_effective_rate ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-sixth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        HSD Amount (Rs.) :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {(parseFloat(generatedBillObject?.hsd_amount) + parseFloat(generatedBillObject?.totalRoundOffHSDAmount)).toLocaleString('en-IN')}

                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-sixth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Updated Diesel Amount Ratio :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {generatedBillObject?.updated_diesel_amount_ratio ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid className={classes["final-track-second"]}>
                            <Grid className={classes["billing-prefix-form"]}>
                                <Grid container spacing={0}>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Form.Item label="Billing Prefix" name='Billing_Prefix' rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please enter billing prefix'
                                                }
                                            ]
                                        }>
                                            <Input
                                                placeholder="Enter the Billing Prefix"
                                                className={classes["input-field"]}
                                            />
                                        </Form.Item>
                                    </Grid>

                                    <Grid container item lg={6} md={6} xs={12}>
                                        <Form.Item label="Bill No." name="Bill_No" style={{ width: '95%' }} rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please enter bill no'
                                                }
                                            ]
                                        }>
                                            <Input value="HR 1" className={classes["input-field"]} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Form.Item label="Date" name="Date" rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please select date'
                                                }
                                            ]
                                        }>
                                            <DatePicker
                                                maxDate={dayjs(currentDate, "YYYY-MM-DD")}
                                                style={{ width: "100%" }}
                                                format={constantsArr.GLOBAL_DATE_FORMAT}
                                                className={classes["input-field"]}
                                            />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Form.Item label="GST (0 / 5 / 12 / 18)" name="GST" rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please select GST'
                                                }
                                            ]
                                        }>
                                            <Select
                                                className={classes["input-field"]}
                                                showSearch
                                                placeholder="Select a GST"
                                                optionFilterProp="children"
                                                onChange={(e) => onChange({ target: { id: 'GST', value: e } })}
                                                id="GST"
                                                onSearch={onSearch}
                                                filterOption={filterOption}
                                                options={constantsArr.GST_RATES}
                                            />
                                        </Form.Item>
                                    </Grid>
                                    <Grid container item lg={6} md={6} xs={12}>
                                        <Form.Item label="Other Deduction" name="Other_Deduction" style={{ width: '95%' }}>
                                            <Input type="number" id="Other_Deduction" onKeyPress={AppHelper.preventNegativeValue} min={0} onChange={onChange} className={classes["input-field"]}  autoComplete="off" />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Form.Item label="Less TDS" name="Less_TDS" rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please enter less TDS value'
                                                }
                                            ]
                                        }>
                                            <Select
                                                showSearch
                                                placeholder="Select a TDS"
                                                optionFilterProp="children"
                                                onChange={(e) => onChange({ target: { id: 'Less_TDS', value: e } })}
                                                id="Less_TDS"
                                                onSearch={onSearch}
                                                filterOption={filterOption}
                                                options={constantsArr.LESS_TDS_RATES}
                                            />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item lg={12} md={12} xs={12}>
                                        <Form.Item label="Remark for Deduction" name="Remark_for_Deduction">
                                            <TextArea
                                                placeholder="Remarks"
                                                rows={7}
                                                className={classes["input-field"]}
                                            />
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes["total-amount-wrapper"]}>
                                <Grid container spacing={0}>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <Typography variant="h5" color="#fff">
                                            Total Payable Amount
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <Typography variant="h4" color="#fff" fontWeight="bold" className={classes["amount-bold"]}>
                                            <span className={classes['rupee_symbol_payable_amount']}>₹</span>
                                            {(parseFloat(totalWorkAmount) + parseFloat(gstAmount) - (parseFloat(totalHSDAmount) + parseFloat(otherDeductionAmount) + parseFloat(lessTDSAmount))).toLocaleString('en-IN')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className={classes["submit-button-area"]}>
                            <UIButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={diableSubmitButton}
                                className={classes["submit-button"]}
                            >
                                Save and Export
                            </UIButton>
                        </div>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};
