import React, { useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./EditPendingPetrolPumpBill.module.scss";
import { BreadCrumbPetrolPump } from "../BreadCrumbPetrolPump/BreadCrumbPetrolPump";
import {
  DatePicker,
  Drawer,
  Form,
  Table,
  Select,
  notification,
  Spin,
  Tooltip,
  Pagination,
  Checkbox,
} from "antd";
import Search from "antd/es/input/Search";
import { FilterOutlined } from "@ant-design/icons";
import petrolPumpImg from "../../../../assets/images/BillsIcons/petrolpumpimg.svg";

const columns = [
  {
    title: "S.No.",
    dataIndex: "S_No",
    // width: "4%",
  },
  {
    title: "Date of Loading",
    dataIndex: "Date_Of_Loading",
    // width: "10%",
  },
  {
    title: "Date of UL",
    dataIndex: "Date_Of_Unloading",
    // width: "8%",
  },
  {
    title: "Vehicle No.",
    dataIndex: "vehicle_number",
    // width: "6%",
  },
  {
    title: "Challan No.",
    dataIndex: "Challan_Number",
    // width: "6%",
  },
  {
    title: "Agency Name",
    dataIndex: "agency_name",
    // width: "7%",
  },
  {
    title: "D/o No.",
    dataIndex: "Do_No",
    // width: "5%",
  },
  {
    title: "HSD LTR",
    dataIndex: "hsd_ltr",
    // width: "5%",
  },
  {
    title: "Rate",
    dataIndex: "rate",
    // width: "5%",
  },
  {
    title: "Amount",
    dataIndex: "amount",
    // width: "5%",
  },
];
const data = [];
const avgWeight = 5;
for (let i = 1; i < 11; i++) {
  data.push({
    key: i,
    S_No: `${i}`,
    vehicle_number: `RJ${i} CA 00${i}`,
    Date_Of_Loading: `0${i}-05-2025`,
    Date_Of_Unloading: `0${i}-05-2024`,
    Challan_Number: "200" + `${i}`,
    agency_name: "Hire service",
    Do_No: `${i}`,
    hsd_ltr: `${i}` * `${avgWeight}`,
    rate: `${i}` ** `${avgWeight}`,
    amount: `${i}` + `${avgWeight}`,
  });
}

export const EditPendingPetrolPumpBill = () => {
  const activeButton = "pending_bills";
  const [open, setOpen] = useState(false);
  const { RangePicker } = DatePicker;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
  };

  const showDrawer = () => setOpen(true);

  const onClose = () => setOpen(false);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  return (
    <>
       <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["pending-petrol-pump-bill-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={5} md={4} sm={12} xs={12}>
            <BreadCrumbPetrolPump
              activeButton={activeButton}
              page="petrolpumpEdit"
            />
          </Grid>

          <Grid item lg={7} md={8} sm={12} xs={12}>
            <Grid
              lg={12}
              md={12}
              sm={12}
              xs={12}
              spacing={2}
              container
              textAlign="right"
            >
              <Grid item lg={5} md={4} sm={6} xs={6}>
                <Search
                  placeholder="Search By DO No."
                  className={classes["search-new-entry"]}
                />
              </Grid>
              <Grid item lg={5} md={4} sm={6} xs={6}>
                <Form>
                  <Form.Item
                    label="Sort By"
                    className={classes["select-new-entry"]}
                  >
                    <Select className={classes["select-button-height"]}>
                      <Select.Option value="Challan_Number:asc">
                        Challan No. (asc)
                      </Select.Option>
                      <Select.Option value="Challan_Number:desc">
                        Challan No. (desc)
                      </Select.Option>
                      <Select.Option value="Date_of_Loading:asc">
                        Date Of Loading (asc)
                      </Select.Option>
                      <Select.Option value="Date_of_Loading:desc">
                        Date Of Loading (desc)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Grid>
              <Grid
                item
                lg={2}
                md={4}
                sm={12}
                xs={12}
                className={classes["xs-filter-position"]}
              >
                <UIButton
                  variant="outlined"
                  className={classes["filter-new-entry"]}
                  onClick={showDrawer}
                  startIcon={<FilterOutlined />}
                >
                  Filters
                </UIButton>
                <Drawer title="Filters" onClose={onClose} open={open}>
                  <Form layout="vertical">
                    <Form.Item label="Agency Name" name="agency_name">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        style={{ width: "100%" }}
                        placeholder="Select"
                      />
                    </Form.Item>
                    <Form.Item label="Date of Loading" name="Date_of_Loading">
                      <RangePicker style={{ width: "100%" }} picker="date" />
                    </Form.Item>
                    <Form.Item
                      label="Date of Unloading"
                      name="Date_of_Unloading"
                    >
                      <RangePicker style={{ width: "100%" }} picker="date" />
                    </Form.Item>
                    <Form.Item label="Vehicle Number" name="Vehicle_Number">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        style={{ width: "100%" }}
                        placeholder="Select"
                      />
                    </Form.Item>
                    <Form.Item label="Challan Number" name="Challan_Number">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={filterOption}
                        style={{ width: "100%" }}
                        placeholder="Select"
                      />
                    </Form.Item>
                    <Form.Item
                      messageVariables=""
                      className={classes["ant-form-item"]}
                    >
                      <Checkbox name="Cancelled_challan">
                        Cancel Challan
                      </Checkbox>
                    </Form.Item>
                    <div className={classes["search-button-area"]}>
                      <UIButton size="medium" variant="outlined" color="error">
                        Reset
                      </UIButton>
                      <UIButton
                        size="medium"
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes["submit-button"]}
                      >
                        Apply
                      </UIButton>
                    </div>
                  </Form>
                </Drawer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["pending-petrol-pump-bill-padding"]}
      >
        <Grid
          className={classes["transport-agency-bill-number-wrapper"]}
          container
        >
          <Grid className={classes["transport-agency-wrappper"]}>
            <img src={petrolPumpImg} alt="petrol pump agency" />
            <Typography variant="h6" color="#000" fontWeight="bold">
              Petrol Pump
            </Typography>
            <Form>
              <Select
                showSearch
                allowClear
                placeholder="All"
                optionFilterProp="children"
                filterOption={filterOption}
                className={classes["select-new-entry"]}
              />
            </Form>
          </Grid>
          <Grid className={classes["transport-agency-bill-number"]}>
            <Typography variant="h6" color="#000" fontWeight="bold">
              Bill Number
            </Typography>
            <Typography
              variant="body"
              color="#353535"
              className={classes["hr-transport-box"]}
            >
              PT 01
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["pending-petrol-pump-bill-padding"]}
      >
        <Grid className={classes["middle-boundary-transport"]} container>
          <Grid>
            {/* Will change in future dynamically */}
            <Typography
              variant="h5"
              color="#fff"
              className={classes["text-boundary-tranport"]}
            >
              Hira Service Section
            </Typography>
          </Grid>
          <Grid className={classes["total-amount-transport"]}>
            <Typography variant="h6" fontWeight="bold" color="#fff">
              Total Payable Amount
            </Typography>
            <Typography variant="h6" color="#fff" fontWeight="bold">
              = 39483033
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["pending-petrol-pump-bill-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-first-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                450
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Trip Count
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-second-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Total Excavator Count
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-third-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD LTR In Vehicle
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={3} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-fourth-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD LTR In Excavator
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-fivth-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                Total HSD LTR
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={4} sm={6} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-sixth-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD Effective Rate
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={1.7} md={4} sm={12} xs={12}>
            <Grid
              className={`${classes["petrol-pump-counter"]} ${classes["petrol-pump-seventh-counter"]}`}
            >
              <Typography
                variant="h5"
                fontWeight="bold"
                color="#fff"
                className={classes["h5"]}
              >
                3434
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                className={classes["para"]}
              >
                HSD Effective Rate
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div className={classes["pagination-box"]}>
        <Pagination defaultCurrent={1} total={50} />
      </div>
      <Table
        className={classes["pending-petrol-pump-bill-padding"]}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
        bordered={true}
        tableLayout="fixed"
        scroll={{ x: 1500 }}
        pagination={false}
      />
      <div className={classes["pagination-box"]}>
        <Pagination defaultCurrent={1} total={50} />
      </div>

      <Grid className={classes["export-submit-button"]}>
        <UIButton variant="outlined" className={classes["export-button"]}>
          Export
        </UIButton>
        <UIButton variant="contained" className={classes["submit-button"]}>
          Submit
        </UIButton>
      </Grid>
    </>
  )
}
