import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./ProfileSettings.module.scss";
import { Form, Input, notification } from "antd";
import { useNavigate } from "react-router-dom";
import AppHelper from "../../helpers/AppHelper";

export const ProfileSettings = () => {
  const [profileForm] = Form.useForm();
  const [formInputFields, setFormInputFields] = useState({})
  const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem('userInfo'))
  const loadId = sessionUserInfo?.userID ?? "";
  const navigate = useNavigate();

  const getStaffUserByID = async () => {
    const response = await AppHelper.getUserDetailsById(loadId);
    const defaultValueArrThermalPlant = [];
    const defaultValueArrThermalPlantIds = [];
    // Assuming getStaffUsersDetails is available in your component
    const thermalData = response?.data?.attributes?.Thermal_Plant?.data;
    thermalData.forEach((item) => {
      if (item?.attributes?.Name) {
        defaultValueArrThermalPlant.push(item?.attributes?.Name);
        defaultValueArrThermalPlantIds.push(item?.id);
      }
    });
    profileForm.setFieldsValue({
      Name: response?.data?.attributes?.Name,
      Contact_Number: response?.data?.attributes?.Contact_Number,
      Company_ID: response?.data?.attributes?.Company_ID ?? "-",
      Email_id: response?.data?.attributes?.Email_id,
      Thermal_Plant: defaultValueArrThermalPlant,
    });
    let inputFields = response?.data?.attributes
    inputFields.Thermal_Plant = defaultValueArrThermalPlantIds
    setFormInputFields({ ...formInputFields, ...inputFields })
  };

  const updateProfileSetting = async () => {
    const payload = { data: formInputFields }
    const response = await AppHelper.updateGetStaffUsers(loadId, payload);
    if (response?.error) {
      notification.error({
        message: "Error",
        description: response?.error.message,
        duration: 1.5,
      });
    } else {
      sessionUserInfo.userName = response?.attributes?.Name
      console.log(sessionUserInfo);
      const encryptedUserInfo = AppHelper.encryptText(sessionUserInfo)
      sessionStorage.setItem("userInfo", encryptedUserInfo);
      notification.success({
        message: "Success",
        description: "User's profile has been updated successfully",
        duration: 1.5,
      });
      getStaffUserByID();
    }
  };

  const handleInputChange = (e) => {
    setFormInputFields({ ...formInputFields, [e.target.id]: e.target.value })
  };

  const backtoloadingPage = () => {
    navigate("/loadings");
  };

  useEffect(() => {
    document.title = "Profile Settings"
    getStaffUserByID();
  }, []);

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        alignContent="stretch"
        wrap="wrap"
        className={classes["header-profile-fixed"]}
      >
        <Typography
          variant="h5"
          color="initial"
          fontWeight="bold"
          className={classes["grid-space"]}
        >
          Profile Settings
        </Typography>
      </Grid>

      <Form
        layout="vertical"
        onFinish={updateProfileSetting}
        form={profileForm}
        className={classes["header-profile-fixed"]}
      >
        <Grid
          container
          spacing={1}
          direction="row"
          justify="center"
          alignItems="center"
          alignContent="center"
          wrap="wrap"
        >
          <Grid item lg={6} md={6} xs={12}>
            <Form.Item label="Name"
              name="Name">
              <Input
                id="Name"
                readOnly={false}
                onChange={handleInputChange}
              />
            </Form.Item>

            <Form.Item
              name="Contact_Number"
              label="Contact Number"
              rules={[
                {
                  max: 10,
                  message: 'Contact No should be of 10 digits only'
                },
                {
                  min: 10,
                  message: 'Contact No should be of 10 digits only'
                }
              ]}
            >
              <Input
                readOnly={false}
                type="number"
                id="Contact_Number"
                onChange={handleInputChange}
              />
            </Form.Item>

            <Form.Item label="Company ID" name="Company_ID">
              <Input
                readOnly={true}
                id="Company_ID"
              />
            </Form.Item>
          </Grid>

          <Grid item lg={6} md={6} xs={12} className={classes["column-space"]}>
            <Form.Item label="Email" name="Email_id">
              <Input
                readOnly={true}
                id="Company_ID"
              />
            </Form.Item>

            <Form.Item
              messageVariables=""
              label="Thermal Plant"
              name="Thermal_Plant"
            >
              <Input id="Thermal_Plant" readOnly/>
            </Form.Item>
          </Grid>
        </Grid>
        <div className={classes["submit-button-area"]}>
          <UIButton variant="contained" color="primary" type="submit">
            Save
          </UIButton>
          <UIButton
            variant="outlined"
            color="error"
            onClick={backtoloadingPage}
          >
            Cancel
          </UIButton>
        </div>
      </Form>
    </>
  );
};
