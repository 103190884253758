import React from "react";
import ReactApexChart from "react-apexcharts";
import classes from "./TransporationQntyGraph.module.scss";

export const TransporationQntyGraph = ({ transportationBarChartInfo }) => {

    const options4 = {
        chart: {
            height: 400,
            type: "bar",
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    const findElement = transportationBarChartInfo?.quantityRecords.find(item => item?.weight_percentage === value)
                    return findElement?.month_total_weight ? parseFloat((findElement?.month_total_weight / 1000).toFixed(2)) + ' MT' : 0 + ' MT'
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"],
            },
        },
        xaxis: {
            categories: transportationBarChartInfo?.quantityRecords?.length > 0 ? transportationBarChartInfo?.quantityRecords.filter(item => item?.weight_percentage > 0)?.map((item) => { return item?.month ?? '' }) : [],
            position: "bottom",
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            tooltip: {
                enabled: true,
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val + " MT";
                },
            },
            title: {
                text: "Total QTY (MT)",
            },
        },
        colors: ["#0067B6"],
    };

    const series4 = [
        {
            name: "Weight",
            data: transportationBarChartInfo?.quantityRecords?.length > 0 ? transportationBarChartInfo?.quantityRecords.filter(item => item?.weight_percentage > 0)?.map((item) => { return item?.weight_percentage ?? 0 }) : [],
        },
    ];


    return (
        <>
            {
                transportationBarChartInfo?.quantityRecords?.length > 0
                    ?
                    <ReactApexChart
                        options={options4}
                        series={series4}
                        type="bar"
                        height={400}
                    />
                    :
                    <div className={classes['graphArea']}>
                        <div className={classes['spinStyle']}>No Data Found</div>
                    </div>
            }
        </>
    );
};
