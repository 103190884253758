import { ArrowLeftOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import classes from '../AgencyData.module.scss'
import React, { useEffect, useState } from 'react'
import { Button as UIButton, Grid, Typography, Box } from '@mui/material'
import { Col, DatePicker, Form, Input, Row, Select, Spin, notification, Modal } from 'antd'
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import constantsArr from '../../../../constants/global-constants.js'
import AppHelper from '../../../../helpers/AppHelper.js'
import { useNavigate } from 'react-router-dom'

const TabElementData = (props) => {
    const { value, children, index, ...other } = props
    return <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        className='agency-tab-content'
        aria-labelledby={`simple-tab-${index}`}
        {...other}
    >
        <Box sx={{ pt: 3 }}>
            {children}
        </Box>
    </div>
}

function CreateAgency() {

    const navigate = useNavigate();
    const [agencyForm] = Form.useForm()
    const [spinLoading, setIsSpinLoading] = useState(false)
    const [inputFields, setHandleInputFields] = useState({
        Company_name: "",
        Contact_person: "",
        Mobile_Number: "",
        Type_of_agency: "",
        Location: "",
        Loading_Rate: {
            Rates: [{
                Start_Date: "",
                Rate_Type: "",
                Rate: ""
            }]
        },
        Unloading_Rates: {
            Rates: [{
                Start_Date: "",
                Rate_Type: "",
                Rate: ""
            }]
        },
        Transportation_Rate: {
            Rates: [{
                Start_Date: "",
                Rate_Type: "",
                Rate: ""
            }]
        }
    })
    const [selectedAgencyInDropDown, setSelectedAgencyInDropDown] = useState([])
    const [additionalFieldsLoading, setAdditionalFieldsLoading] = useState([{ 'Start_Date': '', 'Rate_Type': '', 'Rate': '' }]);
    const [additionalFieldsUnloading, setAdditionalFieldsUnloading] = useState([{ 'Start_Date': '', 'Rate_Type': '', 'Rate': '' }]);
    const [additionalFieldsTransportation, setAdditionalFieldsTransportation] = useState([{ 'Start_Date': '', 'Rate_Type': '', 'Rate': '' }]);
    const [mathingAgencies, setMatchingAgencies] = useState([])
    const [displayMatchingAgencies, setDisplayMatchingAgencies] = useState(false)
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const [tabsValue, setTabsValue] = useState(0)
    const [projectName, setProjectName] = useState('');
    const [agencyCreateValidationErrors, setAgencyValidationErrors] = useState([])
    const [validationModelOpen, setValidationModelOpen] = useState(false)
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);

    const backToAgenciesList = (e) => {
        e.preventDefault();
        navigate('/agencies')
    }

    const preventNegativeValue = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    const handleOk = () => setValidationModelOpen(false)

    const makeSelectedAgencyDropDown = (value, options) => {
        setSelectedAgencyInDropDown(value)
        setHandleInputFields({ ...inputFields, 'Type_of_agency': value })
    }

    const agencyValueIndexInDropDown = (agencyName) => {
        return selectedAgencyInDropDown.indexOf(agencyName)
    }

    /** ------------------------------- Form validation methods starts from here ---------------------------- */

    const handleChange = (e) => {
        setHandleInputFields({ ...inputFields, [e.target.id]: e.target.value })
    }

    const submitForm = async (event) => {
        setIsSubmittingResponse(true)
        setIsSpinLoading(false)
        let postPayload = AppHelper.preparePayloadForAgency(inputFields)
        postPayload = { ...postPayload, ['Project']: sessionGlobalValues?.projectGlobal }
        const validateRecordBeforeSubmit = validateRatesRowsBeforeSubmit(postPayload)
        if (validateRecordBeforeSubmit.length === 0) {
            const createAgencyResponse = await AppHelper.createAgency(postPayload);
            if (createAgencyResponse?.error) {
                setIsSpinLoading(false)
                // if error from api then show error message
                notification.error({
                    message: "Error",
                    description: `${createAgencyResponse?.error?.message}`,
                    duration: 1.5,
                });
            } else {
                // show success message in case of successful api
                notification.success({
                    message: "Success",
                    description: "Agency has been created Successfully.",
                    duration: 1.5,
                });
                navigate('/agencies')
            }
        }
        setIsSubmittingResponse(false);
    }

    const findDuplicateStarDateByAgency = (agencyType, payload) => {
        if (!agencyType) return false;
        if (!payload) return false
        let duplicateStartDates = []
        for (let index = 0; index < payload?.[agencyType]?.Rates.length; index++) {
            if ((payload?.[agencyType]?.Rates[index]?.Start_Date && payload?.[agencyType]?.Rates[index]?.Start_Date !== "")) {
                if (!duplicateStartDates[payload?.[agencyType]?.Rates[index]?.Start_Date]
                ) {
                    duplicateStartDates[payload?.[agencyType]?.Rates[index]?.Start_Date] = true
                } else {
                    const startDate = new Date(payload?.[agencyType]?.Rates[index]?.Start_Date)
                    return `${startDate.getDate()}-${(startDate.getMonth() + 1).toString().padStart(2, '0')}-${startDate.getFullYear()}`
                }
            }
        }
        return false
    }

    const validateRatesRowsBeforeSubmit = (payload) => {
        let errorRows = []
        // checking for loading rates
        payload?.Loading_Rate?.Rates.forEach((value, key) => {
            if ((!value?.Start_Date || value?.Start_Date === "")) {
                errorRows.push(`In Loading agency, (Row ${key + 1}) - please provide start date`)
            } else if (!value?.Rate_Type || value?.Rate_Type === "") {
                errorRows.push(`In Loading agency, (Row ${key + 1}) - please provide rate type.`)
            } else if (((!value?.Rate || value?.Rate === "") && value?.Rate !== '0')) {
                errorRows.push(`In Loading agency, (Row ${key + 1}) - please provide rate.`)
            }
        })
        const findDuplicateStartDateLoading = findDuplicateStarDateByAgency('Loading_Rate', payload);
        if (findDuplicateStartDateLoading) {
            errorRows.push(`In Loading agency, Duplicate Start date ${findDuplicateStartDateLoading} detected.`)
        }

        // checking for unloading rates
        payload?.Unloading_Rates?.Rates.forEach((value, key) => {
            if ((!value?.Start_Date || value?.Start_Date === "")) {
                errorRows.push(`In Unloading agency, (Row ${key + 1}) - please provide start date`)
            } else if (!value?.Rate_Type || value?.Rate_Type === "") {
                errorRows.push(`In Unloading agency, (Row ${key + 1}) - please provide rate type.`)
            } else if (((!value?.Rate || value?.Rate === "") && value?.Rate !== '0')) {
                errorRows.push(`In Unloading agency, (Row ${key + 1}) - please provide rate.`)
            }
        })
        const findDuplicateStartDateUnloading = findDuplicateStarDateByAgency('Unloading_Rates', payload);
        if (findDuplicateStartDateUnloading) {
            errorRows.push(`In Unloading agency, Duplicate Start date ${findDuplicateStartDateUnloading} detected.`)
        }
        // checking for transportation rates
        payload?.Transportation_Rate?.Rates.forEach((value, key) => {
            if ((!value?.Start_Date || value?.Start_Date === "")) {
                errorRows.push(`In Transportation agency, (Row ${key + 1}) - please provide start date`)
            } else if (!value?.Rate_Type || value?.Rate_Type === "") {
                errorRows.push(`In Transportation agency, (Row ${key + 1}) - please provide rate type.`)
            } else if (((!value?.Rate || value?.Rate === "") && value?.Rate !== '0')) {
                errorRows.push(`In Transportation agency, (Row ${key + 1}) - please provide rate.`)
            }
        })
        const findDuplicateStartDateTransportationLoading = findDuplicateStarDateByAgency('Transportation_Rate', payload);
        if (findDuplicateStartDateTransportationLoading) {
            errorRows.push(`In Transportation agency, Duplicate Start date ${findDuplicateStartDateTransportationLoading} detected.`)
        }
        if (errorRows.length > 0) {
            setAgencyValidationErrors(errorRows)
            setValidationModelOpen(true)
        } else {
            setAgencyValidationErrors([])
            setValidationModelOpen(false)
        }
        return errorRows;
    }

    const showMatchingAgencies = async (e) => {
        if (e.target.value !== "") {
            const findMatchingAgencies = await AppHelper.findMatchingAgencies(e.target.value)
            if (findMatchingAgencies?.error) {
                console.log("errors", findMatchingAgencies?.error)
            } else {
                setDisplayMatchingAgencies(true)
                setMatchingAgencies(findMatchingAgencies?.data)
            }
        } else {
            setDisplayMatchingAgencies(false)
        }
    }

    const hideMatchingAgenciesDisplayElement = () => {
        setDisplayMatchingAgencies(false)
    }

    const handleAddMoreRatesLoading = () => {
        setAdditionalFieldsLoading([...additionalFieldsLoading, { 'Start_Date': '', 'Rate_Type': '', 'Rate': '' }]);
        //setTabItems(tabItems)
        // update input fields array
        let updatedInputFieldsArray = { ...inputFields }
        updatedInputFieldsArray['Loading_Rate']['Rates'] = [
            ...inputFields['Loading_Rate']['Rates'], {
                Start_Date: "",
                Rate_Type: "",
                Rate: ""
            }]
        setHandleInputFields(updatedInputFieldsArray)
    };

    const handleAddMoreRatesUnloading = () => {
        setAdditionalFieldsUnloading([...additionalFieldsUnloading, { 'Start_Date': '', 'Rate_Type': '', 'Rate': '' }]);
        // update input fields array
        let updatedInputFieldsArray = { ...inputFields }
        updatedInputFieldsArray['Unloading_Rates']['Rates'] = [
            ...inputFields['Unloading_Rates']['Rates'], {
                Start_Date: "",
                Rate_Type: "",
                Rate: ""
            }]
        setHandleInputFields(updatedInputFieldsArray)
    };

    const handleAddMoreRatesTransportation = () => {
        setAdditionalFieldsTransportation([...additionalFieldsTransportation, { 'Start_Date': '', 'Rate_Type': '', 'Rate': '' }]);
        // update input fields array
        let updatedInputFieldsArray = { ...inputFields }
        updatedInputFieldsArray['Transportation_Rate']['Rates'] = [
            ...inputFields['Transportation_Rate']['Rates'], {
                Start_Date: "",
                Rate_Type: "",
                Rate: ""
            }]
        setHandleInputFields(updatedInputFieldsArray)
    };

    const handleDeleteFieldLoading = index => {
        let currentLoadingRatesElements = [...additionalFieldsLoading]
        currentLoadingRatesElements.splice(index, 1);
        setAdditionalFieldsLoading(currentLoadingRatesElements);
        // update input fields array accordingly
        let currentInputFieldsArr = { ...inputFields }
        currentInputFieldsArr['Loading_Rate']['Rates'].splice(index, 1)
        setHandleInputFields(currentInputFieldsArr)
    };

    const handleDeleteFieldUnloading = index => {
        let currentUnloadingRatesElements = [...additionalFieldsUnloading]
        currentUnloadingRatesElements.splice(index, 1);
        setAdditionalFieldsUnloading(currentUnloadingRatesElements);
        // update input fields array accordingly
        let currentInputFieldsArr = { ...inputFields }
        currentInputFieldsArr['Unloading_Rates']['Rates'].splice(index, 1)
        setHandleInputFields(currentInputFieldsArr)
    };

    const handleDeleteFieldTransportation = index => {
        let currentTransportationRatesElements = [...additionalFieldsTransportation]
        currentTransportationRatesElements.splice(index, 1);
        setAdditionalFieldsTransportation(currentTransportationRatesElements);
        // update input fields array accordingly
        let currentInputFieldsArr = { ...inputFields }
        currentInputFieldsArr['Transportation_Rate']['Rates'].splice(index, 1)
        setHandleInputFields(currentInputFieldsArr)
    };

    const onChangeAgencytabs = (key) => {
        console.log(key);
    };

    const onTabChange = (e, newVal) => {
        setTabsValue(newVal)
    }

    useEffect(() => {
        document.title = "Create Agency";

        async function fetchProjectName() {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
            const thermalPlantId = globalTpProjectValues?.thermalPlantGlobal;
            const globalProjectId = globalTpProjectValues?.projectGlobal;
            const projects = await AppHelper.getProjectsByThermalPlants(thermalPlantId);

            let projectNameFromStorage = sessionStorage.getItem('projectName');
            if (!projectNameFromStorage) {
                projects.forEach((value) => {
                    if (value.id === globalProjectId) {
                        projectNameFromStorage = value.Name_of_project;
                        sessionStorage.setItem('projectName', projectNameFromStorage);
                    }
                });
            } else {
                // If projectNameFromStorage exists and the current project id is different from the stored project id,
                // Update the project name in the session storage
                const currentProject = projects.find(project => project.id === globalProjectId);
                if (currentProject && currentProject.Name_of_project !== projectNameFromStorage) {
                    projectNameFromStorage = currentProject.Name_of_project;
                    sessionStorage.setItem('projectName', projectNameFromStorage);
                }
            }

            setProjectName(projectNameFromStorage);
        }

        fetchProjectName();
    }, []);
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["create-agency-padding"]}
            >
                <Grid item lg={11} md={11} xs={12}>
                    <Typography variant="h1" fontSize="1.5rem" fontWeight="bold" className="title-un-loading-text">
                        Create Agency
                    </Typography>
                </Grid>

                <Grid item lg={1} md={1} xs={12} container className={classes['action-button']}>
                    <UIButton size="small" variant="contained" onClick={backToAgenciesList} className={classes['add-new-entry']}>
                        <ArrowLeftOutlined />&nbsp;Agencies
                    </UIButton>
                </Grid>
            </Grid>
            <Spin tip="Loading.." spinning={spinLoading}>
                <div className={classes['agency-details-margin']}>
                    <Form layout='vertical' onFinish={submitForm} form={agencyForm}>
                        <div className={classes['sub-headings']}>Basic Information</div>
                        <Row gutter={[16, 16]} wrap={true}>
                            <Col span={8}>
                                <Form.Item
                                    messageVariables=""
                                    label="Company Name"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                    name="Company_name"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please enter company name',
                                        },
                                    ]}>
                                    <Input id='Company_name' onChange={handleChange} onKeyUp={showMatchingAgencies} onBlur={hideMatchingAgenciesDisplayElement} autoComplete='off' />
                                </Form.Item>
                                {
                                    displayMatchingAgencies === true && mathingAgencies?.length > 0
                                        ?
                                        <div className={classes['matching-agencies-div-element']}>
                                            <div className={classes['matching-agencies-area-heading']}>
                                                <h3>Following agencies already registered, matching the company name.</h3>
                                            </div>
                                            <div className={classes['matching-agencies-area']}>
                                                <ul>
                                                    {
                                                        mathingAgencies?.map((value, key) => {
                                                            return <li key={key}>{value?.attributes?.Company_name.length > 100 ? <span title={value?.attributes?.Company_name}>{value?.attributes?.Company_name.substring(0, 100)}....</span> : value?.attributes?.Company_name ?? "-"}</li>
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }

                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    messageVariables=""
                                    label="Contact Person"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}
                                    name='Contact_person'>
                                    <Input id='Contact_person' onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    messageVariables=""
                                    label="Mobile Number"
                                    wrapperCol={{ span: 30 }}
                                    labelCol={{ span: 30 }}
                                    className={classes['ant-form-item']}
                                    name='Mobile_Number'
                                    rules={[
                                        {
                                            max: 10,
                                            message: 'Phone number should be of 10 digits only'
                                        },
                                        {
                                            min: 10,
                                            message: 'Phone number should be of 10 digits only'
                                        }
                                    ]}
                                >
                                    <Input id='Mobile_Number' type='number' onChange={handleChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]} wrap={true}>
                            <Col span={8}>
                                <Form.Item
                                    messageVariables=""
                                    label="Type of Agency"
                                    wrapperCol={{ span: 30 }}
                                    labelCol={{ span: 30 }}
                                    className={classes['ant-form-item']}
                                    name='Type_of_agency'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please select at least one agency',
                                        },
                                    ]}
                                >
                                    <Select id='Type_of_agency' mode='multiple' allowClear placeholder="Please select" options={constantsArr.AGENCY_TYPES} onChange={(value, options) => makeSelectedAgencyDropDown(value, options)} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    messageVariables=""
                                    label="Location"
                                    name="Location"
                                    wrapperCol={{ span: 30 }}
                                    labelCol={{ span: 30 }}
                                    className={classes['ant-form-item']}
                                    rules={[{
                                        required: true,
                                        message: "Please enter the Location."
                                    }]}>

                                    <Input id='Location' onChange={handleChange} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    messageVariables=""
                                    label="Remarks"
                                    wrapperCol={{ span: 30 }}
                                    labelCol={{ span: 30 }}
                                    className={classes['ant-form-item']}>
                                    <Input id='Remarks' onChange={handleChange} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs TabIndicatorProps={{ style: { backgroundColor: 'rgb(68, 76, 247)' } }} value={tabsValue} onChange={onTabChange} aria-label="basic tabs example">
                                    {
                                        selectedAgencyInDropDown?.map((agencyName) => (
                                            <Tab key={agencyName} label={agencyName} style={{ fontWeight: 'bold', textTransform: 'capitalize', fontSize: '16px' }} sx={{
                                                '&.Mui-selected': {
                                                    color: 'rgb(68, 76, 247)',
                                                },

                                            }} />
                                        ))
                                    }
                                </Tabs>
                            </Box>
                        </Box>
                        {
                            selectedAgencyInDropDown.includes('Loading') &&
                            <TabElementData value={tabsValue} index={agencyValueIndexInDropDown('Loading')} key="loading-details">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    className={classes["tab-content-heading"]}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography variant="body2" color="initial">
                                            Project: <strong>{projectName ?? ''}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <UIButton style={{ float: "right", backgroundColor: "#444CF7", color: "#fff" }} startIcon={<PlusOutlined />} onClick={handleAddMoreRatesLoading}>
                                            Add More Rates
                                        </UIButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    style={{ scrollBehavior: "smooth", overflowY: "scroll", marginBottom: '50px', width: "100%", marginTop: "20px", marginLeft: "2px", backgroundColor: "#CAE4FF", padding: "10px", borderRadius: "5px", boxShadow: "0px 1px 10px #00000054" }}
                                >
                                    {/* Render additional form fields based on the additionalFields state */}
                                    {additionalFieldsLoading.map((value, index) => (
                                        <Grid container spacing={1} direction="row" justify="center" alignItems="center" alignContent="center" wrap="wrap" key={index}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Start Date`}
                                                >
                                                    <DatePicker placeholder='Pick a date' format="DD-MM-YYYY" name={`${`Loading_Rate.Rates.${index}.Start_Date`}`} style={{ width: "100%" }} onChange={(e) => handleChange({ target: { id: `${`Loading_Rate.Rates.${index}.Start_Date`}`, value: e } })} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Type of Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                >
                                                    <Select id={`${`Loading_Rate.Rates.${index}.Rate_Type`}`} name={`${`Loading_Rate.Rates.${index}.Rate_Type`}`} style={{ width: "100%" }} options={constantsArr.AGENCY_RATE_TYPES} onChange={(e) => handleChange({ target: { id: `${`Loading_Rate.Rates.${index}.Rate_Type`}`, value: e } })} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={3} md={3} xs={3}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                >
                                                    <Input type='number' id={`${`Loading_Rate.Rates.${index}.Rate`}`} min={0} name={`${`Loading_Rate.Rates.${index}.Rate`}`} style={{ width: "100%" }} onKeyPress={preventNegativeValue} onChange={handleChange} />
                                                </Form.Item>
                                            </Grid>
                                            {/* Delete button for each additional field */}
                                            {
                                                index > 0 && index === (additionalFieldsLoading.length - 1) ?

                                                    <Grid item lg={1} md={1} xs={1}>
                                                        <UIButton onClick={() => handleDeleteFieldLoading(index)}>
                                                            <DeleteOutlined style={{ border: "1px solid #ff0000", padding: "7px", marginTop: "8px", borderRadius: "5px", color: "#ff0000" }} />
                                                        </UIButton>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                        </Grid>
                                    ))}

                                </Grid>
                            </TabElementData>
                        }
                        {
                            selectedAgencyInDropDown.includes('Unloading') &&
                            <TabElementData value={tabsValue} index={agencyValueIndexInDropDown('Unloading')} key="unloading-details">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    className={classes["tab-content-heading"]}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography variant="body2" color="initial">
                                            Project:  <strong>{projectName ?? ''}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <UIButton style={{ float: "right", backgroundColor: "#444CF7", color: "#fff" }} startIcon={<PlusOutlined />} onClick={handleAddMoreRatesUnloading}>
                                            Add More Rates
                                        </UIButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    style={{ scrollBehavior: "smooth", overflowY: "scroll", marginBottom: '50px', width: "100%", marginTop: "20px", marginLeft: "2px", backgroundColor: "#D5E3CD", padding: "10px", borderRadius: "5px", boxShadow: "0px 1px 10px #00000054" }}
                                >
                                    {/* Render additional form fields based on the additionalFields state */}
                                    {additionalFieldsUnloading.map((value, index) => (
                                        <Grid container spacing={1} direction="row" justify="center" alignItems="center" alignContent="center" wrap="wrap" key={index}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Start Date`}
                                                >
                                                    <DatePicker placeholder='Pick a date' format="DD-MM-YYYY" name={`${`Unloading_Rates.Rates.${index}.Start_Date`}`} style={{ width: "100%" }} onChange={(e) => handleChange({ target: { id: `${`Unloading_Rates.Rates.${index}.Start_Date`}`, value: e } })} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Type of Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                >
                                                    <Select id={`${`Unloading_Rates.Rates.${index}.Rate_Type`}`} name={`${`Unloading_Rates.Rates.${index}.Rate_Type`}`} style={{ width: "100%" }} options={constantsArr.AGENCY_RATE_TYPES} onChange={(e) => handleChange({ target: { id: `${`Unloading_Rates.Rates.${index}.Rate_Type`}`, value: e } })} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={3} md={3} xs={3}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                >
                                                    <Input type='number' id={`${`Unloading_Rates.Rates.${index}.Rate`}`} min={0} name={`${`Unloading_Rates.Rates.${index}.Rate`}`} onKeyPress={preventNegativeValue} onChange={handleChange} />
                                                </Form.Item>
                                            </Grid>
                                            {/* Delete button for each additional field */}
                                            {
                                                index > 0 && index === (additionalFieldsUnloading.length - 1) ?
                                                    <Grid item lg={1} md={1} xs={1}>
                                                        <UIButton onClick={() => handleDeleteFieldUnloading(index)}>
                                                            <DeleteOutlined style={{ border: "1px solid #ff0000", padding: "7px", marginTop: "8px", borderRadius: "5px", color: "#ff0000" }} />
                                                        </UIButton>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                        </Grid>
                                    ))}

                                </Grid>
                            </TabElementData>
                        }
                        {
                            selectedAgencyInDropDown.includes('Transportation') &&
                            <TabElementData value={tabsValue} index={agencyValueIndexInDropDown('Transportation')} key="transportation-details">
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    className={classes["tab-content-heading"]}
                                >
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Typography variant="body2" color="initial">
                                            Project:  <strong>{projectName ?? ''}</strong>
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <UIButton style={{ float: "right", backgroundColor: "#444CF7", color: "#fff" }} startIcon={<PlusOutlined />} onClick={handleAddMoreRatesTransportation}>
                                            Add More Rates
                                        </UIButton>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    direction="row"
                                    justify="center"
                                    alignItems="center"
                                    alignContent="center"
                                    wrap="wrap"
                                    style={{ scrollBehavior: "smooth", overflowY: "scroll", marginBottom: '50px', width: "100%", marginTop: "20px", marginLeft: "2px", backgroundColor: "#F9EAE0", padding: "10px", borderRadius: "5px", boxShadow: "0px 1px 10px #00000054" }}
                                >
                                    {/* Render additional form fields based on the additionalFields state */}
                                    {additionalFieldsTransportation.map((value, index) => (
                                        <Grid container spacing={1} direction="row" justify="center" alignItems="center" alignContent="center" wrap="wrap" key={index}>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Start Date`}
                                                >
                                                    <DatePicker format="DD-MM-YYYY" id={`${`Transportation_Rate.Rates.${index}.Start_Date`}`} placeholder='Pick a date' style={{ width: "100%" }} name={`${`Transportation_Rate.Rates.${index}.Start_Date`}`} onChange={(e) => handleChange({ target: { id: `${`Transportation_Rate.Rates.${index}.Start_Date`}`, value: e } })} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={4} md={4} xs={4}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Type of Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                >
                                                    <Select id={`${`Transportation_Rate.Rates.${index}.Rate_Type`}`} name={`${`Transportation_Rate.Rates.${index}.Rate_Type`}`} style={{ width: "100%" }} options={constantsArr.AGENCY_RATE_TYPES} onChange={(e) => handleChange({ target: { id: `${`Transportation_Rate.Rates.${index}.Rate_Type`}`, value: e } })} />
                                                </Form.Item>
                                            </Grid>
                                            <Grid item lg={3} md={3} xs={3}>
                                                <Form.Item
                                                    messageVariables=""
                                                    label={`Rate`}
                                                    wrapperCol={{ span: 24 }}
                                                    className={classes['ant-form-item']}
                                                >
                                                    <Input type='number' id={`${`Transportation_Rate.Rates.${index}.Rate`}`} min={0} name={`${`Transportation_Rate.Rates.${index}.Rate`}`} onKeyPress={preventNegativeValue} onChange={handleChange} />
                                                </Form.Item>
                                            </Grid>
                                            {/* Delete button for each additional field */}
                                            {
                                                index > 0 && index === (additionalFieldsTransportation.length - 1)
                                                    ?
                                                    <Grid item lg={1} md={1} xs={1}>
                                                        <UIButton onClick={() => handleDeleteFieldTransportation(index)}>
                                                            <DeleteOutlined style={{ border: "1px solid #ff0000", padding: "7px", marginTop: "8px", borderRadius: "5px", color: "#ff0000" }} />
                                                        </UIButton>
                                                    </Grid>
                                                    :
                                                    ""
                                            }
                                        </Grid>
                                    ))}

                                </Grid>
                            </TabElementData>
                        }
                        <div className={classes['submit-button-area']}>
                            <UIButton variant="contained" color="primary" type='submit' className={classes['submit-button']} disabled={isSubmittingResponse}>
                                Save
                            </UIButton>
                            <UIButton variant="outlined" color="error" onClick={backToAgenciesList}>
                                Cancel
                            </UIButton>
                        </div>
                    </Form>
                </div>
            </Spin>
            {
                validationModelOpen === true &&
                    agencyCreateValidationErrors.length > 0
                    ?
                    <Modal
                        open={validationModelOpen}
                        onOk={handleOk}
                        centered
                        className={classes['error-agency-modal']}
                        title=""
                        width={1024}
                        onCancel={handleOk}
                        footer=""
                    >
                        <>
                            <h3 className={classes['error-agency-modal-header']}>Please check below errors in agency</h3>
                            <div className={classes['error-agency-excel-display-data']}>
                                <table border={0} cellPadding={0} cellSpacing={0} width="100%" className={classes['agency-errors-table']}>
                                    <thead>
                                        <tr>
                                            <th width="10%" className={classes['agency-errors-table-first-td']}>SL No.</th>
                                            <th width="90%">Error</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            agencyCreateValidationErrors.map((value, key) => {
                                                return <tr key={key}>
                                                    <td className={classes['agency-errors-table-first-td']}>{key + 1}</td>
                                                    <td className={classes['error-message-row']}>{value}</td>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </>
                    </Modal >
                    :
                    ""
            }
        </>
    )
}

export default CreateAgency