import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons'
import { Grid, Typography, Button as UIButton } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import AppHelper from '../../../../helpers/AppHelper'
import { Form, Input, Select, Skeleton, Spin, Upload, notification, Modal } from 'antd'
import classes from '../../HSDDetails/CreateHsdDetails/HSDDetails.module.scss'
import dayjs from 'dayjs'
import moment from 'moment'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import arrConstants from "../../../../constants/global-constants";
import constantsArr from "../../../../constants/agency-constant";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });


export const CreateHsdDetails = () => {
    const navigate = useNavigate()
    const params = useParams();
    const agencyId = params?.id ?? ""
    const [hsdForm] = Form.useForm()
    const [loadingDetails, setLoadingDetails] = useState([])
    const [skeleteonLoading, setSekeletonLoading] = useState(true)
    const [spinLoading, setSpinLoading] = useState(false)
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState(true)
    const [petrolPumpsValues, setPetrolPumpsValues] = useState(true)
    const [agencies, setAgencies] = useState([]);
    const [transportationAgencyDropDown, setTransportationAgencyDropDown] = useState([]);
    const [vehicleLists, setVehicleLists] = useState([]);
    const [vehicleDropDown, setVehicleDropDown] = useState([]);
    const [formInputFields, setFormInputFields] = useState({
        Challan_Number: "",
        Vehicle_Number: "",
        Date_of_Loading: "",
        Transporter_Name: "",
        Petrolpump_Name: "",
        Owner: "",
        Rate_INR: "0",
        DO_No: "",
        HSD_LTR: "0",
        Remarks: "",
        HSD_Amt: "",
    })
    const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const maxNoOfUploadsAllowed = arrConstants.MAX_UPLOAD_IMAGES
    const [fileList, setFileList] = useState([]);
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const allowedMimeTypes = arrConstants.ALLOWED_MIME_TYPES
    const [viewPdf, setViewPdf] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const backToLoadingsList = (e) => {
        e.preventDefault();
        navigate("/loadings");
    };


    const formatDefaultDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const setFormDefaultValues = (values) => {
        const defaultValues = {
            Challan_Number: values?.attributes?.Challan_Number,
            Date_of_Loading: formatDefaultDate(values?.attributes?.Date_of_Loading ?? ""),
            Vehicle_Number: values?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number ?? "",
            Vehicle_Number_Id: values?.attributes?.Vehicle_Number?.data?.id ?? "",
            Transporter_Name_Id: values?.attributes?.Transporter_Agency?.data?.id ?? null,
            Owner: values?.attributes?.Vehicle_Number?.data?.attributes?.Owner ?? "",
            D_O_NO: values?.attributes?.DO_No ?? "",
            Transporter_Name: values?.attributes?.Transporter_Agency?.data?.attributes?.Company_name ?? null,
            HSD_LTR: formInputFields.HSD_LTR, // Use the state value
        };
        hsdForm.setFieldsValue(defaultValues);
    };

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const smallerDateRateExist = (petrolPumpRateArray, iterateIndex) => {
        let smallerDateExist = false
        for (let index = iterateIndex; index < petrolPumpRateArray?.attributes?.Rate?.length; index++) {
            const element = petrolPumpRateArray?.attributes?.Rate[index];
            const comparisonDate = petrolPumpRateArray?.attributes?.Rate[iterateIndex];
            if (new Date(element.Start_Date) < new Date(comparisonDate.Start_Date)) {
                smallerDateExist = true
                break
            }
        }
        return smallerDateExist
    }

    const FilterRateForCurrentData = (petrolPumpRateArray) => {
        let petrolPumpObject = null;
        let selectedDate = moment(hsdForm.getFieldValue("Date_of_Loading"), 'DD-MM-YYYY').format("YYYY-MM-DD")
        for (let index = 0; index < petrolPumpRateArray?.attributes?.Rate?.length; index++) {
            const dateElement = petrolPumpRateArray.attributes?.Rate[index];
            if (new Date(dateElement.Start_Date) <= new Date(selectedDate)) {
                petrolPumpObject = dateElement
                if (smallerDateRateExist(petrolPumpRateArray, index)) {
                    break;
                }
            }
        }
        return petrolPumpObject

    }

    const submitForm = async (formValues) => {
        setIsSubmittingResponse(true);
        const vehicle = vehicleLists?.data.find((item) => item?.Vehicle_Number === formValues?.Vehicle_Number)
        const agency = agencies.find((item) => item?.attributes?.Company_name === formValues?.Transporter_Name)
        const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
        let filesJson = JSON.stringify(uploadedFileList)
        const uploadImageResponse = await AppHelper.savePictureData(filesJson)
        const payLoadData = {
            ...formInputFields,
            ...formValues,
            Petrolpump_Name: formValues.Petrolpump_Name_Id,
            Challan_Number: loadingDetails?.attributes?.Challan_Number,
            Vehicle_Number: vehicle?.id,
            Transporter_Name: agency?.id ?? null,
            Project: globalTpProjectValues.projectGlobal ?? "",
            Rate_INR: `${formValues?.Rate_INR}`,
            Date_of_Loading: moment(formValues?.Date_of_Loading, 'DD-MM-YYYY').format("YYYY-MM-DD"),
            Loading: loadingDetails?.id,
            Uploaded_Pictures: { 'id': uploadImageResponse?.data?.id ?? "" }
        };
        const hsdCreateResponse = await AppHelper.saveHSDDetails(payLoadData)
        if (hsdCreateResponse?.error) {
            notification.error({
                message: "Error",
                description: `${hsdCreateResponse?.error.message}`,
                duration: 3,
            });
            return false
        } else {
            notification.success({
                message: "Success",
                description: "HSD Details created successfully.",
                duration: 3,
            });

            navigate("/loadings?sort=updatedAt:desc");
        }
        setIsSubmittingResponse(false);
    };

    const loadVehicleDetails = (e) => {
        const vehicleDetailsById = vehicleLists?.data?.find((obj) => obj?.Vehicle_Number === e);
        hsdForm.setFieldsValue({
            Owner: vehicleDetailsById?.Owner,
        });
    };

    const handleChange = (e) => {
        setIsSubmittingResponse(false);
        const newValue = e.target.value;
        // calculate rate on basis of petrol pump drop down value and set to form input field values
        if (e.target.id === "Petrolpump_Name") {
            const selectedDate = ((hsdForm.getFieldValue("Date_of_Loading")) ? dayjs(hsdForm.getFieldValue("Date_of_Loading"), "DD-MM-YYYY").format("YYYY-MM-DD") : null);
            if (!selectedDate || selectedDate === "") {
                notification.error({
                    message: "Error",
                    description: "Please select date first to fetch the petrol pump rate",
                    duration: 3,
                });
                hsdForm.setFieldsValue({
                    Petrol_Pumps: null
                })
                return false
            }
            const hsdDetailsById = petrolPumpsValues.find((obj) => obj.attributes?.Petrolpump_Name === newValue);
            const petrolPumpRates = hsdDetailsById?.attributes?.Rate;
            const sortPetrolPumpRates = AppHelper.makePetrolPumpRatesDescendingOrder(petrolPumpRates)
            // find rate by petrol pump and date
            const petrolPumpRate = AppHelper.findPetrolPumpRatebyDateAndPetrolPumpValue(selectedDate, sortPetrolPumpRates)
            hsdForm.setFieldsValue({
                Rate_INR: petrolPumpRate,
                Petrolpump_Name_Id: hsdDetailsById?.id
            });
            setFormInputFields({ ...formInputFields, [e.target.id]: newValue });
            setFormInputFields({ ...formInputFields, ['Rate_INR']: petrolPumpRate });

            const hsdLtr = hsdForm.getFieldValue('HSD_LTR') ?? 0;
            displayHSDAmt(hsdLtr, petrolPumpRate)
        } else {
            const newValue = e.target.value;
            const numericValue = parseFloat(newValue);

            if (!isNaN(numericValue)) {
                setFormInputFields({ ...formInputFields, [e.target.id]: numericValue });
            } else {
                setFormInputFields({ ...formInputFields, [e.target.id]: "" });
            }

            // Update HSD_Amt based on valid values
            const hsdLtr = e.target.id === "HSD_LTR" ? numericValue : formInputFields.HSD_LTR;
            const rateInr = formInputFields.Rate_INR;
            displayHSDAmt(hsdLtr, rateInr)
        }
    }

    const displayHSDAmt = (hsdLtr, rateInr) => {
        const hsdAmt = isNaN(hsdLtr) || isNaN(rateInr) ? 0 : ((parseFloat(hsdLtr) * parseFloat(rateInr)).toFixed(2)).toString();
        hsdForm.setFieldsValue({
            HSD_Amt: hsdAmt
        })
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    if (uploadefFileType === 'application/pdf') {
                        // Do not set viewPdf here
                    }
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, png and pdf.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }


    const handleChangePicture = async (values) => {
        const fileStatus = values?.file?.status
        if (fileStatus !== 'removed') {
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
        }
        setFileList(values?.fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleCancel = () => setPreviewOpen(false);


    useEffect(() => {
        document.title = "Create HSD Details"
        if (agencyId) {
            AppHelper.getLoadingDetails(agencyId).then((response) => {
                setLoadingDetails(response);
                setSekeletonLoading(false);
                setFormDefaultValues(response);
            });
        }
        // Fetch vehicle and agency data
        AppHelper.getVehiclesWithoutPagination().then((response) => {
            setVehicleLists(response);
            setVehicleDropDown(AppHelper.getVehicleNumberDropDownWithoutPagination(response?.data));
        });
        AppHelper.getAgenciesByProject(globalTpProjectValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setTransportationAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', globalTpProjectValues));
        });

        AppHelper.getPetrolPumps().then((response) => {
            setPetrolPumpsValues(response);
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpsDropDown(response));
        });
        // Fixed dependency array here
    }, []);

    return (
        <>
            <Skeleton active loading={skeleteonLoading}>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["hsd-details-grid-padding"]}
                >
                    <Grid item lg={10} md={9} xs={12}>
                        <Typography
                            variant="h1"
                            fontSize="1.5rem"
                            fontWeight="bold"
                            className="title-un-loading-text"
                        >
                            Create HSD Details (Challan No: {loadingDetails?.attributes?.Cancelled === true ? <span className={classes['cancelled-loading-challan-no']}>{loadingDetails?.attributes?.Challan_Number}</span> : loadingDetails?.attributes?.Challan_Number})
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                        <UIButton size="small" variant="contained" onClick={backToLoadingsList} className={classes["add-new-entry"]}>
                            <ArrowLeftOutlined />
                            &nbsp;Loadings
                        </UIButton>
                    </Grid>
                </Grid>
                <Spin spinning={spinLoading}>
                    <div className={classes['agency-details-margin']}>
                        <Form layout='vertical' form={hsdForm} onFinish={submitForm}>
                            <Form.Item noStyle name="Petrolpump_Name_Id">
                                <Input type='hidden' />
                            </Form.Item>
                            <Form.Item noStyle name="Vehicle_Number_Id">
                                <Input type='hidden' />
                            </Form.Item>
                            <Form.Item noStyle name="Transporter_Name_Id">
                                <Input type='hidden' />
                            </Form.Item>
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                                alignContent="center"
                                wrap="wrap"
                            >
                                <Grid item lg={6} md={6} sm={12}>
                                    <Form.Item
                                        name="Challan_Number"
                                        label="Challan Number"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter challan number'
                                            }
                                        ]}
                                    >
                                        <Input readOnly disabled onChange={handleChange} className={classes['disabled-input']} />
                                    </Form.Item>

                                    <Form.Item
                                        name="Vehicle_Number"
                                        label="Vehicle No."
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Select
                                            showSearch
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            style={{ width: "100%" }}
                                            onChange={loadVehicleDetails}
                                            options={vehicleDropDown}
                                            placeholder="Select"
                                            disabled
                                            className={classes['disabled-input']}
                                        />
                                    </Form.Item>
                                    <Form.Item messageVariables="" label="Transporting Agency" name="Transporter_Name" wrapperCol={{ span: 24 }} className={classes['ant-form-item']}>
                                        <Select
                                            showSearch
                                            disabled
                                            className={classes['disabled-input']}
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            style={{ width: "100%" }}
                                            options={transportationAgencyDropDown}
                                            placeholder="Select"
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12}>
                                    <Form.Item
                                        name="Date_of_Loading"
                                        label="Date of Loading"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input
                                            readOnly
                                            onChange={handleChange}
                                            disabled
                                            className={classes['disabled-input']} />
                                    </Form.Item>
                                    <Form.Item
                                        name="Owner"
                                        label="Owner"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input
                                            onChange={handleChange}
                                            disabled
                                            className={classes['disabled-input']} />
                                    </Form.Item>
                                    <Form.Item
                                        name="Petrolpump_Name"
                                        label="Petrol Pump Name"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please select petrol pump"
                                            }
                                        ]}
                                    >
                                        <Select
                                            showSearch
                                            filterOption={filterOption}
                                            options={petrolPumpsDropDownValues}
                                            placeholder="Please Select"
                                            id='Petrolpump_Name'
                                            onChange={(e => handleChange({ target: { id: 'Petrolpump_Name', value: e } }))}
                                        />
                                    </Form.Item>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12}>
                                    <Form.Item
                                        name="D_O_NO"
                                        label="D/O No."
                                        onKeyDown={AppHelper.preventMinus}
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input
                                            readOnly
                                            onChange={handleChange}
                                            disabled
                                            className={classes['disabled-input']} />
                                    </Form.Item>
                                    <Form.Item
                                        name="HSD_LTR"
                                        label="HSD LTR"
                                        onKeyDown={AppHelper.preventMinus}
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter the HSD Litre."
                                            }
                                        ]}
                                    >
                                        <Input
                                            type="number"
                                            onChange={handleChange}
                                            step="any"
                                            onKeyDown={AppHelper.preventMinus}
                                            min={0} />
                                    </Form.Item>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12}>

                                    <Form.Item
                                        name="Rate_INR"
                                        label="Rate"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input id='Rate_INR' readOnly onChange={handleChange} disabled className={classes['disabled-input']} />
                                    </Form.Item>
                                    <Form.Item
                                        name="HSD_Amt"
                                        label="HSD Amount"

                                        wrapperCol={{ span: 24 }}
                                        min={0}
                                        step="any"
                                        className={classes['ant-form-item']}
                                    >
                                        <Input readOnly disabled className={classes['disabled-input']} />
                                    </Form.Item>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12}>
                                    <Form.Item
                                        name="Remarks"
                                        label="Remarks"
                                        wrapperCol={{ span: 24 }}
                                        className={classes['ant-form-item']}
                                    >
                                        <Input onChange={handleChange} style={{width: "50%"}} />
                                    </Form.Item>
                                </Grid>

                                <Grid item lg={12} md={12} sm={12}>
                                    <Upload
                                        customRequest={customRequest}
                                        multiple
                                        listType="picture-card"
                                        fileList={fileList}
                                        onPreview={handlePreview}
                                        onChange={handleChangePicture}
                                        name="Unloading_Images"
                                        withCredentials={true}
                                        beforeUpload={beforeUpload}
                                        accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                                    >
                                        {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                                    </Upload>
                                    <Modal
                                        open={previewOpen}
                                        title={previewTitle}
                                        footer={null}
                                        onCancel={handleCancel}
                                        width={800}
                                        styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                                    >
                                        <div
                                            className="rpv-core__viewer"
                                            style={{
                                                border: '1px solid rgba(0, 0, 0, 0.3)',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                height: '100%',
                                            }}
                                        >
                                            {viewPdf && (
                                                <>
                                                    <div
                                                        style={{
                                                            alignItems: 'center',
                                                            backgroundColor: '#eeeeee',
                                                            borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                            display: 'flex',
                                                            padding: '0.25rem',
                                                        }}
                                                    >
                                                        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                                    </div>
                                                    <>
                                                        <div
                                                            style={{
                                                                flex: 1,
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                                <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                            </Worker>
                                                        </div>
                                                    </>
                                                </>
                                            )}
                                            {previewImage && ( // Render image if not viewing PDF
                                                <img
                                                    alt="example"
                                                    style={{
                                                        width: "100%",
                                                    }}
                                                    src={previewImage}
                                                />
                                            )}
                                        </div>
                                    </Modal>
                                </Grid>
                            </Grid>
                            <div className={classes['submit-button-area']}>
                                <UIButton variant="contained" color="primary" type='submit' className={classes['submit-button']} disabled={isSubmittingResponse}>
                                    Save
                                </UIButton>
                                <UIButton variant="outlined" color="error" onClick={backToLoadingsList}>
                                    Cancel
                                </UIButton>
                            </div>
                        </Form>
                    </div>
                </Spin>
            </Skeleton>
        </>
    )
}

export default CreateHsdDetails;