import React, { useEffect, useState } from "react";
import { DatePicker, Drawer, Form, Input, Select, Table, notification, Tooltip, Spin, Pagination } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Grid, Button } from "@mui/material";
import loadingImg from "../../../../assets/images/MenuIcon/loading-stroke.svg";
import classes from "./PendingloadingBills.module.scss";
import { useNavigate } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import { BreadCrumbLoading } from "../BreadCrumbLoading/BreadCrumbLoading";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

const pendingBillsTablecolumns = [
    {
        title: "S.No.",
        dataIndex: "sl_no",
        width: '5%'
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: '10%'
    },
    {
        title: "Date of Unloading",
        dataIndex: "Date_of_Unloading",
        width: '10%'
    },
    {
        title: "Challan No",
        dataIndex: "Challan_Number",
        width: '9%'
    },
    {
        title: "Vehicle No.",
        dataIndex: "Vehicle_Number",
        width: '9%'
    },
    {
        title: "Kanta Slip No",
        dataIndex: "Kanta_Slip_No",
        width: '10%'
    },
    {
        title: "Net Wt. UL (MT)",
        dataIndex: "Net_Weight",
        width: '10%'
    },
    {
        title: "Work Rate",
        dataIndex: "Work_Rate",
        width: '8%'
    },
    {
        title: "Work Amt.",
        dataIndex: "Work_Amount",
        width: '8%'
    },
    {
        title: "D/O No",
        dataIndex: "DO_No",
        width: '6%'
    },
    {
        title: "HSD (LTR)",
        dataIndex: "HSD_LTR",
        width: '8%'
    },
    {
        title: "HSD Rate",
        dataIndex: "Rate_INR",
        width: '8%'
    },
    {
        title: "HSD Amount",
        dataIndex: "HSD_Amt",
        width: '10%'
    },
];

export const PendingLoadingBills = () => {
    const navigate = useNavigate();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [pendingForm] = Form.useForm()
    const [loadingAgencyDropDown, setLoadingAgencyDropDown] = useState([])
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [totalUnloadingWeight, setTotalUnloadingWeight] = useState(0)
    const [workEffectiveRate, setWorkEffectiveRate] = useState(0)
    const [workAmount, setWorkAmount] = useState(0)
    const [updatedHSD, setUpdatedHSD] = useState(0)
    const [HSDAmount, setHSDAmount] = useState(0)
    const [dieselAmountRatio, setDieselAmountRatio] = useState(0)
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const [billNo, setBillNo] = useState("NA")
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT);
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT];
    const [pendingBillsTabledata, setPendingBillsTabledata] = useState([])
    const activeButton = 'pending_bills'
    const [agencies, setAgencies] = useState([]);
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [filterForm] = Form.useForm()
    const [filterFormValues, setFilterFormValues] = useState({})
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const [showPagination, setShowPagination] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const loadingPendingBillsSelectedAgency = AppHelper.decryptText(sessionStorage.getItem('loading_pending_bills_selected_agency'))
    const loadingPendingBillsActiveSortOrder = AppHelper.decryptText(sessionStorage.getItem('loading_pending_bills_active_sort_order'))
    const loadingPendingBillsScreenFilterValues = AppHelper.decryptText(sessionStorage.getItem('loading_pending_bills_filter_values'))
    const loadingPendingBillsGeneratedIDs = AppHelper.decryptText(sessionStorage.getItem('loading_pending_bills_to_generate_ids'))
    const [rangePickerLoadingStartDateDefaultValue, setRangePickerLoadingStartDateDefaultValue] = useState("")
    const [rangePickerLoadingEndDateDefaultValue, setRangePickerLoadingEndDateDefaultValue] = useState("")
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        getPendingBillsLoadingAgency(agencies, loadingPendingBillsActiveSortOrder, loadingPendingBillsSelectedAgency, true, currentPage, pageSize, loadingPendingBillsScreenFilterValues)
    };

    const changeSortOrder = (e) => {
        sessionStorage.setItem("loading_pending_bills_active_sort_order", AppHelper.encryptText(e.target.value))
        setCurrentPagePagination(1)
        getPendingBillsLoadingAgency(agencies, e.target.value, loadingPendingBillsSelectedAgency, true, 1, pageSize, loadingPendingBillsScreenFilterValues)
    }

    const redirectToGenerateBill = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({
                message: "Please select loading records to generate bill",
                duration: 3
            })
            return false
        } else {
            sessionStorage.setItem('bill_no', AppHelper.encryptText(billNo))
            navigate('/bills/loading/generate-bill')
        }
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        sessionStorage.setItem('loading_pending_bills_to_generate_ids', AppHelper.encryptText(newSelectedRowKeys))
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
            setRangePickerLoadingStartDateDefaultValue("")
            setRangePickerLoadingEndDateDefaultValue("")
        }
    }

    const exportLoadingPendingBills = async () => {

        setIsExportButtonDisabled(true)

        const agencyByName = agencies.find((item) => item?.attributes?.Company_name === loadingPendingBillsSelectedAgency && item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal)
        const exportResponse = await AppHelper.exportPendingLoadingBills(agencyByName?.id, true, 1, exportPageSize, loadingPendingBillsScreenFilterValues, loadingPendingBillsActiveSortOrder, loadingPendingBillsGeneratedIDs)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Pending Bills", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Date of Unloading', key: "Date_of_Unloading", width: 20 },
                { header: 'Challan No.', key: "Challan_Number", width: 15 },
                { header: 'Vehicle No.', key: "Vehicle_Number", width: 15 },
                { header: 'Kanta Slip No', key: "Kanta_Slip_No", width: 15 },
                { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 15 },
                { header: 'Work Rate', key: "Work_Rate", width: 15 },
                { header: 'Work Amt.', key: "Work_Amount", width: 15 },
                { header: 'D/O No', key: "DO_No", width: 15 },
                { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                { header: 'HSD Rate', key: "Rate_INR", width: 15 },
                { header: 'HSD Amt', key: "HSD_Amt", width: 15 },
            ]
            
            exportResponse?.data?.forEach(billData => { 
                billData.attributes.Date_of_Loading = (billData?.attributes?.Date_of_Loading && billData?.attributes?.Date_of_Loading !== "" && billData?.attributes?.Date_of_Loading !== '-') ? dayjs(billData?.attributes?.Date_of_Loading).format(dateFormat) : '-'
                billData.attributes.Date_of_Unloading = (billData?.attributes?.Date_of_Unloading && billData?.attributes?.Date_of_Unloading !== "" && billData?.attributes?.Date_of_Unloading !== '-') ? dayjs(billData?.attributes?.Date_of_Unloading).format(dateFormat) : '-'
                billData.attributes.Challan_Number = billData?.attributes?.Challan_Number && !isNaN(parseInt(billData?.attributes?.Challan_Number)) ? parseInt(billData?.attributes?.Challan_Number) : "-";
                billData.attributes.Kanta_Slip_No = billData?.attributes?.Kanta_Slip_No && !isNaN(parseInt(billData?.attributes?.Kanta_Slip_No)) ? parseInt(billData?.attributes?.Kanta_Slip_No) : "-";
                workSheetPendingBills.addRow(billData?.attributes)
            })

            // preapare sheet for global counts

            const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Count Items")
            // prepare sheet columns / headers
            workSheetPendingBillGlobalCounts.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: exportResponse?.meta?.thermalPlantName, key: "second_value", width: 20 },
            ]

            // prepare sheet rows to display
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Project", second_value: exportResponse?.meta?.projectName ?? '-' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Agency", second_value: exportResponse?.meta?.agencyName ?? '-' }
            )

            workSheetPendingBillGlobalCounts.addRow({
                first_value: "Bill No.", second_value: exportResponse?.meta?.billNumber ?? '-'
            })

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Trip Count", second_value: exportResponse?.meta?.tripCount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total Unloading Wt (MT)", second_value: exportResponse?.meta?.unloadingWeight ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Effective Rate (Rs.)", second_value: exportResponse?.meta?.workEffectiveRate ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Amount (Rs.)", second_value: exportResponse?.meta?.workAmount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Updated HSD (LTR)", second_value: exportResponse?.meta?.updatedHsdLitre ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Amount (Rs.)", second_value: exportResponse?.meta?.hsdAmount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Diesel Amount Ratio", second_value: exportResponse?.meta?.dieselAmountRatio ?? 0 }
            )

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `loading_pending_bills_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Loading pending bills data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        fixed: "left",
        preserveSelectedRowKeys: true
    };

    const handleSearch = (e) => {
        console.log("search", e.target.value);
    };

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false)

    const handleChangeAgency = async (agency) => {
        if ((agency && agency !== "") || (loadingPendingBillsSelectedAgency && loadingPendingBillsSelectedAgency !== "" && loadingPendingBillsSelectedAgency !== agency)) {
            // remove any stored values in local storage related to loading agency bill because agency is going to change
            sessionStorage.removeItem('generateLoadingBillObject')
            sessionStorage.removeItem('adjustedWeightRateValuesGenerateLoading')
            sessionStorage.removeItem('adjustedHSDRateValuesGenerateLoading')
            sessionStorage.removeItem('loading_pending_bills_to_generate_ids')
            setSelectedRowKeys([])
        }
        setCurrentPagePagination(1)
        // put selected agency in session storage to keep record intact
        sessionStorage.setItem('loading_pending_bills_selected_agency', agency ? AppHelper.encryptText(agency) : '')

        getPendingBillsLoadingAgency(agencies, loadingPendingBillsActiveSortOrder, agency, true, 1, pageSize, filterFormValues)
        sessionStorage.removeItem('bill_no')
    }

    const filterResults = (formValues) => {
        formValues.Date_of_Loading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        sessionStorage.setItem('loading_pending_bills_filter_values', AppHelper.encryptText(formValues))
        getPendingBillsLoadingAgency(agencies, loadingPendingBillsActiveSortOrder, loadingPendingBillsSelectedAgency, true, 1, pageSize, formValues)
        setFilterFormValues(formValues)
        setCurrentPagePagination(1)
        setOpen(false);
        setSelectedRowKeys([])
        sessionStorage.setItem('loading_pending_bills_to_generate_ids', '')
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setRangePickerLoadingStartDateDefaultValue("")
        setRangePickerLoadingEndDateDefaultValue("")
        setSelectedRowKeys([])
        sessionStorage.setItem('loading_pending_bills_to_generate_ids', '')
    }

    const loadAgencyPendingBillsData = (responseData, currentPage) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.attributes?.loadingId,
                sl_no: ((parseInt(currentPage) === 1) ? i + 1 : (((parseInt(i) + 1) + ((parseInt(currentPage) - 1)) * parseInt(pageSize)))),
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading) ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.attributes?.Date_of_Unloading && responseData[i]?.attributes?.Date_of_Unloading !== "-") ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number ?? '-',
                Kanta_Slip_No: responseData[i]?.attributes?.Kanta_Slip_No ?? '-',
                Net_Weight: responseData[i]?.attributes?.Net_Weight ?? 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: responseData[i]?.attributes?.Work_Amount ?? 0,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
            });
        }
        return pendingBillsTabledata
    }

    const getPendingBillsLoadingAgency = (agencies, sortOrder, agencyId = null, pagination, currentPage, pageSize, formValues) => {
        setShowTableSpinner(true)
        const agencyByName = AppHelper.findAgencyIdByNameProject(agencies, agencyId, sessionGlobalValues?.projectGlobal)
        AppHelper.getPendingBillsLoadingAgency(sortOrder, agencyByName?.id, pagination, currentPage, pageSize, formValues).then(response => {
            setGlobalCountItems(response, currentPage)
            setTotalRecords(response?.meta?.pagination?.total ?? 0)
            setShowTableSpinner(false)
            setShowPagination(true)
            if (agencyId) {
                pendingForm.setFieldsValue({
                    bill_no: response?.meta?.billNumber
                })
                setBillNo(response?.meta?.billNumber)
            }
        }).catch(err => {
            setShowPagination(false)
            console.log(`Error while getting pending bills results`, err);
            notification.error({
                message: "Error while getting pending bills results",
                duration: 3
            })
        })
    }

    const setGlobalCountItems = (response, currentPage) => {
        setTotalTripCount(response?.meta?.tripCount ?? 0)
        setTotalUnloadingWeight(response?.meta?.unloadingWeight ?? 0)
        setWorkEffectiveRate(response?.meta?.workEffectiveRate ?? 0)
        setWorkAmount(response?.meta?.workAmount ?? 0)
        setUpdatedHSD(response?.meta?.updatedHsdLitre ?? 0)
        setHSDAmount(response?.meta?.hsdAmount ?? 0)
        setDieselAmountRatio(response?.meta?.dieselAmountRatio ?? 0)
        setTotalPayableAmount(response?.meta?.totalPayableAmount ?? 0)

        // set default unloading date filter form range values
        if (loadingPendingBillsScreenFilterValues && loadingPendingBillsScreenFilterValues?.Date_of_Loading !== "") {
            const breakDateOfLoadingFilterStorage = loadingPendingBillsScreenFilterValues?.Date_of_Loading.split("~")
            if (breakDateOfLoadingFilterStorage[0] && breakDateOfLoadingFilterStorage[0] !== "") {
                const startDateRangeUnLoadingDateDefault = breakDateOfLoadingFilterStorage[0]
                const endDateRangeUnLoadingDateDefault = breakDateOfLoadingFilterStorage[1]
                setRangePickerLoadingStartDateDefaultValue(startDateRangeUnLoadingDateDefault ? dayjs(dayjs(startDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
                setRangePickerLoadingEndDateDefaultValue(endDateRangeUnLoadingDateDefault ? dayjs(dayjs(endDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
            } else {
                setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
                setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
            }
        } else {
            setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
            setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
        }
        const getTableData = loadAgencyPendingBillsData(response?.data, currentPage)
        setPendingBillsTabledata(getTableData)
    }

    useEffect(() => {
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setLoadingAgencyDropDown(AppHelper.getAgencyDropDown(response, 'Loading', sessionGlobalValues));
            // get all the details of all agencies within project
            getPendingBillsLoadingAgency(response, loadingPendingBillsActiveSortOrder, loadingPendingBillsSelectedAgency, true, currentPage, pageSize, loadingPendingBillsScreenFilterValues)
        }).catch(err => {
            console.log(`Error while getting loading agencies`, err);
            notification.error({
                message: "Error while getting loading agencies",
                duration: 3
            })
        });
        pendingForm.setFieldsValue({
            bill_no: billNo
        })

        if (loadingPendingBillsScreenFilterValues && loadingPendingBillsScreenFilterValues !== "") {
            filterForm.setFieldsValue({
                Challan_No: loadingPendingBillsScreenFilterValues?.Challan_No ?? "",
                D_O_No: loadingPendingBillsScreenFilterValues?.D_O_No ?? "",
                Kanta_Slip_No: loadingPendingBillsScreenFilterValues?.Kanta_Slip_No ?? ""
            })
        }

        if (loadingPendingBillsGeneratedIDs && loadingPendingBillsGeneratedIDs !== "") {
            setSelectedRowKeys(loadingPendingBillsGeneratedIDs)
        }

        document.title = "Loading Pending Bills"
    }, [])

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["loading-pending-padding"]}
            >
                <Grid item lg={8} md={7} sm={12} xs={12}>
                    <BreadCrumbLoading activeButton={activeButton} page="loading" />
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    lg={4}
                    md={5}
                    sm={12}
                    xs={12}
                    className={classes["gridmargintop20"]}
                >
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Form>
                            <Form.Item
                                label="Sort By"
                                className={classes["select-new-entry"]}
                            >
                                <Select defaultValue={(loadingPendingBillsActiveSortOrder && loadingPendingBillsActiveSortOrder !== "") ? loadingPendingBillsActiveSortOrder : "Date_of_Loading:asc"} className={classes["select-button-height"]} id="sortOrder" onChange={(e) => changeSortOrder({ target: { id: "sortOrder", value: e } })}>
                                    <Select.Option value="Challan_Number:asc">Challan No. (asc)</Select.Option>
                                    <Select.Option value="Challan_Number:desc">Challan No. (desc)</Select.Option>
                                    <Select.Option value="Date_of_Loading:asc">Date Of Loading (asc)</Select.Option>
                                    <Select.Option value="Date_of_Loading:desc">Date Of Loading (desc)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} className={classes['right-align']}>
                        <Grid item lg={12} md={12} xs={12}>
                            <Button
                                variant="outlined"
                                className={classes["filter-new-entry"]}
                                startIcon={<FilterOutlined />}
                                onClick={showDrawer}
                            >
                                Filters
                            </Button>
                            <Drawer title="Filters" onClose={onClose} open={open}>
                                <Form layout="vertical" form={filterForm} onFinish={filterResults}>
                                    <Form.Item label="Challan No" name="Challan_No">
                                        <Input
                                            placeholder="Enter Challan No"
                                            onChange={handleSearch}
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Date of Loading" name="Date">
                                        <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} defaultValue={[rangePickerLoadingStartDateDefaultValue, rangePickerLoadingEndDateDefaultValue]} />
                                    </Form.Item>
                                    <Form.Item label="D/O No." name="D_O_No">
                                        <Input
                                            placeholder="Enter D/O No."
                                            onChange={handleSearch}
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <Form.Item label="Kanta Slip No. UL" name="Kanta_Slip_No">
                                        <Input
                                            placeholder="Enter Kanta Slip No"
                                            onChange={handleSearch}
                                            addonBefore={<SearchOutlined />}
                                        />
                                    </Form.Item>
                                    <div className={classes["search-button-area"]}>
                                        <Button size="small" variant="outlined" color="error" onClick={resetFilterForm}>
                                            Reset
                                        </Button>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            color="primary"
                                            type="submit"
                                            className={classes["submit-button"]}
                                        >
                                            Apply
                                        </Button>
                                    </div>
                                </Form>
                            </Drawer>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Form layout="vertical" form={pendingForm}>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["loading-pending-padding-loading-agency-dropdown"]}
                >
                    <Grid item lg={10} md={8} xs={12}>
                        <Grid container>
                            <Grid item lg={0.5} md={1} xs={12}>
                                <img src={loadingImg} alt="Loading Agency" width={36} />
                            </Grid>
                            <Grid item lg={2} md={5.5} xs={12}>
                                <h2 className={classes['loading_agency_title_heading']}>Loading Agency</h2>
                            </Grid>
                            <Grid item lg={5} md={5.5} xs={12}>
                                <Form.Item name="loading_agency">
                                    <Select options={loadingAgencyDropDown} showSearch allowClear onChange={(e) => handleChangeAgency(e)} placeholder="All" className={classes['loading_agency_options']} defaultValue={loadingPendingBillsSelectedAgency && loadingPendingBillsSelectedAgency !== "" ? loadingPendingBillsSelectedAgency : null} />
                                </Form.Item>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={2} md={4} xs={12}>
                        <Grid container>
                            <Grid item lg={9} md={9} xs={12}>
                                <h3 className={classes['bill_number_heading']}>Bill Number</h3>
                            </Grid>
                            <Grid item lg={3} md={3} xs={12}>
                                <Form.Item name="bill_no" label={false}>
                                    <Input readOnly className={classes['bill_no_input']} />
                                </Form.Item>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={`${classes["loading-pending-padding-agency-title"]}`}
                >
                    <Grid container className={classes['loading_agency_title_row']}>
                        <Grid item lg={8} md={4} sm={4} xs={12}>
                            <div className={classes['loading_agency_title']}></div>
                        </Grid>
                        <Grid item lg={4} md={8} sm={8} xs={12}>
                            <div className={classes['loading_agency_title_payable_amount']}>Total Payable Amount = <strong>₹ {numberWithCommas(totalPayableAmount)}</strong></div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={`${classes["loading-pending-padding"]}`}
                >
                    <Grid item lg={1.75} md={3} sm={6} xs={12}>
                        <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_trip_count_block']}`}>
                            <p className={classes['loading_agency_bill_count_main']}>{totalTripCount}</p>
                            <p className={classes['loading_agency_bill_count_label']}>Trip Count</p>
                        </div>
                    </Grid>
                    <Grid item lg={1.75} md={3} sm={6} xs={12}>
                        <Tooltip title={totalUnloadingWeight + " MT"}>
                            <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_total_unloading_count_block']}`}>
                                <p className={classes['loading_agency_bill_count_main']}>{totalUnloadingWeight}</p>
                                <p className={classes['loading_agency_bill_count_label']}>Total Unloading Wt (MT)</p>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={1.75} md={3} sm={6} xs={12}>
                        <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_work_effective_block']}`}>
                            <p className={classes['loading_agency_bill_count_main']}>{workEffectiveRate}</p>
                            <p className={classes['loading_agency_bill_count_label']}>Work Effective Rate (Rs.)</p>
                        </div>
                    </Grid>
                    <Grid item lg={1.75} md={3} sm={6} xs={12}>
                        <Tooltip title={"₹ " + workAmount}>
                            <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_work_amount_block']}`}>
                                <p className={classes['loading_agency_bill_count_main']}>{AppHelper.formatNumber(workAmount)}</p>
                                <p className={classes['loading_agency_bill_count_label']}>Work Amount <br />(Rs.)</p>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={1.75} md={3} sm={6} xs={12}>
                        <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_updated_hsd_block']}`}>
                            <p className={classes['loading_agency_bill_count_main']}>{updatedHSD}</p>
                            <p className={classes['loading_agency_bill_count_label']}>Updated HSD <br />(LTR)</p>
                        </div>
                    </Grid>
                    <Grid item lg={1.75} md={3} sm={6} xs={12}>
                        <Tooltip title={"₹ " + HSDAmount}>
                            <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_hsd_amount_block']}`}>
                                <p className={classes['loading_agency_bill_count_main']}>{AppHelper.formatNumber(HSDAmount)}</p>
                                <p className={classes['loading_agency_bill_count_label']}>HSD Amount <br />(Rs.)</p>
                            </div>
                        </Tooltip>
                    </Grid>
                    <Grid item lg={1.50} md={3} sm={6} xs={12}>
                        <div className={`${classes['loading_agency_bill_count_items']} ${classes['loading_agency_bill_diesel_ratio_block']}  ${classes['loading_agency_bill_trip_count_last_item']}`}>
                            <p className={classes['loading_agency_bill_count_main']}>{dieselAmountRatio}</p>
                            <p className={classes['loading_agency_bill_count_label']}>Diesel Amount <br />Ratio</p>
                        </div>
                    </Grid>
                </Grid>
                <>
                    {
                        showPagination === true && totalRecords > 0 ? (
                            <div className={classes["pagination-box"]}>
                                <Pagination
                                    current={currentPagePagination}
                                    showSizeChanger={false}
                                    defaultCurrent={currentPage}
                                    defaultPageSize={currentPageSizePagination}
                                    showTotal={(total) => `Total ${totalRecords} items`}
                                    responsive
                                    pageSize={currentPageSizePagination}
                                    onChange={changePageNumber}
                                    total={totalRecords}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }
                </>
                <Table
                    className={classes["pending-bill-table"]}
                    rowSelection={loadingPendingBillsSelectedAgency && loadingPendingBillsSelectedAgency !== "" ? rowSelection : false}
                    columns={pendingBillsTablecolumns}
                    dataSource={pendingBillsTabledata}
                    rowKey={(record) => record?.Vehicle_Number === 'EXCAVATOR' ? `${record?.key}~EXCAVATOR` : `${record?.key}~Loading`}
                    loading={{ indicator: <div><Spin /></div>, spinning: showTableSpinner }}
                    bordered={true}
                    tableLayout="fixed"
                    scroll={{ x: 1600 }}
                    pagination={false}
                />
                <>
                    {
                        showPagination === true && totalRecords > 0 ? (
                            <div className={classes["pagination-box"]}>
                                <Pagination
                                    current={currentPagePagination}
                                    showSizeChanger={false}
                                    defaultCurrent={currentPage}
                                    defaultPageSize={currentPageSizePagination}
                                    showTotal={(total) => `Total ${totalRecords} items`}
                                    responsive
                                    pageSize={currentPageSizePagination}
                                    onChange={changePageNumber}
                                    total={totalRecords}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }
                </>
                {
                    loadingPendingBillsSelectedAgency ?
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                            className={`${classes["loading-pending-padding"]}`}
                        >
                            <Grid container>
                                <Grid item lg={6} md={6} sm={6} xs={12} className={classes["right-align"]}>
                                    <Button
                                        variant="outlined"
                                        className={`${classes["filter-new-entry"]} ${classes["export-button"]} ${isExportButtonDisabled === true ? classes['button-disabled'] : ''}`}
                                        onClick={exportLoadingPendingBills}
                                        disabled={isExportButtonDisabled}
                                    >
                                        Export
                                    </Button>
                                </Grid>
                                <Grid item lg={5} md={6} sm={6} xs={12}>
                                    <Button
                                        size="small"
                                        variant="contained"
                                        className={classes["add-new-entry"]}
                                        onClick={redirectToGenerateBill}
                                    >
                                        Generate Bill
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        :
                        ""
                }
            </Form>
        </>
    );
};
