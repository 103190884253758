import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Typography, Grid, Button, styled } from "@mui/material";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classes from "./ViewDataPage.module.scss";
import { Form, Input, Modal, Upload } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
const { TextArea } = Input;

const CustomUpload = styled(Upload)(() => ({
    "& .ant-upload-list-item button": {
        display: "none !important"
    }
}));

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const ViewDataPage = () => {
    const navigate = useNavigate();
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const params = useParams();
    const loadId = params?.id ?? "";
    const [loadDetails, setLoadDetails] = useState("");
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const [viewPdf, setViewPdf] = useState(null);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const getLoadingDetails = async () => {
        const response = await AppHelper.getLoadingById(loadId);
        let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";

        if (uploadedPictures) {
            uploadedPictures = JSON.parse(uploadedPictures)
        }
        const newFileList = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
            uid: image?.id,
            name: image?.name,
            status: "done",
            type: image?.mime,
            url: image?.url,
        })) : [];
        setLoadDetails(response.data);
        setFileList(newFileList);
    };

    const backToLoadingsList = (e) => {
        e.preventDefault();
        navigate(`/loadings?currentPage=${pageNo}&pageSize=${pageSize}`);
    };


    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    useEffect(() => {
        document.title = "View Loading"
        getLoadingDetails();
    }, []);
    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["view-loading-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        View Loading Details (Challan No: {loadDetails?.attributes?.Cancelled === true ? <span className={classes['cancelled-loading-challan-no']}>{loadDetails?.attributes?.Challan_Number}</span> : loadDetails?.attributes?.Challan_Number})
                    </Typography>
                </Grid>
                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <Button variant="contained" size="small" onClick={backToLoadingsList} className={classes['back-button']}>
                        <ArrowLeftOutlined />
                        &nbsp;Loadings
                    </Button>
                </Grid>
            </Grid>
            <div className={classes["agency-details-margin"]}>
                <Form
                    layout="vertical"
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Challan No.">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Challan_Number}
                                    name="Challan_Number"
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Vehicle Number">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Vehicle_Number?.data?.attributes?.Vehicle_Number}
                                    name="Vehicle_Number"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Loading Agency">
                                <Input
                                    readOnly
                                    value={
                                        loadDetails?.attributes?.Loading_Agency?.data?.attributes
                                            ?.Company_name
                                    }
                                    name="Loading_Agency"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Transporting Agency">
                                <Input
                                    readOnly
                                    value={
                                        loadDetails?.attributes?.Transporter_Agency?.data?.attributes
                                            ?.Company_name
                                    }
                                    name="Transporter_Agency"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Net Weight at Loading (Kg)">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Net_Weight ?? ""}
                                    name="Net_Weight"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Gross Weight at Loading (Kg)">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Gross_Weight ?? ""}
                                    name="Gross_Weight"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Loading By Employee">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Loading_by_Employee ?? ""}
                                    name="Loading_by_Employee"

                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} sm={12}>
                            <Form.Item messageVariables="" label="Date of Loading">
                                <Input
                                    readOnly
                                    value={formatDate(loadDetails?.attributes?.Date_of_Loading)}
                                    name="Date_of_Loading"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Owner">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Vehicle_Number?.data?.attributes?.Owner}
                                    name="Owner"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Thermal Plant Book No.">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Thermal_Plant_Book_no ?? ""}
                                    name="Thermal_Plant_Book_no"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Kanta Slip No. at Loading">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Kanta_Slip_No ?? ""}
                                    name="Kanta_Slip_No"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Tare Weight at Loading (Kg)">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Tare_Weight ?? ""}
                                    name="Tare_Weight"

                                />
                            </Form.Item>
                            <Form.Item
                                messageVariables=""
                                label="D/O No."
                                wrapperCol={{ span: 30 }}>
                                <Input value={loadDetails?.attributes?.DO_No ?? "-"} readOnly />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Remarks">
                                <Input
                                    readOnly
                                    value={loadDetails?.attributes?.Remarks ?? ""}
                                    name="Remarks"
                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12}>
                            {loadDetails?.attributes?.Cancelled === true && <Form.Item messageVariables="" label="Reason for Cancellation">
                                <TextArea
                                    rows={4}
                                    maxLength={4000}
                                    readOnly
                                    value={loadDetails?.attributes?.Reason_For_Cancellation ?? ""}
                                    name="Reason_For_Cancellation"
                                />
                            </Form.Item>}
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                    >
                        <Grid item lg={4} md={4} xs={12}>
                            Created By: <b>{loadDetails?.attributes?.Created_By?.data?.attributes?.Name ?? "NA"}</b>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12}>
                            Last Edited By: <b>{loadDetails?.attributes?.Updated_By?.data?.attributes?.Name ?? "NA"}</b>
                        </Grid>
                        <Grid item lg={4} md={4} xs={12} container spacing={3}>
                            <Grid item lg={12} md={12}>
                                {
                                    loadDetails?.attributes?.Reviewed_By?.data?.id
                                        ?
                                        <>
                                            Reviewed By:<b> {loadDetails?.attributes?.Reviewed_By?.data?.attributes?.Name ?? "NA"}</b>
                                        </>
                                        :
                                        ""
                                }
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                        className={classes['margintop20']}
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <CustomUpload
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                name="Unloading_Images"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed}
                            </CustomUpload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                width={800}
                                footer={null}
                                style={{ height: '500px' }}
                                onCancel={handleCancel}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                </Form>
            </div>
        </>
    );
};
