import axios from "axios";
import CryptoJS from "crypto-js";
import _ from 'lodash'
import constantsArr from "../constants/global-constants";
import dayjs from "dayjs";

class AppHelper {
    constructor() {
        this.API_URL = process.env.REACT_APP_API_DOMAIN || "";
        this.SECRET_KEY = process.env.ENCRYPTION_SECRET_KEY || "";
        this.Vehicle_API_KEY = process.env.REACT_APP_VEHICLE_API_KEY || ""
        this.Vehicle_API_KEY_SECRET = process.env.REACT_APP_VEHICLE_API_KEY_SECRET || ""
    }

    static login = async (payload) => {
        try {
            let apiURL = new AppHelper().API_URL + "/api/login";
            const loadings = await axios.post(apiURL, payload);
            return loadings?.data;
        } catch (error) {
            return error;
        }
    };

    static getAllloadings = async (sortValue, currentPage = 1, pageSize, isPagination = true, filterArgs = {}, challanNoSearchValue = null) => {
        try {
            console.log(filterArgs, 'filterArgs')
            let apiURL = new AppHelper().API_URL + `/api/loadings?sort=${sortValue}`;
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep`

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            apiURL = apiURL + `&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;

            // filtration starts
            if (challanNoSearchValue && challanNoSearchValue !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${challanNoSearchValue}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Kanta_Slip_No)
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Loading_Agency)
                apiURL = apiURL + `&filters[Loading_Agency][id][$eq]=${filterArgs?.Loading_Agency}`

            if (filterArgs?.Loading_by_Employee)
                apiURL = apiURL + `&filters[Loading_by_Employee][$containsi]=${filterArgs?.Loading_by_Employee}`

            if (filterArgs?.Thermal_Plant_Book_no)
                apiURL = apiURL + `&filters[Thermal_Plant_Book_no][$containsi]=${filterArgs?.Thermal_Plant_Book_no}`

            if (filterArgs?.Transporter_Agency)
                apiURL = apiURL + `&filters[Transporter_Agency][id][$eq]=${filterArgs?.Transporter_Agency}`

            if (filterArgs?.Vehicle_Number)
                apiURL = apiURL + `&filters[Vehicle_Number][id][$eq]=${filterArgs?.Vehicle_Number}`

            if (filterArgs?.Cancelled_Pending_Unload && filterArgs?.Cancelled_Pending_Unload === 1)
                apiURL = apiURL + `&filters[Cancelled][$eq]=${!!filterArgs?.Cancelled_Pending_Unload}`
            else if (filterArgs?.Cancelled_Pending_Unload && filterArgs?.Cancelled_Pending_Unload === 2)
                apiURL = apiURL + `&filters[Unloaded][$eq]=${false}&filters[Cancelled][$eq]=${false}`
            else if (filterArgs?.Cancelled_Pending_Unload && filterArgs?.Cancelled_Pending_Unload === 3)
                apiURL = apiURL + `&filters[Cancelled][$eq]=${false}`


            if (filterArgs?.Cancelled_Pending_hsd_taken && filterArgs?.Cancelled_Pending_hsd_taken === 3)
                apiURL = apiURL + `&filters[HSD_DONE][$eq]=${false}`
            else if (filterArgs?.Cancelled_Pending_hsd_taken && filterArgs?.Cancelled_Pending_hsd_taken === 4)
                apiURL = apiURL + `&filters[HSD_DONE][$eq]=${true}`

            // if (filterArgs?.HSD_DONE && filterArgs?.HSD_DONE === true)
            //     apiURL = apiURL + `&filters[HSD_DONE][$eq]=${false}`

            if (filterArgs?.DO_No)
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.DO_No}`
            const loadings = await axios.get(apiURL);
            return loadings?.data;
        } catch (error) {
            return error;
        }
    };

    static getAllUnloadings = async (sortValue, currentPage = 1, pageSize, isPagination = true, filterArgs = {}) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/unloadings?sort=${sortValue}`;
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep`

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            apiURL = apiURL + `&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;

            // filtration starts
            if (filterArgs?.Challan_Number)
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_Number}`

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            if (filterArgs?.Kanta_Slip_No)
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.is_client_billed)
                apiURL = apiURL + `&filters[is_client_billed]=${filterArgs?.is_client_billed}`

            if (filterArgs?.Unloading_Agency)
                apiURL = apiURL + `&filters[Unloading_Agency][id][$eq]=${filterArgs?.Unloading_Agency}`

            if (filterArgs?.Unloading_by_Employee)
                apiURL = apiURL + `&filters[Unloading_by_Employee][$containsi]=${filterArgs?.Unloading_by_Employee}`

            if (filterArgs?.Thermal_Plant_Book_no)
                apiURL = apiURL + `&filters[Thermal_Plant_Book_no][$containsi]=${filterArgs?.Thermal_Plant_Book_no}`

            if (filterArgs?.Transporter_Agency)
                apiURL = apiURL + `&filters[Transporting_Agency][id][$eq]=${filterArgs?.Transporter_Agency}`

            if (filterArgs?.Vehicle_Number)
                apiURL = apiURL + `&filters[Vehicle_Number][id][$eq]=${filterArgs?.Vehicle_Number}`

            if (filterArgs?.Unloading_Location)
                apiURL = apiURL + `&filters[Unloading_Location][$containsi]=${encodeURIComponent(filterArgs?.Unloading_Location)}`

            if (filterArgs?.netWeight) {
                const breakWeight = filterArgs?.netWeight.split("~")
                const startWeight = breakWeight[0]
                const endWeight = breakWeight[1]
                if (startWeight)
                    apiURL = apiURL + `&filters[Net_Weight][$gte]=${startWeight}`
                if (endWeight)
                    apiURL = apiURL + `&filters[Net_Weight][$lte]=${endWeight}`
            }

            const loadings = await axios.get(apiURL);
            return loadings?.data;
        } catch (error) {
            return error;
        }
    };

    static getAllProjects = async () => {
        try {
            const projects = await axios.get(new AppHelper().API_URL + "/api/projects");
            return projects?.data?.data;
        } catch (error) {
            return error;
        }
    };

    static getAllThermalPlants = async () => {
        try {
            const thermalPlants = await axios.get(new AppHelper().API_URL + "/api/tps");
            return thermalPlants?.data?.data;
        } catch (error) {
            return error;
        }
    };

    static getVehicleNumberDropDown = (vehicles) => {
        let output = [];
        if (vehicles.length > 0) {
            for (let index = 0; index < vehicles.length; index++) {
                output.push({
                    value: vehicles[index]?.attributes?.Vehicle_Number,
                    label: vehicles[index]?.attributes?.Vehicle_Number,
                });
            }
        }
        return output;
    };

    static getLoadingAgencyDropDown = (agencies) => {
        let output = [];
        if (agencies.length > 0) {
            for (let index = 0; index < agencies.length; index++) {
                output.push({
                    value: agencies[index]?.id,
                    label: agencies[index]?.attributes?.Company_name,
                });
            }
        }

        return output;
    };


    static getAgencyDropDown = (agencies, agencyType, sessionGlobalValues) => {

        if (agencyType && agencyType !== '')
            agencies = agencies.filter(item => item?.attributes?.Type_of_agency?.includes(agencyType) === true);

        let output = [];
        let filteredAgencies = [];

        if (sessionGlobalValues && sessionGlobalValues?.projectGlobal && sessionGlobalValues?.projectGlobal !== '')
            filteredAgencies = agencies.filter(item => item?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal);

        output = filteredAgencies.map(item => ({
            value: item?.attributes?.Company_name,
            label: item?.attributes?.Company_name,
        }));

        return output;
    };


    static getProjectsDropDown = (projects) => {
        // make drop down array object
        let output = [];
        if (projects.length > 0) {
            for (let index = 0; index < projects.length; index++) {
                output.push({
                    value: projects[index]?.attributes?.Name_of_project,
                    label: projects[index]?.attributes?.Name_of_project,
                });
            }
        }

        return output;
    };

    static getThermalsDropDown = (thermalPlants) => {
        // make drop down array object
        let output = [];
        if (thermalPlants?.length > 0) {
            for (let index = 0; index < thermalPlants.length; index++) {
                output.push({
                    value: thermalPlants[index]?.attributes?.Name,
                    label: thermalPlants[index]?.attributes?.Name,
                });
            }
        }

        return output;
    };

    /**
     * Iterate through each block rate array object and remove if empty values are there
     */
    static createAgency = async (payload) => {
        try {
            const response = await axios.post(`${new AppHelper().API_URL}/api/agencies`, { data: payload });
            return response.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    /**
     * This method is to update agency
     *
     * @param {Object} payload
     */
    static updateAgency = async (payload, agencyId) => {
        try {
            const response = await axios.put(
                `${new AppHelper().API_URL}/api/agencies/${agencyId}`,
                { data: payload }
            );
            return response.data.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static checkStringStartsWithInArray = (array, value) => {
        return array.some((arr) => value.startsWith(arr));
    };

    static isObject(value) {
        const type = typeof value;
        return value != null && (type === "object" || type === "function");
    }

    static setReccurssiveObjectKeys = (object, path, value) => {
        _.set(object, path, value)
        return object
    }

    static postVehicle = async (vehicleData) => {
        const endpoint = "/api/vehicles";
        try {
            //wrap the vehicleData in a 'data' key
            const payload = { data: vehicleData };
            // Assuming axios.post returns the response
            const response = await axios.post(
                new AppHelper().API_URL + endpoint,
                payload
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static postLoading = async (loadingData) => {
        const endpoint = "/api/loadings";
        try {
            //wrap the loadingData in a 'data' key
            const payload = { data: loadingData };
            // Assuming axios.post returns the response
            const response = await axios.post(new AppHelper().API_URL + endpoint, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static async deleteLoadingById(loadingId) {
        const endpoint = `/api/loadings/${loadingId}`;
        try {
            // Assuming axios.delete returns the response
            const response = await axios.delete(
                new AppHelper().API_URL + endpoint
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static updateLoading = async (loadId, updatedData) => {
        const endpoint = `/api/loadings/${loadId}`;
        try {
            // Assuming axios.put returns the response
            const response = await axios.put(new AppHelper().API_URL + endpoint, updatedData);
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static postExcavator = async (loadingData) => {
        const endpoint = "/api/excavator-details";
        try {
            //wrap the loadingData in a 'data' key
            const payload = { data: loadingData };
            // Assuming axios.post returns the response
            const response = await axios.post(
                new AppHelper().API_URL + endpoint,
                payload
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static postPetrolPump = async (loadingData) => {
        const endpoint = "/api/petrol-pumps";

        try {
            //wrap the loadingData in a 'data' key
            const payload = { data: loadingData };
            // Assuming axios.post returns the response
            const response = await axios.post(
                new AppHelper().API_URL + endpoint,
                payload
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getPetrolPumpById = async (petrolPumpId) => {
        const endpoint = `/api/petrol-pumps/${petrolPumpId}?populate=*`;
        try {
            // Assuming axios.get returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    static getLoadingById = async (loadID) => {
        const endpoint = `/api/loadings/${loadID}?populate=*`;
        try {
            // Assuming axios.get returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    static getVehicleById = async (vehicleId) => {
        const endpoint = `/api/vehicles/${vehicleId}?populate=*`;
        try {
            // Assuming axios.get returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    static updateVehicle = async (vehicleId, updatedData) => {
        const endpoint = `/api/vehicles/${vehicleId}`;
        try {
            // Assuming axios.put returns the response
            const response = await axios.put(new AppHelper().API_URL + endpoint, updatedData);
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static updatePetrolPump = async (petrolPumpId, updatedData) => {
        const endpoint = `/api/petrol-pumps/${petrolPumpId}?populate=*`;
        try {
            // Assuming axios.put returns the response
            const response = await axios.put(
                new AppHelper().API_URL + endpoint,
                updatedData
            );
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static uploadImages = async (loadingData) => {
        const endpoint = "/api/upload";

        try {
            const formData = new FormData();
            formData.append("files", loadingData); // Use loadingData instead of undefined 'file'

            // Assuming axios.post returns the response
            const response = await axios.post(new AppHelper().API_URL + endpoint, formData);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getUploadedFileById = async () => {
        const endpoint = `/api/upload/files`;

        try {
            // Assuming axios.get returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    static getUploadedFile = async (fileId) => {
        const endpoint = `/api/upload/files/${fileId}`;

        try {
            // Assuming axios.get returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    static async postUnLoading(unLoadingData) {
        const endpoint = "/api/unloadings?manualInsert=true";
        try {
            //wrap the unLoadingData in a 'data' key
            const payload = { data: unLoadingData };
            // Assuming axios.post returns the response
            const response = await axios.post(
                new AppHelper().API_URL + endpoint,
                payload
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }


    static updateUnloading = async (unloadId, updatedData) => {
        const endpoint = `/api/unloadings/${unloadId}?populate=*`;
        try {
            // Assuming axios.put returns the response
            const response = await axios.put(new AppHelper().API_URL + endpoint, updatedData);
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getUnloadingById = async (unloadID) => {
        const endpoint = `/api/unloadings/${unloadID}?populate=*`;
        try {
            // Assuming axios.get returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error;
        }
    };

    static checkEmptyValuesInPayloadAndRemove = (
        payload,
        rateBlockName,
        singleElementName,
        childRateElement
    ) => {
        /**
         * Iterate through each block rate array object and remove if empty values are there
         */
        if (payload?.[rateBlockName].length > 1) {
            // iterate each item and check for filled up data
            payload[rateBlockName].forEach(function (item, index) {
                // remove particular item from array
                if (item?.[singleElementName]?.data?.id === "") {
                    payload[rateBlockName].splice(index, 1);
                }
                // iterate through rate array and remove if both fields have empty values
                item?.[childRateElement]?.forEach(function (childitem, childindex) {
                    if (childitem?.Start_Date === "" && childitem?.Rate === "") {
                        payload?.[rateBlockName][index][childRateElement].splice(
                            childindex,
                            1
                        );
                    }
                });
            });
        }

        return payload;
    };

    static preparePayloadForCreateAgency = (payload) => {
        let updatedPayload = { ...payload };
        // remove empty values and array from loading rate block object
        this.checkEmptyValuesInPayloadAndRemove(
            updatedPayload,
            "Loading_Rate",
            "Thermal_Plant",
            "Rate"
        );
        // remove empty values and array from unloading rate block object
        this.checkEmptyValuesInPayloadAndRemove(
            updatedPayload,
            "Unloading_Rates",
            "Thermal_Plant",
            "Rate"
        );
        // remove empty values and array from transportation rate block object
        this.checkEmptyValuesInPayloadAndRemove(
            updatedPayload,
            "Transportation_Rate",
            "project",
            "Rates"
        );
        return payload;
    };

    static getLoadingDetails = async (loadingId) => {
        try {
            const response = await axios.get(
                new AppHelper().API_URL + `/api/loadings/${loadingId}?populate=*`
            );
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getPetrolPumps = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const response = await axios.get(
                new AppHelper().API_URL + `/api/petrol-pumps/?populate=*&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}`
            );
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getStaffUsers = async () => {
        try {
            const response = await axios.get(
                new AppHelper().API_URL + `/api/staff-users`
            );
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static updateGetStaffUsers = async (staffId, updatedData) => {
        const endpoint = `/api/staff-users/${staffId}`;
        try {
            // Assuming axios.put returns the response
            const response = await axios.put(new AppHelper().API_URL + endpoint, updatedData);
            return response?.data?.data;
        } catch (error) {
            return error;
        }
    };

    static getPetrolPumpsDropDown(petrolPumps) {
        // make drop down array object
        let output = [];
        if (petrolPumps?.length > 0) {
            for (let index = 0; index < petrolPumps.length; index++) {
                output.push({
                    value: petrolPumps[index]?.attributes?.Petrolpump_Name,
                    label: petrolPumps[index]?.attributes?.Petrolpump_Name,
                });
            }
        }

        return output;
    }


    static getPetrolPumpNameDropDown(petrolPumps) {
        // make drop down array object
        let output = [];
        if (petrolPumps?.length > 0) {
            for (let index = 0; index < petrolPumps.length; index++) {
                output.push({
                    value: petrolPumps[index]?.id,
                    label: petrolPumps[index]?.attributes?.Petrolpump_Name,
                });
            }
        }

        return output;
    }

    static sortPetrolPumpRateByDate(array) {
        return array.sort((a, b) => new Date(a.Start_Date).getTime() - new Date(b.Start_Date).getTime());
    }

    static saveHSDDetails = async (payload) => {
        try {
            const response = await axios.post(
                new AppHelper().API_URL + `/api/hsd-details?manualInsert=true`,
                { data: payload }
            );
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getHSDDetails = async (sortValue, offset = 0, pageSize = 1, isPagination = true, filterArgs = {}) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/hsd-details?sort=${sortValue}`;
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;

            // filtration starts
            if (filterArgs?.Challan_Number)
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_Number}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.hsdLTR) {
                const breakLTR = filterArgs?.hsdLTR.split("~")
                const startLTR = breakLTR[0]
                const endLTR = breakLTR[1]
                if (startLTR)
                    apiURL = apiURL + `&filters[HSD_LTR][$gte]=${parseFloat(startLTR)}`
                if (endLTR)
                    apiURL = apiURL + `&filters[HSD_LTR][$lte]=${parseFloat(endLTR)}`
            }

            if (filterArgs?.Owner && filterArgs?.Owner !== "")
                apiURL = apiURL + `&filters[Owner][$containsi]=${filterArgs?.Owner}`

            if (filterArgs?.Petrolpump_Name && filterArgs?.Petrolpump_Name !== "")
                apiURL = apiURL + `&filters[Petrolpump_Name][id][$eq]=${filterArgs?.Petrolpump_Name}`

            if (filterArgs?.Transporter_Agency && filterArgs?.Transporter_Agency !== "")
                apiURL = apiURL + `&filters[Transporter_Name][id][$eq]=${filterArgs?.Transporter_Agency}`

            if (filterArgs?.Vehicle_Number && filterArgs?.Vehicle_Number !== "")
                apiURL = apiURL + `&filters[Vehicle_Number][id][$eq]=${filterArgs?.Vehicle_Number}`

            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[Loading][DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Cancelled_Pending_T0_Unload)
                apiURL = apiURL + `&filters[Cancelled_Pending_T0_Unload]=${filterArgs?.Cancelled_Pending_T0_Unload}`

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getExcavatorList = async (
        sortValue,
        offset = 0,
        pageSize = 1,
        isPagination = true,
        filterArgs = {}
    ) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/excavator-details?sort=${sortValue}`;
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;
            // filtration starts
            if (filterArgs?.Agency_Type)
                apiURL = apiURL + `&filters[Agency_Type][$eq]=${filterArgs?.Agency_Type}`

            if (filterArgs?.notBilledInVendorBills)
                apiURL = apiURL + `&filters[is_loading_billed]=false&filters[is_unloading_billed]=false`

            if (filterArgs?.Date) {
                const breakDates = filterArgs?.Date.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date][$lte]=${endDate}`
            }

            if (filterArgs?.hsdLTR) {
                const breakLTR = filterArgs?.hsdLTR.split("~")
                const startLTR = breakLTR[0]
                const endLTR = breakLTR[1]
                if (startLTR)
                    apiURL = apiURL + `&filters[HSD_LTR][$gte]=${parseFloat(startLTR)}`
                if (endLTR)
                    apiURL = apiURL + `&filters[HSD_LTR][$lte]=${parseFloat(endLTR)}`
            }

            if (filterArgs?.DO_No)
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.DO_No}`

            if (filterArgs?.Petrol_Pumps)
                apiURL = apiURL + `&filters[Petrol_Pumps][id][$eq]=${filterArgs?.Petrol_Pumps}`

            if (filterArgs?.agency_id)
                apiURL = apiURL + `&filters[agency_id][id][$eq]=${filterArgs?.agency_id}`

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;
            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static matchStartsWithInArrayByValue = (array, valueToMatch) => {
        return array.some((path) => valueToMatch.startsWith(path));
    };

    static getAllAgencies = async (
        sortValue,
        currentPage = 1,
        pageSize,
        isPagination = true,
        filterArgs = {},
        project = null) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/agencies?sort=${sortValue}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${currentPage}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            // filtration starts
            if (filterArgs?.Company_name)
                apiURL = apiURL + `&filters[Company_name][$containsi]=${filterArgs?.Company_name}`

            if (filterArgs?.Contact_person)
                apiURL = apiURL + `&filters[Contact_person][$containsi]=${filterArgs?.Contact_person}`

            if (filterArgs?.Mobile_Number)
                apiURL = apiURL + `&filters[Mobile_Number][$containsi]=${filterArgs?.Mobile_Number}`

            if (filterArgs?.Type_of_agency)
                apiURL = apiURL + `&filters[Type_of_agency][$eq]=${filterArgs?.Type_of_agency}`

            if (filterArgs?.Location)
                apiURL = apiURL + `&filters[Location][$containsi]=${filterArgs?.Location}`

            if (project)
                apiURL = apiURL + `&filters[Project][id][$eq]=${project}`

            const loadings = await axios.get(apiURL);
            return loadings?.data;
        } catch (error) {
            return error;
        }
    };

    static deleteAgency = async (agencyId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/agencies/${agencyId}`;
            const loadings = await axios.delete(apiURL);
            return loadings?.data;
        } catch (error) {
            return error;
        }
    };

    static getAllVehicles = async (sortValue, offset = 0, pageSize = 1, isPagination = true, filterArgs = {}) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/vehicles?sort=${sortValue}`;
            // filtration starts
            if (filterArgs?.Vehicle_Number)
                apiURL = apiURL + `&filters[Vehicle_Number][$containsi]=${filterArgs?.Vehicle_Number}`

            if (filterArgs?.Owner)
                apiURL = apiURL + `&filters[Owner][$containsi]=${filterArgs?.Owner}`

            if (filterArgs?.No_of_Tyres)
                apiURL = apiURL + `&filters[No_of_Tyres][$eq]=${filterArgs?.No_of_Tyres}`

            if (filterArgs?.Status_Vehicle) {
                if (filterArgs?.Status_Vehicle === 1) {
                    apiURL = apiURL + `&filters[Forcefully_Validated][$eq]=${true}`
                } else if (filterArgs?.Status_Vehicle === 2) {
                    apiURL = apiURL + `&filters[Forcefully_Validated][$eq]=${false}`
                }
            }

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;
            const vehicles = await axios.get(apiURL);
            return vehicles?.data;
        } catch (error) {
            return error;
        }
    };

    static getAgencyById = async (agencyId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/agencies/${agencyId}?populate=deep`;
            const agency = await axios.get(apiURL);
            return agency?.data;
        } catch (error) {
            return error;
        }
    };

    static deleteHSDDetails = async (hsdDetailsId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/hsd-details/${hsdDetailsId}`;
            const hsdDetails = await axios.delete(apiURL);
            return hsdDetails?.data;
        } catch (error) {
            return error;
        }
    };

    static getHSDDetailsById = async (hsdDetailsId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/hsd-details/${hsdDetailsId}?populate=*`;
            const hsdDetails = await axios.get(apiURL);
            return hsdDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static calculatePaginationOffset(currentPageNum, recordsPerPage) {
        return parseInt(currentPageNum - 1) * parseInt(recordsPerPage);
    }

    static updateHSDDetails = async (payload, hsdId) => {
        try {
            const response = await axios.put(new AppHelper().API_URL + `/api/hsd-details/${hsdId}`, { data: payload });
            return response?.data?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getAllPetrolPumps = async (
        sortValue,
        offset = 0,
        pageSize = 1,
        isPagination = true,
        filterArgs = {}
    ) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/petrol-pumps?sort=${sortValue}`;
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;
            // filtration starts
            if (filterArgs?.Petrolpump_Name)
                apiURL = apiURL + `&filters[Petrolpump_Name][$containsi]=${filterArgs?.Petrolpump_Name}`

            if (filterArgs?.Contact_person)
                apiURL = apiURL + `&filters[Contact_person][$containsi]=${filterArgs?.Contact_person}`

            if (filterArgs?.Contact_Number)
                apiURL = apiURL + `&filters[Contact_Number][$containsi]=${filterArgs?.Contact_Number}`

            if (filterArgs?.Location) {
                apiURL = apiURL + `&filters[Location][$containsi]=${filterArgs?.Location}`
            }

            apiURL = apiURL + `&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}`;
            const petrolPumps = await axios.get(apiURL);
            return petrolPumps?.data;
        } catch (error) {
            return error;
        }
    };

    static deletePetrolPump = async (hsdDetailsId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/petrol-pumps/${hsdDetailsId}`;
            const hsdDetails = await axios.delete(apiURL);
            return hsdDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static encryptText = (string) => {
        return CryptoJS.AES.encrypt(JSON.stringify(string), new AppHelper().SECRET_KEY).toString();
    };

    static decryptText = (string) => {
        if (!string) return "";
        const decryptText = CryptoJS.AES.decrypt(string, new AppHelper().SECRET_KEY);
        return JSON.parse(decryptText.toString(CryptoJS.enc.Utf8));
    };

    static getUserDetailsById = async (userId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/staff-users/${userId}?fields[0]=id&fields[1]=Name&fields[2]=Contact_Number&fields[3]=Email_id&fields[4]=Company_ID&fields[5]=Email_id&populate[Thermal_Plant][fields][0]=id&&populate[Thermal_Plant][fields][1]=Name`;
            const userDetails = await axios.get(apiURL);
            return userDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static getProjectsByThermalPlants = async (thermalPlantId) => {
        try {
            if (!thermalPlantId || thermalPlantId === "ALL") {
                return [];
            }
            let apiURL = new AppHelper().API_URL + `/api/project-tp/${thermalPlantId}`;
            const tpDetails = await axios.get(apiURL);
            return tpDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static setProjectDropDownByThermalPlants = (projects) => {
        let output = [];
        if (projects?.length > 0) {
            for (let index = 0; index < projects?.length; index++) {
                output.push({
                    value: projects[index]?.id,
                    label: projects[index]?.Name_of_project,
                });
            }
        }
        return output;
    };

    static updateRatesRateObjectAgency = (payload, parentBlock, rateKey) => {
        if (!parentBlock.startsWith("Loading_Rate.") && !parentBlock.startsWith("Unloading_Rates.") && !parentBlock.startsWith("Transportation_Rate.")) {
            payload?.[parentBlock]?.[rateKey].forEach((rateValue, rateKey2) => {
                Object.keys(rateValue).forEach((item) => {
                    rateValue[item] = item === 'Start_Date' ? ((payload?.[`${parentBlock}.${rateKey}.${rateKey2}.${item}`] && payload?.[`${parentBlock}.${rateKey}.${rateKey2}.${item}`] !== "") ? dayjs(payload?.[`${parentBlock}.${rateKey}.${rateKey2}.${item}`]).format("YYYY-MM-DD") : "") : payload?.[`${parentBlock}.${rateKey}.${rateKey2}.${item}`]
                })
            });
        } else {
            //delete payload?.[parentBlock]
        }
        return payload
    }

    static preparePayloadForAgency = (payload) => {
        // loop through each key of payload and prepare payload as per API requirement
        if (!payload || payload === "") return ""
        Object.entries(payload).forEach(([key, value]) => {
            // remove rate objects if not selected in type of agency
            if (payload?.Type_of_agency?.includes('Loading') === false) {
                // remove loading rates object completely
                delete payload?.['Loading_Rate']
            }
            if (payload?.Type_of_agency?.includes('Unloading') === false) {
                // remove unloading rates object completely
                delete payload?.['Unloading_Rates']
            }
            if (payload?.Type_of_agency?.includes('Transportation') === false) {
                // remove unloading rates object completely
                delete payload?.['Transportation_Rate']
            }
            const targetIdStartsWithBlockIds = AppHelper.checkStringStartsWithInArray(constantsArr.AGENCY_RATES_BLOCK_KEYS, key)
            if (targetIdStartsWithBlockIds) {
                AppHelper.updateRatesRateObjectAgency(payload, key, 'Rates')
            }
        })

        return payload
    }

    static getExcavatorDetailsById = async (excavatorId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/excavator-details/${excavatorId}?populate=deep`;
            const hsdDetails = await axios.get(apiURL);
            return hsdDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static updateExcavator = async (excavatorData, excavatorId) => {
        const endpoint = `/api/excavator-details/${excavatorId}`;
        try {
            //wrap the loadingData in a 'data' key
            const payload = { data: excavatorData };
            // Assuming axios.post returns the response
            const response = await axios.put(new AppHelper().API_URL + endpoint, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static loadingLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Challan_Number: payload.Challan_Number,
                Transporter_Agency: payload.Transporter_Agency,
                Date_of_Loading: payload.Date_of_Loading,
                Vehicle_Number: payload.Vehicle_Number,
                Loading_Agency: payload.Loading_Agency,
                Thermal_Plant_Book_no: payload.Thermal_Plant_Book_no,
                Kanta_Slip_No: payload.Kanta_Slip_No,
                Loading_by_Employee: payload.Loading_by_Employee,
            }
            let apiURL = new AppHelper().API_URL + "/api/loadingLevelInternalFilters";
            const loadings = await axios.post(apiURL, selectionData);
            return loadings;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static unLoadingLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Date_of_Unloading: payload.Date_of_Loading,
                Unloading_Agency: payload.Unloading_Agency,
                Unloading_by_Employee: payload.Unloading_by_Employee,
                Challan_Number: payload.Challan_Number,
                Vehicle_Number: payload.Vehicle_Number,
                Owner: payload.Owner,
                Thermal_Plant_Book_no: payload.Thermal_Plant_Book_no,
                Net_Weight: payload.Net_Weight,
                Gross_Weight: payload.Gross_Weight,
                Tare_Weight: payload.Tare_Weight,
                Kanta_Slip_No: payload.Kanta_Slip_No,
                Unloading_Location: payload.Unloading_Location,
                Remarks: payload.Remarks,
                Created_By: payload.Created_By,
                Reviewed_By: payload.Reviewed_By,
                Cancelled: payload.Cancelled,
                Project: payload.Project,
                Thermal_Plant: payload.Thermal_Plant,
                Bill_Done: payload.Bill_Done,
                Loading: payload.Loading
            }
            let apiURL = new AppHelper().API_URL + "/api/unloadingLevelInternalFilters";
            const unloadings = await axios.post(apiURL, selectionData);
            return unloadings?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static agencyLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Company_name: payload.Company_name,
                Contact_person: payload.Contact_person,
                Mobile_Number: payload.Mobile_Number,
                Type_of_agency: payload.Type_of_agency,
                Loading_Rate: payload.Loading_Rate,
                Unloading_Rate: payload.Unloading_Rate,
                Transportation_Rate: payload.Transportation_Rate,
                Remarks: payload.Remarks,
            }
            let apiURL = new AppHelper().API_URL + "/api/agencyLevelInternalFilters";
            const agencies = await axios.post(apiURL, selectionData);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static vehicleLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Vehicle_Number: payload.Vehicle_Number,
                Owner: payload.Owner,
                Remarks: payload.Remarks
            }
            let apiURL = new AppHelper().API_URL + "/api/vehicleLevelInternalFilters";
            const vehicles = await axios.post(apiURL, selectionData);
            return vehicles?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static hsdLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Date_of_Loading: payload.Date_of_Loading,
                Petrolpump_Name: payload.Petrolpump_Name,
                Vehicle_Number: payload.Vehicle_Number,
                Owner: payload.Owner,
                Challan_Number: payload.Challan_Number,
                DO_No: payload.DO_No,
                HSD_LTR: payload.HSD_LTR,
                HSD_Amt: payload.HSD_Amt,
                Remarks: payload.Remarks,
                Rate_INR: payload.Rate_INR,
                Transporter_Name: payload.Transporter_Name,
                Project: payload.Project
            }
            let apiURL = new AppHelper().API_URL + "/api/hsdLevelInternalFilters";
            const hsd_details = await axios.post(apiURL, selectionData);
            return hsd_details?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static excavatorLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Date: payload.Date,
                Agency_Type: payload.Agency_Type,
                Vehicle_Number: payload.Vehicle_Number,
                Owner: payload.Owner,
                DO_No: payload.DO_No,
                Petrol_Pumps: payload.Petrol_Pumps,
                Rate: payload.Rate,
                HSD_LTR: payload.HSD_LTR,
                HSD_AMOUNT: payload.HSD_AMOUNT,
                Remarks: payload.Remarks,
                agency_id: payload.agency_id,
                Project: payload.Project
            }
            let apiURL = new AppHelper().API_URL + "/api/excavatorLevelInternalFilters";
            const excavator_details = await axios.post(apiURL, selectionData);
            return excavator_details?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static petrolPumpLevelInternalFilters = async (payload) => {
        try {
            let selectionData = {
                Petrolpump_Name: payload.Petrolpump_Name,
                Remarks: payload.Remarks,
                Location: payload.Location,
                Contact_person: payload.Contact_person,
                Contact_Number: payload.Contact_Number,
                Thermal_Plant: payload.Thermal_Plant
            }
            let apiURL = new AppHelper().API_URL + "/api/petrolPumpLevelInternalFilters";
            const petrolPump_details = await axios.post(apiURL, selectionData);
            return petrolPump_details?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static preventMinus = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    };

    static preventMinusDot = (e) => {
        if (e.code === 'Minus' || e.code === 'Period') {
            e.preventDefault();
        }
    };

    static updateExcavatorDataAsOnPetrolPumpRateChange = async (loadId, payload) => {
        try {
            let selectionData = {
                loadId: loadId,
                payload: payload?.data
            }
            let apiURL = new AppHelper().API_URL + "/api/updateExcavatorDataAsOnPetrolPumpRateChange";
            const updatedExcavatorDetails = await axios.post(apiURL, selectionData);
            return updatedExcavatorDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static cancelLoading = async (cancelDetails) => {
        const endpoint = `/api/cancelLoading`;
        try {
            // Assuming axios.put returns the response
            const response = await axios.patch(new AppHelper().API_URL + endpoint, cancelDetails);
            return response;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static savePictureData = async (images) => {
        try {
            const payload = {
                data: {
                    Pictures: images
                }
            }
            let apiURL = new AppHelper().API_URL + "/api/pictures";
            const updatedExcavatorDetails = await axios.post(apiURL, payload);
            return updatedExcavatorDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getAllLoggedInUserProjects = async (thermalPlants = []) => {
        try {
            let apiURL = new AppHelper().API_URL + "/api/projects?sort=Name_of_project:desc&populate=*";
            for (let index = 0; index < thermalPlants.length; index++) {
                apiURL = apiURL + `&filters[Thermal_Plant][id][$in][${index}]=${thermalPlants[index]}`
            }
            const updatedExcavatorDetails = await axios.get(apiURL);
            return updatedExcavatorDetails?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getVehiclesWithoutPagination = async () => {
        try {
            let apiURL = new AppHelper().API_URL + "/api/getAllVehicles?sort=updatedAt:desc";
            const vehicles = await axios.get(apiURL);
            return vehicles;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getVehicleNumberDropDownWithoutPagination = (vehicles) => {
        let output = [];
        if (vehicles.length > 0) {
            for (let index = 0; index < vehicles.length; index++) {
                output.push({
                    value: vehicles[index]?.Vehicle_Number,
                    label: vehicles[index]?.Vehicle_Number,
                });
            }
        }
        return output;
    };

    static getOwnerDropDownWithoutPagination = (owner) => {
        let output = [];
        let uniqueNames = new Set(); // Using a Set to store unique names
        if (owner.length > 0) {
            for (let index = 0; index < owner.length; index++) {
                let ownerName = owner[index]?.Owner;
                if (!uniqueNames.has(ownerName)) { // Checking if the name is already added
                    output.push({
                        value: ownerName,
                        label: ownerName,
                    });
                    uniqueNames.add(ownerName); // Adding the name to the Set
                }
            }
        }
        return output;
    };

    static getNoOfTyresDropDownWithoutPagination = (tyres) => {
        let output = [];
        let uniqueNumber = new Set(); // Using a Set to store unique numbers
        if (tyres.length > 0) {
            for (let index = 0; index < tyres.length; index++) {
                let tyreNumber = tyres[index]?.No_of_Tyres;
                if (tyreNumber && !uniqueNumber.has(tyreNumber)) { // Checking if the number is not empty and not already added
                    output.push({
                        value: tyreNumber,
                        label: tyreNumber,
                    });
                    uniqueNumber.add(tyreNumber); // Adding the number to the Set
                }
            }
        }
        return output;
    };

    static importLoadingExcel = async (uploadedFile) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
            let formData = new FormData()
            formData.append('excelFileData', uploadedFile?.file)
            formData.append('globalThermalPlant', globalTpProjectValues?.thermalPlantGlobal)
            formData.append('globalProject', globalTpProjectValues?.projectGlobal)
            formData.append('loggedUserId', sessionUserInfo?.userID ?? null)
            let apiURL = new AppHelper().API_URL + "/api/importLoadingFormExcel";
            const fileImportResponse = await axios.post(apiURL, formData)
            return fileImportResponse?.data
        } catch (error) {
            return error?.response?.data
        }
    }

    static importVehicleExcel = async (uploadedFile) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
            let formData = new FormData()
            formData.append('excelFileData', uploadedFile?.file)
            formData.append('globalThermalPlant', globalTpProjectValues?.thermalPlantGlobal)
            formData.append('globalProject', globalTpProjectValues?.projectGlobal)
            formData.append('loggedUserId', sessionUserInfo?.userID ?? null)
            let apiURL = new AppHelper().API_URL + "/api/importVehicleDetailFormExcel";
            const fileImportResponse = await axios.post(apiURL, formData)
            return fileImportResponse?.data
        } catch (error) {
            // If there's a network error, throw an object representing the error
            if (error?.response?.status === 0) {
                throw { code: "ERR_NETWORK", message: "Network Error" };
            }
            // If it's not a network error, re-throw the original error
            throw error;
        }
    }

    static importUnLoadingExcel = async (uploadedFile) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
            let formData = new FormData()
            formData.append('excelFileData', uploadedFile?.file)
            formData.append('globalThermalPlant', globalTpProjectValues?.thermalPlantGlobal)
            formData.append('globalProject', globalTpProjectValues?.projectGlobal)
            formData.append('loggedUserId', sessionUserInfo?.userID ?? null)
            let apiURL = new AppHelper().API_URL + "/api/importUnloadingFormExcel";
            const fileImportResponse = await axios.post(apiURL, formData)
            return fileImportResponse?.data
        } catch (error) {
            return error?.response?.data
        }
    }

    static importHSDDetailsExcel = async (uploadedFile) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
            let formData = new FormData()
            formData.append('excelFileData', uploadedFile?.file)
            formData.append('globalThermalPlant', globalTpProjectValues?.thermalPlantGlobal)
            formData.append('globalProject', globalTpProjectValues?.projectGlobal)
            formData.append('loggedUserId', sessionUserInfo?.userID ?? null)
            let apiURL = new AppHelper().API_URL + "/api/importHsdDetailFormExcel";
            const fileImportResponse = await axios.post(apiURL, formData)
            return fileImportResponse?.data
        } catch (error) {
            return error?.response?.data
        }
    }

    static importExcavatorsExcel = async (uploadedFile) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            const sessionUserInfo = AppHelper.decryptText(sessionStorage.getItem("userInfo"));
            let formData = new FormData()
            formData.append('excelFileData', uploadedFile?.file)
            formData.append('globalThermalPlant', globalTpProjectValues?.thermalPlantGlobal)
            formData.append('globalProject', globalTpProjectValues?.projectGlobal)
            formData.append('loggedUserId', sessionUserInfo?.userID ?? null)
            let apiURL = new AppHelper().API_URL + "/api/importExcavatorDetailFormExcel";
            const fileImportResponse = await axios.post(apiURL, formData)
            return fileImportResponse?.data
        } catch (error) {
            return error?.response?.data
        }
    }

    static findMatchingAgencies = async (agencyName) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            let apiURL = new AppHelper().API_URL + "/api/agency-suggestions?sort=updatedAt:desc";
            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;
            // filtration starts
            if (agencyName)
                apiURL = apiURL + `&filters[Company_name][$containsi]=${agencyName}`

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static deleteExcavator = async (excavatorId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/excavator-details/${excavatorId}`;
            const excavator = await axios.delete(apiURL);
            return excavator?.data;
        } catch (error) {
            return error?.response?.data;
        }
    };

    static invokeCancelledLoading = async (loadingId) => {
        const endpoint = `/api/revokeCancelledLoading`;
        try {
            const payload = {
                data: {
                    loadId: loadingId,
                    Cancelled: false,
                }
            }
            // Assuming axios.delete returns the response
            const response = await axios.patch(new AppHelper().API_URL + endpoint, payload?.data);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getPendingBillsLoadingAgency = async (sortOrder = "Date_of_Loading:asc", agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = null, billId = null) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            const sortOrderValue = sortOrder && sortOrder !== "" ? sortOrder : 'Date_of_Loading:asc'

            let apiURL = new AppHelper().API_URL + `/api/get-all-pending-loadings-bills?sort=${sortOrderValue}`;

            if (sortOrder === 'Bill_Creation_Date:asc')
                apiURL = apiURL + `&section=edit_loading_bills`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId)
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`

            if (billId && billId !== "")
                apiURL = apiURL + `&filters[billId]=${billId}`

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`

            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep`;
            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getPendingBillsTransportingAgency = async (sortOrder = 'Date_of_Loading:asc', agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = {}, billId = null) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            const sortOrderValue = sortOrder && sortOrder !== "" ? sortOrder : 'Date_of_Loading:asc'

            let apiURL = new AppHelper().API_URL + `/api/get-all-pending-transport-loadings?sort=${sortOrderValue}`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`

            if (billId && billId !== "")
                apiURL = apiURL + `&filters[billId]=${billId}`

            if (filterArgs?.cancelledWithHsdTakenCheckbox)
                apiURL = apiURL + `&filters[Cancelled]=${true}`

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep`;
            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static formatNumber = (number) => {
        if (number >= 10000000) {
            return `${(number / 10000000).toFixed(2)}Cr`
        } else if (number >= 100000) {
            return `${(number / 100000).toFixed(2)}L`
        }
        return number.toString()
    }

    static getTransportPendingBillsById = async (recordIds = [], isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + "/api/get-all-pending-transport-loadings-by-ids?sort=Date_of_Loading:asc";

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=*`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const payload = {
                data: {
                    loading_Id_Array: recordIds
                }
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getGeneratedTransportAgency = async (agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = {}, sortOrder) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/generated-transport-bills?sort=${sortOrder}`;

            apiURL = apiURL + `&filters[agency_type]=transport&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency]=${agencyId}`;

            if (filterArgs?.bill_number && filterArgs?.bill_number !== "")
                apiURL = apiURL + `&filters[bill_number][$containsi]=${filterArgs?.bill_number}`;

            if (filterArgs?.date_of_billing) {
                const breakDates = filterArgs?.date_of_billing.split("~");
                const startDate = breakDates[0];
                const endDate = breakDates[1];
                if (startDate)
                    apiURL = apiURL + `&filters[date_of_billing][$gte]=${startDate}`;
                if (endDate)
                    apiURL = apiURL + `&filters[date_of_billing][$lte]=${endDate}`;
            }

            if (filterArgs?.hsdRoundOff && filterArgs?.hsdRoundOff !== "")
                apiURL = apiURL + `&filters[hsdRoundOff]=${filterArgs?.hsdRoundOff}`;


            if (filterArgs?.wtRoundOff && filterArgs?.wtRoundOff !== "")
                apiURL = apiURL + `&filters[wtRoundOff]=${filterArgs?.wtRoundOff}`;


            if (filterArgs?.otherDeductionCheckbox && filterArgs?.otherDeductionCheckbox !== "")
                apiURL = apiURL + `&filters[otherDeductionCheckbox]=${filterArgs?.otherDeductionCheckbox}`;

            if (filterArgs?.cancelChallanWithHSDCheckBox && filterArgs?.cancelChallanWithHSDCheckBox !== "")
                apiURL = apiURL + `&filters[cancelChallanWithHSDCheckBox]=${filterArgs?.cancelChallanWithHSDCheckBox}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getGeneratedTransportAgencyById = async (recordIds = [], isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/generated-bills-transaction-summary/transporting/${recordIds}?sort=createdAt:desc`;

            apiURL = apiURL + `&filters[agency_type]=transporting&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=deep`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static deleteGeneratedTransportAgencyById = async (deleteTransportAgencyId) => {
        const endpoint = `/api/bills/${deleteTransportAgencyId}`;
        try {
            const response = await axios.delete(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static updateRatesObjectRoundOff = (adjustedWeightRateArray, inputFields, type) => {
        if (type === 'WeightRate') {
            inputFields?.[`RoundOffWeight`]?.[`Rates`].forEach((rateValue, rateKey2) => {
                Object.entries(rateValue).forEach(([item]) => {
                    rateValue[item] = adjustedWeightRateArray?.[`${`RoundOffWeight`}.${`Rates`}.${rateKey2}.${item}`]
                })
            });
        } else if (type === 'HSDRate') {
            inputFields?.[`RoundOffHSDRate`]?.[`Rates`].forEach((rateValue, rateKey2) => {
                Object.entries(rateValue).forEach(([item]) => {
                    rateValue[item] = adjustedWeightRateArray?.[`${`RoundOffHSDRate`}.${`Rates`}.${rateKey2}.${item}`]
                })
            });
        }
        return inputFields
    }

    static preventNegativeValue = (e) => {
        if (e.code === 'Minus') {
            e.preventDefault();
        }
    }

    static preventNegativeValueForRate = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    static preventNegativeValueForCUM = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 7) {
            e.preventDefault();
        }
    }

    static saveFinalGeneratedBillTransport = async (billDataToPost) => {
        try {
            let apiURL = new AppHelper().API_URL + "/api/save-transport-agency-pending-bill"
            const payload = {
                data: billDataToPost
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getLoadingCountHeaders = async (sortValue, filterArgs = []) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/totalHeaderLoadingDetails?sort=${sortValue}&populate=*`;

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            apiURL = apiURL + `&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;

            // filtration starts
            if (filterArgs?.Challan_Number)
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_Number}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Kanta_Slip_No)
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Loading_Agency)
                apiURL = apiURL + `&filters[Loading_Agency][id][$eq]=${filterArgs?.Loading_Agency}`

            if (filterArgs?.Loading_by_Employee)
                apiURL = apiURL + `&filters[Loading_by_Employee][$containsi]=${filterArgs?.Loading_by_Employee}`

            if (filterArgs?.Thermal_Plant_Book_no)
                apiURL = apiURL + `&filters[Thermal_Plant_Book_no][$containsi]=${filterArgs?.Thermal_Plant_Book_no}`

            if (filterArgs?.Transporter_Agency)
                apiURL = apiURL + `&filters[Transporter_Agency][id][$eq]=${filterArgs?.Transporter_Agency}`

            if (filterArgs?.Vehicle_Number)
                apiURL = apiURL + `&filters[Vehicle_Number][id][$eq]=${filterArgs?.Vehicle_Number}`

            if (filterArgs?.Cancelled_Pending_Unload && filterArgs?.Cancelled_Pending_Unload === 1)
                apiURL = apiURL + `&filters[Cancelled][$eq]=${!!filterArgs?.Cancelled_Pending_Unload}`
            else if (filterArgs?.Cancelled_Pending_Unload && filterArgs?.Cancelled_Pending_Unload === 2)
                apiURL = apiURL + `&filters[Unloaded][$eq]=${false}&filters[Cancelled][$eq]=${false}`

            if (filterArgs?.HSD_DONE && filterArgs?.HSD_DONE === true)
                apiURL = apiURL + `&filters[HSD_DONE][$eq]=${false}`

            if (filterArgs?.DO_No)
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.DO_No}`

            const loadings = await axios.get(apiURL);

            return loadings?.data;
        } catch (error) {
            return error;
        }
    }

    static getGeneratedBillDetails = async (billId) => {
        try {
            const apiURL = new AppHelper().API_URL + `/api/bills/${billId}?populate[loading_id_list][loading][sort][0]=id:asc&populate[loading_id_list][populate][loading][fields][0]=id&populate[Project][fields][0]=id&populate[Project][fields][1]=Name_of_project&populate[RoundOffWeight][fields][0]=id&populate[RoundOffWeight][populate][Rates][fields][0]=Quantity&populate[RoundOffWeight][populate][Rates][fields][1]=Rate&populate[RoundOffHSDRate][populate][Rates][fields][0]=Quantity&populate[RoundOffHSDRate][populate][Rates][fields][1]=Rate&populate[agency][fields]`
            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static updateFinalGeneratedBillTransport = async (billDataToPost, billId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/update-transport-agency-pending-bill/${billId}`
            const payload = {
                data: billDataToPost
            }
            const response = await axios.put(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getPendingClientBills = async (sortValue, offset = 0, pageSize = 1, isPagination = true, filterArgs = {}, billId = null) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            let apiURL = new AppHelper().API_URL + `/api/client-bill-pending-list?sort=${sortValue}`;
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}`;

            if (billId && billId !== "") {
                apiURL = apiURL + `&filters[billId]=${billId}&type=full`
            }

            // filtration starts
            if (filterArgs?.Challan_Number)
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_Number}`

            if (filterArgs?.Date) {
                const breakDates = filterArgs?.Date.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            if (filterArgs?.Thermal_Plant_Book_no && filterArgs?.Thermal_Plant_Book_no !== "")
                apiURL = apiURL + `&filters[Thermal_Plant_Book_no][$containsi]=${filterArgs?.Thermal_Plant_Book_no}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$eq]=${filterArgs?.Kanta_Slip_No}`

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getSelectedLoadingPendingClientBills = async (sortValue, offset = 0, pageSize = 1, isPagination = true, filterArgs = {}, loadingIds = [], isExport = false, billId = null) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
            let apiURL = new AppHelper().API_URL + `/api/client-bill-pending-list-selected?sort=${sortValue}`;
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}&filters[Thermal_Plant][id][$eq]=${globalTpProjectValues.thermalPlantGlobal}`;

            if (isExport)
                apiURL = apiURL + `&filters[isExport]=${isExport}`

            if (billId && billId !== "")
                apiURL = apiURL + `&filters[billId]=${billId}`

            // filtration starts
            if (filterArgs?.Challan_Number)
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_Number}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }
            if (filterArgs?.Date_of_Unloading) {
                const breakDatesUnloading = filterArgs?.Date_of_Unloading.split("~")
                const startDateUnloading = breakDatesUnloading[0]
                const endDateUnloading = breakDatesUnloading[1]
                if (startDateUnloading)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDateUnloading}`
                if (endDateUnloading)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDateUnloading}`
            }

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$eq]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Thermal_Plant_Book_no && filterArgs?.Thermal_Plant_Book_no !== "")
                apiURL = apiURL + `&filters[Thermal_Plant_Book_no][$containsi]=${filterArgs?.Thermal_Plant_Book_no}`

            const payload = {
                data: {
                    loading_ids: loadingIds
                }
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static saveClientBill = async (payloadData) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/save-client-bill`
            const payload = {
                data: payloadData
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getGeneratedLoadingAgency = async (agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = {}, sortOrder = '') => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/bills?sort=${sortOrder}`;

            apiURL = apiURL + `&filters[agency_type]=loading&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency]=${agencyId}`;

            if (filterArgs?.bill_number && filterArgs?.bill_number !== "")
                apiURL = apiURL + `&filters[bill_number][$containsi]=${filterArgs?.bill_number}`;

            if (filterArgs?.date_of_billing) {
                const breakDates = filterArgs?.date_of_billing.split("~");
                const startDate = breakDates[0];
                const endDate = breakDates[1];
                if (startDate)
                    apiURL = apiURL + `&filters[date_of_billing][$gte]=${startDate}`;
                if (endDate)
                    apiURL = apiURL + `&filters[date_of_billing][$lte]=${endDate}`;
            }

            if (filterArgs?.hsdRoundOff && filterArgs?.hsdRoundOff !== "")
                apiURL = apiURL + `&filters[hsdRoundOff]=${filterArgs?.hsdRoundOff}`;


            if (filterArgs?.wtRoundOff && filterArgs?.wtRoundOff !== "")
                apiURL = apiURL + `&filters[wtRoundOff]=${filterArgs?.wtRoundOff}`;


            if (filterArgs?.otherDeductionCheckbox && filterArgs?.otherDeductionCheckbox !== "")
                apiURL = apiURL + `&filters[otherDeductionCheckbox]=${filterArgs?.otherDeductionCheckbox}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getGeneratedLoadingAgencyById = async (recordIds = [], isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/loading-generated-bills-transaction-summary/${recordIds}?sort=createdAt:asc`;

            apiURL = apiURL + `&filters[agency_type]=loading&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=deep`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const payload = {
                data: {
                    loading_Id_Array: recordIds
                }
            }
            const response = await axios.get(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static deleteGeneratedLoadingAgencyById = async (deleteLoadingAgencyId) => {
        const endpoint = `/api/bills/${deleteLoadingAgencyId}`;
        try {
            const response = await axios.delete(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getGeneratedClientBills = async (offset = 0, pageSize = 1, isPagination = true, filterArgs = {}) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/generated-client-bills?sort=bill_date:desc`;
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;

            // filtration starts
            if (filterArgs?.bill_number && filterArgs?.bill_number !== "")
                apiURL = apiURL + `&filters[bill_number][$containsi]=${filterArgs?.bill_number}`;

            if (filterArgs?.date_of_billing) {
                const breakDates = filterArgs?.date_of_billing.split("~");
                const startDate = breakDates[0];
                const endDate = breakDates[1];
                if (startDate)
                    apiURL = apiURL + `&filters[date_of_billing][$gte]=${startDate}`;
                if (endDate)
                    apiURL = apiURL + `&filters[date_of_billing][$lte]=${endDate}`;
            }

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getClientBillDetails = async (billId, isPopulate = true) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/view-client-bill-details/${billId}`;
            if (isPopulate) {
                apiURL = apiURL + `?isPopulate=${isPopulate}`
            }
            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static updateClientBill = async (billId, payload) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/update-client-bill/${billId}`;
            const payloadData = {
                data: payload
            }
            const response = await axios.put(apiURL, payloadData);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static deleteClientBillGenerated = async (billId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/delete-generated-client-bill/${billId}`
            const response = await axios.delete(apiURL);
        } catch (error) {
            return error?.response?.data
        }

    }

    static getGeneratedUnloadingAgency = async (agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = {}) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + "/api/bills?sort=date_of_billing:desc";

            apiURL = apiURL + `&filters[agency_type]=unloading&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency]=${agencyId}`;

            if (filterArgs?.bill_number && filterArgs?.bill_number !== "")
                apiURL = apiURL + `&filters[bill_number][$containsi]=${filterArgs?.bill_number}`;

            if (filterArgs?.date_of_billing) {
                const breakDates = filterArgs?.date_of_billing.split("~");
                const startDate = breakDates[0];
                const endDate = breakDates[1];
                if (startDate)
                    apiURL = apiURL + `&filters[date_of_billing][$gte]=${startDate}`;
                if (endDate)
                    apiURL = apiURL + `&filters[date_of_billing][$lte]=${endDate}`;
            }
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static deleteGeneratedUnloadingAgencyById = async (deleteUnloadingAgencyId) => {
        const endpoint = `/api/bills/${deleteUnloadingAgencyId}`;
        try {
            const response = await axios.delete(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getLoadingPendingBillsById = async (recordIds = [], isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + "/api/get-all-pending-loadings-by-ids?sort=Date_of_Loading:asc";

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=deep`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const payload = {
                data: {
                    loading_Id_Array: recordIds
                }
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static saveFinalGeneratedBillLoading = async (billDataToPost) => {
        try {
            let apiURL = new AppHelper().API_URL + "/api/save-loading-agency-pending-bill"
            const payload = {
                data: billDataToPost
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static updateFinalGeneratedBillLoading = async (billDataToPost, billId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/update-loading-agency-pending-bill/${billId}`
            const payload = {
                data: billDataToPost
            }
            const response = await axios.put(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getPendingBillsUnloadingAgency = async (agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = null, sortOrder = null, billId = null) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-all-pending-unloadings-bills?sort=${sortOrder}`;

            if (sortOrder === 'Bill_Creation_Date:asc')
                apiURL = apiURL + `&section=edit_loading_bills`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId)
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`

            if (billId)
                apiURL = apiURL + `&filters[billId]=${billId}`

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`

            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }
            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=deep`;
            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getUnloadingPendingBillsById = async (recordIds = [], isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + "/api/get-all-pending-unloadings-by-ids?sort=createdAt:asc";

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=deep`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const payload = {
                data: {
                    loading_Id_Array: recordIds
                }
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static saveFinalGeneratedBillUnloading = async (billDataToPost) => {
        try {
            let apiURL = new AppHelper().API_URL + "/api/save-unloading-agency-pending-bill"
            const payload = {
                data: billDataToPost
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getGeneratedUnloadingAgencyBill = async (agencyId = null, isPagination = false, offset = false, pageSize = false, filterArgs = {}, sortOrder = '') => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/bills?sort=${sortOrder}`;

            apiURL = apiURL + `&filters[agency_type]=unloading&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency]=${agencyId}`;

            if (filterArgs?.bill_number && filterArgs?.bill_number !== "")
                apiURL = apiURL + `&filters[bill_number][$containsi]=${filterArgs?.bill_number}`;

            if (filterArgs?.date_of_billing) {
                const breakDates = filterArgs?.date_of_billing.split("~");
                const startDate = breakDates[0];
                const endDate = breakDates[1];
                if (startDate)
                    apiURL = apiURL + `&filters[date_of_billing][$gte]=${startDate}`;
                if (endDate)
                    apiURL = apiURL + `&filters[date_of_billing][$lte]=${endDate}`;
            }

            if (filterArgs?.hsdRoundOff && filterArgs?.hsdRoundOff !== "")
                apiURL = apiURL + `&filters[hsdRoundOff]=${filterArgs?.hsdRoundOff}`;


            if (filterArgs?.wtRoundOff && filterArgs?.wtRoundOff !== "")
                apiURL = apiURL + `&filters[wtRoundOff]=${filterArgs?.wtRoundOff}`;


            if (filterArgs?.otherDeductionCheckbox && filterArgs?.otherDeductionCheckbox !== "")
                apiURL = apiURL + `&filters[otherDeductionCheckbox]=${filterArgs?.otherDeductionCheckbox}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getGeneratedUnLoadingAgencyBillById = async (recordIds = [], isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/generated-bills-transaction-summary/unloading/${recordIds}?sort=createdAt:desc`;

            apiURL = apiURL + `&filters[agency_type]=unloading&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=deep`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const payload = {
                data: {
                    loading_Id_Array: recordIds
                }
            }
            const response = await axios.get(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static updateFinalGeneratedBillUnloading = async (billDataToPost, billId) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/update-unloading-agency-pending-bill/${billId}`
            const payload = {
                data: billDataToPost
            }
            const response = await axios.put(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getGeneratedUnloadingAgencyById = async (recordId, isPagination = false, offset = false, pageSize = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/unloading-generated-bills-transaction-summary/${recordId}?sort=createdAt:asc`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&populate=deep`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static exportPendingTransportBills = async (sortOrder, agencyId, isPagination = false, offset = 1, pageSize, filterArgs = {}, editTransportBill = false, loadingIds = []) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/export-transport-pending-bills?sort=${sortOrder}`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`

            if (editTransportBill && editTransportBill === true)
                apiURL = apiURL + `&filters[editTransportBill]=${true}`

            if (filterArgs?.cancelledWithHsdTakenCheckbox)
                apiURL = apiURL + `&filters[Cancelled]=${true}`

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            apiURL = apiURL + `&populate=deep`;

            const payload = {
                data: {
                    loading_Id_Array: loadingIds
                }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static exportPendingLoadingBills = async (agencyId, isPagination = false, offset = 1, pageSize = 1, filterArgs = {}, sortOrder = null, loadingIds = []) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            const finalSortOrder = ((sortOrder && sortOrder !== '') ? sortOrder : 'Date_of_Loading:asc')

            let apiURL = new AppHelper().API_URL + `/api/get-all-pending-loadings-by-ids?sort=${finalSortOrder}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            apiURL = apiURL + `&populate=deep`;

            const payload = {
                data: {
                    loading_Id_Array: loadingIds
                }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }


    static exportPendingUnloadingBills = async (agencyId, isPagination = false, offset = 1, pageSize = 1, filterArgs = {}, sortOrder = null, loadingIds = []) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            const finalSortOrder = ((sortOrder && sortOrder !== '') ? sortOrder : 'Date_of_Unloading:desc')

            let apiURL = new AppHelper().API_URL + `/api/get-all-pending-unloadings-by-ids?sort=${finalSortOrder}`;

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && Number(agencyId) !== "")
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            apiURL = apiURL + `&populate=deep`;

            const payload = {
                data: { loading_Id_Array: loadingIds }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getAllMatchedClientBillRecords = async (sortOrder, filterArgs = {}) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-client-bill-pending-matched-records?sort=${sortOrder}`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues.projectGlobal}`;

            // filtration starts
            if (filterArgs?.Challan_Number)
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_Number}`

            if (filterArgs?.Date) {
                const breakDates = filterArgs?.Date.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            if (filterArgs?.Thermal_Plant_Book_no && filterArgs?.Thermal_Plant_Book_no !== "")
                apiURL = apiURL + `&filters[Thermal_Plant_Book_no][$containsi]=${filterArgs?.Thermal_Plant_Book_no}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$eq]=${filterArgs?.Kanta_Slip_No}`

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getAgenciesByProject = async (projectId) => {
        try {
            const agencyDetails = await axios.get(new AppHelper().API_URL + `/api/agencies?_limit=1000&populate=*&filters[Project][id]=${projectId}`);
            return agencyDetails?.data?.data;
        } catch (error) {
            return error;
        }
    }

    static findAgencyIdByNameProject = (agenciesArr, agency, project) => {
        return agency ? agenciesArr.find((item) => item?.attributes?.Company_name.toLowerCase() === agency.toLowerCase() && item?.attributes?.Project?.data?.id === project) : []
    }

    static makePetrolPumpRatesDescendingOrder = (petrolPumpsArr = []) => {
        return petrolPumpsArr?.length > 0 ? petrolPumpsArr.sort((firstItem, secondItem) => new Date(secondItem?.Start_Date) - new Date(firstItem?.Start_Date)) : []
    }

    static findPetrolPumpRatebyDateAndPetrolPumpValue = (date, petrolPumpsRateArray) => {
        let finalRate = 0
        if (date === "" || petrolPumpsRateArray.length === 0) return finalRate
        for (let index = 0; index < petrolPumpsRateArray.length; index++) {
            if (new Date(petrolPumpsRateArray[index]['Start_Date']) <= new Date(date)) {
                finalRate = ((petrolPumpsRateArray[index]['Rate'] && petrolPumpsRateArray[index]['Rate'] !== "") ? ((AppHelper.checkIsFloat(petrolPumpsRateArray[index]['Rate'])) ? parseFloat(petrolPumpsRateArray[index]['Rate'].toFixed(2)) : parseInt(petrolPumpsRateArray[index]['Rate'])) : 0)
                break
            }
        }
        return finalRate
    }

    static checkIsFloat = (value) => {
        return value % 1 !== 0
    }

    static checkIsInteger = (value) => {
        return value % 1 === 0
    }

    static getWorkDetailChartInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-work-details`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getWorkInProgressWidgetInformation = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-work-in-progress-widget-info`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getBillWiseTripCountWithProject = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-bill-wise-trip-and-cum-details`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getEffectiveRatesSectionInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-effective-rates-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getWeightSectionInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-weight-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getExpensesInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-expenses-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getDieselInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-diesel-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getPetrolPumpInfoDashboard = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-petrol-pump-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getTripFetailsInformation = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-trip-details-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getAgenciesVehiclesCountInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-agencies-petrol-count-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getAvgPerDayInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-avg-per-day-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getTripAggregationInfo = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-trip-aggregation-information`;

            apiURL = apiURL + `?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }
    static getUnloadingWithoutPagination = async (projectId, offset, pageSize) => {
        try {
            let apiURL = new AppHelper().API_URL + `/api/get-unloading-locations?filters[Project][id][$eq]=${projectId}`;
            const unloading = await axios.get(apiURL);
            const filteredUnloadingData = unloading?.data?.data?.map((item) => {
                return item?.attributes?.Unloading_Location;
            })
            return filteredUnloadingData;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getUnloadingLocationWithoutPagination = (location) => {
        let output = [];
        let uniqueNames = new Set(); // Using a Set to store unique names
        if (location?.length > 0) {
            for (let index = 0; index < location?.length; index++) {
                let locationName = location[index];
                if (!uniqueNames.has(locationName)) { // Checking if the name is already added
                    output.push({
                        value: locationName,
                        label: locationName,
                    });
                    uniqueNames.add(locationName); // Adding the name to the Set
                }
            }
        }
        return output;
    }

    static async deleteUnloadingById(unloadingId) {
        const endpoint = `/api/unloadings/${unloadingId}`;
        try {
            // Assuming axios.delete returns the response
            const response = await axios.delete(
                new AppHelper().API_URL + endpoint
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static async deleteHSDDetailsById(hsdId) {
        const endpoint = `/api/hsd-details/${hsdId}`;
        try {
            // Assuming axios.delete returns the response
            const response = await axios.delete(
                new AppHelper().API_URL + endpoint
            );
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static async loadSummaryUnBilledResults(agencyType, agencyId = null) {

        const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

        let endpoint = `/api/get-summary-unbilled-results?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&filters[agencyType]=${agencyType}`;

        if (agencyId && agencyId !== "")
            endpoint += `&filters[agencyId]=${agencyId}`
        try {
            // Assuming axios.delete returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static async loadSummaryBilledResults(agencyType, agencyId = null) {

        const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

        let endpoint = `/api/get-summary-billed-results?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}&filters[agencyType]=${agencyType}`;

        if (agencyId && agencyId !== "")
            endpoint += `&filters[agencyId]=${agencyId}`

        try {
            // Assuming axios.delete returns the response
            const response = await axios.get(new AppHelper().API_URL + endpoint);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static makeFirstLetterCapitalOfString(string) {
        return string[0].toUpperCase() + string.slice(1).toLowerCase();
    }

    static exportEditPendingTransportBills = async (sortOrder, isPagination, offset, pageSize, filterArgs = {}, selectedRecordIds = [], billId = null, isSelectedLoadingIdsChanged = false) => {
        try {

            let activeSortOrder = ((sortOrder && sortOrder !== "") ? sortOrder : 'Bill_Creation_Date:asc')

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/export-transport-edit-pending-bills?sort=${activeSortOrder}`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (isSelectedLoadingIdsChanged && isSelectedLoadingIdsChanged !== "")
                apiURL = apiURL + `&filters[isSelectedLoadingIdsChanged]=${isSelectedLoadingIdsChanged}`;

            if (billId && billId !== "")
                apiURL = apiURL + `&filters[billId]=${billId}`;

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const payload = {
                data: {
                    loading_Id_Array: selectedRecordIds
                }
            }

            const agencies = await axios.post(apiURL, payload);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static exportEditPendingLoadingBills = async (sortOrder, isPagination, offset, pageSize, filterArgs = {}, selectedRecordIds = [], billId = null, agencyId = null, isSelectedLoadingIdsChanged = false) => {
        try {

            let activeSortOrder = ((sortOrder && sortOrder !== "") ? sortOrder : 'Loading_Bill_Creation_Date:asc')

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/export-loading-edit-pending-bills?sort=${activeSortOrder}`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (billId && billId !== "")
                apiURL = apiURL + `&filters[billId]=${billId}`;

            if (isSelectedLoadingIdsChanged && isSelectedLoadingIdsChanged !== "")
                apiURL = apiURL + `&filters[isSelectedLoadingIdsChanged]=${isSelectedLoadingIdsChanged}`;

            if (agencyId && agencyId !== "")
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`;

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const payload = {
                data: {
                    loading_Id_Array: selectedRecordIds
                }
            }

            const agencies = await axios.post(apiURL, payload);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static exportEditPendingUnloadingBills = async (sortOrder, isPagination, offset, pageSize, filterArgs = {}, selectedRecordIds = [], billId = null, agencyId = null, isSelectedLoadingIdsChanged = false) => {
        try {

            let activeSortOrder = ((sortOrder && sortOrder !== "") ? sortOrder : 'Unloading_Bill_Creation_Date:asc')

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/export-unloading-edit-pending-bills?sort=${activeSortOrder}`;

            apiURL = apiURL + `&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (isSelectedLoadingIdsChanged && isSelectedLoadingIdsChanged !== '')
                apiURL = apiURL + `&filters[isSelectedLoadingIdsChanged]=${isSelectedLoadingIdsChanged}`;

            if (billId && billId !== "")
                apiURL = apiURL + `&filters[billId]=${billId}`;

            if (agencyId && agencyId !== "")
                apiURL = apiURL + `&filters[agency_id]=${agencyId}`;

            // filter starts from here
            if (filterArgs?.Challan_No && filterArgs?.Challan_No !== "")
                apiURL = apiURL + `&filters[Challan_Number][$eq]=${filterArgs?.Challan_No}`


            if (filterArgs?.D_O_No && filterArgs?.D_O_No !== "")
                apiURL = apiURL + `&filters[DO_No][$containsi]=${filterArgs?.D_O_No}`

            if (filterArgs?.Kanta_Slip_No && filterArgs?.Kanta_Slip_No !== "")
                apiURL = apiURL + `&filters[Kanta_Slip_No][$containsi]=${filterArgs?.Kanta_Slip_No}`

            if (filterArgs?.Date_of_Loading) {
                const breakDates = filterArgs?.Date_of_Loading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Loading][$lte]=${endDate}`
            }

            if (filterArgs?.Date_of_Unloading) {
                const breakDates = filterArgs?.Date_of_Unloading.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Unloading][$lte]=${endDate}`
            }

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");
            apiURL = apiURL + `&populate=*`;

            const payload = {
                data: {
                    loading_Id_Array: selectedRecordIds
                }
            }

            const agencies = await axios.post(apiURL, payload);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static compareTwoArrays = (firstArray = [], secondArray = []) => {
        return firstArray.filter(item => !secondArray.includes(item));
    }

    static getMissingChallans = async () => {
        try {

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/get-missing-challans?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static validateVehicleNo = async (vehicleNo = '') => {

        if (!vehicleNo || vehicleNo === "") return {}

        try {

            // check if vehicle no already present and vehicle information is present then need to show details from saved details

            const vehicleAPIURL = new AppHelper().API_URL + `/api/vehicles?filters[Vehicle_Number][$containsi]=${vehicleNo}`

            const vehicleResponse = await axios.get(vehicleAPIURL);

            if (vehicleResponse?.data?.data?.attributes?.results?.length === 0 || (!vehicleResponse?.data?.data?.attributes?.results[0]?.Vehicle_Information || Object.keys(vehicleResponse?.data?.data?.attributes?.results[0]?.Vehicle_Information).length === 0)) {

                const apiURL = `https://api-gate.finhub.habilelabs.io/s1/get-vehicle-details`

                const payload = {
                    vehicleNumber: vehicleNo
                }
                const headers = {
                    Api_key: new AppHelper().Vehicle_API_KEY,
                    Api_secret: new AppHelper().Vehicle_API_KEY_SECRET
                }

                const response = await axios.post(apiURL, payload, {
                    headers: headers
                });

                return response?.data;
            } else {
                return vehicleResponse?.data?.data?.attributes?.results?.[0]?.Vehicle_Information;
            }
        } catch (error) {
            console.log(error, 'error');
            return error?.response?.data;
        }
    }

    static convertStringToLabel = (string) => {

        if (!string || string === "") return ""

        const convertFromCamelCaseToWords = string.replace(/[A-Z]/g, m => ` ${m.toLowerCase()}`)

        const finalStringAsLabel = convertFromCamelCaseToWords.replace(/(^\w|\s\w)/g, m => m.toUpperCase())

        return finalStringAsLabel
    }

    static getFunnelChartInfo = async (agencyType) => {

        if (!agencyType || agencyType === '') return {}

        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/work-done-funnel-chart-info?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const payload = {
                data: {
                    agencyType: agencyType
                }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getFunnelChartAverageWorkInfo = async (agencyType) => {

        if (!agencyType || agencyType === '') return {}

        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/average-work-done-funnel-chart-info?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const payload = {
                data: {
                    agencyType: agencyType
                }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getTransportationBarChartInfo = async () => {

        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/transportation-bar-chart-info?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getLast15DaysAvgTripAndAvgWeightLineChart = async (agencyId = null) => {

        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/last-fifteen-days-trip-count-avg-weight-info?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyId && agencyId !== "")
                apiURL = apiURL + `&filters[Transporting_Agency][id][$eq]=${agencyId}`

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getTimelineChartInfo = async () => {

        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/get-timeline-chart-info?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static getProjectDetails = async () => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'));

            let apiURL = new AppHelper().API_URL + `/api/projects/${globalTpProjectValues?.projectGlobal}`;

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data;
        }
    }

    static ledgerPaymentDetails = async (sortOrder, isPagination = false, offset = 1, pageSize, filterArgs = {}) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            const sortOrderValue = sortOrder && sortOrder !== "" ? sortOrder : 'date:asc'

            let apiURL = new AppHelper().API_URL + `/api/get-ledger-payment-details?sort=${sortOrderValue}&filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (filterArgs?.Agency)
                apiURL = apiURL + `&filters[Agency][id][$eq]=${filterArgs?.Agency}`

            if (filterArgs?.Date_of_Payment) {
                const breakDates = filterArgs?.Date_of_Payment.split("~")
                const startDate = breakDates[0]
                const endDate = breakDates[1]
                if (startDate)
                    apiURL = apiURL + `&filters[Date_of_Payment][$gte]=${startDate}`
                if (endDate)
                    apiURL = apiURL + `&filters[Date_of_Payment][$lte]=${endDate}`
            }

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getPaymentSummaryDetails = async (hardCopyReceived = false) => {
        try {
            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/get-payment-summary-details?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (hardCopyReceived)
                apiURL = apiURL + `&filters[Hard_Copy_Received]=${hardCopyReceived}`

            const agencies = await axios.get(apiURL);
            return agencies?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static allowMaxValuesWeight = (e) => {
        const weightVal = e.target.value
        if (weightVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    static savePayment = async (payloadData) => {
        try {

            let apiURL = new AppHelper().API_URL + `/api/save-payment-details`;

            const payload = {
                data: {
                    payloadData
                }
            }
            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static deletePayment = async (recordId) => {
        try {

            let apiURL = new AppHelper().API_URL + `/api/delete-payment-details?filters[id][$eq]=${recordId}`;
            const response = await axios.delete(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static updatePayment = async (payloadData) => {
        try {

            let apiURL = new AppHelper().API_URL + `/api/update-payment-details`;

            const payload = {
                data: {
                    payloadData
                }
            }
            const response = await axios.put(apiURL, payload);
            return response?.data;
        } catch (error) {

            return error?.response?.data
        }
    }

    static getBilledRecordsBilledDetails = async (isPagination = true, offset, pageSize, agencyType = 'loading', agencyId = null) => {
        try {

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/payment-billed-details?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyType && agencyType !== "")
                apiURL += `&filters[Agency][Agency_type][$eq]=${agencyType}`

            if (agencyId && agencyId !== "")
                apiURL += `&filters[Agency][id][$eq]=${agencyId}`

            apiURL = apiURL + (isPagination === true ? `&pagination[withCount]=true&pagination[page]=${offset}&pagination[pageSize]=${pageSize}` : "");

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static getUnBilledRecordsBilledDetails = async (agencyType = 'loading', agencyId = null) => {
        try {

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/payment-unbilled-details?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            if (agencyType && agencyType !== "")
                apiURL += `&filters[Agency][Agency_type][$eq]=${agencyType}`

            if (agencyId && agencyId !== "")
                apiURL += `&filters[Agency][id][$eq]=${agencyId}`

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static saveWithHeldGST = async (recordIds = [], agencyType = 'loading', agencyId = null) => {
        try {

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/save-with-held-gst?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            apiURL += `&filters[Agency][Agency_type][$eq]=${agencyType}&filters[Agency][id][$eq]=${agencyId}`

            const payload = {
                data: {
                    recordIds: recordIds
                }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static saveHardCopyReceivedTransport = async (recordIds = [], agencyType = 'loading', agencyId = null) => {
        try {

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/save-hard-copy-received?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            apiURL += `&filters[Agency][Agency_type][$eq]=${agencyType}&filters[Agency][id][$eq]=${agencyId}`

            const payload = {
                data: {
                    recordIds: recordIds
                }
            }

            const response = await axios.post(apiURL, payload);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

    static fetchWidgetContentInfo = async (agencyId = null, filterArgs = {}) => {
        try {

            const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))

            let apiURL = new AppHelper().API_URL + `/api/billed-detail-widget-info?filters[Project][id][$eq]=${globalTpProjectValues?.projectGlobal}`;

            apiURL += `&filters[Agency][id][$eq]=${agencyId}`

            if (filterArgs?.Hard_Copy_Received && filterArgs?.Hard_Copy_Received !== '')
                apiURL += `&filters[Hard_Copy_Received]=${filterArgs?.Hard_Copy_Received}`

            if (filterArgs?.With_Held_GST && filterArgs?.With_Held_GST !== '')
                apiURL += `&filters[With_Held_GST]=${filterArgs?.With_Held_GST}`

            const response = await axios.get(apiURL);
            return response?.data;
        } catch (error) {
            return error?.response?.data
        }
    }

}

export default AppHelper;