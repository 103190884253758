import React, { useState, useEffect } from "react";
import { Typography, Grid, Button } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classes from "./ViewPetrolPumpData.module.scss";
import { Card, Form, Input, Space } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";

export const ViewPetrolPumpData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const loadId = params?.id ?? "";
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [petrolPumpDetails, setPetrolPumpDetails] = useState("");
    const [petrolPumpRateDetails, setPetrolPumpRateDetails] = useState([]);

    const backToPetrolPumpList = (e) => {
        e.preventDefault();
        navigate(`/petrol-pumps?currentPage=${pageNo}&pageSize=${pageSize}`);
    };

    const getPetrolPumpDetails = async () => {
        try {
            const response = await AppHelper.getPetrolPumpById(loadId);
            setPetrolPumpDetails(response?.data);
            const sortedPetrolPumpRateArray = response?.data?.attributes?.Rate.sort((firstItem, secondItem) => new Date(firstItem?.Start_Date) - new Date(secondItem?.Start_Date))
            setPetrolPumpRateDetails(sortedPetrolPumpRateArray)
            // Handle the response as needed
        } catch (error) {
            // Handle errors if any
            console.error("Error fetching petrol pump details:", error);
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };

    useEffect(() => {
        document.title = "View Petrol Pump"
        getPetrolPumpDetails();
    }, []);

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["edit-petrol-pump-padding"]}
            >
                <Grid item lg={9.5} md={8} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        View Petrol Pump Details
                    </Typography>
                </Grid>
                <Grid item lg={2.5} md={4} xs={12} container className={classes['action-button']}>
                    <Button
                        size="small"
                        variant="contained"
                        onClick={backToPetrolPumpList}
                        className={classes["add-new-entry"]}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Petrol Pumps
                    </Button>
                </Grid>
            </Grid>
            <div className={classes["agency-details-margin"]}>
                <Form
                    layout="vertical"
                    initialValues={{
                        items: [{}],
                    }}
                    wrapperCol={{
                        span: 24,
                    }}
                >
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item messageVariables="" label="Petrol Pump Name">
                                <Input
                                    readOnly
                                    value={petrolPumpDetails?.attributes?.Petrolpump_Name ?? ""}
                                    name="Petrolpump_Name"

                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Contact Person">
                                <Input
                                    readOnly
                                    value={petrolPumpDetails?.attributes?.Contact_person ?? ""}
                                    name="Contact_person"
                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item
                                messageVariables=""
                                label="Remarks"
                            >
                                <Input
                                    readOnly
                                    value={petrolPumpDetails?.attributes?.Remarks ?? ""}
                                />
                            </Form.Item>
                            <Form.Item messageVariables="" label="Contact Number">
                                <Input
                                    readOnly
                                    value={petrolPumpDetails?.attributes?.Contact_Number ?? ""}
                                    name="Contact_Number"
                                />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={12} md={12} xs={12}>
                            <Form.Item messageVariables="" label="Location">
                                <Input
                                    readOnly
                                    value={petrolPumpDetails?.attributes?.Location ?? ""}
                                    name="Location"
                                />
                            </Form.Item>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <Form.List name="items">
                                {(fields, { add, remove }) => (
                                    <div
                                        style={{
                                            display: "flex",
                                            rowGap: 16,
                                            flexDirection: "column",
                                            width: '100%'
                                        }}
                                    >
                                        {fields.map((field) => (
                                            <Card title="Rates" key={field.key}>
                                                {/* Nest Form.List */}
                                                <Form.Item>
                                                    <Form.List>
                                                        {(subFields, subOpt) => (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    rowGap: 16,
                                                                    flexDirection: "column",
                                                                    padding: 18,
                                                                }}
                                                            >
                                                                {petrolPumpRateDetails?.map(
                                                                    (rate, index) => (
                                                                        <Space key={index}>
                                                                            <Form.Item label="Start Date">
                                                                                <Input
                                                                                    size="large"
                                                                                    readOnly
                                                                                    value={formatDate(rate.Start_Date ?? "")}
                                                                                    name={`Start_Date_${index}`}
                                                                                />
                                                                            </Form.Item>
                                                                            <Form.Item label="Rate">
                                                                                <Input
                                                                                    size="large"
                                                                                    readOnly
                                                                                    value={rate.Rate ?? ""}
                                                                                    name={`Rate_${index}`}
                                                                                />
                                                                            </Form.Item>
                                                                        </Space>
                                                                    )
                                                                )}
                                                            </div>
                                                        )}
                                                    </Form.List>
                                                </Form.Item>
                                            </Card>
                                        ))}
                                    </div>
                                )}
                            </Form.List>
                        </Grid>
                    </Grid>
                </Form>
            </div>
        </>
    );
};
