import React from "react";
import classes from "./BreadCrumbLoading.module.scss";
import { Button } from "@mui/material";
import pendingActiveIcon from "../../../../assets/images/BillsIcons/pending-bill-white.svg";
import pendingInActiveIcon from "../../../../assets/images/BillsIcons/Pending.svg";
import generateBillActiveIcon from "../../../../assets/images/BillsIcons/generate-bill-white.svg";
import generateBillInActiveIcon from "../../../../assets/images/BillsIcons/Generate.svg";
import FinalCalculationActiveIcon from "../../../../assets/images/BillsIcons/Final-white.svg";
import FinalCalculationInActiveIcon from "../../../../assets/images/BillsIcons/Final-calculation.svg";
import { useNavigate } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";

export const BreadCrumbLoading = ({ activeButton, page, params }) => {
  const navigate = useNavigate();
  const loading_pending_bills_selected_agency = AppHelper.decryptText(sessionStorage.getItem('loading_pending_bills_to_generate_ids'))
  const loading_edit_pending_bills_selected_agency = AppHelper.decryptText(sessionStorage.getItem('loading_edit_pending_bills_to_generate_ids'))
  const generateBillObject = AppHelper.decryptText(sessionStorage.getItem('generateLoadingBillObject'))
  const generateBillEditObject = AppHelper.decryptText(sessionStorage.getItem('generateEditLoadingBillObject'))
  const redirectToPendingBillScreen = () => {
    if (page === "loading") {
      navigate("/bills/loading/pending-loading-bills");
      return false;
    } else if (page === "loadingEdit") {
      navigate(`/bills/loading/edit-loading-bill/${params?.id}`);
      return false;
    }
  };

  const redirectToGenerateBillScreen = () => {
    if (page === "loading") {
      navigate("/bills/loading/generate-bill");
      return false;
    } else if (page === "loadingEdit") {
      navigate(`/bills/loading/edit-loading-bill-generate/${params?.id}`);
      return false;
    }
  };

  const redirectToFinalCalculationScreen = () => {
    if (page === "loading") {
      navigate("/bills/loading/final-calculation");
      return false;
    } else if (page === "loadingEdit") {
      navigate(`/bills/loading/edit-loading-bill-final-calculation/${params?.id}`);
      return false;
    }
  };
  return (
    <>
      <ul className={classes["breadcrumb_buttons"]}>
        <li>
          <Button
            type="primary"
            className={`${classes["pending_bills_icon"]} ${activeButton === "pending_bills"
              ? classes["button_active"]
              : classes["button_inactive"]
              }`}
            onClick={
              activeButton === "pending_bills"
                ||
                (
                  (loading_pending_bills_selected_agency === "" && page === 'loading') ||
                  (loading_edit_pending_bills_selected_agency === "" && page === 'loadingEdit')
                )
                ? undefined
                : redirectToPendingBillScreen
            }
          >
            <img
              src={
                activeButton === "pending_bills"
                  ? pendingActiveIcon
                  : pendingInActiveIcon
              }
              alt="Pending Bills Screen"
            />
            Pending
          </Button>
        </li>
        <li>
          <Button
            type="primary"
            className={`${classes["generate_bills_icon"]} ${classes["step_button"]
              } ${activeButton === "generate_bill"
                ? classes["button_active"]
                : classes["button_inactive"]
              }`}
            onClick={
              activeButton === "generate_bill"
                ||
                (
                  (loading_pending_bills_selected_agency === "" && page === 'loading') ||
                  (loading_edit_pending_bills_selected_agency === "" && page === 'loadingEdit')
                )
                ? undefined
                : redirectToGenerateBillScreen
            }
          >
            <img
              src={
                activeButton === "generate_bill"
                  ? generateBillActiveIcon
                  : generateBillInActiveIcon
              }
              alt="Generate Bills Screen"
            />
            Generate
          </Button>
        </li>
        <li>
          <Button
            type="primary"
            className={`${classes["final_calculation_bills_icon"]} ${classes["step_button"]
              } ${activeButton === "final_calculation"
                ? classes["button_active"]
                : classes["button_inactive"]
              }`}
            onClick={
              activeButton === "final_calculation"
                ||
                (
                  (generateBillObject === "" && page === 'unloading') ||
                  (generateBillEditObject === "" && page === 'unloadingEdit')
                )
                ? undefined
                : redirectToFinalCalculationScreen
            }
          >
            <img
              src={
                activeButton === "final_calculation"
                  ? FinalCalculationActiveIcon
                  : FinalCalculationInActiveIcon
              }
              alt="Final Calculation Screen"
            />
            Final Calculation
          </Button>
        </li>
      </ul>
    </>
  );
};
