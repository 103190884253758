import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, CloseOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { Form, Input, Space, Card, DatePicker, notification, Modal } from "antd";
import classes from "./CreatePetrolPump.module.scss";
import AppHelper from "../../../../helpers/AppHelper";

export const CreatePetrolPump = () => {
  const navigate = new useNavigate();
  const [createPetrolPumpForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const dateFormat = "DD-MM-YYYY";
  const [validationPetrolPumpRates, setValidationPetrolPumpRates] = useState([])
  const [validationModelOpen, setValidationModelOpen] = useState(false)
  const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);

  const formatDate = (dateString) => {
    if (!dateString) return '-';
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const handleOk = () => setValidationModelOpen(false)

  const validatePetrolPumpRates = (ratesArray) => {
    if (!ratesArray || ratesArray.length === 0) return false

    let errorsArray = []
    for (let index = 0; index < ratesArray.length; index++) {
      if ((!ratesArray[index]['Start_Date'] || ratesArray[index]['Start_Date'] === "") && (!ratesArray[index]['Rate'] || ratesArray[index]['Rate'] === "")) {
        errorsArray.push(`Row no ${index + 1} - Please provide both the start date and rate.`)
      } else if ((!ratesArray[index]['Start_Date'] || ratesArray[index]['Start_Date'] === "") && (ratesArray[index]['Rate'] && ratesArray[index]['Rate'] !== "")) {
        errorsArray.push(`Row no ${index + 1} - Please provide both the start date and rate.`)
      } else if ((ratesArray[index]['Start_Date'] && ratesArray[index]['Start_Date'] !== "") && (!ratesArray[index]['Rate'] || ratesArray[index]['Rate'] === "")) {
        errorsArray.push(`Row no ${index + 1} - Please provide both the start date and rate.`)
      }
    }
    if (errorsArray.length > 0) {
      setValidationModelOpen(true);
      setValidationPetrolPumpRates(errorsArray)
    } else {
      setValidationModelOpen(false);
      setValidationPetrolPumpRates([])
    }
    return errorsArray;
  }

  const preventNegativeValue = (e) => {
      const rateVal = e.target.value
      if (e.code === 'Minus' || rateVal.toString().length > 6) {
          e.preventDefault();
      }
  }

  const checkRateForInputtedDateAlreadyExist = (ratesArray) => {
    if (!ratesArray.length) return false
    let duplicatesArray = []
    for (let index = 0; index < ratesArray.length; index++) {
      if (!duplicatesArray.includes(ratesArray[index]['Start_Date'])) {
        duplicatesArray.push(ratesArray[index]['Start_Date']);
      } else {
        return ratesArray[index]['Start_Date']
      }
    }
    return false
  }

  const onFinish = async (values) => {
    setIsSubmittingResponse(true);
    let formValues = { ...values };
    let rateElementArray = [];

    //validate each and every petrol pump rate row and throw error for any invalid data
    const validationRateErrors = validatePetrolPumpRates(values?.items?.[0]?.list);
    if (validationRateErrors.length === 0) {
      values?.items?.[0]?.list.map((childItem, childIndex) => (
        childItem?.Start_Date || childItem?.Rate ?
          rateElementArray = [
            ...rateElementArray,
            {
              Start_Date: `${childItem?.Start_Date?.$y}-${(childItem?.Start_Date?.$M + 1).toString().padStart(2, 0)}-${(childItem?.Start_Date?.$D).toString().padStart(2, 0)}`,
              Rate: childItem?.Rate,
            },
          ]
          :
          "")
      );

      if (rateElementArray.length > 0) {
        formValues = { ...formValues, "Rate": rateElementArray };
      }

      let duplicateRateExistsForDate = checkRateForInputtedDateAlreadyExist(rateElementArray)
      if (duplicateRateExistsForDate) {
        notification.error({
          message: "Error",
          description: `Rate for Start Date ${formatDate(duplicateRateExistsForDate)} already exists`,
          duration: 3,
        });
        return false
      }

      setLoading(true);

      const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
      formValues['Thermal_Plant'] = { 'id': globalTpProjectValues ? globalTpProjectValues.thermalPlantGlobal : null }
      const response = await AppHelper.postPetrolPump(formValues);
      if (response?.error) {
        notification.error({
          message: `Error: `,
          description: response?.error?.message,
          duration: 1.5,
        });
      } else {
        notification.success({
          message: "Success",
          description: "Petrol Pump created successfully.",
          duration: 1.5,
        });
        navigate("/petrol-pumps");
      }
    }
    setIsSubmittingResponse(false);
  };



  const backToPetrolPumpList = (e) => {
    e.preventDefault();
    navigate("/petrol-pumps");
  };

  useEffect(() => {
    document.title = "Create Petrol Pump"
  }, []);

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["create-petrol-pump-padding"]}
      >
        <Grid item lg={10} md={9} xs={12}>
          <Typography
            variant="h1"
            fontSize="1.5rem"
            fontWeight="bold"
            className="title-un-loading-text"
          >
            Create Petrol Pump
          </Typography>
        </Grid>
        <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
          <UIButton
            size="small"
            variant="contained"
            onClick={backToPetrolPumpList}
            className={classes["add-new-entry"]}
          >
            <ArrowLeftOutlined />
            &nbsp;Petrol Pumps
          </UIButton>
        </Grid>
      </Grid>

      <div className={classes["agency-details-margin"]}>
        <Form
          layout="vertical"
          initialValues={{
            items: [{}],
          }}
          wrapperCol={{
            span: 24,
          }}
          form={createPetrolPumpForm}
          onFinish={onFinish}
        >
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
          >
            <Grid item lg={6} md={6} xs={12}>
              <Form.Item
                label="Petrol Pump Name"
                name="Petrolpump_Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Petrol Pump Name.",
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="Contact_person" label="Contact Person">
                <Input />
              </Form.Item>
            </Grid>
            <Grid item lg={6} md={6} xs={12}>
              <Form.Item
                name="Contact_Number"
                label="Contact Number"
                rules={[
                  {
                    min: 10,
                    message: 'Contact No should be of 10 digits only'
                  },
                  {
                    max: 10,
                    message: 'Contact No should be of 10 digits only'
                  }
                ]}
              >
                <Input type="number" />
              </Form.Item>
              <Form.Item label="Location" name="Location"
                rules={[
                  {
                    required: true,
                    message: "Please enter the Location.",
                  }
                ]}>
                <Input />
              </Form.Item>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Form.Item name="Remarks" label="Remarks" >
              <Input />
            </Form.Item>
          </Grid>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
          >
            <Grid item lg={12} md={12} xs={12}>
              <Form.List name="items">
                {(fields, { add, remove }) => (
                  <div
                    style={{
                      display: "flex",
                      rowGap: 16,
                      flexDirection: "column",
                      width: '100%'
                    }}
                  >
                    {fields.map((field) => (
                      <Card
                        title="Rates"
                        key={field.key}
                        style={{
                          width: '100%'
                        }}
                        extra={
                          <CloseOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        }
                      >
                        {/* Nest Form.List */}
                        <Form.Item>
                          <Form.List name={[field.name, "list"]} initialValue={[{}]} >
                            {(subFields, subOpt) => (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  rowGap: 16,
                                  padding: 18,
                                  width: '100%'
                                }}
                              >
                                {subFields.map((subField, index) => (
                                  <Space key={subField.key || index} direction="horizontal" size="middle">
                                    <Form.Item
                                      name={[subField.name, "Start_Date"]}
                                      label="Start Date"
                                    >
                                      <DatePicker size="large" format={dateFormat} />
                                    </Form.Item>
                                    <Form.Item
                                      name={[subField.name, "Rate"]}
                                      label="Rate"
                                    >
                                      <Input size="large" step={0.01} type="number" onKeyPress={preventNegativeValue} min={0}/>
                                    </Form.Item>
                                    <MinusCircleOutlined
                                      onClick={() => {
                                        subOpt.remove(subField.name);
                                      }}
                                    />
                                  </Space>
                                ))}
                                <UIButton
                                  className={classes["button-outline"]}
                                  onClick={() => subOpt.add()}
                                  block
                                >
                                  + Add More Item
                                </UIButton>
                              </div>
                            )}
                          </Form.List>
                        </Form.Item>
                      </Card>
                    ))}
                  </div>
                )}
              </Form.List>
            </Grid>
          </Grid>
          <div className={classes["submit-button-area"]}>
            <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
              Save
            </UIButton>
            <UIButton
              variant="outlined"
              color="error"
              onClick={backToPetrolPumpList}
            >
              Cancel
            </UIButton>
          </div>
        </Form>
      </div>

      {
        validationModelOpen === true &&
          validationPetrolPumpRates.length > 0
          ?
          <Modal
            open={validationModelOpen}
            centered
            className={classes['error-agency-modal']}
            title=""
            width={1024}
            onCancel={handleOk}
            footer=""
          >
            <>
              <h3 className={classes['error-agency-modal-header']}>Please check below errors in petrol pump</h3>
              <div className={classes['error-agency-excel-display-data']}>
                <table border={0} cellPadding={0} cellSpacing={0} width="100%" className={classes['agency-errors-table']}>
                  <thead>
                    <tr>
                      <th width="10%" className={classes['agency-errors-table-first-td']}>SL No.</th>
                      <th width="90%">Error</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      validationPetrolPumpRates.map((value, key) => {
                        return <tr key={key}>
                          <td className={classes['agency-errors-table-first-td']}>{key + 1}</td>
                          <td className={classes['error-message-row']}>{value}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            </>
          </Modal >
          :
          ""
      }
    </>
  );
};
