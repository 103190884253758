import React from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./PetrolPumpViewTransactionSummary.module.scss";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import billingPageImg from "../../../../assets/images/BillsIcons/billingpage.svg";
import petrolPumpBrownImg from "../../../../assets/images/BillsIcons/petrolpumpbigbrownimg.svg";

export const PetrolPumpViewTransactionSummary = () => {
  const navigate = useNavigate();

  const redirectBackToAllGeneratedBill = () => {
    navigate("/bills/petrol-pump/generated-petrol-pump-bill");
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="row"
        display="flex"
        justifyContent="flex-end"
        className={classes["transaction-summary-padding"]}
      >
        <UIButton
          className={classes["generate-new-entry"]}
          startIcon={<ArrowLeftOutlined />}
          onClick={redirectBackToAllGeneratedBill}
        >
          All Generated Bills
        </UIButton>
      </Grid>

      <Grid
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["transaction-summary-padding"]}
      >
        <Grid className={classes["transaction-summary-border"]}>
          <img
            src={petrolPumpBrownImg}
            alt=""
            className={classes["transaction-transport-img"]}
          />
          <Grid className={classes["first-boundary-transaction"]}>
            <Typography
              variant="body1"
              color="#fff"
              fontWeight="bold"
              letterSpacing="1px"
              fontSize="1.3rem"
            >
              Hire station station
            </Typography>
            <Typography
              variant="body1"
              color="#fff"
              display="flex"
              fontSize="1.3rem"
            >
              <img src={billingPageImg} alt="Billing Page" /> Bill No.:&nbsp;
              <strong>06</strong>
            </Typography>
          </Grid>
          <Grid
            className={classes["summary-list-padding"]}
            container
            spacing={3}
          >
            <Grid item lg={6} md={12} xs={12}>
              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Trip Count :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    98328984
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Total Excavator Count :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    438478
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes["work-type-object-second"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    HSD LTR In Vehicle :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    4873274
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    HSD LTR In Excavator :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    3123
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Total HSD LTR :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    3213
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes["work-type-object-second"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    HSD Effective Rate :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    32132
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    HSD Amount
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    32321
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item lg={6} md={12} xs={12}>
              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Billing Prefix :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    232132
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className={classes["work-type-object-second"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Date :
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    20/20/2024
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Deduction
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    321321
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes["work-type-object-first"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography
                    variant="body1"
                    className={classes["normal-para"]}
                  >
                    Remark
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="body1" className={classes["bold-para"]}>
                    321321
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={0}
                className={classes["work-type-object-third"]}
              >
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="h5" className={classes["normal-para"]}>
                    Total Payable Amount
                  </Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <Typography variant="h4" className={classes["bold-para"]}>
                    &#8377;3232
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
