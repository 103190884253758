import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { Form, notification, Select, Spin, Tabs } from "antd";
import classes from "./BilledDetail.module.scss";
import agencyImg from "../../../../assets/images/reportsummaryIcons/agencyimg.svg";
import { AllBillsBD } from "./AllBillsBD/AllBillsBD";
import { HardCopyReceivedBD } from "./HardCopyReceivedBD/HardCopyReceivedBD";
import { PendingHardCopyBD } from "./PendingHardCopyBD/PendingHardCopyBD";
import { WithHeldGstBD } from "./WithHeldGstBD/WithHeldGstBD";
import transportGrayImg from "../../../../assets/images/reportsummaryIcons/transportgray.svg";
import loadingGrayImg from "../../../../assets/images/reportsummaryIcons/loadinggray.svg";
import unloadingGrayImg from "../../../../assets/images/reportsummaryIcons/unloadinggray.svg";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import { TransporterBilled } from "./Transporter/TransporterBilled/TransporterBilled";
import { LoadingExcavatorBilled } from "./LoadingExcavator/LoadingExcavatorBilled/LoadingExcavatorBilled";
import { UnloadingExcavatorBilled } from "./UnloadingExcavator/UnloadingExcavatorBilled/UnloadingExcavatorBilled";

const { TabPane } = Tabs;

export const BilledDetail = () => {
    const [activeTab, setActiveTab] = useState("1");
    const [billUnbilledTab, setBillUnbilledTab] = useState("1");
    const [transporterActiveTab, setTransporterActiveTab] = useState("billed");
    const [agencies, setAgencies] = useState([])
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const sessionActivePaymentDetailSelectedAgency = AppHelper.decryptText(sessionStorage.getItem('paymentDetailSelectedAgency'))
    const [agencyDropDown, setAgencyDropDown] = useState([]);
    const [currentSelectedAgencyId, setCurrentSelectedAgencyId] = useState(null)

    const [billedRecordData, setBilledRecordData] = useState(false)
    const [loadingbilledRecordData, setLoadingBilledRecordData] = useState(false)
    const [unloadingbilledRecordData, setUnloadingBilledRecordData] = useState(false)

    const [widgetContentInfoAllBills, setWidgetContentInfoAllBills] = useState({})
    const [widgetContentInfoHardCopyReceived, setWidgetContentInfoHardCopyReceived] = useState({})
    const [widgetContentInfoPendingHardCopy, setWidgetContentInfoPendingHardCopy] = useState({})
    const [widgetContentInfoWithHeldGST, setWidgetContentInfoWithHeldGST] = useState({})
    const [transportLoadingBar, showTransportLoadingBar] = useState(true)
    const [loadingLoadingBar, showLoadingLoadingBar] = useState(true)
    const [unloadingLoadingBar, showUnloadingLoadingBar] = useState(true)
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE
    const [widgetLoadingBar, setWidgetLoadingBar] = useState(true)

    const AllBillTab = () => {
        return (
            <>
                <Typography variant="body1" className={classes["allbill-text"]}>
                    All Bills{" "}
                    <span
                        className={`${classes["allbill-span-text"]} ${activeTab === "1"
                            ? classes["all-bill-span-bg-color"]
                            : classes["inactive-tab-bg-color"]
                            }`}
                    >
                        {widgetContentInfoAllBills?.data?.attributes?.total_count_bill ?? 0}
                    </span>
                </Typography>
            </>
        );
    };

    const HardCopyReceivedTab = () => {
        return (
            <>
                <Typography variant="body1" className={classes["allbill-text"]}>
                    Hard Copy Received{" "}
                    <span
                        className={`${classes["allbill-span-text"]} ${activeTab === "2"
                            ? classes["all-bill-span-bg-color"]
                            : classes["inactive-tab-bg-color"]
                            }`}
                    >
                        {widgetContentInfoAllBills?.data?.attributes?.total_count_bill_hard_copy_received ?? 0}
                    </span>
                </Typography>
            </>
        );
    };

    const PendinghardCopyTab = () => {
        return (
            <>
                <Typography variant="body1" className={classes["allbill-text"]}>
                    Pending Hard Copy{" "}
                    <span
                        className={`${classes["allbill-span-text"]} ${activeTab === "3"
                            ? classes["all-bill-span-bg-color"]
                            : classes["inactive-tab-bg-color"]
                            }`}
                    >
                        {widgetContentInfoAllBills?.data?.attributes?.total_count_bill_hard_copy_pending ?? 0}
                    </span>
                </Typography>
            </>
        );
    };

    const WithHeldGstTab = () => {
        return (
            <>
                <Typography variant="body1" className={classes["allbill-text"]}>
                    With Held Gst{" "}
                    <span
                        className={`${classes["allbill-span-text"]} ${activeTab === "4"
                            ? classes["all-bill-span-bg-color"]
                            : classes["inactive-tab-bg-color"]
                            }`}
                    >
                        {widgetContentInfoAllBills?.data?.attributes?.total_count_bill_with_held_gst ?? 0}
                    </span>
                </Typography>
            </>
        );
    };

    const TabBarExtraContent = () => {
        return (
            <>
                <div className={classes["tab-bar-extra-content"]}>
                    <UIButton
                        className={
                            transporterActiveTab === "billed"
                                ? `${classes["btn-bg-color"]}`
                                : `${classes["btn-text-color"]}`
                        }
                        size="small"
                        onClick={() => setTransporterActiveTab("billed")}
                    >
                        Billed
                    </UIButton>
                    <UIButton
                        className={
                            transporterActiveTab === "unbilled"
                                ? `${classes["btn-bg-color"]}`
                                : `${classes["btn-text-color"]}`
                        }
                        size="small"
                        onClick={() => setTransporterActiveTab("unbilled")}
                    >
                        UnBilled
                    </UIButton>
                </div>
            </>
        );
    };

    const Transporter = () => {
        return (
            <>
                <div className={classes["flex-item-bill-unbilled"]}>
                    <img
                        src={transportGrayImg}
                        alt="transporter gray"
                        className={`${billUnbilledTab === "1"
                            ? classes["img-color-active"]
                            : classes["img-color-inactive"]
                            }`}
                    />
                    <Typography variant="body1" className={classes["allbill-text"]}>
                        Transporter &nbsp;&nbsp;
                        <span
                            className={`${classes["allbill-span-text"]} ${billUnbilledTab === "1"
                                ? classes["all-bill-span-bg-color"]
                                : classes["inactive-tab-bg-color"]
                                }`}
                        >
                            {billedRecordData?.data?.attributes?.pagination?.total ?? 0}
                        </span>
                    </Typography>
                </div>
            </>
        );
    };

    const LoadingExcavator = () => {
        return (
            <>
                <div className={classes["flex-item-bill-unbilled"]}>
                    <img
                        src={loadingGrayImg}
                        alt="transporter gray"
                        className={`${billUnbilledTab === "2"
                            ? classes["img-color-active"]
                            : classes["img-color-inactive"]
                            }`}
                    />
                    <Typography variant="body1" className={classes["allbill-text"]}>
                        Loading Excavator &nbsp;&nbsp;
                        <span
                            className={`${classes["allbill-span-text"]} ${billUnbilledTab === "2"
                                ? classes["all-bill-span-bg-color"]
                                : classes["inactive-tab-bg-color"]
                                }`}
                        >
                            {loadingbilledRecordData?.data?.attributes?.pagination?.total ?? 0}
                        </span>
                    </Typography>
                </div>
            </>
        );
    };

    const UnloadingExcavator = () => {
        return (
            <>
                <div className={classes["flex-item-bill-unbilled"]}>
                    <img
                        src={unloadingGrayImg}
                        alt="transporter gray"
                        className={`${billUnbilledTab === "3"
                            ? classes["img-color-active"]
                            : classes["img-color-inactive"]
                            }`}
                    />
                    <Typography variant="body1" className={classes["allbill-text"]}>
                        Unloading Excavator &nbsp;&nbsp;
                        <span
                            className={`${classes["allbill-span-text"]} ${billUnbilledTab === "3"
                                ? classes["all-bill-span-bg-color"]
                                : classes["inactive-tab-bg-color"]
                                }`}
                        >
                            {unloadingbilledRecordData?.data?.attributes?.pagination?.total ?? 0}
                        </span>
                    </Typography>
                </div>
            </>
        );
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleTabChange = (key) => setActiveTab(key);

    const handleBillUnbilledTabChange = (key) => setBillUnbilledTab(key);

    const handleAgencyChange = (e) => {
        const agency = findAgencyIdByName(agencies, e)
        setCurrentSelectedAgencyId(agency)
        sessionStorage.setItem('paymentDetailSelectedAgency', AppHelper.encryptText(e))
    }

    const findAgencyIdByName = (agenciesList, agencyName) => {
        const agency = agenciesList.find(agency => agency.attributes.Company_name === agencyName)
        return agency?.id
    }

    const fetchWidgetContentInfoAllBills = () => {
        setWidgetLoadingBar(true)
        AppHelper.fetchWidgetContentInfo(currentSelectedAgencyId).then(response => {
            setWidgetContentInfoAllBills(response)
            setWidgetLoadingBar(false)
        })
    }

    const fetchWidgetContentInfoHardCopyReceived = () => {
        const filterArgs = { Hard_Copy_Received: 'true' }
        setWidgetLoadingBar(true)
        AppHelper.fetchWidgetContentInfo(currentSelectedAgencyId, filterArgs).then(response => {
            setWidgetContentInfoHardCopyReceived(response)
            setWidgetLoadingBar(false)
        })
    }

    const fetchWidgetContentInfoPendingHardCopy = () => {
        const filterArgs = { Hard_Copy_Received: 'false' }
        setWidgetLoadingBar(true)
        AppHelper.fetchWidgetContentInfo(currentSelectedAgencyId, filterArgs).then(response => {
            setWidgetContentInfoPendingHardCopy(response)
            setWidgetLoadingBar(false)
        })
    }

    const fetchWidgetContentInfoWithHeldGST = () => {
        const filterArgs = { With_Held_GST: 'true' }
        setWidgetLoadingBar(true)
        AppHelper.fetchWidgetContentInfo(currentSelectedAgencyId, filterArgs).then(response => {
            setWidgetContentInfoWithHeldGST(response)
            setWidgetLoadingBar(false)
        })
    }

    const getBilledRecordsTransportDetails = (pagination, currentPage, pageSize, agencyType, agencyId) => {
        showTransportLoadingBar(true)
        AppHelper.getBilledRecordsBilledDetails(pagination, currentPage, pageSize, agencyType, agencyId).then(response => {
            setBilledRecordData(response)
            showTransportLoadingBar(false)
        }).catch(err => {
            showTransportLoadingBar(false)
            console.log(err)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const getBilledRecordsUnloading = (pagination, currentPage, pageSize, agencyType, agencyId) => {
        showUnloadingLoadingBar(true)
        AppHelper.getBilledRecordsBilledDetails(pagination, currentPage, pageSize, agencyType, agencyId).then(response => {
            setUnloadingBilledRecordData(response)
            showUnloadingLoadingBar(false)
        }).catch(err => {
            console.log(err)
            showUnloadingLoadingBar(false)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    const getBilledRecordsLoading = (pagination, currentPage, pageSize, agencyType, agencyId) => {
        showLoadingLoadingBar(true)
        AppHelper.getBilledRecordsBilledDetails(pagination, currentPage, pageSize, agencyType, agencyId).then(response => {
            setLoadingBilledRecordData(response)
            showLoadingLoadingBar(false)
        }).catch(err => {
            showLoadingLoadingBar(false)
            notification.error({
                message: "Error",
                description: err?.error?.message,
                duration: 3
            })
        })
    }

    useEffect(() => {
        if (currentSelectedAgencyId) {
            getBilledRecordsTransportDetails(true, 1, exportPageSize, 'transporting', currentSelectedAgencyId)
            getBilledRecordsLoading(true, 1, exportPageSize, 'loading', currentSelectedAgencyId)
            getBilledRecordsUnloading(true, 1, exportPageSize, 'unloading', currentSelectedAgencyId)
            fetchWidgetContentInfoAllBills()
            fetchWidgetContentInfoHardCopyReceived()
            fetchWidgetContentInfoPendingHardCopy()
            fetchWidgetContentInfoWithHeldGST()
        }
    }, [currentSelectedAgencyId])

    useEffect(() => {
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            if (!sessionActivePaymentDetailSelectedAgency || sessionActivePaymentDetailSelectedAgency === '') {
                const firstAgencyValue = response[0]?.attributes?.Company_name ?? null
                sessionStorage.setItem('paymentDetailSelectedAgency', AppHelper.encryptText(firstAgencyValue))
                setCurrentSelectedAgencyId(response[0]?.id)
            } else {
                const agency = findAgencyIdByName(response, sessionActivePaymentDetailSelectedAgency)
                setCurrentSelectedAgencyId(agency)
            }
            setAgencyDropDown(AppHelper.getAgencyDropDown(response, '', sessionGlobalValues));
        });
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["billed-detail-padding"]}
            >
                <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["agency-flex-select-bar"]}>
                            <img src={agencyImg} alt="Agency Image" />
                            <Typography variant="h5" color="initial" fontWeight="bold">
                                Agency
                            </Typography>
                            {
                                sessionActivePaymentDetailSelectedAgency && sessionActivePaymentDetailSelectedAgency !== ''
                                    ?
                                    <Form>
                                        <Select
                                            defaultValue={sessionActivePaymentDetailSelectedAgency ?? currentSelectedAgencyId}
                                            showSearch
                                            options={agencyDropDown}
                                            placeholder="All"
                                            onChange={handleAgencyChange}
                                            optionFilterProp="children"
                                            filterOption={filterOption}
                                            className={classes["select-new-entry"]}
                                        />
                                    </Form>
                                    :
                                    ""
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["billed-detail-padding"]}
            >
                {
                    widgetLoadingBar
                        ?
                        <div className={classes['summary-table-spin-element']}><Spin /></div>
                        :
                        <Tabs
                            activeKey={activeTab}
                            onChange={handleTabChange}
                            className={classes["tab-change-summary"]}
                            type="card"
                        >
                            <TabPane tab={<AllBillTab />} key="1">

                                <AllBillsBD widgetContentInfoAllBills={widgetContentInfoAllBills} />
                            </TabPane>
                            <TabPane tab={<HardCopyReceivedTab />} key="2">
                                <HardCopyReceivedBD widgetContentInfoHardCopyReceived={widgetContentInfoHardCopyReceived} />
                            </TabPane>
                            <TabPane tab={<PendinghardCopyTab />} key="3">
                                <PendingHardCopyBD widgetContentInfoPendingHardCopy={widgetContentInfoPendingHardCopy} />
                            </TabPane>
                            <TabPane tab={<WithHeldGstTab />} key="4">
                                <WithHeldGstBD widgetContentInfoWithHeldGST={widgetContentInfoWithHeldGST} />
                            </TabPane>
                        </Tabs>
                }
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["billed-detail-padding"]}
            >
                <Tabs
                    activeKey={billUnbilledTab}
                    onChange={handleBillUnbilledTabChange}
                    className={classes["tab-change-summary"]}
                    tabBarExtraContent={<TabBarExtraContent />}
                >
                    <TabPane key="1" tab={<Transporter />}>
                        {
                            transportLoadingBar
                                ?
                                <div className={classes['summary-table-spin-element']}><Spin /></div>
                                :
                                <TransporterBilled
                                    currentSelectedAgencyId={currentSelectedAgencyId}
                                    transporterActiveTab={transporterActiveTab}
                                    widgetContentInfoAllBills={widgetContentInfoAllBills}
                                    widgetContentInfoHardCopyReceived={widgetContentInfoHardCopyReceived}
                                    widgetContentInfoPendingHardCopy={widgetContentInfoPendingHardCopy}
                                    widgetContentInfoWithHeldGST={widgetContentInfoWithHeldGST}
                                    billedRecordData={billedRecordData}
                                    fetchWidgetContentInfoAllBills={fetchWidgetContentInfoAllBills}
                                    fetchWidgetContentInfoHardCopyReceived={fetchWidgetContentInfoHardCopyReceived}
                                    fetchWidgetContentInfoPendingHardCopy={fetchWidgetContentInfoPendingHardCopy}
                                    fetchWidgetContentInfoWithHeldGST={fetchWidgetContentInfoWithHeldGST}
                                />
                        }
                    </TabPane>
                    <TabPane key="2" tab={<LoadingExcavator />}>

                        <LoadingExcavatorBilled
                            currentSelectedAgencyId={currentSelectedAgencyId}
                            transporterActiveTab={transporterActiveTab}
                            widgetContentInfoAllBills={widgetContentInfoAllBills}
                            widgetContentInfoHardCopyReceived={widgetContentInfoHardCopyReceived}
                            widgetContentInfoPendingHardCopy={widgetContentInfoPendingHardCopy}
                            widgetContentInfoWithHeldGST={widgetContentInfoWithHeldGST}
                            loadingbilledRecordData={loadingbilledRecordData}
                            fetchWidgetContentInfoAllBills={fetchWidgetContentInfoAllBills}
                            fetchWidgetContentInfoHardCopyReceived={fetchWidgetContentInfoHardCopyReceived}
                            fetchWidgetContentInfoPendingHardCopy={fetchWidgetContentInfoPendingHardCopy}
                            fetchWidgetContentInfoWithHeldGST={fetchWidgetContentInfoWithHeldGST}
                        />
                        }
                    </TabPane>
                    <TabPane key="3" tab={<UnloadingExcavator />}> {
                        unloadingLoadingBar
                            ?
                            <div className={classes['summary-table-spin-element']}><Spin /></div>
                            :
                            <UnloadingExcavatorBilled
                                currentSelectedAgencyId={currentSelectedAgencyId}
                                transporterActiveTab={transporterActiveTab}
                                widgetContentInfoAllBills={widgetContentInfoAllBills}
                                widgetContentInfoHardCopyReceived={widgetContentInfoHardCopyReceived}
                                widgetContentInfoPendingHardCopy={widgetContentInfoPendingHardCopy}
                                widgetContentInfoWithHeldGST={widgetContentInfoWithHeldGST}
                                unloadingbilledRecordData={unloadingbilledRecordData}
                                fetchWidgetContentInfoAllBills={fetchWidgetContentInfoAllBills}
                                fetchWidgetContentInfoHardCopyReceived={fetchWidgetContentInfoHardCopyReceived}
                                fetchWidgetContentInfoPendingHardCopy={fetchWidgetContentInfoPendingHardCopy}
                                fetchWidgetContentInfoWithHeldGST={fetchWidgetContentInfoWithHeldGST}
                            />
                    }
                    </TabPane>
                </Tabs>
            </Grid>
        </>
    );
};
