import React, { useEffect, useState } from "react";
import { Button, Grid, Typography } from "@mui/material";
import classes from "./LoadingSummary.module.scss";
import loadingImg from "../../../../assets/images/MenuIcon/loading-stroke.svg";
import { Select, Form, notification, Spin, Table } from "antd";
import { LineWrapper } from "../LineWrapper/LineWrapper";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import { TotalWidget } from "../TotalWidgets/TotalWidget"
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

const billedColumns = [
    {
        "title": "Bill No.",
        "dataIndex": "bill_number",
        "width": "5%"
    },
    {
        "title": "Trip Count",
        "dataIndex": "trip_count",
        "width": "6%"
    },
    {
        "title": "Last Date",
        "dataIndex": "last_date",
        "width": "7%"
    },
    {
        "title": "Unloaded Wt(MT)",
        "dataIndex": "unloaded_wt",
        "width": "8%"
    },
    {
        "title": "Adjusted Wt",
        "dataIndex": "adjusted_wt",
        "width": "7%"
    },
    {
        "title": "Rate",
        "dataIndex": "rate",
        "width": "5%"
    },
    {
        "title": "Bill Amount",
        "dataIndex": "bill_amount",
        "width": "6%"
    },
    {
        "title": "GST",
        "dataIndex": "gst",
        "width": "5%"
    },
    {
        "title": "Diesel(LTR) Deduction",
        "dataIndex": "diesel_deduction",
        "width": "6%"
    },
    {
        "title": "Adjusted Diesel(LTR)",
        "dataIndex": "adjusted_diesel",
        "width": "6%"
    },
    {
        "title": "Diesel(Rate)",
        "dataIndex": "diesel_rate",
        "width": "6%"
    },
    {
        "title": "Diesel Deduction Amount",
        "dataIndex": "diesel_deduction_amount",
        "width": "8%"
    },
    {
        "title": "Diesel Ratio",
        "dataIndex": "diesel_ratio",
        "width": "7%"
    },
    {
        "title": "Other Deduction",
        "dataIndex": "other_deduction",
        "width": "6%"
    },
    {
        "title": "Less TDS 1%",
        "dataIndex": "less_tds",
        "width": "6%"
    },
    {
        "title": "Bill Amount Payable",
        "dataIndex": "bill_amount_payable",
        "width": "6%"
    }
];

const unBilledColumns = [
    {
        title: "Trip Count",
        dataIndex: "trip_count",
        width: "9%",
    },
    {
        title: "Last Date",
        dataIndex: "last_date",
        width: "9%",
    },
    {
        title: "Unloaded Wt(MT)",
        dataIndex: "unloaded_wt",
        width: "12%",
    },
    {
        title: "Rate",
        dataIndex: "rate",
        width: "8%",
    },
    {
        title: "Bill Amount",
        dataIndex: "bill_amount",
        width: "10%",
    },
    {
        title: "Diesel(LTR) Deduction",
        dataIndex: "diesel_deduction",
        width: "15%",
    },
    {
        title: "Diesel Deduction Amount",
        dataIndex: "diesel_deduction_amount",
        width: "16%",
    },
    {
        title: "Diesel Ratio",
        dataIndex: "diesel_ratio",
    },
    {
        title: "Bill Amount Payable",
        dataIndex: "bill_amount_payable",
    }
];

const grandTotalTableColumns = [
    {
        title: "Bill Count",
        dataIndex: "bill_count",
        width: "6%",
    },
    {
        title: "Trip Count",
        dataIndex: "trip_count",
        width: "6%",
    },
    {
        title: "Unloaded Wt (MT)",
        dataIndex: "unloaded_wt",
        width: "10%",
    },
    {
        title: "Adjusted Wt",
        dataIndex: "adjusted_wt",
        width: "8%",
    },
    {
        title: "Rate",
        dataIndex: "rate",
        width: "8%",
    },
    {
        title: "Bill Amount",
        dataIndex: "bill_amount",
        width: "9%",
    },
    {
        title: "GST",
        dataIndex: "gst",
        width: "6%",
    },
    {
        title: "Diesel(LTR) Deduction",
        dataIndex: "diesel_deduction",
        width: "11%",
    },
    {
        title: "Adjusted Diesel (LTR)",
        dataIndex: "adjusted_diesel",
        width: "8%",
    },
    {
        title: "Diesel(Rate)",
        dataIndex: "diesel_rate",
        width: "8%",
    },
    {
        title: "Diesel Deduction Amount",
        dataIndex: "diesel_deduction_amount",
        width: "12%",
    },
    {
        title: "Diesel Ratio",
        dataIndex: "diesel_ratio",
        width: "8%",
    },
    {
        title: "Other Deduction",
        dataIndex: "other_deduction",
        width: "9%",
    },
    {
        title: "Less TDS 1%",
        dataIndex: "less_tds",
        width: "7%",
    },
    {
        title: "Bill Amount Payable",
        dataIndex: "bill_amount_payable",
        width: "10%",
    },
];

export const LoadingSummary = ({ sessionGlobalValues }) => {

    const [agencyDropDownOptions, setAgenciesDropDownOptions] = useState([])
    const [grandTotal, setGrandTotal] = useState(0)
    const [billedTotal, setBilledTotal] = useState(0)
    const [unbilledTotal, setUnBilledTotal] = useState(0)
    const [totalNetWeightAllAgencies, setTotalUnloadWeightAllAgencies] = useState(0)
    const [summaryBilledTableDate, setSummaryBilledTableData] = useState([])
    const [summaryUnBilledTableDate, setSummaryUnBilledTableData] = useState([])
    const [summaryGrandTotalTableData, setSummaryGrandTotalTableData] = useState([])
    const [projectDetails, setProjectDetails] = useState([])
    const [agencyName, setAgencyName] = useState('')
    const [showBilledTableLoader, setShowBilledTableLoader] = useState(true)
    const [showUnBilledTableLoader, setShowUnBilledTableLoader] = useState(true)
    const agenciesStoredInSession = AppHelper.decryptText(sessionStorage.getItem('summary_session_stored_All_agencies'))
    const selectedLoadingSummaryAgency = AppHelper.decryptText(sessionStorage.getItem('summary_section_loading_agency'))
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    /**
     * This function is used to perform changes in records while changing agency from drop down
     * 
     * @param string agency 
     */
    const handleChangeAgency = async (agency) => {
        setAgencyName(agency)
        setShowBilledTableLoader(true)
        setShowUnBilledTableLoader(true)
        loadSummaryResults('loading', agency)
        sessionStorage.setItem('summary_section_loading_agency', agency ? AppHelper.encryptText(agency) : '')
    }

    const getAgencyIdByName = (agency) => {
        const agencyByName = AppHelper.findAgencyIdByNameProject(AppHelper.decryptText(sessionStorage.getItem('summary_session_stored_All_agencies')), agency, sessionGlobalValues?.projectGlobal)
        return agencyByName?.id ?? null
    }

    /**
     * Function is being used to get all loading generated bills for billed data table
     * 
     * @param string agencyType 
     * @param integer | null agencyId 
     */
    const loadSummaryResults = (agencyType, agencyId = null) => {
        const agencyIdByName = getAgencyIdByName(agencyId)
        AppHelper.loadSummaryBilledResults(agencyType, agencyIdByName).then(response => {

            setShowBilledTableLoader(false)
            setShowUnBilledTableLoader(false)

            const tableData = loadSummaryBilledData(response?.data?.attributes?.billedResults?.tableData ?? [])
            setSummaryBilledTableData(tableData)
            setBilledTotal(response?.data?.attributes?.billedResults?.totalCount?.totalBillAmountPayable ?? 0)

            // load unbilled results in table data
            const tableDataUnbilled = loadSummaryUnBilledData(response?.data?.attributes?.unbilledResults ?? {})
            setSummaryUnBilledTableData(Object.keys(response?.data?.attributes?.unbilledResults).length > 0 ? tableDataUnbilled : [])
            setUnBilledTotal(response?.data?.attributes?.unbilledResults?.totalBillAmountPayable ?? 0)

            // load grand total results in table data
            const tableDataGrandTotal = loadSummaryGrandTotalData(response?.data?.attributes?.grandTotalResults ?? {})
            setSummaryGrandTotalTableData(Object.keys(response?.data?.attributes?.grandTotalResults).length > 0 ? tableDataGrandTotal : [])

            setProjectDetails(response?.data?.attributes?.projectDetails ?? {})
        }).catch(err => {
            setShowBilledTableLoader(false)
            setShowUnBilledTableLoader(false)
            notification.error({
                message: 'Unable to get summary results for loading',
                description: err?.message,
                duration: 3
            })
        })
    }

    /**
    * Function is being used to generate billed table data
    * 
    * @param array responseData
    */
    const loadSummaryBilledData = (responseData) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {

            // calculate work effective rate
            const totalNetWeight = parseFloat((responseData?.[i]['billNetWeight'] + responseData?.[i]['billRoundOffWeight']))
            const totalWorkAmount = responseData?.[i]['billWorkAmount']
            let workEffectiveRate = parseFloat((totalWorkAmount / totalNetWeight).toFixed(2))
            workEffectiveRate = (!isNaN(workEffectiveRate)) ? workEffectiveRate : 0
            workEffectiveRate = (isFinite(workEffectiveRate)) ? workEffectiveRate : 0

            // calculate hsd effective rate
            const totalHSDLTR = parseFloat((responseData?.[i]['billTotalHSDLTR'] + responseData?.[i]['billRoundOffHSDLTR']))
            const totalHSDAmount = responseData?.[i]['billHSDAmount']
            let hsdRate = parseFloat((totalHSDAmount / totalHSDLTR).toFixed(2))
            hsdRate = (!isNaN(hsdRate)) ? hsdRate : 0
            hsdRate = (isFinite(hsdRate)) ? hsdRate : 0

            pendingBillsTabledata.push({
                key: i,
                bill_number: responseData?.[i]['billNo'],
                trip_count: responseData?.[i]['billTripCount'],
                last_date: dayjs(responseData?.[i]['billMaxDate']).format(dateFormat),
                unloaded_wt: responseData?.[i]['billNetWeight'],
                adjusted_wt: responseData?.[i]['billRoundOffWeight'],
                rate: workEffectiveRate,
                bill_amount: totalWorkAmount.toLocaleString('en-in'),
                gst: responseData?.[i]['billGST'],
                diesel_deduction: responseData?.[i]['billTotalHSDLTR'],
                adjusted_diesel: responseData?.[i]['billRoundOffHSDLTR'],
                diesel_rate: hsdRate,
                diesel_deduction_amount: totalHSDAmount.toLocaleString('en-in'),
                diesel_ratio: responseData?.[i]['billDieselRatio'],
                other_deduction: responseData?.[i]['billOtherDeduction'],
                less_tds: responseData?.[i]['billLessTDS'],
                bill_amount_payable: responseData?.[i]['billAmountPayable'].toLocaleString('en-in')
            });
        }
        return pendingBillsTabledata
    }

    const exportLoadingPendingBills = async () => {

        setIsExportButtonDisabled(true)

        // export xlsx code goes here
        const agencyIdByName = getAgencyIdByName(agencyName)
        const exportResponse = await AppHelper.loadSummaryBilledResults('loading', agencyIdByName)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            const workBook = new exceljs.Workbook()

            const workSheetBilledResults = workBook.addWorksheet("Billed Results", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            const workSheetUnBilledResults = workBook.addWorksheet("UnBilled Results", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            const workSheetGrandTotal = workBook.addWorksheet("Grand Total (Billed + Unbilled)", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            const workSheetCountItems = workBook.addWorksheet("Count Items", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })

            workSheetBilledResults.columns = [
                { header: 'Bill No.', key: "billNo", width: 8 },
                { header: 'Trip Count', key: "billTripCount", width: 15 },
                { header: 'Last Date', key: "billMaxDate", width: 15 },
                { header: 'Unloaded Wt(MT)', key: "billNetWeight", width: 20 },
                { header: 'Adjusted Wt', key: "billRoundOffWeight", width: 15 },
                { header: 'Rate', key: "billWorkEffectivateRate", width: 15 },
                { header: 'Bill Amount', key: "billWorkAmount", width: 15 },
                { header: 'GST', key: "billGST", width: 15 },
                { header: 'Diesel(LTR) Deduction', key: "billTotalHSDLTR", width: 25 },
                { header: 'Adjusted Diesel(LTR)', key: "billRoundOffHSDLTR", width: 25 },
                { header: 'Diesel(Rate)', key: "hsdEffectiveRate", width: 15 },
                { header: 'Diesel Deduction Amount', key: "billHSDAmount", width: 25 },
                { header: 'Diesel Ratio', key: "billDieselRatio", width: 15 },
                { header: 'Other Deduction', key: "billOtherDeduction", width: 15 },
                { header: 'Less TDS 1%', key: "billLessTDS", width: 15 },
                { header: 'Bill Amount Payable', key: "billAmountPayable", width: 25 },
            ]

            exportResponse?.data?.attributes?.billedResults?.tableData?.forEach(billData => {
                // calculate work effective rate
                const totalNetWeight = parseFloat((billData?.['billNetWeight'] + billData?.['billRoundOffWeight']))
                const totalWorkAmount = billData?.['billWorkAmount']
                let workEffectiveRate = parseFloat((totalWorkAmount / totalNetWeight).toFixed(2))
                workEffectiveRate = (!isNaN(workEffectiveRate)) ? workEffectiveRate : 0
                workEffectiveRate = (isFinite(workEffectiveRate)) ? workEffectiveRate : 0

                // calculate hsd effective rate
                const totalHSDLTR = parseFloat((billData?.['billTotalHSDLTR'] + billData?.['billRoundOffHSDLTR']))
                const totalHSDAmount = billData?.['billHSDAmount']
                let hsdRate = parseFloat((totalHSDAmount / totalHSDLTR).toFixed(2))
                hsdRate = (!isNaN(hsdRate)) ? hsdRate : 0
                hsdRate = (isFinite(hsdRate)) ? hsdRate : 0
                workSheetBilledResults.addRow({
                    billNo: billData?.['billNo'],
                    billTripCount: billData?.['billTripCount'],
                    billMaxDate: dayjs(billData?.['billMaxDate']).format(dateFormat),
                    billNetWeight: billData?.['billNetWeight'],
                    billRoundOffWeight: billData?.['billRoundOffWeight'],
                    billWorkEffectivateRate: workEffectiveRate,
                    billWorkAmount: totalWorkAmount,
                    billGST: billData?.['billGST'],
                    billTotalHSDLTR: billData?.['billTotalHSDLTR'],
                    billRoundOffHSDLTR: billData?.['billRoundOffHSDLTR'],
                    hsdEffectiveRate: hsdRate,
                    billHSDAmount: totalHSDAmount,
                    billDieselRatio: billData?.['billDieselRatio'],
                    billOtherDeduction: billData?.['billOtherDeduction'],
                    billLessTDS: billData?.['billLessTDS'],
                    billAmountPayable: billData?.['billAmountPayable']
                })
            })

            workSheetUnBilledResults.columns = [
                { header: 'Trip Count', key: "trip_count", width: 15 },
                { header: 'Last Date', key: "last_date", width: 15 },
                { header: 'Unloaded Wt(MT)', key: "unloaded_wt", width: 20 },
                { header: 'Rate', key: "rate", width: 15 },
                { header: 'Bill Amount', key: "bill_amount", width: 15 },
                { header: 'Diesel(LTR) Deduction', key: "diesel_deduction", width: 25 },
                { header: 'Diesel Deduction Amount', key: "diesel_deduction_amount", width: 25 },
                { header: 'Diesel Ratio', key: "diesel_ratio", width: 25 },
                { header: 'Bill Amount Payable', key: "bill_amount_payable", width: 25 }
            ]

            workSheetUnBilledResults.addRow({
                trip_count: exportResponse?.data?.attributes?.unbilledResults?.totalTripCount,
                last_date: dayjs(exportResponse?.data?.attributes?.unbilledResults?.lastLoadingDate).format(dateFormat),
                unloaded_wt: exportResponse?.data?.attributes?.unbilledResults?.totalUnloadedWeight,
                rate: exportResponse?.data?.attributes?.unbilledResults?.finalEffectiveRate,
                bill_amount: exportResponse?.data?.attributes?.unbilledResults?.totalBillAmount,
                diesel_deduction: exportResponse?.data?.attributes?.unbilledResults?.totalHSDLtr,
                diesel_deduction_amount: exportResponse?.data?.attributes?.unbilledResults?.totalHSDAmount,
                diesel_ratio: exportResponse?.data?.attributes?.unbilledResults?.totalDieselRatio,
                bill_amount_payable: exportResponse?.data?.attributes?.unbilledResults?.totalBillAmountPayable
            })

            workSheetGrandTotal.columns = [
                { header: 'Bill Count', key: "bill_count", width: 15 },
                { header: 'Trip Count', key: "trip_count", width: 15 },
                { header: 'Unloaded Wt (MT)', key: "unloaded_wt", width: 20 },
                { header: 'Adjusted Wt', key: "adjusted_wt", width: 15 },
                { header: 'Rate', key: "rate", width: 15 },
                { header: 'Bill Amount', key: "bill_amount", width: 25 },
                { header: 'GST', key: "gst", width: 25 },
                { header: 'Diesel(LTR) Deduction', key: "diesel_deduction", width: 25 },
                { header: 'Adjusted Diesel (LTR)', key: "adjusted_diesel", width: 25 },
                { header: 'Diesel(Rate)', key: "diesel_rate", width: 25 },
                { header: 'Diesel Deduction Amount', key: "diesel_deduction_amount", width: 25 },
                { header: 'Diesel Ratio', key: "diesel_ratio", width: 25 },
                { header: 'Other Deduction', key: "other_deduction", width: 25 },
                { header: 'Less TDS 1%', key: "less_tds", width: 25 },
                { header: 'Bill Amount Payable', key: "bill_amount_payable", width: 25 }
            ]
            workSheetGrandTotal.addRow({
                bill_count: exportResponse?.data?.attributes?.grandTotalResults?.bill_count,
                trip_count: exportResponse?.data?.attributes?.grandTotalResults?.trip_count,
                unloaded_wt: exportResponse?.data?.attributes?.grandTotalResults?.unloaded_wt,
                adjusted_wt: exportResponse?.data?.attributes?.grandTotalResults?.adjusted_wt,
                rate: exportResponse?.data?.attributes?.grandTotalResults?.rate,
                bill_amount: exportResponse?.data?.attributes?.grandTotalResults?.bill_amount,
                gst: exportResponse?.data?.attributes?.grandTotalResults?.gst,
                diesel_deduction: exportResponse?.data?.attributes?.grandTotalResults?.diesel_deduction,
                adjusted_diesel: exportResponse?.data?.attributes?.grandTotalResults?.adjusted_diesel,
                diesel_rate: exportResponse?.data?.attributes?.grandTotalResults?.diesel_rate,
                diesel_deduction_amount: exportResponse?.data?.attributes?.grandTotalResults?.diesel_deduction_amount,
                diesel_ratio: exportResponse?.data?.attributes?.grandTotalResults?.diesel_ratio,
                other_deduction: exportResponse?.data?.attributes?.grandTotalResults?.other_deduction,
                less_tds: exportResponse?.data?.attributes?.grandTotalResults?.less_tds,
                bill_amount_payable: exportResponse?.data?.attributes?.grandTotalResults?.bill_amount_payable
            })

            // preapare sheet for global counts

            workSheetCountItems.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: projectDetails?.Thermal_Plant?.Name, key: "second_value", width: 20 },
            ]

            workSheetCountItems.addRow(
                { first_value: "Project", second_value: projectDetails?.Name_of_project ?? '-' }
            )

            workSheetCountItems.addRow(
                { first_value: "Agency", second_value: agencyName ?? '-' }
            )

            workSheetCountItems.addRow({
                first_value: "Grand Total", second_value: ((grandTotal && grandTotal !== "") ? grandTotal : '')
            })

            workSheetCountItems.addRow(
                { first_value: "Billed", second_value: ((billedTotal && billedTotal !== "") ? billedTotal : '') }
            )

            workSheetCountItems.addRow(
                { first_value: "UnBilled", second_value: ((unbilledTotal && unbilledTotal !== "") ? unbilledTotal : '') }
            )

            workSheetCountItems.addRow(
                { first_value: "Work Percentage", second_value: !isFinite(parseFloat(((summaryGrandTotalTableData[0]?.unloaded_wt / totalNetWeightAllAgencies) * 100)).toFixed(2)) ? 0 : parseFloat(((summaryGrandTotalTableData[0]?.unloaded_wt / totalNetWeightAllAgencies) * 100).toFixed(2)) }
            )

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `transaction_summary_loading_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Transaction summary loading data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: '',
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    /**
    * Function is being used to generate unbilled table data
    * 
    * @param object responseData
    */
    const loadSummaryUnBilledData = (responseData) => {
        return [{
            trip_count: responseData?.totalTripCount ?? 0,
            last_date: ((responseData?.lastLoadingDate && responseData?.lastLoadingDate !== '') ? dayjs(responseData?.lastLoadingDate).format(dateFormat) : ''),
            unloaded_wt: responseData?.totalUnloadedWeight ?? 0,
            rate: responseData?.finalEffectiveRate ?? 0,
            bill_amount: ((responseData?.totalBillAmount) ? responseData?.totalBillAmount?.toLocaleString('en-in') : 0),
            diesel_deduction: responseData?.totalHSDLtr ?? 0,
            diesel_deduction_amount: ((responseData?.totalHSDAmount) ? responseData?.totalHSDAmount?.toLocaleString('en-in') : 0),
            diesel_ratio: responseData?.totalDieselRatio ?? 0,
            bill_amount_payable: ((responseData?.totalBillAmountPayable) ? responseData?.totalBillAmountPayable?.toLocaleString('en-in') : 0),
        }]
    }

    /**
    * Function is being used to generate unbilled table data
    * 
    * @param object responseData
    */
    const loadSummaryGrandTotalData = (responseData) => {
        if (Object.keys(responseData).length > 0) {
            responseData.bill_amount = responseData?.bill_amount?.toLocaleString('en-in')
            responseData.diesel_deduction_amount = responseData?.diesel_deduction_amount?.toLocaleString('en-in')
            setGrandTotal(responseData?.bill_amount_payable ?? 0)
            responseData.bill_amount_payable = responseData?.bill_amount_payable.toLocaleString('en-in')
            setTotalUnloadWeightAllAgencies(responseData?.totalUnloadWeightInAllAgency ?? 0)
            return [responseData]
        } else {
            setGrandTotal(0)
            setTotalUnloadWeightAllAgencies(0)
            return []
        }
    }

    const loadAllAgenciesDropDown = () => {
        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then(response => {
            sessionStorage.setItem('summary_session_stored_All_agencies', AppHelper.encryptText(response))
            // load loading agencies by default for agency drop down 
            const agencyDropDownOptionsByTabType = AppHelper.getAgencyDropDown(response, 'Loading', sessionGlobalValues)
            setAgenciesDropDownOptions(agencyDropDownOptionsByTabType)
            // load records of by default first option of agency
            sessionStorage.setItem('summary_section_loading_agency', AppHelper.encryptText(agencyDropDownOptionsByTabType?.[0]?.value))
            handleChangeAgency(agencyDropDownOptionsByTabType?.[0]?.value)
        }).catch(err => {
            notification.error({
                message: 'Unable to get agencies',
                description: err?.message,
                duration: 3
            })
        })
    }

    useEffect(() => {
        if (agenciesStoredInSession && agenciesStoredInSession?.length > 0) {
            const agencyDropDownOptionsByTabType = AppHelper.getAgencyDropDown(agenciesStoredInSession, 'Loading', sessionGlobalValues)
            setAgenciesDropDownOptions(agencyDropDownOptionsByTabType)
            // load records of by default first option of agency
            handleChangeAgency(((selectedLoadingSummaryAgency && selectedLoadingSummaryAgency !== "") ? selectedLoadingSummaryAgency : agencyDropDownOptionsByTabType?.[0]?.value))
        } else {
            loadAllAgenciesDropDown()
        }
    }, [])

    return (
        <>
            <Grid className={classes["fixed-header-sticky"]}>
                <div className={classes["loading-flex"]}>
                    <img src={loadingImg} alt="Loading icon" />
                    <Typography variant="h6" color="initial" fontWeight="bold">
                        Loading Agency
                    </Typography>
                    <Form>
                        <Select
                            showSearch
                            optionFilterProp="children"
                            placeholder="All"
                            filterOption={filterOption}
                            options={agencyDropDownOptions}
                            value={((selectedLoadingSummaryAgency && selectedLoadingSummaryAgency !== "") ? selectedLoadingSummaryAgency : agencyDropDownOptions?.[0]?.value)}
                            onChange={handleChangeAgency}
                            className={classes["select-new-entry"]}
                        />
                    </Form>
                </div>
                <TotalWidget grandTotal={grandTotal} billedTotal={billedTotal} unbilledTotal={unbilledTotal} summaryGrandTotalTableData={summaryGrandTotalTableData} totalNetWeightAllAgencies={totalNetWeightAllAgencies} classes={classes} />
            </Grid>
            <LineWrapper summaryText="Billed" />
            {
                !showBilledTableLoader
                    ?
                    <Table
                        className={classes["top-spacer"]}
                        columns={billedColumns}
                        dataSource={summaryBilledTableDate}
                        bordered={true}
                        tableLayout="fixed"
                        scroll={{ x: 2000, y: 300 }}
                        pagination={false}
                    />
                    :
                    <div className={classes['summary-table-spin-element']}><Spin /></div>
            }
            <LineWrapper summaryText="Unbilled" />
            {
                !showUnBilledTableLoader ?
                    <Table
                        className={classes["top-spacer"]}
                        columns={unBilledColumns}
                        dataSource={summaryUnBilledTableDate}
                        bordered={true}
                        tableLayout="fixed"
                        scroll={{ x: 1000, y: 300 }}
                        pagination={false}
                    />
                    :
                    <div className={classes['summary-table-spin-element']}><Spin /></div>
            }
            <LineWrapper summaryText="Grand Total (Billed + Unbilled)" />
            {
                summaryGrandTotalTableData
                    ?
                    <Table
                        className={classes["top-spacer"]}
                        columns={grandTotalTableColumns}
                        dataSource={summaryGrandTotalTableData}
                        bordered={true}
                        tableLayout="fixed"
                        scroll={{ x: 2000, y: 300 }}
                        pagination={false}
                    />
                    :
                    <div className={classes['summary-table-spin-element']}><Spin /></div>
            }
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={`${classes["loading-pending-padding"]}`}
            >
                <Grid container>
                    <Grid item lg={12} md={12} sm={12} xs={12} className={classes["align-center"]}>
                        <Button
                            variant="outlined"
                            className={`${classes["add-new-entry"]} ${classes["export-button"]} ${isExportButtonDisabled === true ? classes['button-disabled'] : ''}`}
                            onClick={exportLoadingPendingBills}
                            disabled={isExportButtonDisabled}
                        >
                            Export
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};