import React from "react";
import classes from "./BillWiseTripGraph.module.scss";
import Highcharts, { color } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";

export const BillWiseTripGraph = React.memo(({ graphDetails, displayLoadingBar }) => {

    function getSubtitleBillWiseNoofTrip() {
        return `<div style="text-align: center; margin-top: 15px;">
                <h3 style="color: #1f226f; font-size: 22px;">${graphDetails?.totalBillWiseTripCount ?? 0}</h3><br>
                <p style="color: #555555; font-size: 22px; font-weight: 200;">Total Trip</p><br>
           </div>`;
    }

    const options3 = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
        },
        title: {
            useHTML: true,
            text: getSubtitleBillWiseNoofTrip(),
            align: "center",
            verticalAlign: "middle",
            y: 60,
            style: {
                fontSize: "1.1em",
            },
        },
        tooltip: {
            pointFormat: "Total Trip: <b>{point.y}</b>",
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            },
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                cursor: "pointer",
                dataLabels: [
                    {
                        enabled: true,
                        distance: 25,
                        style: {
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#1f226f"
                        },
                        useHTML: true,
                    },
                    {
                        enabled: true,
                        distance: -28,
                        format: "{point.percentage:.2f}%",
                        style: {
                            fontSize: "14px",
                            textOutline: "none",
                            opacity: 0.7,
                        },
                        filter: {
                            operator: ">",
                            property: "percentage",
                            value: 5,
                        },
                    },
                ],
                startAngle: -90,
                endAngle: 90,
                center: ["50%", "80%"],
                size: "120%",
            },
        },
        series: [
            {
                type: "pie",
                name: "Percentage",
                colorByPoint: true,
                innerSize: "75%",
                data: graphDetails?.billWiseTripCountArray ?? [],
            },
        ],
    };
    return (
        <>
            <div className={classes['graphArea']}>
                {displayLoadingBar ? <Spin className={classes['spinStyle']} size="large" /> : <HighchartsReact highcharts={Highcharts} options={options3} />}
            </div>
        </>
    );
});
