import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { ArrowLeftOutlined } from "@ant-design/icons";
import classes from "./EditVehicleData.module.scss";
import { Checkbox, Form, Input, Modal, notification } from "antd";
import { useNavigate } from "react-router-dom";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import html2pdf from 'html2pdf.js'
import downloadPDFIcon from '../../../../assets/images/download-pdf.png'

export const EditVehicleData = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [vehicleForm] = Form.useForm()
    const loadId = params?.id ?? "";
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [vehicleFetchInfo, setVehicleFetchInfo] = useState({})
    const [vehicleNoValue, setVehicleNoValue] = useState("")
    const [vehicleDetails, setVehicleDetails] = useState("");
    const [isForcefullyValidated, setIsForceFullyValidated] = useState(false)

    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE

    const getVehiclesDetails = async () => {
        const response = await AppHelper.getVehicleById(loadId);
        vehicleForm.setFieldsValue({
            Vehicle_Number: response?.data?.attributes?.Vehicle_Number ?? "",
            Remarks: response?.data?.attributes?.Remarks ?? "",
            Owner: response?.data?.attributes?.Owner ?? "",
            No_of_Tyres: response?.data?.attributes?.No_of_Tyres ?? 0,
            Gross_Weight: response?.data?.attributes?.Gross_Weight ?? 0,
            Vehicle_Category: response?.data?.attributes?.Vehicle_Category ?? '',
        })
        setVehicleNoValue(response?.data?.attributes?.Vehicle_Number ?? "")
        setVehicleDetails(response?.data?.attributes ?? "")
        setIsForceFullyValidated(response?.data?.attributes?.Forcefully_Validated ?? false)
    };

    const onChangeCheckbox = (e) => {
        setIsForceFullyValidated(e.target.checked)
    }

    const handleDownloadPdf = async () => {

        let element = '<h1 style="padding:25px 0;text-align:center;">Vehicle Details</h1>'

        element += `<div>`
        {
            Object.entries(vehicleDetails?.Vehicle_Information).filter(item => item?.[0] !== 'splitPresentAddress' && item?.[0] !== 'splitPermanentAddress').map(([value, key], i) => {

                const widthPercentage = constantsArr.LONG_LABELS_VEHICLE_INFO_TABLE.includes(value) ? '100%' : '49%'

                element += `<div style="font-size: 18px;float: left;padding: 10px 0px;border-bottom: 1px solid #ccc;width:${widthPercentage}">
                <span style="font-weight: bold;color: #444CF7 !important;padding: 10px !important;margin-right: 10px !important;">`
                element += `${AppHelper.convertStringToLabel(value)}`

                element += `:</span>`
                element += `${(!key || key === '') ? 'NA' : key}`
                element += `</div>`
                {
                    ++i % 2 === 0 ?
                        element += `<div style="clear: both;"></div>`
                        :
                        element += ""
                }
            })
        }
        element += `</div>`

        const options = {
            margin: 1.0,
            filename: 'vehicle_information_details.pdf',
            image: { type: 'jpeg', quality: 1 },
            html2canvas: { scale: 3 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'l', putOnlyUsedFonts: true, floatPrecision: 16 }
        };

        html2pdf().from(element).set(options).save();
    };

    const validateVehicleNumberOnClick = async () => {
        if (!vehicleNoValue || vehicleNoValue === '') {
            notification.error({
                message: "Error",
                description: "Please enter vehicle number",
                duration: 3,
            });
            return false
        }
        const getVehicleInformation = await AppHelper.validateVehicleNo(vehicleNoValue)
        if (getVehicleInformation?.error) {
            notification.error({
                message: "Error",
                description: getVehicleInformation?.error?.message,
                duration: 3,
            });
        } else {
            setVehicleFetchInfo(getVehicleInformation?.result ?? getVehicleInformation ?? {})
            setIsModalOpen(true)
        }
    };

    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const handleChange = (e) => {
        setVehicleNoValue(e.target.value)
    }

    const putVehicle = async (formValues) => {
        setIsSubmittingResponse(true);
        formValues.Forcefully_Validated = isForcefullyValidated
        const payload = { data: formValues };
        const response = await AppHelper.updateVehicle(loadId, payload);
        if (response?.error) {
            notification.error({
                message: "Error",
                description: response?.error?.message,
                duration: 3,
            });
        } else {
            notification.success({
                message: "Success",
                description: "Vehicle updated successfully.",
                duration: 3,
            });
            navigate(`/vehicles`);
        }
        setIsSubmittingResponse(false);
    };

    const backToVehiclesList = (e) => {
        e.preventDefault();
        navigate(`/vehicles?currentPage=${pageNo}&pageSize=${pageSize}`);
    };

    // Validator function for the vehicle number
    const validateVehicleNumber = (_, value) => {
        if (/^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/i.test(value)) {
            return Promise.resolve();
        } else {
            return Promise.reject("Enter Valid Vehicle No\n(Ex: TN46AM1234, TN46A1234)");
        }
    };


    useEffect(() => {
        document.title = "Edit Vehicle"
        getVehiclesDetails();
    }, []);


    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["edit-vehicle-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Edit Vehicle Details
                    </Typography>
                </Grid>

                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        size="small"
                        variant="contained"
                        onClick={backToVehiclesList}
                        className={classes['back-button']}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Vehicles
                    </UIButton>
                </Grid>
            </Grid>

            <Form layout="vertical" onFinish={putVehicle} form={vehicleForm}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["grid-space"]}
                >
                    <Grid item lg={6} md={6} xs={12} className={classes["position-relative"]}>
                        <Form.Item
                            label="Vehicle Number"
                            name="Vehicle_Number"
                            rules={[
                                {
                                    required: true, message: "Please enter the vehicle number"
                                },
                                {
                                    validator: validateVehicleNumber
                                }
                            ]}>
                            <Input className={classes['width88']} onChange={handleChange} />
                        </Form.Item>
                        <div className={classes["input-field-challan-button"]}>
                            <Typography className={classes["stop-missing-icon"]} onClick={validateVehicleNumberOnClick}>Validate</Typography>
                        </div>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Owner" name="Owner"
                            rules={[
                                {
                                    required: true, message: "Please enter the Owner Name"
                                }
                            ]}>
                            <Input />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    className={classes["edit-vehicle-padding"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item name="No_of_Tyres" label="No of Tyres"
                            rules={[
                                {
                                    pattern: new RegExp(/^[0-9]+$/),
                                    message: "No of Tyres should be integer only"
                                }
                            ]}>
                            <Input type="number" min={0} max={20} defaultValue={0} />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item messageVariables="" label="Remarks" name="Remarks">
                            <Input />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    className={classes["edit-vehicle-padding"]}
                >
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Gross Weight" name="Gross_Weight">
                            <Input readOnly />
                        </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <Form.Item label="Vehicle Category" name="Vehicle_Category">
                            <Input readOnly />
                        </Form.Item>
                    </Grid>
                </Grid>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="nowrap"
                    className={classes["edit-vehicle-padding"]}
                >
                    <Grid item lg={12} md={12} xs={12}>
                        <Form.Item
                            messageVariables=""
                            wrapperCol={{ span: 30 }}
                            labelCol={{ span: 30 }}
                            className={classes['ant-form-item-radio']}
                        >
                            <Checkbox value={isForcefullyValidated} checked={isForcefullyValidated} onChange={onChangeCheckbox}>Forcefully Validated</Checkbox>
                        </Form.Item>
                    </Grid>
                </Grid>
                {
                    vehicleDetails?.Vehicle_Information && Object.keys(vehicleDetails?.Vehicle_Information).length > 0
                        ?
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                            className={classes["edit-vehicle-padding"]}
                        >
                            <Grid item lg={12} md={12} xs={12}>
                                <>
                                    <button type="button" title="Download Vehicle Details PDF" className={classes['download_vehicle_details_pdf']}><img src={downloadPDFIcon} alt="Download Vehicle Details PDF" onClick={handleDownloadPdf} width={64} /></button>
                                </>
                            </Grid>
                        </Grid>
                        :
                        ""
                }
                <div className={classes["submit-button-area"]}>
                    <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                        Save
                    </UIButton>
                    <UIButton
                        variant="outlined"
                        color="error"
                        onClick={backToVehiclesList}
                    >
                        Cancel
                    </UIButton>
                </div>
            </Form>


            <Modal open={isModalOpen} footer={false} onCancel={handleCancel} centered className={classes["model-missing-challan"]} width={1000}>
                {
                    Object.keys(vehicleFetchInfo)?.length > 0
                        ?
                        <>
                            <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>Vehicle Details</Typography>
                            <div className={classes["missing-tags"]} style={{ height: 400, overflowY: 'scroll' }}>
                                {
                                    Object.entries(vehicleFetchInfo).filter(item => item?.[0] !== 'splitPresentAddress' && item?.[0] !== 'splitPermanentAddress').map(([value, key], i) => {
                                        return <>
                                            <div className={classes['vehicle-info-row']} key={key} style={{ width: constantsArr.LONG_LABELS_VEHICLE_INFO_TABLE.includes(value) ? '100%' : '49%' }}>
                                                <span className={classes['label']}>
                                                    {AppHelper.convertStringToLabel(value)}:
                                                </span>
                                                {(!key || key === '') ? 'NA' : key}
                                            </div>
                                            {++i % 2 === 0 ? <div className={classes['clear-both']}></div> : ""}
                                        </>
                                    })
                                }
                            </div>

                            <div className={classes["flex-items-missing"]}>
                                <Typography variant="body2" color="initial" className={classes["description-missing-challan"]}>

                                </Typography>
                            </div>
                        </>
                        :
                        <Typography variant="h6" color="initial" className={classes["missing-challan-heading"]}>No Vehicle information is available</Typography>
                }
            </Modal>
        </>
    );
};
