import React from "react";
import ReactApexChart from "react-apexcharts";
import classes from "./TransporationAverageWorkDoneDaysGraph.module.scss";

export const TransporationAverageWorkDoneDaysGraph = ({ funnelChartTransportationAverage }) => {


    const options = {
        chart: {
            type: "bar",
            height: 350,
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: true,
                barHeight: "80%",
                isFunnel: true,
            },
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "18px",
                fontFamily: "Helvetica, Arial, sans-serif"
            },
            formatter: function (val, opt) {
                return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val + ' MT';
            },
            dropShadow: {
                enabled: true,
            },
        },
        xaxis: {
            categories: funnelChartTransportationAverage?.last15DaysRecords?.length > 0 ? funnelChartTransportationAverage?.last15DaysRecords.filter(item => item?.avergae_weight_agency > 0)?.map((item) => { return item?.agency_name ?? '' }) : [],
        },
        legend: {
            show: false,
        },
        colors: ["#9C3E00"],
    };

    const series = [
        {
            name: "Funnel Series",
            data: funnelChartTransportationAverage?.last15DaysRecords?.length > 0 ? funnelChartTransportationAverage?.last15DaysRecords.filter(item => item?.avergae_weight_agency > 0)?.map((item) => { return item?.avergae_weight_agency ?? '' }) : [],
        },
    ];

    return (
        <>
            {
                funnelChartTransportationAverage?.last15DaysRecords?.length > 0
                    ?
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height={400}
                    />
                    :
                    <div className={classes['graphArea']}>
                        <div className={classes['spinStyle']}>No Data Found</div>
                    </div>
            }
        </>
    );
};
