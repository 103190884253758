const constantsArr = {
    'AGENCY_TYPES' : [
        {
            label: 'Loading',
            value: 'Loading'
        },
        {
            label: 'Unloading',
            value: 'Unloading'
        },
    ],
}

export default constantsArr