import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import { Button, Flex, Select, Form, Table, notification, Spin, Tooltip } from "antd"
import classes from "./Dashboard.module.scss";
import workInProgressImg from "../../assets/images/DashboardIcons/workinprogress.svg";
import avgLeadImg from "../../assets/images/DashboardIcons/avglead.svg";
import fleetAvgImg from "../../assets/images/DashboardIcons/fleetavg.svg";
import transportImg from "../../assets/images/DashboardIcons/transport.svg";
import loadingImg from "../../assets/images/DashboardIcons/loading.svg";
import unloadingImg from "../../assets/images/DashboardIcons/unloading.svg";
import ltrImg from "../../assets/images/DashboardIcons/litres.svg";
import successTripImg from "../../assets/images/DashboardIcons/successtrip.svg";
import tentativeTripleft from "../../assets/images/DashboardIcons/tentativetrip.svg";
import avgTripPerDay from "../../assets/images/DashboardIcons/avgtripperday.svg";
import maxTripImg from "../../assets/images/DashboardIcons/maxtrip.svg";
import cancelledImg from "../../assets/images/DashboardIcons/cancelledtrip.svg";
import unloadTripImg from "../../assets/images/DashboardIcons/unloadtrip.svg";
import hsdLeftTripImg from "../../assets/images/DashboardIcons/hsdlefttrip.svg";
import challanMissTripImg from "../../assets/images/DashboardIcons/challanmisstrip.svg";
import transportImgSecond from "../../assets/images/DashboardIcons/transport-second.svg";
import loadingImgSecond from "../../assets/images/DashboardIcons/loading-second.svg";
import unloadingImgSecond from "../../assets/images/DashboardIcons/unloading-second.svg";
import totalAmountImgPP from "../../assets/images/DashboardIcons/PPtotalamount.svg";
import totalLtrImgPP from "../../assets/images/DashboardIcons/PPtotalltr.svg";
import differenceImgPP from "../../assets/images/DashboardIcons/PPdiffrence.svg";
import vendorPaymentImg from "../../assets/images/DashboardIcons/venderpayment.svg";
import totalOtherDeductionImg from "../../assets/images/DashboardIcons/totalotherdeduction.svg";
import petrolPumpImg from "../../assets/images/DashboardIcons/petrolpumpyellow.svg";
import workInProgressLocationTripImg from "../../assets/images/DashboardIcons/workinprogresslocationtrip.svg";
import { WorkDetailGraph } from "./SemiDonutGraph/WorkDetailGraph/WorkDetailGraph";
import { BillCumDetailGraph } from "./SemiDonutGraph/BillCumDetailGraph/BillCumDetailGraph";
import { BillWiseTripGraph } from "./SemiDonutGraph/BillWiseTripGraph/BillWiseTripGraph";
import { TransporationQntyGraph } from "./ColumnGraph/TransporationQntyGraph/TransporationQntyGraph";
import { TransporationNoOfTripGraph } from "./ColumnGraph/TransporationNoOfTripGraph/TransporationNoOfTripGraph";
import { TripDaysGraph } from "./LineGraph/TripDaysGraph/TripDaysGraph";
import { AvgWTDaysGraph } from "./LineGraph/AvgWTDaysGraph/AvgWTDaysGraph";
import { TransporationWorkDoneAllTimeGraph } from "./FunnelGraph/TransporationWorkDoneAllTimeGraph/TransporationWorkDoneAllTimeGraph";
import { TransporationWorkDoneDaysGraph } from "./FunnelGraph/TransporationWorkDoneDaysGraph/TransporationWorkDoneDaysGraph";
import vehicleUsedImg from "../../assets/images/DashboardIcons/activevehicle.svg";
import holdVehicleImg from "../../assets/images/DashboardIcons/holdvehicle.svg";
import { TransporationAverageWorkDoneAllTimeGraph } from "./FunnelGraph/TransporationAverageWorkDoneAllTimeGraph/TransporationAverageWorkDoneAllTimeGraph";
import { TransporationAverageWorkDoneDaysGraph } from "./FunnelGraph/TransporationAverageWorkDoneDaysGraph/TransporationAverageWorkDoneDaysGraph";
import { LoadingWorkDoneAllTimeGraph } from "./FunnelGraph/LoadingWorkDoneAllTimeGraph/LoadingWorkDoneAllTimeGraph";
import { LoadingWorkDoneDaysGraph } from "./FunnelGraph/LoadingWorkDoneDaysGraph/LoadingWorkDoneDaysGraph";
import { UnloadingWorkDoneAllTimeGraph } from "./FunnelGraph/UnloadingWorkDoneAllTImeGraph/UnloadingWorkDoneAllTimeGraph";
import { UnloadingWorkDoneDaysGraph } from "./FunnelGraph/UnloadingWorkDoneDaysGraph/UnloadingWorkDoneDaysGraph";
import { ProjectTimelineGraph } from "./GanttGraph/ProjectTimelineGraph/ProjectTimelineGraph";
import styled from "styled-components";
import AppHelper from "../../helpers/AppHelper";
import checkIcon from '../../assets/images/accept.png'

const StyledTableFirst = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #ea0000;
    color: #fff;
  }
`;

const StyledTableSecond = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #ff9900;
    color: #fff;
  }
`;

const StyledTableThird = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: #479036;
    color: #fff;
  }
`;

const StyleSelect = styled(Form.Item)`
  && {
    margin-bottom: 0px;
  }

  && {
    .ant-form-item-control-input {
      min-height: 0px;
    }
  }
`;

const columns1 = [
    {
        title: "Transporter",
        dataIndex: "transporter_name",
        width: 150,
    },
    {
        title: "Percent Work Done",
        dataIndex: "percent_work_done",
        width: 150,
    },
];

const data1 = [];
for (let i = 0; i < 50; i++) {
    data1.push({
        key: i,
        transporter_name: `QWERT private limited`,
        percent_work_done: `${i}` * 5 + `%`,
    });
}

export const Dashboard = () => {
    const [selectedGraph, setSelectedGraph] = useState("cum");
    const [transportationGraph, setTransportationGraph] = useState("qnty");
    const [tripOfLastGraph, setTripOfLastGraph] = useState("trip");
    const [transportationWorkDoneGraph, setTransportationWorkDoneGraph] = useState("alltime");
    const [transportationAverageWorkDoneGraph, setTransportationAverageWorkDoneGraph] = useState("alltime");
    const [loadingWorkDoneGraph, setLoadingWorkDoneGraph] = useState("alltime");
    const [unloadingWorkDoneGraph, setUnloadingWorkDoneGraph] = useState("alltime");
    const [workDetailsGraphInformation, setWorkDetailsGraphInformation] = useState({})
    const [workDetailsGraphInformationLoadingBar, setWorkDetailsGraphInformationLoadingBar] = useState(true)
    const [workInProgressWidgetInformation, setWorkInProgressWidgetInformation] = useState({})
    const [workInProgressWidgetInformationLoadingBar, setWorkInProgressWidgetInformationLoadingBar] = useState(true)
    const [billWiseTripCountChartInformation, setBillWiseTripCountChartInformation] = useState({})
    const [billWiseTripCountChartInformationLoadingBar, setBillWiseTripCountChartInformationLoadingBar] = useState(true)
    const [effectiveRateInformation, setEffectiveRateInformation] = useState({})
    const [weightInformation, setWeightInformation] = useState({})
    const [expensesInformation, setExpensesInformation] = useState({})
    const [dieselInformation, setDieselInformation] = useState({})
    const [petrolPumpInformation, setPetrolPumpInformation] = useState({})
    const [petrolPumpInformationLoadingBar, setPetrolPumpInformationLoadingBar] = useState(true)
    const [tripsDetailsInformation, setTripsDetailsInformation] = useState(true)
    const [agenciesVehicleDetailsInformation, setAgenciesVehicleDetailsInformation] = useState(true)
    const [avgPerDayInformation, setAvgPerDayInformation] = useState(true)
    const [tripAggregationInformation, setTripAggregationInformation] = useState(true)
    const [funnelChartTransportation, setFunnelChartTransportation] = useState(true)
    const [funnelChartTransportationLoadingBar, setFunnelChartTransportationLoadingBar] = useState(true)
    const [funnelChartLoading, setFunnelChartLoading] = useState(true)
    const [funnelChartLoadingLoadingBar, setFunnelChartLoadingLoadingBar] = useState(true)
    const [funnelChartUnloading, setFunnelChartUnloading] = useState(true)
    const [funnelChartUnloadingLoadingBar, setFunnelChartUnloadingLoadingBar] = useState(true)
    const [funnelChartTransportationAverag, setFunnelChartTransportationAverage] = useState(true)
    const [funnelChartTransportationAverageLoadingBar, setFunnelChartTransportationAverageLoadingBar] = useState(true)
    const [transportationBarChartInfo, setTransportationBarChartInfo] = useState(true)
    const [transportationBarChartInfoLoadingBar, setTransportationBarChartInfoLoadingBar] = useState(true)
    const [avg15DaysTripWeightLineChart, setAvg15DaysTripWeightLineChart] = useState(true)
    const [avg15DaysTripWeightLineChartInfoLoadingBar, setAvg15DaysTripWeightLineChartInfoLoadingBar] = useState(true)
    const [transportAgenciesDropDown, setTransportAgenciesDropDown] = useState(true)
    const [timelineChartInfo, setTimelineChartInfo] = useState(true)
    const [timelineChartInfoLoadingBar, setTimelineChartInfoLoadingBar] = useState(true)
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const [agencies, setAgencies] = useState([])
    const [tabsValue, setTabsValue] = useState(0)
    const [projectDetails, setProjectDetails] = useState({})

    const handleGraphChange = (graph) => {
        setSelectedGraph(graph);
    };

    const handleTransporationChange = (transporter) => {
        setTransportationGraph(transporter);
    };

    const handleTripOfLastChange = (trip) => {
        setTripOfLastGraph(trip);
    };

    const handleTransportationWorkDoneChange = (transporterWork) => {
        setTransportationWorkDoneGraph(transporterWork);
    };

    const handleTransportationAverageWorkDoneChange = (transporterAvg) => {
        setTransportationAverageWorkDoneGraph(transporterAvg);
    };

    const handleLoadingWorkDoneGraph = (loadingWork) => {
        setLoadingWorkDoneGraph(loadingWork);
    };

    const handleUnloadingWorkDoneGraph = (unloadingWork) => {
        setUnloadingWorkDoneGraph(unloadingWork);
    };

    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleTransportAgencyChange = (agency) => {
        setAvg15DaysTripWeightLineChartInfoLoadingBar(true)
        const agencyByName = agencies.find(element => element?.attributes?.Company_name?.toLowerCase() === agency?.toLowerCase() && element?.attributes?.Project?.data?.id === sessionGlobalValues?.projectGlobal);
        AppHelper.getLast15DaysAvgTripAndAvgWeightLineChart(agencyByName?.id).then(response => {
            setAvg15DaysTripWeightLineChart(response?.data?.attributes)
            setAvg15DaysTripWeightLineChartInfoLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching last 15 days avg trip and avg weight line chart`, err);
            notification.error({
                message: "Error while fetching last 15 days avg trip and avg weight line chart",
                duration: 3
            })
            setAvg15DaysTripWeightLineChartInfoLoadingBar(false)
        })
    }

    useEffect(() => {

        // get project details
        AppHelper.getProjectDetails().then(response => {
            setProjectDetails(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching project details`, err);
            notification.error({
                message: "Error while fetching project details",
                duration: 3
            })
        })

        // get effective rate section information to display
        AppHelper.getEffectiveRatesSectionInfo().then(response => {
            setEffectiveRateInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching effective rate information`, err);
            notification.error({
                message: "Error while fetching effective rate information",
                duration: 3
            })
        })

        // get work detail high chart information 
        AppHelper.getWorkDetailChartInfo().then(response => {
            setWorkDetailsGraphInformationLoadingBar(false)
            setWorkDetailsGraphInformation(response?.data?.attributes)
        }).catch(err => {
            setWorkDetailsGraphInformationLoadingBar(false)
            console.log(`Error while fetching work detail graph information`, err);
            notification.error({
                message: "Error while fetching work detail graph information",
                duration: 3
            })
        })

        // get work in progress cum and trip count widget information
        AppHelper.getWorkInProgressWidgetInformation().then(response => {
            setWorkInProgressWidgetInformationLoadingBar(false)
            setWorkInProgressWidgetInformation(response?.data?.attributes)
        }).catch(err => {
            setWorkInProgressWidgetInformationLoadingBar(false)
            console.log(`Error while fetching work in progress widget information`, err);
            notification.error({
                message: "Error while fetching work in progress widget information",
                duration: 3
            })
        })

        // get bill wise trip count information to display in high pie chart
        AppHelper.getBillWiseTripCountWithProject().then(response => {
            setBillWiseTripCountChartInformationLoadingBar(false)
            setBillWiseTripCountChartInformation(response?.data?.attributes)
        }).catch(err => {
            setBillWiseTripCountChartInformationLoadingBar(false)
            console.log(`Error while fetching bill wise trip count information`, err);
            notification.error({
                message: "Error while fetching bill wise trip count information",
                duration: 3
            })
        })

        // get weight section information to display
        AppHelper.getWeightSectionInfo().then(response => {
            setWeightInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching weight information`, err);
            notification.error({
                message: "Error while fetching weight information",
                duration: 3
            })
        })

        // get expenses section information to display
        AppHelper.getExpensesInfo().then(response => {
            setExpensesInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching expenses information`, err);
            notification.error({
                message: "Error while fetching expenses information",
                duration: 3
            })
        })

        // get diesel section information to display
        AppHelper.getDieselInfo().then(response => {
            setDieselInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching diesel information`, err);
            notification.error({
                message: "Error while fetching diesel information",
                duration: 3
            })
        })

        // get petrol pump section information to display
        AppHelper.getPetrolPumpInfoDashboard().then(response => {
            setPetrolPumpInformation(response?.data?.attributes)
            setPetrolPumpInformationLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching petrol pump information`, err);
            setPetrolPumpInformationLoadingBar(false)
            notification.error({
                message: "Error while fetching petrol pump information",
                duration: 3
            })
        })

        // get trip details section information to display
        AppHelper.getTripFetailsInformation().then(response => {
            setTripsDetailsInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching trips detail information`, err);
            notification.error({
                message: "Error while fetching trips detail information",
                duration: 3
            })
        })

        // get agencies and vehicle count details section information to display
        AppHelper.getAgenciesVehiclesCountInfo().then(response => {
            setAgenciesVehicleDetailsInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching agencies and vehicles count information`, err);
            notification.error({
                message: "Error while fetching agencies and vehicles count information",
                duration: 3
            })
        })

        // get average per day section information to display
        AppHelper.getAvgPerDayInfo().then(response => {
            setAvgPerDayInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching average per day count information`, err);
            notification.error({
                message: "Error while fetching average per day count information",
                duration: 3
            })
        })

        // get trip aggregation section information to display
        AppHelper.getTripAggregationInfo().then(response => {
            setTripAggregationInformation(response?.data?.attributes)
        }).catch(err => {
            console.log(`Error while fetching trip aggregation section information`, err);
            notification.error({
                message: "Error while fetching trip aggregation section information",
                duration: 3
            })
        })

        // get funnel chart section information to display for transportation
        AppHelper.getFunnelChartInfo('transporting').then(response => {
            setFunnelChartTransportation(response?.data?.attributes)
            setFunnelChartTransportationLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching funnel chart information for transporting agency`, err);
            notification.error({
                message: "Error while fetching  funnel chart information for transporting agency",
                duration: 3
            })
            setFunnelChartTransportationLoadingBar(false)
        })

        // get funnel chart section information to display for loading
        AppHelper.getFunnelChartInfo('loading').then(response => {
            setFunnelChartLoading(response?.data?.attributes)
            setFunnelChartLoadingLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching  funnel chart information for loading agency`, err);
            notification.error({
                message: "Error while fetching  funnel chart information for loading agency",
                duration: 3
            })
            setFunnelChartLoadingLoadingBar(false)
        })

        // get funnel chart section information to display for unloading
        AppHelper.getFunnelChartInfo('unloading').then(response => {
            setFunnelChartUnloading(response?.data?.attributes)
            setFunnelChartUnloadingLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching  funnel chart information for unloading agency`, err);
            notification.error({
                message: "Error while fetching  funnel chart information for unloading agency",
                duration: 3
            })
            setFunnelChartUnloadingLoadingBar(false)
        })

        // get funnel chart average work done info
        AppHelper.getFunnelChartAverageWorkInfo('transporting').then(response => {
            setFunnelChartTransportationAverage(response?.data?.attributes)
            setFunnelChartTransportationAverageLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching funnel chart average work done`, err);
            notification.error({
                message: "Error while fetching funnel chart average work done",
                duration: 3
            })
            setFunnelChartTransportationAverageLoadingBar(false)
        })

        // get transportation bar chart info
        AppHelper.getTransportationBarChartInfo().then(response => {
            setTransportationBarChartInfo(response?.data?.attributes)
            setTransportationBarChartInfoLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching transportation bar chart info`, err);
            notification.error({
                message: "Error while fetching transportation bar chart info",
                duration: 3
            })
            setTransportationBarChartInfoLoadingBar(false)
        })

        // get last 15 days avg trip and avg weight line chart info
        AppHelper.getLast15DaysAvgTripAndAvgWeightLineChart().then(response => {
            setAvg15DaysTripWeightLineChart(response?.data?.attributes)
            setAvg15DaysTripWeightLineChartInfoLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching last 15 days avg trip and avg weight line chart`, err);
            notification.error({
                message: "Error while fetching last 15 days avg trip and avg weight line chart",
                duration: 3
            })
            setAvg15DaysTripWeightLineChartInfoLoadingBar(false)
        })

        // get timeline chart info
        AppHelper.getTimelineChartInfo().then(response => {
            setTimelineChartInfo(response?.data?.attributes)
            setTimelineChartInfoLoadingBar(false)
        }).catch(err => {
            console.log(`Error while fetching project timeline chart info`, err);
            notification.error({
                message: "Error while fetching project timeline chart info",
                duration: 3
            })
            setTimelineChartInfoLoadingBar(false)
        })

        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setAgencies(response)
            setTransportAgenciesDropDown(AppHelper.getAgencyDropDown(response, 'Transportation', sessionGlobalValues));
        });

        document.title = "Dashboard";
    }, []);

    return (
        <>
            {
                projectDetails?.Project_Completed
                    ?
                    <div className={classes['projectCompletedMessage']}>Project Completed</div>
                    :
                    ""
            }
            {/* -------------------Graph(Work detail, Bill wise trip and cum detail------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <Typography
                                variant="body1"
                                color="#555555"
                                className={classes["dashboard-top-box-heading"]}
                            >
                                Work Detail
                            </Typography>
                            <WorkDetailGraph graphDetails={workDetailsGraphInformation} displayLoadingBar={workDetailsGraphInformationLoadingBar} classes={classes} />
                        </div>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Bill Wise CUM and Trip Detail
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Button
                                            size="small"
                                            className={
                                                selectedGraph === "cum"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleGraphChange("cum")}
                                        >
                                            Cum
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                selectedGraph === "noOfTrip"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleGraphChange("noOfTrip")}
                                        >
                                            No. of Trip
                                        </Button>
                                    </Flex>
                                </div>
                            </div>

                            {selectedGraph === "noOfTrip" && (
                                /* Render your No. of Trip graph component here */
                                <BillWiseTripGraph graphDetails={billWiseTripCountChartInformation} displayLoadingBar={billWiseTripCountChartInformationLoadingBar} />
                            )}

                            {selectedGraph === "cum" && (
                                /* Render your Cum graph component here */
                                <BillCumDetailGraph graphDetails={billWiseTripCountChartInformation} displayLoadingBar={billWiseTripCountChartInformationLoadingBar} />
                            )}
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* -------------------Graph(Work detail, Bill wise trip and cum detail------------- */}

            {/* -------------------work in progres, avg. lead, fleet avg.----------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    container
                    spacing={0}
                    className={classes["headerMarginTop"]}
                >
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-first"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={workInProgressImg}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {workInProgressWidgetInformationLoadingBar ? <Spin /> : <Tooltip title={workInProgressWidgetInformation?.totalWeightWorkInProgressCUM ?? 0}> {AppHelper.formatNumber(workInProgressWidgetInformation?.totalWeightWorkInProgressCUM) ?? 0}  </Tooltip>} CUM

                                        {/* {AppHelper.formatNumber(agencyData?.meta?.totalLoading ?? 0)} */}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Work In Progress
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={workInProgressLocationTripImg}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {workInProgressWidgetInformationLoadingBar ? <Spin /> : workInProgressWidgetInformation?.totalWorkInProgressTripCount ?? 0} Trips
                                        {/* {AppHelper.formatNumber(agencyData?.meta?.cancelledLoading ?? 0)} */}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Work In Progress
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-third"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={avgLeadImg}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {workInProgressWidgetInformationLoadingBar ? <Spin /> : <Tooltip title={workInProgressWidgetInformation?.totalBilledCUM ?? 0}> {AppHelper.formatNumber(workInProgressWidgetInformation?.totalBilledCUM) ?? 0}  </Tooltip>} CUM
                                        {/* {AppHelper.formatNumber(agencyData?.meta?.unloadPending ?? 0)} */}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Billed - CUM
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={fleetAvgImg}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {workInProgressWidgetInformationLoadingBar ? <Spin /> : workInProgressWidgetInformation?.totalBilledTripCount ?? 0} Trips
                                        {/* {AppHelper.formatNumber(agencyData?.meta?.unloadPending ?? 0)} */}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white">
                                        Billed - No. of Trip
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* -------------------work in progres, avg. lead, fleet avg.----------------------- */}

            {/* -------------------------------Project Timeline--------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <div className={classes["dashboard-top-box-heading"]}>
                        <div className={classes["bill-wise-flex-item"]}>
                            <Typography variant="body1" color="#555555">
                                {" "}
                                Project Timeline 
                                {timelineChartInfo?.isProjectCompletedBeforeTime ? <span className={classes['projectCompletedBeforeTime']}> <img src={checkIcon} alt="" width={16} /> Completed Before Time </span> : timelineChartInfo?.isProjectCompleted ? <span className={classes['projectCompletedBeforeTime']}> <img src={checkIcon} alt="" width={16} /> Completed </span> : ''}
                            </Typography>

                            <Flex gap="1rem">
                                <Typography
                                    variant="body1"
                                    color="#ffffff"
                                    className={classes["blank-day-text"]}
                                    fontWeight="bold"
                                >
                                    {timelineChartInfo?.noOfBlankDays ?? 0}
                                </Typography>
                                <Typography variant="body1" color="initial">
                                    No. of Blank Days
                                </Typography>
                            </Flex>
                        </div>
                    </div>
                    <div style={{ padding: "24px" }}>
                        {
                            timelineChartInfoLoadingBar
                                ?
                                <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                :
                                <ProjectTimelineGraph timelineChartInfo={timelineChartInfo} projectDetails={projectDetails} />
                        }
                    </div>
                </div>
            </Grid>
            {/* -------------------------------Project Timeline--------------------------------- */}

            {/* ----------------------------------Effective Rates------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <fieldset className={classes["fieldset-box"]}>
                    <legend>
                        <Typography
                            variant="h5"
                            color="#555555"
                            padding="0 8px"
                            letterSpacing={0.5}
                        >
                            Effective Rates
                        </Typography>
                    </legend>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        container
                        spacing={0}
                        className={classes["headerMarginTopScroll"]}
                    >
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography
                                variant="body1"
                                className={`${classes["total-items-second"]} ${classes["total-item-first"]}`}
                                fontSize={14}
                            >
                                <div className={classes["header-grid-flex"]}>
                                    <div>
                                        <img
                                            src={transportImg}
                                            alt="Total count"
                                            className={classes["header-grid-image"]}
                                        />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="body"
                                            color="#682a01"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            ₹ {effectiveRateInformation?.transportationEffectiveRate ?? 0}
                                        </Typography>
                                        <br></br>
                                        <Typography variant="body" color="#682a01">
                                            Transportation
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography
                                variant="body1"
                                className={`${classes["total-items-second"]} ${classes["total-item-second"]}`}
                                fontSize={14}
                            >
                                <div className={classes["header-grid-flex"]}>
                                    <div>
                                        <img
                                            src={loadingImg}
                                            alt="Total count"
                                            className={classes["header-grid-image"]}
                                        />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="body"
                                            color="#4ca6ff"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            ₹ {effectiveRateInformation?.loadingEffectiveRate ?? 0}
                                        </Typography>
                                        <br></br>
                                        <Typography variant="body" color="#4ca6ff">
                                            Loading
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography
                                variant="body1"
                                className={`${classes["total-items-second"]} ${classes["total-item-third"]}`}
                                fontSize={14}
                            >
                                <div className={classes["header-grid-flex"]}>
                                    <div>
                                        <img
                                            src={unloadingImg}
                                            alt="Total count"
                                            className={classes["header-grid-image"]}
                                        />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="body"
                                            color="#479036"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            ₹ {effectiveRateInformation?.unloadingEffectiveRate ?? 0}
                                        </Typography>
                                        <br></br>
                                        <Typography variant="body" color="#479036">
                                            Unloading
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={3} xs={3}>
                            <Typography
                                variant="body1"
                                className={`${classes["total-items-second"]} ${classes["total-item-fourth"]}`}
                                fontSize={14}
                            >
                                <div className={classes["header-grid-flex"]}>
                                    <div>
                                        <img
                                            src={ltrImg}
                                            alt="Total count"
                                            className={classes["header-grid-image"]}
                                        />
                                    </div>
                                    <div>
                                        <Typography
                                            variant="body"
                                            color="#ce9a02"
                                            className={classes["header-text-fontSize"]}
                                        >
                                            ₹ {effectiveRateInformation?.totalEffectiveDieselRate ?? 0}
                                        </Typography>
                                        <br></br>
                                        <Typography variant="body" color="#ce9a02">
                                            Diesel Rate
                                        </Typography>
                                    </div>
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                </fieldset>
            </Grid>
            {/* ----------------------------------Effective Rates------------------------------- */}

            {/* ------------------------------------Trip Detail--------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <Typography
                        variant="body1"
                        color="#555555"
                        className={classes["dashboard-top-box-heading"]}
                    >
                        Trip Detail
                    </Typography>

                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        spacing={6}
                        container
                        className={classes["trip-detail-padding"]}
                        textAlign="center"
                    >
                        <Grid item lg={5} md={12} sm={12} xs={12}>
                            <div className={classes["trip-highlight"]}>
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    Success Trips
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.successTripCount ?? 0}
                                    </Typography>
                                    <img src={successTripImg} alt="Success Trip" />
                                </div>
                            </div>

                            <div
                                className={classes["trip-highlight"]}
                                style={{ marginTop: "20px" }}
                            >
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    Tentative Trips Left
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.tentativeTripsLeft ?? 0}
                                    </Typography>
                                    <img src={tentativeTripleft} alt="Success Trip" />
                                </div>
                            </div>

                            <div
                                className={classes["trip-highlight"]}
                                style={{ marginTop: "20px" }}
                            >
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    Cancelled With HSD
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.cancelledWithHSD ?? 0}
                                    </Typography>
                                    <img src={avgTripPerDay} alt="Success Trip" />
                                </div>
                            </div>

                            <div
                                className={classes["trip-highlight"]}
                                style={{ marginTop: "20px" }}
                            >
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    Maximum Trip in a Single Day
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.maximumTripInSingleDay ?? 0}
                                    </Typography>
                                    <img src={maxTripImg} alt="Success Trip" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={5} md={12} sm={12} xs={12}>
                            <div className={classes["trip-highlight"]}>
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    Cancelled Trips
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.cancelledTrips ?? 0}
                                    </Typography>
                                    <img src={cancelledImg} alt="Success Trip" />
                                </div>
                            </div>

                            <div
                                className={classes["trip-highlight"]}
                                style={{ marginTop: "20px" }}
                            >
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    To Be Unloaded Trips
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.toBeUnloadedTrips ?? 0}
                                    </Typography>
                                    <img src={unloadTripImg} alt="Success Trip" />
                                </div>
                            </div>

                            <div
                                className={classes["trip-highlight"]}
                                style={{ marginTop: "20px" }}
                            >
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    To Be HSD Left Trips
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.toBeHSDLeftTrips ?? 0}
                                    </Typography>
                                    <img src={hsdLeftTripImg} alt="Success Trip" />
                                </div>
                            </div>

                            <div
                                className={classes["trip-highlight"]}
                                style={{ marginTop: "20px" }}
                            >
                                <Typography
                                    variant="body1"
                                    color="#555555"
                                    className={classes["marginauto-text"]}
                                >
                                    Challan Missing
                                </Typography>
                                <div className={classes["amount-highlight"]}>
                                    <Typography variant="body1" color="#fff">
                                        {tripsDetailsInformation?.challanMissing ?? 0}
                                    </Typography>
                                    <img src={challanMissTripImg} alt="Success Trip" />
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={2} md={12} sm={12} xs={12}>
                            <div className={classes["avg-trip-box"]}>
                                <Typography
                                    variant="h5"
                                    color="#fff"
                                    fontWeight="bold"
                                    style={{ backgroundColor: "#c89705" }}
                                >
                                    {tripsDetailsInformation?.avgTripPerDay ?? 0}
                                </Typography>
                                <Typography variant="body1" color="#c89705">
                                    Avg. Trip Per Day
                                </Typography>
                            </div>
                            <div className={classes["avg-trip-box"]}>
                                <Typography variant="h5" color="#fff" fontWeight="bold" style={{ backgroundColor: ((tripsDetailsInformation?.avgTrip15Days < tripsDetailsInformation?.avgTripPerDay) ? "#f00" : "#008d38") }}>
                                    {tripsDetailsInformation?.avgTrip15Days ?? 0}
                                </Typography>
                                <Typography variant="body1" color="#008d38">
                                    Avg. Trip in 15 Days
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {/* ------------------------------------Trip Detail--------------------------------- */}

            {/* -----------------------------------Trip Aggregation----------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <Typography
                        variant="body1"
                        color="#555555"
                        className={classes["dashboard-top-box-heading"]}
                    >
                        Trip Aggregation
                    </Typography>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        spacing={0}
                        className={classes["trip-aggregation-box-padding"]}
                    >
                        <Grid item lg={6} md={6} sm={6} xs={12}>
                            <Typography
                                variant="body1"
                                color="initial"
                                className={classes["bottom-border-line"]}
                            >
                                Maximum Wt (MT)
                            </Typography>
                            <Typography
                                variant="body1"
                                color="initial"
                                className={classes["bottom-border-line"]}
                            >
                                Minimum Wt (MT)
                            </Typography>
                            <Typography
                                variant="body1"
                                color="initial"
                                className={classes["bottom-border-line"]}
                            >
                                Avg Lead (KM)
                            </Typography>
                            <Typography variant="body1" color="initial">
                                Fleet Avg (KM/Ltr)
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={6} xs={12} textAlign="right">
                            <Typography
                                variant="body1"
                                color="initial"
                                fontWeight="bold"
                                className={classes["bottom-border-line"]}
                            >
                                {tripAggregationInformation?.maxWeightInaTrip ?? 0}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="initial"
                                fontWeight="bold"
                                className={classes["bottom-border-line"]}
                            >
                                {tripAggregationInformation?.minWeightInaTrip ?? 0}
                            </Typography>
                            <Typography
                                variant="body1"
                                color="initial"
                                fontWeight="bold"
                                className={classes["bottom-border-line"]}
                            >
                                {tripAggregationInformation?.avgLeadKM ?? 0}
                            </Typography>
                            <Typography variant="body1" color="initial" fontWeight="bold">
                                {tripAggregationInformation?.fleetAVG ?? 0}
                            </Typography>
                        </Grid>
                        <Grid item lg={3} md={3} sm={12} xs={12}>
                            <div className={classes["avg-trip-box"]}>
                                <Typography
                                    variant="h6"
                                    color="#fff"
                                    fontWeight="bold"
                                    style={{ backgroundColor: "#c89705" }}
                                >
                                    {tripAggregationInformation?.avgWeightPerDay ?? 0}
                                </Typography>
                                <Typography variant="body1" color="#c89705">
                                    Avg. WT Per Trip (MT)
                                </Typography>
                            </div>

                            <div className={classes["avg-trip-box"]}>
                                <Typography variant="h6" color="#fff" fontWeight="bold" style={{ backgroundColor: ((tripAggregationInformation?.avgWeightLast15Days < tripAggregationInformation?.avgWeightPerDay) ? "#f00" : "#008d38") }}>
                                    {tripAggregationInformation?.avgWeightLast15Days ?? 0}
                                </Typography>
                                <Typography variant="body1" color="#008d38">
                                    Avg. WT Per Trip 15 days (MT)
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {/* -----------------------------------Trip Aggregation----------------------------- */}

            {/* --------avg. Per Day working, vendor payment left, total other deduction-------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <Typography
                                variant="body1"
                                color="#555555"
                                className={classes["dashboard-top-box-heading"]}
                            >
                                Avg. Per Day Working (MT)
                            </Typography>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                container
                                spacing={0}
                                className={classes["weight-wise-box-padding"]}
                            >
                                <Grid item lg={6} md={6} sm={6} xs={6}>
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        className={classes["bottom-border-line"]}
                                    >
                                        As per PO
                                    </Typography>
                                    <Typography variant="body1" color="initial">
                                        All time
                                    </Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={6} xs={6} textAlign="right">
                                    <Typography
                                        variant="body1"
                                        color="initial"
                                        fontWeight="bold"
                                        className={classes["bottom-border-line"]}
                                    >
                                        {avgPerDayInformation?.avgPerDayWorkingAsPO ?? 0} MT
                                    </Typography>
                                    <Typography variant="body1" color="initial" fontWeight="bold">
                                        {avgPerDayInformation?.avgAllTime ?? 0} MT
                                    </Typography>
                                </Grid>
                                <Grid item lg={3} md={3} sm={12} xs={12}>
                                    <div className={avgPerDayInformation?.difference >= 0 ? classes["avg-trip-box"] : classes["avg-trip-box-red"]}>
                                        <Typography variant="h6" color="#fff" fontWeight="bold">
                                            {avgPerDayInformation?.difference ? Math.abs(avgPerDayInformation?.difference) : 0}
                                        </Typography>
                                        <Typography variant="body1" color="initial">
                                            Difference
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Grid
                            className={classes["vender-other-box-border"]}
                            textAlign="center"
                        >
                            <img src={vendorPaymentImg} alt="Vendor payment" />
                            <Typography variant="h4" color="initial">
                                {avgPerDayInformation?.vendorsPaymentLeft && avgPerDayInformation?.vendorsPaymentLeft.toLocaleString('en-IN')}
                            </Typography>
                            <Typography variant="body1" color="initial" fontSize="1.3rem">
                                Vendor Payment Left
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={12}>
                        <Grid
                            className={classes["vender-other-box-border"]}
                            textAlign="center"
                        >
                            <img src={totalOtherDeductionImg} alt="Other deduction" />
                            <Typography variant="h4" color="initial">
                                {avgPerDayInformation?.totalOtherDeduction && avgPerDayInformation?.totalOtherDeduction.toLocaleString('en-IN')}
                            </Typography>
                            <Typography variant="body1" color="initial" fontSize="1.3rem">
                                Total Other Deduction
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {/* -------avg. Per Day working, vendor payment left, total other deduction--------- */}

            {/* ---------------------------------------weight----------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <Typography
                        variant="body1"
                        color="#555555"
                        className={classes["dashboard-top-box-heading"]}
                    >
                        Weight
                    </Typography>

                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        spacing={2}
                        className={classes["expense-internal-box"]}
                    >
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Typography variant="h5" color="#555555" marginBottom="10px">
                                Transportation
                            </Typography>
                            <div
                                className={`${classes["expenses-border-box"]} ${classes["expenses-border-box-height"]}`}
                            >
                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-background-first"]}`}
                                >
                                    <Typography variant="body1" color="#555555">
                                        {weightInformation?.totalTransportationWeight ?? 0}
                                        <br />
                                        <span>Total Transportation Wt</span>
                                    </Typography>
                                    <img src={transportImgSecond} alt="" />
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Billed Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalBilledTransportationWeight ?? 0}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Round off Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalRoundOffTransportationWeight ?? 0}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Unbilled Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalUnbilledTransportationWeight ?? 0}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Typography variant="h5" color="#555555" marginBottom="10px">
                                Loading
                            </Typography>
                            <div
                                className={`${classes["expenses-border-box"]} ${classes["expenses-border-box-height"]}`}
                            >
                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-background-second"]}`}
                                >
                                    <Typography variant="body1" color="#555555">
                                        {weightInformation?.totalLoadingWeight ?? 0}
                                        <br />
                                        <span>Total Loading Wt</span>
                                    </Typography>
                                    <img src={loadingImgSecond} alt="" />
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Billed Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalBilledLoadingWeight ?? 0}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Round off Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalRoundOffLoadingWeight ?? 0}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Unbilled Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalUnbilledLoadingWeight ?? 0}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Typography variant="h5" color="#555555" marginBottom="10px">
                                Unloading
                            </Typography>

                            <div
                                className={`${classes["expenses-border-box"]} ${classes["expenses-border-box-height"]}`}
                            >
                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-background-third"]}`}
                                >
                                    <Typography variant="body1" color="#555555">
                                        {weightInformation?.totalUnloadingWeight ?? 0}
                                        <br />
                                        <span>Total Unloading Wt</span>
                                    </Typography>
                                    <img src={unloadingImgSecond} alt="" />
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Billed Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalBilledUnloadingWeight ?? 0}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Round off Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalRoundOffUnloadingWeight ?? 0}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Unbilled Wt (MT){" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        {weightInformation?.totalUnbilledUnloadingWeight ?? 0}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {/* ---------------------------------------weight----------------------------------- */}

            {/* --------------------------------------expenses---------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <Typography
                        variant="body1"
                        color="#555555"
                        className={classes["dashboard-top-box-heading"]}
                    >
                        Expenses
                    </Typography>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        className={classes["total-expenses-box"]}
                    >
                        <Grid item lg={5} md={5} sm={12} xs={12}>
                            <Typography variant="h4" color="#1f226f">
                                Total Expense
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            lg={7}
                            md={7}
                            sm={12}
                            xs={12}
                            container
                            spacing={1}
                            textAlign="center"
                        >
                            <Grid item lg={3.5} md={12} sm={12} xs={12}>
                                <Typography variant="h5" color="#1f226f">
                                    ₹ {(expensesInformation?.totalBilledAmount ?? 0).toLocaleString('en-IN')}
                                </Typography>
                                <Typography variant="body1" color="#1f226f">
                                    Total Billed Amt.
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={12} sm={12} xs={12}>
                                <Typography variant="h4" color="initial">
                                    +
                                </Typography>
                            </Grid>
                            <Grid item lg={3.5} md={12} sm={12} xs={12}>
                                <Typography variant="h5" color="#1f226f">
                                    ₹ {(expensesInformation?.totalUnBilledAmount ?? 0).toLocaleString('en-IN')}
                                </Typography>
                                <Typography variant="body1" color="#1f226f">
                                    Total Unbilled Amt.
                                </Typography>
                            </Grid>
                            <Grid item lg={0.5} md={12} sm={12} xs={12}>
                                <Typography variant="h4" color="initial">
                                    =
                                </Typography>
                            </Grid>
                            <Grid item lg={4} md={12} sm={12} xs={12}>
                                <Typography variant="h5" color="#1f226f">
                                    ₹ {(expensesInformation?.finalBillingAmount ?? 0).toLocaleString('en-IN')}
                                </Typography>
                                <Typography variant="body1" color="#1f226f">
                                    Total Billing Amt.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        spacing={2}
                        className={classes["expense-internal-box"]}
                    >
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Typography variant="h5" color="#555555" marginBottom="10px">
                                Transportation
                            </Typography>
                            <div className={classes["expenses-border-box"]}>
                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-background-first"]}`}
                                >
                                    <Typography variant="body1" color="#555555">
                                        ₹ {(expensesInformation?.totalTransportationAmount ?? 0).toLocaleString('en-IN')}
                                        <br />
                                        <span>Total Expenses</span>
                                    </Typography>
                                    <img src={transportImgSecond} alt="" />
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Billed Amount{" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        ₹ {(expensesInformation?.totalTransportationBilledAmount ?? 0).toLocaleString('en-IN')}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Unbilled Amount{" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        ₹ {(expensesInformation?.totalTransportationUnBilledAmount ?? 0).toLocaleString('en-IN')}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Typography variant="h5" color="#555555" marginBottom="10px">
                                Loading
                            </Typography>
                            <div className={classes["expenses-border-box"]}>
                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-background-second"]}`}
                                >
                                    <Typography variant="body1" color="#555555">
                                        ₹ {(expensesInformation?.totalLoadingAmount ?? 0).toLocaleString('en-IN')}
                                        <br />
                                        <span>Total Expenses</span>
                                    </Typography>
                                    <img src={loadingImgSecond} alt="" />
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Billed Amount{" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        ₹ {(expensesInformation?.totalLoadingBilledAmount ?? 0).toLocaleString('en-IN')}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Unbilled Amount{" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        ₹ {(expensesInformation?.totalLoadingUnBilledAmount ?? 0).toLocaleString('en-IN')}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Typography variant="h5" color="#555555" marginBottom="10px">
                                Unloading
                            </Typography>

                            <div className={classes["expenses-border-box"]}>
                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-background-third"]}`}
                                >
                                    <Typography variant="body1" color="#555555">
                                        ₹ {(expensesInformation?.totalUnloadingAmount ?? 0).toLocaleString('en-IN')}
                                        <br />
                                        <span>Total Expenses</span>
                                    </Typography>
                                    <img src={unloadingImgSecond} alt="" />
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Billed Amount{" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        ₹ {(expensesInformation?.totalUnloadingBilledAmount ?? 0).toLocaleString('en-IN')}
                                    </Typography>
                                </div>

                                <div
                                    className={`${classes["flex-items-under-box"]} ${classes["item-padding"]}`}
                                >
                                    <Typography variant="body1" color="#706f6f">
                                        Unbilled Amount{" "}
                                    </Typography>
                                    <Typography variant="body1" color="#706f6f">
                                        ₹ {(expensesInformation?.totalUnloadingUnBilledAmount ?? 0).toLocaleString('en-IN')}
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {/* --------------------------------------expenses---------------------------------- */}

            {/* -------------------------------------Petrol Pump-------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <Typography
                        variant="body1"
                        color="#555555"
                        className={classes["dashboard-top-box-heading"]}
                    >
                        Petrol Pump
                    </Typography>
                    <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                        <Grid item lg={8} md={12} sm={12} xs={12}>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                container
                                spacing={0}
                                className={classes["petrol-box-padding"]}
                                textAlign="center"
                            >
                                <Grid item lg={5} md={5} sm={4} xs={12}>
                                    <Typography variant="body1" color="#908f8f">
                                        Petrol Pump Name
                                    </Typography>
                                </Grid>
                                <Grid item lg={2} md={2} sm={4} xs={12}>
                                    <Typography variant="body1" color="#908f8f">
                                        Ltr.
                                    </Typography>
                                </Grid>
                                <Grid item lg={5} md={5} sm={4} xs={12}>
                                    <Typography variant="body1" color="#908f8f">
                                        Amount
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                container
                                spacing={0}
                                className={classes["petrol-pump-table-padding"]}
                                textAlign="center"
                            >
                                {
                                    petrolPumpInformationLoadingBar
                                        ?
                                        <div className={classes['petrolPumpArea']}>
                                            <Spin className={classes['spinStyle']} size="large" />
                                        </div>
                                        :
                                        petrolPumpInformation?.petrolPumpsInfoArray?.map((item) => (
                                            <>
                                                <Grid item lg={5} md={5} sm={4} xs={12}>
                                                    <Typography variant="body1" color="#555555" className={classes["bottom-border-line"]}>
                                                        {item?.petrolPumpName?.length > 20 ? <span title={item?.petrolPumpName}>{item?.petrolPumpName.substring(0, 20)}....</span> : item?.petrolPumpName ?? "-"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={2} md={2} sm={4} xs={12}>
                                                    <Typography variant="body1" color="#555555" className={classes["bottom-border-line"]}>
                                                        {item?.totalLtr ?? 0}
                                                    </Typography>
                                                </Grid>
                                                <Grid item lg={5} md={5} sm={4} xs={12}>
                                                    <Typography variant="body1" color="#555555" className={classes["bottom-border-line"]}>
                                                        ₹ {item?.totalHSDAMount ?? 0}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        ))
                                }
                            </Grid>
                        </Grid>
                        <Grid item lg={4} md={12} sm={12} xs={12}>
                            <Grid padding="10px 20px">
                                <Typography
                                    variant="body1"
                                    className={`${classes["total-items-third"]} ${classes["total-item-first"]}`}
                                    fontSize={14}
                                >
                                    <div className={classes["header-grid-flex"]}>
                                        <div>
                                            <img
                                                src={totalAmountImgPP}
                                                alt="Total count"
                                                className={`${classes["header-grid-image"]} ${classes["amount-image"]}`}
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="body"
                                                color="#0067b6"
                                                className={classes["header-text-fontSize"]}
                                            >
                                                ₹ {petrolPumpInformation?.totalAmount ?? 0}
                                            </Typography>
                                            <br></br>
                                            <Typography variant="body" color="#0067b6">
                                                Total Amount
                                            </Typography>
                                        </div>
                                    </div>
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className={`${classes["total-items-third"]} ${classes["total-item-second"]}`}
                                    fontSize={14}
                                >
                                    <div className={classes["header-grid-flex"]}>
                                        <div>
                                            <img
                                                src={totalLtrImgPP}
                                                alt="Total count"
                                                className={`${classes["header-grid-image"]} ${classes["ltr-image"]}`}
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="body"
                                                color="#dfa803"
                                                className={classes["header-text-fontSize"]}
                                            >
                                                {petrolPumpInformation?.totalLitre ?? 0}
                                            </Typography>
                                            <br></br>
                                            <Typography variant="body" color="#dfa803">
                                                Total Ltr.
                                            </Typography>
                                        </div>
                                    </div>
                                </Typography>

                                <Typography
                                    variant="body1"
                                    className={`${classes["total-items-third"]} ${classes["total-item-third"]}`}
                                    fontSize={14}
                                >
                                    <div className={classes["header-grid-flex"]}>
                                        <div>
                                            <img
                                                src={differenceImgPP}
                                                alt="Total count"
                                                className={`${classes["header-grid-image"]} ${classes["diff-image"]}`}
                                            />
                                        </div>
                                        <div>
                                            <Typography
                                                variant="body"
                                                color="#555555"
                                                className={classes["header-text-fontSize"]}
                                            >
                                                {petrolPumpInformation?.difference ?? 0}
                                            </Typography>
                                            <br></br>
                                            <Typography variant="body" color="#555555">
                                                Difference
                                            </Typography>
                                        </div>
                                    </div>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {/* -------------------------------------Petrol Pump-------------------------------- */}

            {/* -------------------------------------Diesel------------------------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <div className={classes["box-border-dashboard"]}>
                    <Typography
                        variant="body1"
                        color="#555555"
                        className={classes["dashboard-top-box-heading"]}
                    >
                        Diesel
                    </Typography>

                    <Grid
                        item
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        container
                        spacing={2}
                        padding="20px"
                    >
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography variant="body1" color="initial" marginBottom="10px">
                                Transporter
                            </Typography>
                            <div
                                className={`${classes["diesel-wrapper-box-flex"]} ${classes["first-box-bg-color"]}`}
                            >
                                <div>
                                    <Typography variant="body1" color="#682A01" fontWeight="bold">
                                        {dieselInformation?.transportationDieselRatio ?? 0}%
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="#682A01"
                                        textAlign="center"
                                    >
                                        Ratio
                                    </Typography>
                                </div>
                                <span className={classes["span-line"]}></span>
                                <div>
                                    <Typography variant="body1" color="#682A01" fontWeight="bold">
                                        {dieselInformation?.transportationLtrQuantity ?? 0} Ltr.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="#682A01"
                                        textAlign="center"
                                    >
                                        Quantity
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography variant="body1" color="initial" marginBottom="10px">
                                Loading
                            </Typography>
                            <div
                                className={`${classes["diesel-wrapper-box-flex"]} ${classes["second-box-bg-color"]}`}
                            >
                                <div>
                                    <Typography variant="body1" color="#4CA6FF" fontWeight="bold">
                                        {dieselInformation?.loadingDieselRatio ?? 0}%
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="#4CA6FF"
                                        textAlign="center"
                                    >
                                        Ratio
                                    </Typography>
                                </div>
                                <span className={classes["span-line"]}></span>
                                <div>
                                    <Typography variant="body1" color="#4CA6FF" fontWeight="bold">
                                        {dieselInformation?.loadingLtrQuantity ?? 0} Ltr.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="#4CA6FF"
                                        textAlign="center"
                                    >
                                        Quantity
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item lg={4} md={4} sm={12} xs={12}>
                            <Typography variant="body1" color="initial" marginBottom="10px">
                                Unloading
                            </Typography>
                            <div
                                className={`${classes["diesel-wrapper-box-flex"]} ${classes["third-box-bg-color"]}`}
                            >
                                <div>
                                    <Typography variant="body1" color="#479036" fontWeight="bold">
                                        {dieselInformation?.unloadingDieselRatio ?? 0}%
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="#479036"
                                        textAlign="center"
                                    >
                                        Ratio
                                    </Typography>
                                </div>
                                <span className={classes["span-line"]}></span>
                                <div>
                                    <Typography variant="body1" color="#479036" fontWeight="bold">
                                        {dieselInformation?.unloadingLtrQuantity ?? 0} Ltr.
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        color="#479036"
                                        textAlign="center"
                                    >
                                        Quantity
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            {/* -------------------------------------Diesel------------------------------------- */}

            {/* -----------------------------total agencies, total vehicle---------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={8} md={12} sm={12} xs={12}>
                        {" "}
                        <fieldset className={classes["fieldset-box"]}>
                            <legend>
                                <Typography
                                    variant="h5"
                                    color="#555555"
                                    padding="0 8px"
                                    letterSpacing={0.5}
                                >
                                    Total Agencies
                                </Typography>
                            </legend>
                            <Grid
                                item
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                container
                                spacing={0}
                                className={classes["headerMarginTopScroll-total"]}
                            >
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={`${classes["total-items-fourth"]} ${classes["total-item-first"]}`}
                                        fontSize={14}
                                    >
                                        <div className={classes["header-grid-flex"]}>
                                            <div>
                                                <img
                                                    src={transportImg}
                                                    alt="Total count"
                                                    className={classes["header-grid-image"]}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body"
                                                    color="#682a01"
                                                    className={classes["header-text-fontSize"]}
                                                >
                                                    {agenciesVehicleDetailsInformation?.totalTransportationAgencies ?? 0}
                                                </Typography>
                                                <br></br>
                                                <Typography variant="body" color="#682a01">
                                                    Transportation
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes["header-grid-flex"]} ${classes["top-box-bottom"]}`}
                                        >
                                            <Typography variant="body1" color="initial">
                                                Last 15 Days
                                            </Typography>
                                            <Typography variant="body1" color="initial">
                                                {agenciesVehicleDetailsInformation?.totalTranportationAgencies15Days ?? 0}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={`${classes["total-items-fourth"]} ${classes["total-item-second"]}`}
                                        fontSize={14}
                                    >
                                        <div className={classes["header-grid-flex"]}>
                                            <div>
                                                <img
                                                    src={loadingImg}
                                                    alt="Total count"
                                                    className={classes["header-grid-image"]}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body"
                                                    color="#4ca6ff"
                                                    className={classes["header-text-fontSize"]}
                                                >
                                                    {agenciesVehicleDetailsInformation?.totalLoadingAgencies ?? 0}
                                                </Typography>
                                                <br></br>
                                                <Typography variant="body" color="#4ca6ff">
                                                    Loading
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes["header-grid-flex"]} ${classes["top-box-bottom"]}`}
                                        >
                                            <Typography variant="body1" color="initial">
                                                Last 15 Days
                                            </Typography>
                                            <Typography variant="body1" color="initial">
                                                {agenciesVehicleDetailsInformation?.totalLoadingAgencies15Days ?? 0}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={`${classes["total-items-fourth"]} ${classes["total-item-third"]}`}
                                        fontSize={14}
                                    >
                                        <div className={classes["header-grid-flex"]}>
                                            <div>
                                                <img
                                                    src={unloadingImg}
                                                    alt="Total count"
                                                    className={classes["header-grid-image"]}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body"
                                                    color="#479036"
                                                    className={classes["header-text-fontSize"]}
                                                >
                                                    {agenciesVehicleDetailsInformation?.totalUnloadingAgencies ?? 0}
                                                </Typography>
                                                <br></br>
                                                <Typography variant="body" color="#479036">
                                                    Unloading
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes["header-grid-flex"]} ${classes["top-box-bottom"]}`}
                                        >
                                            <Typography variant="body1" color="initial">
                                                Last 15 Days
                                            </Typography>
                                            <Typography variant="body1" color="initial">
                                                {agenciesVehicleDetailsInformation?.totalUnloadingAgencies15Days ?? 0}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} sm={12} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={`${classes["total-items-fourth"]} ${classes["total-item-fourth"]}`}
                                        fontSize={14}
                                    >
                                        <div className={classes["header-grid-flex"]}>
                                            <div>
                                                <img
                                                    src={petrolPumpImg}
                                                    alt="Total count"
                                                    className={classes["header-grid-image"]}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body"
                                                    color="#ce9a02"
                                                    className={classes["header-text-fontSize"]}
                                                >
                                                    {agenciesVehicleDetailsInformation?.totalPetrolPumpAgencies ?? 0}
                                                </Typography>
                                                <br></br>
                                                <Typography variant="body" color="#ce9a02">
                                                    Petrol Pump Agency
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes["header-grid-flex"]} ${classes["top-box-bottom"]}`}
                                        >
                                            <Typography variant="body1" color="initial">
                                                Last 15 Days
                                            </Typography>
                                            <Typography variant="body1" color="initial">
                                                {agenciesVehicleDetailsInformation?.totalPetrolPumpAgencies15Days ?? 0}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                    <Grid item lg={4} md={12} sm={12} xs={12}>
                        <fieldset className={classes["fieldset-box"]}>
                            <legend>
                                <Typography
                                    variant="h5"
                                    color="#555555"
                                    padding="0 8px"
                                    letterSpacing={0.5}
                                >
                                    Total Vehicle
                                </Typography>
                            </legend>
                            <Grid item lg={12} md={12} sm={12} xs={12} container spacing={0}>
                                <Grid item lg={12} md={6} sm={12} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={`${classes["total-items-fourth"]} ${classes["total-item-fivth"]}`}
                                        fontSize={14}
                                    >
                                        <div className={classes["header-grid-flex"]}>
                                            <div>
                                                <img
                                                    src={vehicleUsedImg}
                                                    alt="Total count"
                                                    className={classes["header-grid-image"]}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body"
                                                    color="#fff"
                                                    className={classes["header-text-fontSize"]}
                                                >
                                                    {agenciesVehicleDetailsInformation?.totalVehicles ?? 0}
                                                </Typography>
                                                <br></br>
                                                <Typography variant="body" color="#fff">
                                                    Vehicle Used
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes["header-grid-flex"]} ${classes["top-box-bottom"]}`}
                                        >
                                            <Typography variant="body1" color="#fff">
                                                Last 15 Days
                                            </Typography>
                                            <Typography variant="body1" color="#fff">
                                                {agenciesVehicleDetailsInformation?.totalVehicle15Days ?? 0}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={6} sm={12} xs={12}>
                                    <Typography
                                        variant="body1"
                                        className={`${classes["total-items-fourth"]} ${classes["total-item-sixth"]}`}
                                        fontSize={14}
                                    >
                                        <div className={classes["header-grid-flex"]}>
                                            <div>
                                                <img
                                                    src={holdVehicleImg}
                                                    alt="Total count"
                                                    className={classes["header-grid-image"]}
                                                />
                                            </div>
                                            <div>
                                                <Typography
                                                    variant="body"
                                                    color="#fff"
                                                    className={classes["header-text-fontSize"]}
                                                >
                                                    {agenciesVehicleDetailsInformation?.totalHoldVehicles ?? 0}
                                                </Typography>
                                                <br></br>
                                                <Typography variant="body" color="#fff">
                                                    Hold Vehicles
                                                </Typography>
                                            </div>
                                        </div>
                                        <div
                                            className={`${classes["header-grid-flex"]} ${classes["top-box-bottom"]}`}
                                        >
                                            <Typography variant="body1" color="#fff">
                                                Last 15 Days
                                            </Typography>
                                            <Typography variant="body1" color="#fff">
                                                {agenciesVehicleDetailsInformation?.totalHoldVehicles15Days ?? 0}
                                            </Typography>
                                        </div>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </fieldset>
                    </Grid>
                </Grid>
            </Grid>
            {/* -----------------------------total agencies, total vehicle---------------------- */}

            {/* --------------------------Transpotation, Trip of Last 15 Days------------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Transportation
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Button
                                            size="small"
                                            className={
                                                transportationGraph === "qnty"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleTransporationChange("qnty")}
                                        >
                                            Qnty
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                transportationGraph === "noOfTrip"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleTransporationChange("noOfTrip")}
                                        >
                                            No. of Trip
                                        </Button>
                                    </Flex>
                                </div>
                            </div>

                            {
                                transportationGraph === "qnty"
                                    ?
                                    transportationBarChartInfoLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TransporationQntyGraph transportationBarChartInfo={transportationBarChartInfo} />
                                    :
                                    ""
                            }

                            {
                                transportationGraph === "noOfTrip"
                                    ?
                                    transportationBarChartInfoLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TransporationNoOfTripGraph transportationBarChartInfo={transportationBarChartInfo} />
                                    :
                                    ""
                            }
                        </div>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Trip of Last 15 Days
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Form>
                                            <StyleSelect>
                                                <Select
                                                    allowClear
                                                    style={{ width: "200px" }}
                                                    size="small"
                                                    showSearch
                                                    optionFilterProp="children"
                                                    filterOption={filterOption}
                                                    placeholder="Select"
                                                    options={transportAgenciesDropDown}
                                                    onChange={handleTransportAgencyChange}
                                                />
                                            </StyleSelect>
                                        </Form>

                                        <Button
                                            size="small"
                                            className={
                                                tripOfLastGraph === "trip"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleTripOfLastChange("trip")}
                                        >
                                            Trip
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                tripOfLastGraph === "avgwt"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleTripOfLastChange("avgwt")}
                                        >
                                            Avg. Wt
                                        </Button>
                                    </Flex>
                                </div>
                            </div>

                            {
                                tripOfLastGraph === "trip"
                                    ?
                                    avg15DaysTripWeightLineChartInfoLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TripDaysGraph avg15DaysTripWeightLineChart={avg15DaysTripWeightLineChart} />
                                    :
                                    ""
                            }

                            {
                                tripOfLastGraph === "avgwt"
                                    ?
                                    avg15DaysTripWeightLineChartInfoLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <AvgWTDaysGraph avg15DaysTripWeightLineChart={avg15DaysTripWeightLineChart} />
                                    :
                                    ""
                            }

                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* --------------------------Transpotation, Trip of Last 15 Days------------------- */}

            {/* ------------Transporter work done, Transportation average work done------------- */}
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Transportation Work Done
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Button
                                            size="small"
                                            className={
                                                transportationWorkDoneGraph === "alltime"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() =>
                                                handleTransportationWorkDoneChange("alltime")
                                            }
                                        >
                                            All Time
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                transportationWorkDoneGraph === "15days"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() =>
                                                handleTransportationWorkDoneChange("15days")
                                            }
                                        >
                                            15 Days
                                        </Button>
                                    </Flex>
                                </div>
                            </div>
                            {
                                transportationWorkDoneGraph === "alltime"
                                    ?
                                    funnelChartTransportationLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TransporationWorkDoneAllTimeGraph funnelChartTransportation={funnelChartTransportation} />
                                    :
                                    ""
                            }

                            {
                                transportationWorkDoneGraph === "15days"
                                    ?
                                    funnelChartTransportationLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TransporationWorkDoneDaysGraph funnelChartTransportation={funnelChartTransportation} />
                                    :
                                    ""
                            }
                        </div>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Transportation Average Weight
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Button
                                            size="small"
                                            className={
                                                transportationAverageWorkDoneGraph === "alltime"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() =>
                                                handleTransportationAverageWorkDoneChange("alltime")
                                            }
                                        >
                                            All Time
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                transportationAverageWorkDoneGraph === "15days"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() =>
                                                handleTransportationAverageWorkDoneChange("15days")
                                            }
                                        >
                                            15 Days
                                        </Button>
                                    </Flex>
                                </div>
                            </div>

                            {
                                transportationAverageWorkDoneGraph === "alltime"
                                    ?
                                    funnelChartTransportationAverageLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TransporationAverageWorkDoneAllTimeGraph funnelChartTransportationAverage={funnelChartTransportationAverag} />
                                    :
                                    ""
                            }

                            {
                                transportationAverageWorkDoneGraph === "15days"
                                    ?
                                    funnelChartTransportationAverageLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <TransporationAverageWorkDoneDaysGraph funnelChartTransportationAverage={funnelChartTransportationAverag} />
                                    :
                                    ""
                            }
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            {/* ------------Transporter work done, Transportation average work done------------- */}

            {/* ------------------------Loading and unloading work done------------------------- */}

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["dashboard-padding"]}
            >
                <Grid item lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Loading Work Done
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Button
                                            size="small"
                                            className={
                                                loadingWorkDoneGraph === "alltime"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleLoadingWorkDoneGraph("alltime")}
                                        >
                                            All Time
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                loadingWorkDoneGraph === "15days"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleLoadingWorkDoneGraph("15days")}
                                        >
                                            15 Days
                                        </Button>
                                    </Flex>
                                </div>
                            </div>

                            {
                                loadingWorkDoneGraph === "alltime"
                                    ?
                                    funnelChartLoadingLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <LoadingWorkDoneAllTimeGraph funnelChartLoading={funnelChartLoading} />
                                    :
                                    ""
                            }

                            {
                                loadingWorkDoneGraph === "15days"
                                    ?
                                    funnelChartLoadingLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <LoadingWorkDoneDaysGraph funnelChartLoading={funnelChartLoading} />
                                    :
                                    ""
                            }
                        </div>
                    </Grid>
                    <Grid item lg={6} md={12} sm={12} xs={12}>
                        <div className={classes["box-border-dashboard"]}>
                            <div className={classes["dashboard-top-box-heading"]}>
                                <div className={classes["bill-wise-flex-item"]}>
                                    <Typography variant="body1" color="#555555">
                                        {" "}
                                        Unloading Work Done
                                    </Typography>

                                    <Flex gap="0.5rem">
                                        <Button
                                            size="small"
                                            className={
                                                unloadingWorkDoneGraph === "alltime"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleUnloadingWorkDoneGraph("alltime")}
                                        >
                                            All Time
                                        </Button>
                                        <Button
                                            size="small"
                                            className={
                                                unloadingWorkDoneGraph === "15days"
                                                    ? classes["btn-selected"]
                                                    : classes["btn-selected-border"]
                                            }
                                            onClick={() => handleUnloadingWorkDoneGraph("15days")}
                                        >
                                            15 Days
                                        </Button>
                                    </Flex>
                                </div>
                            </div>

                            {
                                unloadingWorkDoneGraph === "alltime"
                                    ?
                                    funnelChartUnloadingLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <UnloadingWorkDoneAllTimeGraph funnelChartUnloading={funnelChartUnloading} />
                                    :
                                    ""
                            }

                            {
                                unloadingWorkDoneGraph === "15days"
                                    ?
                                    funnelChartUnloadingLoadingBar
                                        ?
                                        <div className={classes['graphArea']}><Spin className={classes['spinStyle']} size="large" /></div>
                                        :
                                        <UnloadingWorkDoneDaysGraph funnelChartUnloading={funnelChartUnloading} />
                                    :
                                    ""
                            }

                        </div>
                    </Grid>
                </Grid>
            </Grid>

            {/* ------------------------Loading and unloading work done------------------------- */}

            {/* --------------------------------Transporter Zone-------------------------------- */}
            {/* <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["dashboard-padding"]}
      >
        <div className={classes["box-border-dashboard"]}>
          <Typography
            variant="body1"
            color="#555555"
            className={classes["dashboard-top-box-heading"]}
          >
            Transporter Zone
          </Typography>
          <Grid
            lg={12}
            md={12}
            sm={12}
            xs={12}
            container
            spacing={2}
            padding="20px"
          >
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <StyledTableFirst
                columns={columns1}
                dataSource={data1}
                bordered
                pagination={false}
                rowClassName={classes["header-row-table-bg-color-first"]}
                scroll={{
                  y: 275,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <StyledTableSecond
                columns={columns1}
                dataSource={data1}
                bordered
                pagination={false}
                rowClassName={classes["header-row-table-bg-color-second"]}
                scroll={{
                  y: 275,
                }}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <StyledTableThird
                columns={columns1}
                dataSource={data1}
                bordered
                pagination={false}
                rowClassName={classes["header-row-table-bg-color-third"]}
                scroll={{
                  y: 275,
                }}
              />
            </Grid>
          </Grid>
        </div>
      </Grid> */}
            {/* --------------------------------Transporter Zone-------------------------------- */}
        </>
    );
};
