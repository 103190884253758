import React from 'react'
import { SamplePage } from '../../components/SamplePage/SamplePage'

export const SampleDataPage = () => {
  return (
    <div>
        <SamplePage />
    </div>
  )
}
