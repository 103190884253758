import React, { useEffect } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { IndustryImgComponent } from "../ui/IndustryImgComponent/IndustryImgComponent";
import { useNavigate } from "react-router-dom";
import classes from "./Login.module.scss";
import { Form, Input, notification } from "antd";
import AppHelper from "../../helpers/AppHelper";

export const Login = () => {
  const navigate = useNavigate();

  const handleSignIn = async (values) => {
    const lowercaseEmail = values?.Email_id.toLowerCase();

    if (!lowercaseEmail || !/^\S+@\S+\.\S+$/.test(lowercaseEmail)) {
      notification.error({
        message: "Invalid Email",
        description: "Please enter a valid email address.",
        duration: 1.5,
      });
      return;
    }

    // Make API call using POST request
    const response = await AppHelper.login({
      data: {
        id: values?.id,
        Email_id: lowercaseEmail,
        Password: values?.Password,
      },
    });
    
    if (response?.code === "ERR_NETWORK") {
      notification.error({
        message: response?.code,
        description: response?.message,
        duration: 1.5,
      });
    } else if (response?.response?.data?.error) {
      notification.error({
        message: "Invalid Credentials",
        description: "Invalid Email or password entered",
        duration: 1.5,
      });
    } else {
      notification.success({
        message: "Success",
        description: "User has been  loggedIn successfully",
        duration: 1.5,
      });
      // Store userInfo in session storage
      const userInfo = {
        userEmail: values?.Email_id,
        userPassword: values?.Password,
        userName: response?.data?.Name,
        userID: response?.data?.id
      }
      const encryptedUserInfo = AppHelper.encryptText(userInfo)
      sessionStorage.setItem("userInfo", encryptedUserInfo);
      navigate("/dashboard");
    }
  };

  useEffect(() => {
    document.title = "TMS - Login"
  })

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
      >
        <IndustryImgComponent />
        <Grid
          item
          md={6}
          xs={12}
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          className={classes["wrapper-section-two"]}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            maxWidth="sm"
            className={classes["grid-space"]}
          >
            <Typography
              variant="h1"
              fontSize="2rem"
              className={classes["typo-heading"]}
            >
              Login To Your Account
            </Typography>
            <Form
              layout="vertical"
              onFinish={handleSignIn}
              style={{ width: "100%" }}
            >
              <Form.Item
                name="Email_id"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email address",
                  },
                ]}
              >
                <Input type="email" className={classes["form-input-box"]} />
              </Form.Item>
              <Form.Item
                name="Password"
                label="Password"
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input.Password
                  type="password"
                  className={classes["form-input-box"]}
                />
              </Form.Item>
              <div className={classes["submit-button-area"]}>
                <Button variant="contained" color="primary" type="submit" className={classes['submit-button']}>
                  Sign In
                </Button>
              </div>
            </Form>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
