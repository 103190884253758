import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Grid, Typography, styled } from '@mui/material'
import { Form, Input, Skeleton, Upload, Modal } from 'antd';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import classes from "./ExcavatorData.module.scss";
import AppHelper from '../../../helpers/AppHelper.js'
import constantsArr from '../../../constants/global-constants.js';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const CustomUpload = styled(Upload)(() => ({
    "& .ant-upload-list-item button": {
        display: "none !important"
    }
}));


const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

function ViewExcavatorData() {
    const params = useParams();
    const navigate = useNavigate();
    const excavatorId = params?.id ?? ""
    const paramsQuery = new URLSearchParams(document.location.search);
    const pageNo = paramsQuery.get('currentPage') ?? constantsArr.PAGINATION_CURRENT_PAGE
    const pageSize = paramsQuery.get('pageSize') ?? constantsArr.DEFAULT_PAGINATION_SIZE
    const [excavatorDetails, setExcavatorDetails] = useState("");
    const [skeletonLoading, setSkeletonLoading] = useState(false)
    const maxNoOfUploadsAllowed = constantsArr.MAX_UPLOAD_IMAGES
    const [fileList, setFileList] = useState([]);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const [viewPdf, setViewPdf] = useState(null);

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });



    const backToHSDDetails = (e) => {
        e.preventDefault();
        navigate(`/excavators?currentPage=${pageNo}&pageSize=${pageSize}`)
    }

    const getHSDDetails = async (excavatorId) => {
        setSkeletonLoading(true)
        AppHelper.getExcavatorDetailsById(excavatorId).then((response) => {
            let uploadedPictures = response?.data?.attributes?.Uploaded_Pictures?.data?.attributes?.Pictures ?? "";

            if (uploadedPictures) {
                uploadedPictures = JSON.parse(uploadedPictures)
            }
            const newFileList = uploadedPictures.length > 0 ? uploadedPictures.map((image) => ({
                uid: image?.id,
                name: image?.name,
                status: "done",
                type: image?.mime,
                url: image?.url,
            })) : [];
            setExcavatorDetails(response.data)
            setFileList(newFileList);
            setSkeletonLoading(false)
        }).catch(err => {
            console.log(err.response.data);
        })
    }

    const handleCancel = () => setPreviewOpen(false);

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };

    const formatDate = (dateString) => {
        if (!dateString) return '-';
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      };

    useEffect(() => {
        document.title = "View Excavator Details"
        getHSDDetails(excavatorId);
    }, [])

    return (
        <>
            <Skeleton active loading={skeletonLoading} className={classes["view-excavator-padding"]}>
                <Grid
                    container
                    spacing={0}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["view-excavator-padding"]}
                >
                    <Grid item lg={10} md={9} xs={12}>
                        <Typography variant="h1" fontSize="1.5rem" fontWeight="bold" className="title-un-loading-text">
                            View Excavator Details
                        </Typography>
                    </Grid>

                    <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                        <Button size="small" variant="contained" onClick={backToHSDDetails} className={classes["add-new-entry"]} >
                            <ArrowLeftOutlined />&nbsp;Excavators
                        </Button>
                    </Grid>
                </Grid>
                <div className={classes['agency-details-margin']}>
                    <Form layout='vertical'>
                        <Grid
                            container
                            spacing={1}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    messageVariables=""
                                    label="Vehicle No."
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}>
                                    <Input value={excavatorDetails?.attributes?.Vehicle_Number ?? ""} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Type of Agency"
                                    wrapperCol={{ span: 24 }}
                                    className={classes['ant-form-item']}>
                                    <Input value={excavatorDetails?.attributes?.Agency_Type ?? "-"} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Agency Name"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.agency_id?.data?.attributes?.Company_name ?? "-"} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="D/O No."
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.DO_No ?? "-"} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="HSD LTR"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.HSD_LTR ?? "-"} readOnly />
                                </Form.Item>
                            </Grid>
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    messageVariables=""
                                    label="Owner"
                                    wrapperCol={{ span: 30 }}
                                    labelCol={{ span: 30 }}
                                    className={classes['ant-form-item']}>
                                    <Input value={excavatorDetails?.attributes?.Owner ?? "-"} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Date"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={formatDate(excavatorDetails?.attributes?.Date ?? "-")} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Petrol Pump Name"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.Petrol_Pumps?.data[0]?.attributes?.Petrolpump_Name ?? "-"} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="Rate"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.Rate ?? "-"} readOnly />
                                </Form.Item>
                                <Form.Item
                                    messageVariables=""
                                    label="HSD Amount"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.HSD_AMOUNT ?? 0} readOnly />
                                </Form.Item>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justify="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="wrap"
                        >
                            <Grid item lg={6} md={6} sm={12}>
                                <Form.Item
                                    messageVariables=""
                                    label="Remarks"
                                    wrapperCol={{ span: 30 }}>
                                    <Input value={excavatorDetails?.attributes?.Remarks ?? "-"} readOnly />
                                </Form.Item>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={0}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                            alignContent="center"
                            wrap="nowrap"
                        >
                            <Grid item lg={12} md={12} xs={12}>
                                Last Edited By: <b>{excavatorDetails?.attributes?.Updated_By?.data?.attributes?.Name ?? "NA"}</b>
                            </Grid>
                        </Grid>
                        <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="nowrap"
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <CustomUpload
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                name="Unloading_Images"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed}
                            </CustomUpload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                width={800}
                                footer={null}
                                style={{ height: '500px' }}
                                onCancel={handleCancel}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                        </Grid>
                    </Grid>
                    </Form>
                </div >
            </Skeleton>
        </>
    )
}

export default ViewExcavatorData