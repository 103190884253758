import React from "react";
import ReactApexChart from "react-apexcharts";
import classes from "../TransporationQntyGraph/TransporationQntyGraph.module.scss";

export const TransporationNoOfTripGraph = ({ transportationBarChartInfo }) => {

    const options5 = {
        chart: {
            height: 400,
            type: "bar",
        },
        plotOptions: {
            bar: {
                borderRadius: 10,
                dataLabels: {
                    position: "top", // top, center, bottom
                },
            },
        },
        tooltip: {
            y: {
                formatter: function (value) {
                    const findElement = transportationBarChartInfo?.noOfTripRecords.find(item => item?.trip_count_percentage === value)
                    return findElement?.month_total_trip_count ? findElement?.month_total_trip_count : 0
                }
            }
        },
        dataLabels: {
            enabled: true,
            formatter: function (val) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: "12px",
                colors: ["#304758"],
            },
        },
        xaxis: {
            categories: transportationBarChartInfo?.noOfTripRecords?.length > 0 ? transportationBarChartInfo?.noOfTripRecords.filter(item => item?.trip_count_percentage > 0)?.map((item) => { return item?.month }) : [],
            position: "bottom",
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },
            crosshairs: {
                fill: {
                    type: "gradient",
                    gradient: {
                        colorFrom: "#D8E3F0",
                        colorTo: "#BED1E6",
                        stops: [0, 100],
                        opacityFrom: 0.4,
                        opacityTo: 0.5,
                    },
                },
            },
            tooltip: {
                enabled: true,
            },
        },
        yaxis: {
            labels: {
                formatter: function (val) {
                    return val;
                },
            },
            title: {
                text: "Total QTY (MT)",
            },
        },
        colors: ["#00BFFF"],
    };

    const series5 = [
        {
            name: "No of Trip",
            data: transportationBarChartInfo?.noOfTripRecords?.length > 0 ? transportationBarChartInfo?.noOfTripRecords.filter(item => item?.trip_count_percentage > 0)?.map((item) => { return item?.trip_count_percentage ?? 0 }) : [],
        },
    ];

    return (
        <>
            {
                transportationBarChartInfo?.noOfTripRecords?.length > 0
                    ?
                    <ReactApexChart
                        options={options5}
                        series={series5}
                        type="bar"
                        height={400}
                    />
                    :
                    <div className={classes['graphArea']}>
                        <div className={classes['spinStyle']}>No Data Found</div>
                    </div>
            }
        </>
    );
};
