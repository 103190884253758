import React, { useEffect, useState } from "react";
import { DatePicker, Drawer, Form, Input, Table, notification, Spin, Tooltip, Select, Pagination } from "antd";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./EditTransportBills.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import transportAgency from "../../../../assets/images/BillsIcons/transportageny.svg";
import constantsArr from "../../../../constants/global-constants";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import { BreadCrumbTransport } from "../BreadCrumbTransport/BreadCrumbTransport";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

const pendingBillsTablecolumns = [
    {
        title: "SL No",
        dataIndex: "sl_no",
        width: '6%'
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: '10%'
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: '10%'
    },
    {
        title: "Ch. No.",
        dataIndex: "Challan_Number",
        width: '6%'
    },
    {
        title: "Vehicle No.",
        dataIndex: "Vehicle_Number",
        width: '10%'
    },
    {
        title: "Kanta Slip No",
        dataIndex: "Kanta_Slip_No",
        width: '10%'
    },
    {
        title: "Net wt. UL (MT)",
        dataIndex: "Net_Weight",
        width: '10%'
    },
    {
        title: "Work Rate",
        dataIndex: "Work_Rate",
        width: '8%'
    },
    {
        title: "Work Amt.",
        dataIndex: "Work_Amount",
        width: '8%'
    },
    {
        title: "D/O No",
        dataIndex: "DO_No",
        width: '6%'
    },
    {
        title: "HSD (LTR)",
        dataIndex: "HSD_LTR",
        width: '8%'
    },
    {
        title: "HSD Rate",
        dataIndex: "Rate_INR",
        width: '8%'
    },
    {
        title: "HSD Amt.",
        dataIndex: "HSD_Amt",
        width: '10%'
    },
];

export const EditTransportBills = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [filterForm] = Form.useForm()
    const [generateForm] = Form.useForm()
    const [billNo, setBillNo] = useState("NA")
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [totalUnloadingWeight, setTotalUnloadingWeight] = useState(0)
    const [workEffectiveRate, setWorkEffectiveRate] = useState(0)
    const [workAmount, setWorkAmount] = useState(0)
    const [updatedHSD, setUpdatedHSD] = useState(0)
    const [HSDAmount, setHSDAmount] = useState(0)
    const [dieselAmountRatio, setDieselAmountRatio] = useState(0)
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const [selectedLoadingAgency, setSelectedLoadingAgency] = useState(null)
    const [selectedLoadingAgencyId, setSelectedLoadingAgencyId] = useState(null)
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT);
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT];
    const [pendingBillsTabledata, setPendingBillsTabledata] = useState([])
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [rangePickerStartDateValueUnloading, setRangePickerStartDateValueUnloading] = useState("")
    const [rangePickerEndDateValueUnloading, setRangePickerEndDateValueUnloading] = useState("")
    const activeButton = 'pending_bills'
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [billDetails, setBillDetails] = useState([])
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const [filterFormValues, setFilterFormValues] = useState({})
    const dateFormatDayjsConversion = constantsArr.GLOBAL_DATE_FORMAT_DAYJS;
    const transportPendingBillsScreenFilterValues = AppHelper.decryptText(sessionStorage.getItem(`edit_transport_pending_bills_${params?.id}_filter_values`))
    const transportPendingBillsGeneratedIDs = AppHelper.decryptText(sessionStorage.getItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`))
    const adjustedWeightRateValuesSession = AppHelper.decryptText(sessionStorage.getItem(`adjustedHSDRateValuesEditGenerateTransport_${params?.id}`))
    const adjustedHSDRateValuesSession = AppHelper.decryptText(sessionStorage.getItem(`adjustedHSDRateValuesEditGenerateTransport_${params?.id}`))
    const [rangePickerLoadingStartDateDefaultValue, setRangePickerLoadingStartDateDefaultValue] = useState("")
    const [rangePickerLoadingEndDateDefaultValue, setRangePickerLoadingEndDateDefaultValue] = useState("")
    const [rangePickerLoadingStartDateDefaultValueUnloading, setRangePickerLoadingStartDateDefaultValueUnloading] = useState("")
    const [rangePickerLoadingEndDateDefaultValueUnloading, setRangePickerLoadingEndDateDefaultValueUnloading] = useState("")
    const [defaultGeneratedBillEntriesSelected, setDefaultGeneratedBillEntriesSelected] = useState("")
    const transportPendingBillsActiveSortOrder = AppHelper.decryptText(sessionStorage.getItem('transport_pending_bills_active_sort_order'))
    const [showPagination, setShowPagination] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0)
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE

    const redirectToGenerateBillScreen = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({
                message: "Please select records to generate bill",
                duration: 3
            })
            return false
        } else {
            sessionStorage.setItem(`transport_pending_bills_${params?.id}_selected_agency`, AppHelper.encryptText(selectedLoadingAgency))
            sessionStorage.setItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`, AppHelper.encryptText(selectedRowKeys))
            navigate(`/bills/transport/edit-transport-bill-generate/${params?.id}`)
        }
    }

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadPendingBills(selectedLoadingAgencyId, transportPendingBillsActiveSortOrder, true, currentPage, pageSize, transportPendingBillsScreenFilterValues)
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 0) {
            sessionStorage.setItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`, "")
        } else {
            sessionStorage.setItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`, AppHelper.encryptText(newSelectedRowKeys))
        }
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        fixed: "left",
        preserveSelectedRowKeys: true
    };

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
            setRangePickerLoadingStartDateDefaultValue("")
            setRangePickerLoadingEndDateDefaultValue("")
        }
    }

    const handleRangePickerUnloading = (e) => {
        if (e) {
            setRangePickerStartDateValueUnloading(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValueUnloading(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValueUnloading("")
            setRangePickerEndDateValueUnloading("")
            setRangePickerLoadingStartDateDefaultValueUnloading("")
            setRangePickerLoadingEndDateDefaultValueUnloading("")
        }
    }

    const showDrawer = () => setOpen(true);

    const onClose = () => setOpen(false);

    const loadPendingBills = (agency, sortOrder, pagination, currentPage, pageSize, formValues) => {
        setShowTableSpinner(true)
        AppHelper.getPendingBillsTransportingAgency(sortOrder, agency, pagination, currentPage, pageSize, formValues, params?.id).then(response => {
            setShowTableSpinner(false)
            setShowPagination(true)
            setTotalRecords(response?.meta?.pagination?.total ?? 0)

            if (formValues && Object.keys(formValues).length > 0)
                setGlobalCountItems(response, formValues)

            const getTableData = loadAgencyPendingBillsData(response?.data, currentPage)
            setPendingBillsTabledata(getTableData)
        }).catch(err => {
            setShowPagination(false)
            notification.error({
                message: "Unable to load pending bills data",
                description: '',
                duration: 3
            })
        })
    }

    const getLoadingIdsInBillToAutoSelect = (loadings) => {
        let loadingIds = []
        loadings?.attributes?.loading_id_list?.forEach((value, key) => {
            loadingIds.push(value?.loading?.data?.id)
        })
        if (transportPendingBillsGeneratedIDs.length === 0) {
            setSelectedRowKeys(loadingIds)
            sessionStorage.setItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`, AppHelper.encryptText(loadingIds))
        }
        setDefaultGeneratedBillEntriesSelected([...new Set(loadingIds)].sort())
    }

    const setGlobalCountItems = (response, formValues) => {
        setTotalTripCount(response?.meta?.tripCount ?? 0)
        setTotalUnloadingWeight(response?.meta?.unloadingWeight ?? 0)
        setWorkEffectiveRate(response?.meta?.workEffectiveRate ?? 0)
        setWorkAmount(response?.meta?.workAmount ?? 0)
        setUpdatedHSD(parseFloat(response?.meta?.updatedHsdLitre).toFixed(2))
        setHSDAmount(response?.meta?.hsdAmount ?? 0)
        setDieselAmountRatio(response?.meta?.dieselAmountRatio ?? 0)
        setTotalPayableAmount(response?.meta?.totalPayableAmount ?? 0)
        setBillNo(response?.meta?.billNumber ?? "NA")

        // set default unloading date filter form range values
        if (formValues && Object.keys(formValues).length > 0 && formValues?.Date_of_Loading && formValues?.Date_of_Loading !== "") {
            const breakDateOfLoadingFilterStorage = formValues?.Date_of_Loading.split("~")
            if (breakDateOfLoadingFilterStorage[0] && breakDateOfLoadingFilterStorage[0] !== "") {
                const startDateRangeUnLoadingDateDefault = breakDateOfLoadingFilterStorage[0]
                const endDateRangeUnLoadingDateDefault = breakDateOfLoadingFilterStorage[1]
                setRangePickerLoadingStartDateDefaultValue(startDateRangeUnLoadingDateDefault ? dayjs(dayjs(startDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
                setRangePickerLoadingEndDateDefaultValue(endDateRangeUnLoadingDateDefault ? dayjs(dayjs(endDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
                // } else {
                //     setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
                //     setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
                // }
                // } else {
                //     setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
                //     setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
                // }
            }
        }
        if (formValues && Object.keys(formValues).length > 0 && formValues?.Date_of_Unloading && formValues?.Date_of_Unloading !== "") {
            const breakDateOfUnLoadingFilterStorage = formValues?.Date_of_Unloading.split("~")
            if (breakDateOfUnLoadingFilterStorage[0] && breakDateOfUnLoadingFilterStorage[0] !== "") {
                const startDateRangeUnLoadingDateDefault = breakDateOfUnLoadingFilterStorage[0]
                const endDateRangeUnLoadingDateDefault = breakDateOfUnLoadingFilterStorage[1]
                setRangePickerLoadingStartDateDefaultValueUnloading(startDateRangeUnLoadingDateDefault ? dayjs(dayjs(startDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
                setRangePickerLoadingEndDateDefaultValueUnloading(endDateRangeUnLoadingDateDefault ? dayjs(dayjs(endDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
                // } else {
                //     setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
                //     setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
                // }
                // } else {
                //     setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
                //     setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
                // }
            }
        }
    }

    const setGlobalCountItemsDefault = (response) => {
        setTotalTripCount(response?.data?.attributes?.loading_id_list.length ?? 0)
        setTotalUnloadingWeight(response?.data?.attributes?.updated_quantity_weight ?? 0)
        setWorkEffectiveRate(response?.data?.attributes?.work_effective_rate ?? 0)
        setWorkAmount(response?.data?.attributes?.work_amount ?? 0)
        setUpdatedHSD((parseFloat(response?.data?.attributes?.hsd_litre) + parseFloat(response?.data?.attributes?.round_off_hsd)).toFixed(2))
        setHSDAmount(response?.data?.attributes?.hsd_amount ?? 0)
        setDieselAmountRatio(response?.data?.attributes?.updated_diesel_amount_ratio ?? 0)
        setTotalPayableAmount(response?.data?.attributes?.total_payable_amount ?? 0)
        setBillNo(response?.data?.attributes?.bill_number ?? "NA")

        // set default unloading date filter form range values
        // if (loadingPendingBillsScreenFilterValues && loadingPendingBillsScreenFilterValues?.Date_of_Loading !== "") {
        //     const breakDateOfLoadingFilterStorage = loadingPendingBillsScreenFilterValues?.Date_of_Loading.split("~")
        //     if (breakDateOfLoadingFilterStorage[0] && breakDateOfLoadingFilterStorage[0] !== "") {
        //         const startDateRangeUnLoadingDateDefault = breakDateOfLoadingFilterStorage[0]
        //         const endDateRangeUnLoadingDateDefault = breakDateOfLoadingFilterStorage[1]
        //         setRangePickerLoadingStartDateDefaultValue(startDateRangeUnLoadingDateDefault ? dayjs(dayjs(startDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
        //         setRangePickerLoadingEndDateDefaultValue(endDateRangeUnLoadingDateDefault ? dayjs(dayjs(endDateRangeUnLoadingDateDefault, dateFormatDayjsConversion)) : null)
        //     } else {
        //         setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
        //         setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
        //     }
        // } else {
        //     setRangePickerLoadingStartDateDefaultValue(response?.meta?.minLoadingDate ? dayjs(response?.meta?.minLoadingDate, dateFormatDayjsConversion) : null)
        //     setRangePickerLoadingEndDateDefaultValue(response?.meta?.maxLoadingDate ? dayjs(response?.meta?.maxLoadingDate, dateFormatDayjsConversion) : null)
        // }
    }

    const filterResults = (formValues) => {
        if (rangePickerStartDateValue !== "" && rangePickerEndDateValue !== "") {
            formValues.Date_of_Loading = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        } else {
            formValues.Date_of_Loading = `~`
        }

        if (rangePickerStartDateValueUnloading !== "" && rangePickerEndDateValueUnloading !== "") {
            formValues.Date_of_Unloading = `${rangePickerStartDateValueUnloading}~${rangePickerEndDateValueUnloading}`
        } else {
            formValues.Date_of_Unloading = `~`
        }

        sessionStorage.setItem(`edit_transport_pending_bills_${params?.id}_filter_values`, AppHelper.encryptText(formValues))
        loadPendingBills(selectedLoadingAgencyId, transportPendingBillsActiveSortOrder, true, currentPage, pageSize, formValues)
        setFilterFormValues(formValues)
        setOpen(false);
        setSelectedRowKeys(defaultGeneratedBillEntriesSelected)
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerLoadingStartDateDefaultValue("")
        setRangePickerLoadingEndDateDefaultValue("")
        setRangePickerLoadingStartDateDefaultValueUnloading("")
        setRangePickerLoadingEndDateDefaultValueUnloading("")
        setSelectedRowKeys(defaultGeneratedBillEntriesSelected)
        sessionStorage.setItem(`transport_edit_pending_bills_${params?.id}_to_generate_ids`, AppHelper.encryptText(defaultGeneratedBillEntriesSelected))
    }

    const preventNegativeValue = (e) => {
        const rateVal = e.target.value
        if (e.code === 'Minus' || rateVal.toString().length > 6) {
            e.preventDefault();
        }
    }

    const exportTransportPendingBills = async () => {
        setIsExportButtonDisabled(true)
        const updatedSelectedUnLoadingIds = [...new Set(transportPendingBillsGeneratedIDs)].sort()
        const isSelectedLoadingIdChanged = JSON.stringify(defaultGeneratedBillEntriesSelected) !== JSON.stringify(updatedSelectedUnLoadingIds)
        console.log(isSelectedLoadingIdChanged, 'isSelectedLoadingIdChanged')
        const exportResponse = await AppHelper.exportEditPendingTransportBills(transportPendingBillsActiveSortOrder, true, currentPage, exportPageSize, transportPendingBillsScreenFilterValues, transportPendingBillsGeneratedIDs, params?.id, isSelectedLoadingIdChanged)
        if (exportResponse?.error) {
            setIsExportButtonDisabled(false)
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Pending Bills", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Date of Unloading', key: "Date_of_Unloading", width: 20 },
                { header: 'Challan No.', key: "Challan_Number", width: 15 },
                { header: 'Vehicle No.', key: "Vehicle_Number", width: 15 },
                { header: 'Kanta Slip No', key: "Kanta_Slip_No", width: 15 },
                { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 15 },
                { header: 'Work Rate', key: "Work_Rate", width: 15 },
                { header: 'Work Amt.', key: "Work_Amount", width: 15 },
                { header: 'D/O No', key: "DO_No", width: 15 },
                { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                { header: 'HSD Rate', key: "Rate_INR", width: 15 },
                { header: 'HSD Amt', key: "HSD_Amt", width: 15 },
            ]

            exportResponse?.data?.forEach(billData => {
                billData.attributes.Date_of_Loading = (billData?.attributes?.Date_of_Loading && billData?.attributes?.Date_of_Loading !== "" && billData?.attributes?.Date_of_Loading !== '-') ? dayjs(billData?.attributes?.Date_of_Loading).format(dateFormat) : '-'
                billData.attributes.Date_of_Unloading = (billData?.attributes?.Date_of_Unloading && billData?.attributes?.Date_of_Unloading !== "" && billData?.attributes?.Date_of_Unloading !== '-') ? dayjs(billData?.attributes?.Date_of_Unloading).format(dateFormat) : '-'
                billData.attributes.Challan_Number = billData?.attributes?.Challan_Number && !isNaN(parseInt(billData?.attributes?.Challan_Number)) ? parseInt(billData?.attributes?.Challan_Number) : "-";
                billData.attributes.Kanta_Slip_No = billData?.attributes?.Kanta_Slip_No && !isNaN(parseInt(billData?.attributes?.Kanta_Slip_No)) ? parseInt(billData?.attributes?.Kanta_Slip_No) : "-";
                workSheetPendingBills.addRow(billData?.attributes)
            })
            // preapare sheet for global counts

            const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Count Items")
            // prepare sheet columns / headers
            workSheetPendingBillGlobalCounts.columns = [
                { header: 'TP', key: "first_value", width: 25 },
                { header: exportResponse?.meta?.thermalPlantName, key: "second_value", width: 20 },
            ]

            // prepare sheet rows to display
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Project", second_value: exportResponse?.meta?.projectName ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Agency", second_value: exportResponse?.meta?.agencyName ?? '' }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Bill No.", second_value: exportResponse?.meta?.billNumber ?? ''}
            )
            
            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Period", second_value: `${dayjs(exportResponse?.meta?.minLoadingDate).format(dateFormat)} - ${dayjs(exportResponse?.meta?.maxLoadingDate).format(dateFormat)}` }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Trip Count", second_value: exportResponse?.meta?.tripCount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total Unloading Wt (MT)", second_value: exportResponse?.meta?.unloadingWeight ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Effective Rate (Rs.)", second_value: exportResponse?.meta?.workEffectiveRate ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Work Amount (Rs.)", second_value: exportResponse?.meta?.workAmount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Updated HSD (LTR)", second_value: exportResponse?.meta?.updatedHsdLitre ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "HSD Amount (Rs.)", second_value: exportResponse?.meta?.hsdAmount ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Diesel Amount Ratio", second_value: exportResponse?.meta?.dieselAmountRatio ?? 0 }
            )

            workSheetPendingBillGlobalCounts.addRow(
                { first_value: "Total Payable Amount", second_value: exportResponse?.meta?.totalPayableAmount ?? 0 }
            )

            // set header to export as excel file
            try {
                const buffer = await workBook.xlsx.writeBuffer()
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                const blob = new Blob([buffer], { type: fileType })
                FileSaver.saveAs(blob, `transport_pending_bills_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                // Conclude function now
                notification.success({
                    message: "Transporting pending bills data exported successfully",
                    description: '',
                    duration: 3
                })
            } catch (error) {
                console.log(error, 'error');
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            }
            setIsExportButtonDisabled(false)
        }
    }

    const loadAgencyPendingBillsData = (responseData, currentPage) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.attributes?.loadingId,
                sl_no: ((parseInt(currentPage) === 1) ? i + 1 : (((parseInt(i) + 1) + ((parseInt(currentPage) - 1)) * parseInt(pageSize)))),
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading) ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.attributes?.Date_of_Unloading) ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number,
                Kanta_Slip_No: responseData[i]?.attributes?.Kanta_Slip_No ?? '-',
                Net_Weight: responseData[i]?.attributes?.Net_Weight ?? 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: parseFloat(responseData[i]?.attributes?.Work_Amount).toFixed(2) ?? 0,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
                Cancelled: responseData[i]?.attributes?.Cancelled ?? false,
            });
        }
        return pendingBillsTabledata
    }


    const changeSortOrder = (e) => {
        sessionStorage.setItem("transport_pending_bills_active_sort_order", AppHelper.encryptText(e.target.value))
        loadPendingBills(selectedLoadingAgencyId, e.target.value, true, 1, pageSize, transportPendingBillsScreenFilterValues)
    }

    useEffect(() => {
        AppHelper.getGeneratedBillDetails(params?.id).then((response) => {
            setBillDetails(response?.data)
            if (!adjustedWeightRateValuesSession || adjustedWeightRateValuesSession === "")
                sessionStorage.setItem(`adjustedHSDRateValuesEditGenerateTransport_${params?.id}`, AppHelper.encryptText(response?.data?.attributes?.RoundOffWeight))
            if (!adjustedHSDRateValuesSession || adjustedHSDRateValuesSession === "")
                sessionStorage.setItem(`adjustedHSDRateValuesEditGenerateTransport_${params?.id}`, AppHelper.encryptText(response?.data?.attributes?.RoundOffHSDRate))

            setSelectedLoadingAgency(response?.data?.attributes?.agency?.data?.attributes?.Company_name ?? '')
            setSelectedLoadingAgencyId(response?.data?.attributes?.agency?.data?.id ?? '')
            loadPendingBills(response?.data?.attributes?.agency?.data?.id, (transportPendingBillsActiveSortOrder && transportPendingBillsActiveSortOrder !== "" ? transportPendingBillsActiveSortOrder : 'Bill_Creation_Date:asc'), true, currentPage, pageSize, transportPendingBillsScreenFilterValues)
            getLoadingIdsInBillToAutoSelect(response?.data)

            if (!transportPendingBillsScreenFilterValues || Object.keys(transportPendingBillsScreenFilterValues).length === 0)
                setGlobalCountItemsDefault(response)

        }).catch(err => {
            console.log(`Error while loading transport bill records`, err);
            notification.error({
                message: `Error while loading transport bill records`, err,
                description: "",
                duration: 3
            })
        })

        if (transportPendingBillsScreenFilterValues && transportPendingBillsScreenFilterValues !== "") {
            let dateColumn = transportPendingBillsScreenFilterValues?.Date_of_Loading ?? '~'
            dateColumn = dateColumn.split("~")
            const startDate = dateColumn[0] ?? ''
            const endDate = dateColumn[1] ?? ''
            setRangePickerLoadingStartDateDefaultValue(startDate && startDate !== "" ? dayjs(dayjs(startDate).format("YYYY-MM-DD")) : '')
            setRangePickerLoadingEndDateDefaultValue(endDate && endDate !== "" ? dayjs(dayjs(endDate).format("YYYY-MM-DD")) : '')

            let dateColumnUnloading = transportPendingBillsScreenFilterValues?.Date_of_Unloading ?? '~'
            dateColumnUnloading = dateColumnUnloading.split("~")
            const startDateUnloading = dateColumnUnloading[0] ?? ''
            const endDateUnloading = dateColumnUnloading[1] ?? ''
            setRangePickerLoadingStartDateDefaultValueUnloading(startDateUnloading && startDateUnloading !== "" ? dayjs(dayjs(startDateUnloading).format("YYYY-MM-DD")) : '')
            setRangePickerLoadingEndDateDefaultValueUnloading(endDateUnloading && endDateUnloading !== "" ? dayjs(dayjs(endDateUnloading).format("YYYY-MM-DD")) : '')

            filterForm.setFieldsValue({
                Challan_No: transportPendingBillsScreenFilterValues?.Challan_No ?? "",
                D_O_No: transportPendingBillsScreenFilterValues?.D_O_No ?? "",
                Kanta_Slip_No: transportPendingBillsScreenFilterValues?.Kanta_Slip_No ?? ""
            })
        }

        if (transportPendingBillsGeneratedIDs && transportPendingBillsGeneratedIDs.length > 0) {
            setSelectedRowKeys(transportPendingBillsGeneratedIDs)
        }

        document.title = "Edit Generated Transporting Bills"
    }, [])

    console.log(rowSelection, 'rowSelection');
    

    return (
        <>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid item lg={8} md={7} sm={12} xs={12}>
                    <BreadCrumbTransport activeButton={activeButton} page="transportationEdit" params={params} />
                </Grid>

                <Grid item lg={4} md={5} sm={12} xs={12} container spacing={1}>
                    <Grid item lg={6} md={6} sm={6} xs={6}>
                        <Form>
                            <Form.Item
                                label="Sort By"
                            >
                                <Select defaultValue={(transportPendingBillsActiveSortOrder && transportPendingBillsActiveSortOrder !== "") ? transportPendingBillsActiveSortOrder : "Bill_Creation_Date:asc"} className={classes["select-button-height"]} id="sortOrder" onChange={(e) => changeSortOrder({ target: { id: "sortOrder", value: e } })}>
                                    <Select.Option value="Bill_Creation_Date:asc">Bill Creation Date (asc)</Select.Option>
                                    <Select.Option value="Challan_Number:asc">Challan No. (asc)</Select.Option>
                                    <Select.Option value="Challan_Number:desc">Challan No. (desc)</Select.Option>
                                    <Select.Option value="Date_of_Loading:asc">Date Of Loading (asc)</Select.Option>
                                    <Select.Option value="Date_of_Loading:desc">Date Of Loading (desc)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={6} textAlign="right">
                        <UIButton
                            variant="outlined"
                            className={classes["filter-new-entry"]}
                            onClick={showDrawer}
                            startIcon={<FilterOutlined />}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={filterForm} onFinish={filterResults}>
                                <Form.Item label="Challan No" name="Challan_No">
                                    <Input
                                        type="number"
                                        min={0}
                                        max={999999}
                                        step={1}
                                        placeholder="Enter Challan No"
                                        onKeyPress={preventNegativeValue}
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Date of Loading" name="Date_of_Loading">
                                    <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} defaultValue={[rangePickerLoadingStartDateDefaultValue, rangePickerLoadingEndDateDefaultValue]} />
                                </Form.Item>
                                <Form.Item label="Date of Unloading" name="Date_of_Unloading">
                                    <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePickerUnloading} format={dateFormat} defaultValue={[rangePickerLoadingStartDateDefaultValueUnloading, rangePickerLoadingEndDateDefaultValueUnloading]} />
                                </Form.Item>
                                <Form.Item label="DO No." name="D_O_No">
                                    <Input
                                        placeholder="Enter DO No."
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Kanta Slip No" name="Kanta_Slip_No">
                                    <Input
                                        placeholder="Enter Kanta Slip No"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["submit-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid className={classes["transport-agency-bill-number-wrapper"]} container>
                    <Grid className={classes["transport-agency-wrappper"]}>
                        <img src={transportAgency} alt="Transporting agency" />
                        <Typography variant="h6" color="#000" fontWeight="bold">
                            Transporting Agency
                        </Typography>
                        {/* <Form>
              <Select
                showSearch
                allowClear
                placeholder="All"
                optionFilterProp="children"
                onChange={(e) => handleChangeAgency(e)}
                onSearch={onSearch}
                filterOption={filterOption}
                value={selectedLoadingAgency}
                className={classes["select-new-entry"]}
                options={transportationAgencyDropDown}
              />
            </Form> */}
                    </Grid>
                    <Grid className={classes["transport-agency-bill-number"]}>
                        <Typography variant="h6" color="#000" fontWeight="bold">Bill Number</Typography>
                        <Typography variant="body" color="#353535" className={classes["hr-transport-box"]}>{billNo}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid className={classes["middle-boundary-transport"]} container>
                    <Grid>
                        {/* Will change in future dynamically */}
                        <Typography variant="h5" color="#fff" className={classes["text-boundary-tranport"]}>{selectedLoadingAgency}</Typography>
                    </Grid>
                    <Grid className={classes["total-amount-transport"]}>
                        <Typography variant="h6" fontWeight="bold" color="#fff">Total Payable Amount</Typography>
                        <Typography variant="h6" color="#fff" fontWeight="bold">= &nbsp; &#8377; {totalPayableAmount.toLocaleString('en-IN')}</Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-transport-padding"]}
            >
                <Grid className={classes["bottom-transport-counter"]} container>
                    <Grid className={classes["bottom-transport-first-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{totalTripCount}</Typography>
                        <Typography variant="body" color="#fff" className={classes["para"]}>Trip Count</Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-second-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}><span title={totalUnloadingWeight}>{AppHelper.formatNumber(totalUnloadingWeight)}</span></Typography>
                        <Typography variant="body" color="#fff" className={classes["para"]}>Total Unloading Wt (MT)</Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-third-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{workEffectiveRate}</Typography>
                        <Typography variant="body" color="#fff" className={classes["para"]}>Work Effective Rate (Rs.)</Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-fourth-counter"]}>
                        <Tooltip title={workAmount}>
                            <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}><span title={workAmount}>{AppHelper.formatNumber(workAmount)}</span></Typography>
                            <Typography variant="body" color="#fff" className={classes["para"]}>Work Amount (Rs.)</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid className={classes["bottom-transport-fivth-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{updatedHSD}</Typography>
                        <Typography variant="body" color="#fff" className={classes["para"]}>Updated HSD (LTR)</Typography>
                    </Grid>
                    <Grid className={classes["bottom-transport-sixth-counter"]}>
                        <Tooltip title={HSDAmount}>
                            <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}><span title={HSDAmount}>{AppHelper.formatNumber(HSDAmount)}</span></Typography>
                            <Typography variant="body" color="#fff" className={classes["para"]}>HSD Amount (Rs.)</Typography>
                        </Tooltip>
                    </Grid>
                    <Grid className={classes["bottom-transport-seventh-counter"]}>
                        <Typography variant="h5" fontWeight="bold" color="#fff" className={classes["h6"]}>{dieselAmountRatio}</Typography>
                        <Typography variant="body" color="#fff" className={classes["para"]}>Diesel Amount Ratio</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Form layout="vertical" form={generateForm}>
                <>
                    {
                        showPagination === true && totalRecords > 0 ? (
                            <div className={classes["pagination-box"]}>
                                <Pagination
                                    current={currentPagePagination}
                                    showSizeChanger={false}
                                    defaultCurrent={currentPage}
                                    defaultPageSize={currentPageSizePagination}
                                    showTotal={(total) => `Total ${totalRecords} items`}
                                    responsive
                                    pageSize={currentPageSizePagination}
                                    onChange={changePageNumber}
                                    total={totalRecords}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }
                </>
                <Table
                    className={classes["pending-bill-table"]}
                    rowSelection={selectedLoadingAgency ? rowSelection : false}
                    columns={pendingBillsTablecolumns}
                    rowClassName={(record) => record?.Cancelled === true ? classes['table-row-cancelled'] : ''}
                    dataSource={pendingBillsTabledata}
                    rowKey={(record) => record?.key}
                    bordered={true}
                    scroll={{ x: 1600 }}
                    loading={{ indicator: <div><Spin /></div>, spinning: showTableSpinner }}
                    pagination={false}
                />
                <>
                    {
                        showPagination === true && totalRecords > 0 ? (
                            <div className={classes["pagination-box"]}>
                                <Pagination
                                    current={currentPagePagination}
                                    showSizeChanger={false}
                                    defaultCurrent={currentPage}
                                    defaultPageSize={currentPageSizePagination}
                                    showTotal={(total) => `Total ${totalRecords} items`}
                                    responsive
                                    pageSize={currentPageSizePagination}
                                    onChange={changePageNumber}
                                    total={totalRecords}
                                />
                            </div>
                        ) : (
                            ""
                        )
                    }
                </>
                {
                    selectedLoadingAgency ?
                        <div className={classes["submit-button-area"]}>
                            <UIButton
                                variant="outlined"
                                className={`${classes["submit-outline-button"]} ${classes["export-button"]} ${isExportButtonDisabled === true ? classes['button-disabled'] : ''}`}
                                onClick={exportTransportPendingBills}
                                disabled={isExportButtonDisabled}
                            >
                                Export
                            </UIButton>
                            <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} onClick={redirectToGenerateBillScreen}>
                                Generate Bill
                            </UIButton>
                        </div>
                        :
                        ""
                }
            </Form>
        </>
    );
};
