import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Spin } from "antd";

export const WorkDetailGraph = React.memo(({ graphDetails, displayLoadingBar, classes }) => {

    const getSubtitleWorkDetail = () => {
        return `<div style="text-align: center; margin-top: 15px;">
                    <h3 style="color: #1f226f; font-size: 22px;">${graphDetails?.totalPO ?? 0} CUM</h3><br>
                    <p style="color: #555555; font-size: 22px; font-weight: 200;">Total PO (CUM)</p><br>
                </div>`;
    }

    const getTotalWorkDone = () => {
        return `<p style="font-size: 16px; font-weight: bold; color: #1f226f;" >${graphDetails?.totalWorkDone ?? 0} CUM</p><br>
                <p style="font-size: 14px; font-weight: 200; color: #353535;">Total Work Done</p>`;
    }

    const getRemainingWork = () => {
        return `<p style="font-size: 16px; color: #1f226f; font-weight: bold;">${graphDetails?.remainingWorkDone ?? 0} CUM</p><br>
                <p style="font-size: 14px; color: #353535; font-weight: 200;">Remaining Work</p>`;
    }

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: 0,
            plotShadow: false,
        },
        title: {
            useHTML: true,
            text: getSubtitleWorkDetail(),
            align: "center",
            verticalAlign: "middle",
            y: 60,
            style: {
                fontSize: "1.1em",
            },
        },
        legend: true,
        tooltip: {
            enabled: false
        },
        accessibility: {
            point: {
                valueSuffix: "%",
            }
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                enableMouseTracking: false,
                cursor: "pointer",
                dataLabels: [
                    {
                        enabled: true,
                        distance: 30,
                        style: {
                            fontWeight: "bold",
                        },
                        useHTML: true,
                    },
                    {
                        enabled: true,
                        distance: -28,
                        format: "{point.percentage:.2f}%",
                        style: {
                            fontSize: "14px",
                            textOutline: "none",
                            opacity: 0.7,
                        },
                        filter: {
                            operator: ">",
                            property: "percentage",
                            value: 10,
                        },
                    },
                ],
                startAngle: -90,
                endAngle: 90,
                center: ["50%", "80%"],
                size: "120%",
            },
        },
        series: [
            {
                type: "pie",
                name: "Percentage",
                innerSize: "75%",
                data: [
                    {
                        name: getTotalWorkDone(),
                        y: graphDetails?.totalWorkDone ?? 0,
                        color: "#0067b6", // Specify color for Chrome
                    },
                    {
                        name: getRemainingWork(),
                        y: graphDetails?.remainingWorkDone ?? 0,
                        color: "#50d3ff", // Specify color for Edge
                    },
                ],
            },
        ],
    };

    return (
        <>
            <div className={classes['graphArea']}>
                {displayLoadingBar ? <Spin className={classes['spinStyle']} size="large" /> : <HighchartsReact highcharts={Highcharts} options={options} />}
            </div>
        </>
    );
});
