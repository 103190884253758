import React, { useState } from "react";
import { Table, Form, Select, Drawer, Input, DatePicker } from "antd";
import Search from "antd/es/input/Search";
import classes from "./VehicleDataReport.module.scss";
import { Typography, Button as UIButton, Grid } from "@mui/material";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import totalCountImg from "../../../../assets/images/HeaderIcons/totalcount.svg";
import activeVehicleImg from "../../../../assets/images/HeaderIcons/activevehicle.svg";
import inActiveVehicleImg from "../../../../assets/images/HeaderIcons/inactivevehicle.svg";
import holdVehicleImg from "../../../../assets/images/HeaderIcons/holdvehicle.svg";

const columns = [
  {
    title: "Vehicle No.",
    dataIndex: "vehicle_number",
    width: "6%",
  },
  {
    title: "Owner Name",
    dataIndex: "owner_name",
    width: "10%",
  },
  {
    title: "Last Tranporter",
    dataIndex: "tranporter_name",
    width: "13%",
  },
  {
    title: "Last UL Date",
    dataIndex: "unload_date",
    width: "8%",
  },
  {
    title: "Last Loading Date",
    dataIndex: "load_date",
    width: "10%",
  },
  {
    title: "Total Trips",
    dataIndex: "trip_no",
    width: "6%",
  },
  {
    title: "No. of Tyres",
    dataIndex: "tyres_no",
    width: "7%",
  },
  {
    title: "Avg. Net wt. of the UL (MT)",
    dataIndex: "avg_weight",
    width: "10%",
  },
];
const data = [];
const avgWeight = 5;
for (let i = 1; i < 32; i++) {
  data.push({
    key: i,
    vehicle_number: `RJ${i} CA 00${i}`,
    owner_name: "Ajay kumar agarwal",
    tranporter_name: "Meera construction " + `${i}`,
    unload_date: `0${i}-05-2024`,
    load_date: `0${i}-05-2025`,
    trip_no: `${i}`,
    tyres_no: `${i}`,
    avg_weight: `${i}` * `${avgWeight}`,
  });
}

export const VehicleDataReport = () => {
  const [openFilterSidebar, setOpenFilterSidebar] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rangePickerStartDateValue, setRangePickerStartDateValue] =
    useState("");
  const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("");
  const { RangePicker } = DatePicker;
  const dateFormat = "DD-MM-YYYY";

  const showDrawer = () => setOpenFilterSidebar(true);

  const onClose = () => setOpenFilterSidebar(false);

  const handleRangePicker = (e) => {
    if (e) {
      setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "");
      setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "");
    } else {
      setRangePickerStartDateValue("");
      setRangePickerEndDateValue("");
    }
  };

  const showModalPopUp = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["report-petrol-pump-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Typography variant="h5" color="initial" fontWeight="bold">
              Vehicle Reports
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12} textAlign="right">
            <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
              <Grid item lg={5} md={4} sm={4} xs={6}>
                <Search
                  placeholder="Search By Vehicle No."
                  className={classes["search-new-entry"]}
                />
              </Grid>
              <Grid item lg={5} md={4} sm={4} xs={6}>
                <Form>
                  <Form.Item
                    label="Sort By"
                    className={classes["select-new-entry"]}
                  >
                    <Select className={classes["select-button-height"]}>
                      <Select.Option value="updatedAt:desc">
                        Last Unloading Ascending
                      </Select.Option>
                      <Select.Option value="Date_of_Loading:asc">
                        Last Unloading Descending
                      </Select.Option>
                      <Select.Option value="Date_of_Loading:desc">
                        Last Loading Ascending
                      </Select.Option>
                      <Select.Option value="Challan_Number:asc">
                        Last Loading Descending
                      </Select.Option>
                      <Select.Option value="Challan_Number:desc">
                        Total Trip
                      </Select.Option>
                      <Select.Option value="Challan_Number:desc">
                        Avg. net wt. of Ul(MT)
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Form>
              </Grid>
              <Grid
                item
                lg={2}
                md={4}
                sm={4}
                xs={12}
                className={classes["xs-size-filter"]}
              >
                <UIButton
                  onClick={showDrawer}
                  variant="outlined"
                  startIcon={<FilterOutlined />}
                  className={classes["filter-new-entry"]}
                >
                  filter
                </UIButton>
                <Drawer
                  title="Filters"
                  onClose={onClose}
                  open={openFilterSidebar}
                >
                  <Form layout="vertical">
                    <Form.Item label="Last Transporter" name="last_transporter">
                      <Input
                        placeholder="Enter Last Transporter"
                        addonBefore={<SearchOutlined />}
                      />
                    </Form.Item>

                    <Form.Item label="Total Trips" name="total_trips">
                      <Input
                        placeholder="Enter Total Trips"
                        addonBefore={<SearchOutlined />}
                      />
                    </Form.Item>

                    <Form.Item label="No. of Tyres" name="No_of_tyres">
                      <Input
                        placeholder="Enter No. of Tyres"
                        addonBefore={<SearchOutlined />}
                      />
                    </Form.Item>

                    <Form.Item label="Avg. net wt. of UL" name="avg_net_wt_ul">
                      <Input
                        placeholder="Enter Avg. net wt. of UL"
                        addonBefore={<SearchOutlined />}
                      />
                    </Form.Item>

                    <Form.Item
                      label="Last Loading Date"
                      name="Last_Loading_Date"
                    >
                      <RangePicker
                        style={{ width: "100%" }}
                        onChange={handleRangePicker}
                        format={dateFormat}
                      />
                    </Form.Item>

                    <Form.Item label="Last UL Date" name="Last_Unloading_Date">
                      <RangePicker
                        style={{ width: "100%" }}
                        onChange={handleRangePicker}
                        format={dateFormat}
                      />
                    </Form.Item>

                    <div className={classes["search-button-area"]}>
                      <UIButton size="small" variant="outlined" color="error">
                        Reset
                      </UIButton>
                      <UIButton
                        size="small"
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes["add-new-filter-entry"]}
                      >
                        Apply
                      </UIButton>
                    </div>
                  </Form>
                </Drawer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["report-petrol-padding"]}
      >
        <Grid
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          spacing={1.5}
          className={classes["header-scroll-horizontal"]}
        >
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Typography
              variant="body1"
              className={`${classes["total-items"]} ${classes["total-item-first"]}`}
              fontSize={14}
            >
              <div className={classes["header-grid-flex"]}>
                <div>
                  <img
                    src={totalCountImg}
                    alt="Total count"
                    className={classes["header-grid-image"]}
                  />
                </div>
                <div>
                  <Typography
                    variant="body"
                    color="white"
                    className={classes["header-text-fontSize"]}
                  >
                    748399.99
                    {/* {AppHelper.formatNumber(
                      agencyData?.meta?.totalLoading ?? 0
                    )} */}
                  </Typography>
                  <br></br>
                  <Typography variant="body" color="white">
                    Total Count
                  </Typography>
                </div>
              </div>
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Typography
              variant="body1"
              className={`${classes["total-items"]} ${classes["total-item-second"]}`}
              fontSize={14}
            >
              <div className={classes["header-grid-flex"]}>
                <div>
                  <img
                    src={activeVehicleImg}
                    alt="active vehicle"
                    className={classes["header-grid-image"]}
                  />
                </div>
                <div>
                  <Typography
                    variant="body"
                    color="white"
                    className={classes["header-text-fontSize"]}
                  >
                    7483
                    {/* {AppHelper.formatNumber(
                      agencyData?.meta?.totalLoading ?? 0
                    )} */}
                  </Typography>
                  <br></br>
                  <Typography variant="body" color="white">
                    Active Vehicles
                  </Typography>
                </div>
              </div>
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Typography
              variant="body1"
              className={`${classes["total-items"]} ${classes["total-item-third"]}`}
              fontSize={14}
            >
              <div className={classes["header-grid-flex"]}>
                <div>
                  <img
                    src={inActiveVehicleImg}
                    alt="In active vehicle"
                    className={classes["header-grid-image"]}
                  />
                </div>
                <div>
                  <Typography
                    variant="body"
                    color="white"
                    className={classes["header-text-fontSize"]}
                  >
                    322323
                    {/* {AppHelper.formatNumber(
                      agencyData?.meta?.totalLoading ?? 0
                    )} */}
                  </Typography>
                  <br></br>
                  <Typography variant="body" color="white">
                    Inactive Vehicles
                  </Typography>
                </div>
              </div>
            </Typography>
          </Grid>
          <Grid item lg={3} md={3} sm={3} xs={3}>
            <Typography
              variant="body1"
              className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
              fontSize={14}
            >
              <div className={classes["header-grid-flex"]}>
                <div>
                  <img
                    src={holdVehicleImg}
                    alt="Hold vehicle"
                    className={classes["header-grid-image"]}
                  />
                </div>
                <div>
                  <Typography
                    variant="body"
                    color="white"
                    className={classes["header-text-fontSize"]}
                  >
                    322323.00
                    {/* {AppHelper.formatNumber(
                      agencyData?.meta?.totalLoading ?? 0
                    )} */}
                  </Typography>
                  <br></br>
                  <Typography variant="body" color="white">
                    Hold Vehicles
                  </Typography>
                </div>
              </div>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Table
        className={classes["report-petol-pump-table"]}
        columns={columns}
        dataSource={data}
        bordered={true}
        tableLayout="fixed"
        scroll={{ x: 1500 }}
        pagination={{ position: ["bottomCenter"] }}
      />
    </>
  );
};
