import React from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./EditFinalCalulationPetrolPumpBill.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Form, Input, DatePicker, notification } from "antd";
import { BreadCrumbPetrolPump } from "../BreadCrumbPetrolPump/BreadCrumbPetrolPump";
import TextArea from "antd/es/input/TextArea";

export const EditFinalCalulationPetrolPumpBill = () => {
  const navigate = useNavigate();
  const activeButton = "final_calculation";

  const backToGenerateBill = () => {
    navigate("/bills/petrol-pump/edit-generate-petrol-pump-bill");
  };
  return (
    <>
      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["final-calculation-petrol-pump-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={9} md={8} sm={4} xs={12}>
            <BreadCrumbPetrolPump
              activeButton={activeButton}
              page="petrolpumpEdit"
            />
          </Grid>
          <Grid
            item
            lg={3}
            md={4}
            sm={8}
            xs={12}
            className={classes["back-button-position"]}
          >
            <UIButton
              variant="contained"
              className={`${classes["back-new-entry"]}`}
              startIcon={<ArrowLeftOutlined />}
              onClick={backToGenerateBill}
            >
              Generate Bills
            </UIButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={1}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="wrap"
        className={classes["final-calculation-petrol-pump-padding"]}
      >
        <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid
              className={`${classes["data-track-section"]} ${classes["data-first-track"]}`}
            >
              <Typography variant="body1" color="#fff">
                Pump Name
              </Typography>
              <Typography
                variant="body1"
                color="#fff"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                Hire Service Station
              </Typography>
            </Grid>

            <Grid
              className={`${classes["data-track-section"]} ${classes["data-second-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                Total Trip:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                21324
              </Typography>
            </Grid>
            <Grid
              className={`${classes["data-track-section"]} ${classes["data-third-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                Total Excavator Count:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                450
              </Typography>
            </Grid>

            <Grid
              className={`${classes["data-track-section"]} ${classes["data-second-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                Date <span style={{fontSize: "14px"}}>(From - To)</span> :
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                20/06/2023 - 20/06/2024
              </Typography>
            </Grid>

            <Grid
              className={`${classes["data-track-section"]} ${classes["data-second-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                HSD LTR In Vehicle:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                21324
              </Typography>
            </Grid>
            <Grid
              className={`${classes["data-track-section"]} ${classes["data-third-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                HSD LTR In Excavator:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                45220
              </Typography>
            </Grid>
            <Grid
              className={`${classes["data-track-section"]} ${classes["data-third-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                Total HSD LTR:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                450
              </Typography>
            </Grid>

            <Grid
              className={`${classes["data-track-section"]} ${classes["data-second-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                HSD Effective Rate:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                2139024
              </Typography>
            </Grid>
            <Grid
              className={`${classes["data-track-section"]} ${classes["data-third-track"]}`}
            >
              <Typography variant="body1" color="#555555">
                HSD Amount:
              </Typography>
              <Typography
                variant="body1"
                color="#555555"
                fontWeight="bold"
                className={classes["align-bold-text"]}
              >
                4502132
              </Typography>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid className={classes["form-track-section"]}>
              <Grid className={classes["form-prefix-padding"]}>
                <Form layout="vertical">
                  <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Form.Item
                        label="Billing Prefix"
                        name="Billing_Prefix"
                        rules={[
                          {
                            required: true,
                            message: "Please enter billing prefix",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter the Billing Prefix"
                          className={classes["input-field"]}
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Form.Item
                        label="Bill No."
                        name="bill_no"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Bill No.",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter the Bill No."
                          className={classes["input-field"]}
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                      <Form.Item
                        label="Date"
                        name="Date"
                        rules={[
                          {
                            required: true,
                            message: "Please select date",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          className={classes["input-field"]}
                        />
                      </Form.Item>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Form.Item
                        label="Deduction"
                        name="deduction"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Deduction",
                          },
                        ]}
                      >
                        <Input
                          placeholder="Enter the Deduction"
                          className={classes["input-field"]}
                        />
                      </Form.Item>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Form.Item label="Remark" name="Remark">
                        <TextArea
                          placeholder="Remarks"
                          rows={4}
                          className={classes["input-field"]}
                        />
                      </Form.Item>
                    </Grid>
                  </Grid>
                </Form>
              </Grid>
              <Grid className={classes["total-amount-wrapper"]}>
                <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography variant="h5" color="#fff">
                      Total Payable Amount
                    </Typography>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12} textAlign="right">
                    <Typography variant="h4" color="#fff" fontWeight="bold">
                      <span className={classes["rupee_symbol_payable_amount"]}>
                        ₹
                      </span>
                      9843274
                      {/* {(parseFloat(totalWorkAmount) + parseFloat(gstAmount) - (parseFloat(totalHSDAmount) + parseFloat(otherDeductionAmount) + parseFloat(lessTDSAmount))).toLocaleString('en-IN')} */}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid className={classes["export-submit-button"]}>
        <UIButton variant="contained" className={classes["submit-button"]}>
          save and export
        </UIButton>
      </Grid>
    </>
  );
};
