import React, { useEffect, useState } from "react";
import classes from "./EditPendingClientBilling.module.scss";
import { Typography, Button as UIButton, Grid } from "@mui/material";
import { BreadCrumbClientBilling } from "../BreadCrumbClientBilling/BreadCrumbClientBilling";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons";
import {
    DatePicker,
    Drawer,
    Form,
    Input,
    Table,
    Select,
    Spin,
    notification,
    Pagination,
} from "antd";
import CBTripCount from "../../../../assets/images/BillsIcons/CBTripCount.svg";
import CBStartDate from "../../../../assets/images/BillsIcons/CBstartdate.svg";
import CBLastDate from "../../../../assets/images/BillsIcons/CBlastdate.svg";
import CBNetWeight from "../../../../assets/images/BillsIcons/CBnetweight.svg";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/global-constants";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'

const pendingBillsTablecolumns = [
    {
        title: "S.No.",
        dataIndex: "s_no",
        width: "70px",
    },
    {
        title: "Date of Loading",
        dataIndex: "Date_of_Loading",
        width: "140px",
    },
    {
        title: "Loading Agency",
        dataIndex: "Loading_Agency",
        width: "170px",
    },
    {
        title: "Date of UL",
        dataIndex: "Date_of_Unloading",
        width: "110px",
    },
    {
        title: "Unloading Agency",
        dataIndex: "Unloading_Agency",
        width: "180px",
    },
    {
        title: "Vehicle Number",
        dataIndex: "Vehicle_Number",
        width: "140px",
    },
    {
        title: "Challan No.",
        dataIndex: "Challan_Number",
        width: "120px",
    },
    {
        title: "D/O No.",
        dataIndex: "DO_No",
        width: "110px",
    },
    {
        title: "HSD LTR",
        dataIndex: "HSD_LTR",
        width: "110px",
    },
    {
        title: "Transporter Agency",
        dataIndex: "Transporter_Agency",
        width: "180px",
    },
    {
        title: "Thermal Plant Book No.",
        dataIndex: "Thernal_Plant_Book_No",
        width: "110px",
    },
    {
        title: "Net weight (UL) (MT)",
        dataIndex: "Net_Weight",
        width: "110px",
    },
    {
        title: "Gross weight (MT)",
        dataIndex: "Gross_weight",
        width: "130px",
    },
    {
        title: "Tare weight (MT)",
        dataIndex: "Tare_weight",
        width: "110px",
    },
    {
        title: "Kanta Slip No. UL",
        dataIndex: "Kanta_slip_no",
        width: "110px",
    },
    {
        title: "Unloading Location",
        dataIndex: "Unloading_location",
        width: "170px",
    },
];

export const EditPendingClientBilling = () => {
    const activeButton = "edit_pending_client_bills";
    const navigate = useNavigate()
    const params = useParams()
    const [open, setOpen] = useState(false);
    const { RangePicker } = DatePicker;
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const showDrawer = () => setOpen(true);
    const onClose = () => setOpen(false);
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT
    const currentDate = dayjs().format("YYYY-MM-DD")
    const [showTableSpinner, setShowTableSpinner] = useState(true)
    const [pendingBillsTabledata, setPendingBillsTabledata] = useState([])
    const [sortOrder] = useState("Bill_Creation_Date:asc")
    const [currentPage, pageSize] = [constantsArr.PAGINATION_CURRENT_PAGE, constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT];
    const [currentPagePagination, setCurrentPagePagination] = useState(1)
    const [currentPageSizePagination, setCurrentPageSizePagination] = useState(constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT);
    const [billNo, setBillNo] = useState("NA")
    const [totalTripCount, setTotalTripCount] = useState(0)
    const [minUnloadingDate, setMinUnloadingDate] = useState(0)
    const [maxUnloadDate, setMaxUnloadingDate] = useState(0)
    const [totalNetWeight, setTotalNetWeight] = useState(0)
    const [rangePickerStartDateValue, setRangePickerStartDateValue] = useState("")
    const [rangePickerEndDateValue, setRangePickerEndDateValue] = useState("")
    const [filterForm] = Form.useForm()
    const [billDate, setBillDate] = useState(currentDate)
    const [showPagination, setShowPagination] = useState(false);
    const [billRecords, setBillRecords] = useState(false);
    const [startDateOfUnloadingFilterForm, setStartDateOfUnloadingFilterForm] = useState("")
    const [endDateOfUnloadingFilterForm, setEndDateOfUnloadingFilterForm] = useState("")
    const clientBillPendingScreenFilterValues = AppHelper.decryptText(sessionStorage.getItem(`edit_client_bill_pending_screen_filter_values_${params?.id}`))
    const clientBillPendingScreenActiveSortOrder = AppHelper.decryptText(sessionStorage.getItem(`edit_client_pending_bill_active_sort_order_${params?.id}`))
    const clientBillPendingSelectedRecords = AppHelper.decryptText(sessionStorage.getItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`))
    const [isExportButtonDisabled, setIsExportButtonDisabled] = useState(false)
    const exportPageSize = constantsArr.DEFAULT_PAGINATION_SIZE_BILLS_TABLE

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
        if (newSelectedRowKeys.length === 0) {
            sessionStorage.setItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`, "")
        } else {
            sessionStorage.setItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`, AppHelper.encryptText(newSelectedRowKeys))
        }
    };

    const changePageNumber = (currentPage, pageSize, newSorting = null) => {
        // check page no is displaying in url then set that page no otherwise default 1
        setCurrentPageSizePagination(pageSize);
        if (currentPage === currentPagePagination && currentPageSizePagination === pageSize) {
            // page number is being changed then go to next page
            currentPage++;
        }
        setCurrentPagePagination(currentPage)
        loadPendingBills(((clientBillPendingScreenActiveSortOrder && clientBillPendingScreenActiveSortOrder !== "") ? clientBillPendingScreenActiveSortOrder : sortOrder), currentPage, pageSize, true, clientBillPendingScreenFilterValues)
    };

    const changeBillDate = (e) => {
        setBillDate(e.target.value)
    }


    const exportClientPendingBills = async () => {
        setIsExportButtonDisabled(true)
        const exportResponse = await AppHelper.getSelectedLoadingPendingClientBills(((clientBillPendingScreenActiveSortOrder && clientBillPendingScreenActiveSortOrder !== "") ? clientBillPendingScreenActiveSortOrder : sortOrder), currentPage, exportPageSize, true, clientBillPendingScreenFilterValues, clientBillPendingSelectedRecords, true, params?.id)
        if (exportResponse?.error) {
            notification.error({
                message: "Error",
                description: exportResponse?.error?.message,
                duration: 3
            })
            setIsExportButtonDisabled(false)
        } else {
            // export xlsx code goes here
            const workBook = new exceljs.Workbook()
            const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                views: [{ ySplit: 1, state: 'frozen' }]
            })
            workSheetPendingBills.columns = [
                { header: 'S.NO.', key: "sl_no", width: 8 },
                { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                { header: 'Loading Agency', key: "Loading_Agency", width: 20 },
                { header: 'Date of UL', key: "Date_of_Unloading", width: 20 },
                { header: 'Unloading Agency', key: "Unloading_Agency", width: 20 },
                { header: 'Vehicle Number.', key: "Vehicle_Number", width: 15 },
                { header: 'Challan No.', key: "Challan_Number", width: 15 },
                { header: 'D/O No', key: "DO_No", width: 15 },
                { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                { header: 'Transporter Agency', key: "Transporter_Agency", width: 20 },
                { header: 'Thermal Plant Book No', key: "Thermal_Plant_Book_no", width: 20 },
                { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 20 },
                { header: 'Gross weight (MT)', key: "Gross_Weight", width: 20 },
                { header: 'Tare weight (MT)', key: "Tare_Weight", width: 15 },
                { header: 'Kanta Slip No. UL', key: "Kanta_Slip_No", width: 25 },
                { header: 'Unloading Location', key: "Unloading_Location", width: 20 },
            ]

            if (exportResponse?.data?.length === 0) {
                notification.error({
                    message: "Error",
                    description: 'Unable to export as data is empty',
                    duration: 3
                })
            } else {

                exportResponse?.data.forEach((billData, key) => {
                    billData.sl_no = key + 1
                    billData.Unloading_Location = billData?.attributes?.Unloading_Location
                    billData.Net_Weight = billData?.attributes?.Net_Weight ? parseFloat((billData?.attributes?.Net_Weight / 1000).toFixed(2)) : 0
                    billData.Kanta_Slip_No = parseInt(billData?.attributes?.Kanta_Slip_No)
                    billData.Transporter_Agency = billData?.attributes?.Transporter_Agency ? billData?.attributes?.Transporter_Agency : ""
                    billData.Unloading_Agency = billData?.attributes?.Unloading_Agency ? billData?.attributes?.Unloading_Agency : ""
                    billData.HSD_LTR = parseFloat(billData?.attributes?.hsd_detail?.HSD_LTR ?? 0) ?? 0
                    billData.Tare_Weight = parseFloat((billData?.attributes?.Tare_weight / 1000).toFixed(2)) ?? 0
                    billData.Gross_Weight = parseFloat((billData?.attributes?.Gross_weight / 1000).toFixed(2)) ?? 0
                    billData.Vehicle_Number = billData?.attributes?.Vehicle_Number ?? ""
                    billData.Loading_Agency = billData?.attributes?.Loading_Agency ? billData?.attributes?.Loading_Agency : ""
                    billData.Date_of_Unloading = ((billData?.attributes?.Date_of_Unloading) ? dayjs(billData?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-')
                    billData.Date_of_Loading = ((billData?.attributes?.Date_of_Loading) ? dayjs(billData?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-')
                    billData.Challan_Number = parseInt(billData?.attributes?.Challan_Number)
                    billData.DO_No = billData?.attributes?.DO_No
                    billData.Thermal_Plant_Book_no = billData?.attributes?.Thermal_Plant_Book_no ? billData?.attributes?.Thermal_Plant_Book_no : ""
                    workSheetPendingBills.addRow(billData)
                })

                // preapare sheet for global counts

                const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Global Counts")
                // prepare sheet columns / headers
                workSheetPendingBillGlobalCounts.columns = [
                    { header: 'Bill No.', key: "first_value", width: 25 },
                    { header: exportResponse?.meta?.billNumber ?? 'NA', key: "second_value", width: 25 },
                ]

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Trip Count", second_value: exportResponse?.meta?.totalLoading ?? 0 }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Starting Date (UL)", second_value: exportResponse?.meta?.start_unloading_date && exportResponse?.meta?.start_unloading_date !== "" ? dayjs(exportResponse?.meta?.start_unloading_date).format("DD-MM-YYYY") : '-' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "End Date (UL)", second_value: exportResponse?.meta?.end_unloading_date && exportResponse?.meta?.end_unloading_date !== "" ? dayjs(exportResponse?.meta?.end_unloading_date).format("DD-MM-YYYY") : '-' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Net Weight (UL)", second_value: parseFloat(exportResponse?.meta?.total_net_weight) ?? 0 }
                )


                // set header to export as excel file
                try {
                    const buffer = await workBook.xlsx.writeBuffer()
                    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                    const blob = new Blob([buffer], { type: fileType })
                    FileSaver.saveAs(blob, `client_pending_bills_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                    // Conclude function now
                    notification.success({
                        message: "Client pending bills data exported successfully",
                        description: '',
                        duration: 3
                    })
                } catch (error) {
                    console.log(error, 'error');
                    notification.error({
                        message: "Error",
                        description: exportResponse?.error?.message,
                        duration: 3
                    })
                }
                setIsExportButtonDisabled(false)
            }
        }
    }

    const redirectToGeneratePage = () => {
        if (selectedRowKeys.length === 0) {
            notification.error({
                message: "Please select records to generate client bill",
                duration: 3
            })
            return false
        } else {
            sessionStorage.setItem(`selected_bill_date_${params?.id}`, AppHelper.encryptText(billDate))
            sessionStorage.setItem(`selected_bill_no_${params?.id}`, AppHelper.encryptText(billNo))
            sessionStorage.setItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`, AppHelper.encryptText(selectedRowKeys))
            navigate(`/bills/client-bill/edit-generate/${params?.id}`)
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
        fixed: "left",
        preserveSelectedRowKeys: true
    };

    const changeSortOrder = (e) => {
        sessionStorage.setItem(`edit_client_pending_bill_active_sort_order_${params?.id}`, AppHelper.encryptText(e.target.value))
        setCurrentPagePagination(1)
        loadPendingBills(e.target.value, 1, pageSize, true, clientBillPendingScreenFilterValues)
    }

    const setGlobalCountItemsDefault = (response) => {
        setTotalTripCount(response?.trip_count ?? 0)
        setMinUnloadingDate(response?.unloading_starting_date && response?.unloading_starting_date !== "" ? dayjs(response?.unloading_starting_date).format("DD-MM-YYYY") : '-')
        setMaxUnloadingDate(response?.unloading_end_date && response?.unloading_end_date !== "" ? dayjs(response?.unloading_end_date).format("DD-MM-YYYY") : '-')
        setTotalNetWeight(response?.total_net_weight ?? 0)
    }

    const setGlobalCountItems = (response) => {
        setTotalTripCount(response?.meta?.totalLoading ?? 0)
        setMinUnloadingDate(response?.meta?.start_unloading_date && response?.meta?.start_unloading_date !== "" ? dayjs(response?.meta?.start_unloading_date).format("DD-MM-YYYY") : '-')
        setMaxUnloadingDate(response?.meta?.end_unloading_date && response?.meta?.end_unloading_date !== "" ? dayjs(response?.meta?.end_unloading_date).format("DD-MM-YYYY") : '-')
        setTotalNetWeight(response?.meta?.total_net_weight ?? 0)
    }

    const loadAgencyPendingBillsData = (responseData, currentPage) => {
        let pendingBillsTabledata = []
        for (let i = 0; i < responseData?.length; i++) {
            pendingBillsTabledata.push({
                key: responseData[i]?.attributes?.loadingId,
                s_no: ((parseInt(currentPage) === 1) ? i + 1 : (((parseInt(i) + 1) + ((parseInt(currentPage) - 1)) * parseInt(pageSize)))),
                Challan_Number: responseData[i]?.attributes?.Challan_Number,
                Date_of_Loading: ((responseData[i]?.attributes?.Date_of_Loading) ? dayjs(responseData[i]?.attributes?.Date_of_Loading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Date_of_Unloading: ((responseData[i]?.attributes?.Date_of_Unloading) ? dayjs(responseData[i]?.attributes?.Date_of_Unloading).format(constantsArr.GLOBAL_DATE_FORMAT) : '-'),
                Vehicle_Number: responseData[i]?.attributes?.Vehicle_Number ?? "",
                Kanta_slip_no: responseData[i]?.attributes?.Kanta_Slip_No ?? '-',
                Net_Weight: responseData[i]?.attributes?.Net_Weight ? parseFloat((responseData[i]?.attributes?.Net_Weight).toFixed(2)) : 0,
                Work_Rate: responseData[i]?.attributes?.Work_Rate,
                Work_Amount: parseFloat(responseData[i]?.attributes?.Work_Amount).toFixed(2) ?? 0,
                DO_No: responseData[i]?.attributes?.DO_No,
                HSD_LTR: responseData[i]?.attributes?.HSD_LTR ?? 0,
                Rate_INR: responseData[i]?.attributes?.Rate_INR ?? 0,
                HSD_Amt: responseData[i]?.attributes?.HSD_Amt ?? 0,
                Loading_Agency: responseData[i]?.attributes?.Loading_Agency ? responseData[i]?.attributes?.Loading_Agency : "",
                Unloading_Agency: responseData[i]?.attributes?.Unloading_Agency ? responseData[i]?.attributes?.Unloading_Agency : "",
                Transporter_Agency: responseData[i]?.attributes?.Transporter_Agency ? responseData[i]?.attributes?.Transporter_Agency : "",
                Thernal_Plant_Book_No: responseData[i]?.attributes?.Thermal_Plant_Book_no ? responseData[i]?.attributes?.Thermal_Plant_Book_no : "",
                Gross_weight: parseFloat((responseData[i]?.attributes?.Gross_weight).toFixed(2)) ?? 0,
                Tare_weight: parseFloat((responseData[i]?.attributes?.Tare_weight).toFixed(2)) ?? 0,
                Unloading_location: responseData[i]?.attributes?.Unloading_Location ? responseData[i]?.attributes?.Unloading_Location : "",
            });
        }
        return pendingBillsTabledata
    }

    const handleRangePicker = (e) => {
        if (e) {
            setRangePickerStartDateValue(dayjs(e[0]).format("YYYY-MM-DD") ?? "")
            setRangePickerEndDateValue(dayjs(e[1]).format("YYYY-MM-DD") ?? "")
        } else {
            setRangePickerStartDateValue("")
            setRangePickerEndDateValue("")
            setStartDateOfUnloadingFilterForm("")
            setEndDateOfUnloadingFilterForm("")
        }
    }

    const resetFilterForm = (e) => {
        e.preventDefault()
        filterForm.resetFields()
        setRangePickerStartDateValue("")
        setRangePickerEndDateValue("")
        setStartDateOfUnloadingFilterForm('')
        setEndDateOfUnloadingFilterForm('')
        //sessionStorage.setItem(`edit_client_bill_pending_screen_filter_values_${params?.id}`, "")
        setSelectedRowKeys([])
    }

    const filterResults = (formValues) => {
        if (rangePickerStartDateValue !== "" && rangePickerEndDateValue !== "") {
            formValues.Date = `${rangePickerStartDateValue}~${rangePickerEndDateValue}`
        }
        loadPendingBills(((clientBillPendingScreenActiveSortOrder && clientBillPendingScreenActiveSortOrder !== "") ? clientBillPendingScreenActiveSortOrder : sortOrder), currentPage, pageSize, true, formValues)
        setOpen(false);
        sessionStorage.setItem(`edit_client_bill_pending_screen_filter_values_${params?.id}`, AppHelper.encryptText(formValues))
        setSelectedRowKeys([])
    }

    const loadPendingBills = (sortOrder, currentPage, pageSize, pagination, formValues) => {
        setShowTableSpinner(true)
        AppHelper.getPendingClientBills(sortOrder, currentPage, pageSize, pagination, formValues, params?.id).then(response => {
            setShowTableSpinner(false)
            setShowPagination(true)
            setBillRecords(response)

            if (formValues && Object.keys(formValues).length > 0)
                setGlobalCountItems(response)

            const getTableData = loadAgencyPendingBillsData(response?.data, currentPage)
            setPendingBillsTabledata(getTableData)
        }).catch(err => {
            setShowPagination(false)
            console.log(`Error while getting pending bills results`, err);
            notification.error({
                message: `Error while getting pending bills results`, err,
                description: "",
                duration: 3
            })
        })
    }

    const getLoadingIdsInBillToAutoSelect = (loadings) => {
        let loadingIds = []
        loadings?.forEach((value, key) => {
            loadingIds.push(value?.loading?.id)
        })
        if (clientBillPendingSelectedRecords.length === 0) {
            setSelectedRowKeys(loadingIds)
            sessionStorage.setItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`, AppHelper.encryptText(loadingIds))
        }
    }

    useEffect(() => {
        //loadPendingBills(sortOrder, currentPage, pageSize, true)
        AppHelper.getClientBillDetails(params?.id).then(response => {
            setBillDate(response?.bill_date)
            setShowTableSpinner(false)
            setBillNo(response?.bill_no ?? billNo)

            if (!clientBillPendingScreenFilterValues || clientBillPendingScreenFilterValues === "" || Object.keys(clientBillPendingScreenFilterValues).length === 0)
                setGlobalCountItemsDefault(response)

            getLoadingIdsInBillToAutoSelect(response?.loading_ids)
            loadPendingBills(sortOrder, currentPage, pageSize, true, clientBillPendingScreenFilterValues)

            if (clientBillPendingScreenFilterValues !== "") {
                let dateColumn = clientBillPendingScreenFilterValues?.Date ?? '~'
                dateColumn = dateColumn.split("~")
                const startDate = dateColumn[0] ?? ''
                const endDate = dateColumn[1] ?? ''
                setStartDateOfUnloadingFilterForm(startDate && startDate !== "" ? dayjs(dayjs(startDate).format("YYYY-MM-DD")) : '')
                setRangePickerStartDateValue(startDate && startDate !== "" ? dayjs(startDate).format("YYYY-MM-DD") : '')
                setEndDateOfUnloadingFilterForm(endDate && endDate !== "" ? dayjs(dayjs(endDate).format("YYYY-MM-DD")) : '')
                setRangePickerEndDateValue(endDate && endDate !== "" ? dayjs(endDate).format("YYYY-MM-DD") : '')
                filterForm.setFieldsValue({
                    Challan_Number: clientBillPendingScreenFilterValues?.Challan_Number ?? '',
                    Kanta_Slip_No: clientBillPendingScreenFilterValues?.Kanta_Slip_No ?? '',
                    Thermal_Plant_Book_no: clientBillPendingScreenFilterValues?.Thermal_Plant_Book_no ?? '',
                })
            }
        }).catch(err => {
            console.log(`Error while getting pending bills results`, err);
            notification.error({
                message: `Error while getting pending bills results`, err,
                description: "",
                duration: 3
            })
        })

        if (clientBillPendingSelectedRecords.length > 0)
            setSelectedRowKeys(clientBillPendingSelectedRecords)

        document.title = "Pending Client Bills"

    }, [])
    

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid
                    item
                    lg={8}
                    md={7}
                    sm={12}
                    xs={12}
                    className={classes["gridmarginBottom20"]}
                >
                    <BreadCrumbClientBilling
                        activeButton={activeButton}
                        page="clientBillEdit"
                        billId={params?.id}
                    />
                </Grid>
                <Grid
                    container
                    spacing={1}
                    item
                    lg={4}
                    md={5}
                    sm={12}
                    xs={12}
                    className={classes["gridmargintop20"]}
                >
                    <Grid item lg={6} md={3} sm={6} xs={6}>
                        <Form>
                            <Form.Item
                                label="Sort By"
                                className={classes["select-new-entry"]}
                            >
                                <Select defaultValue={(clientBillPendingScreenActiveSortOrder && clientBillPendingScreenActiveSortOrder !== "") ? clientBillPendingScreenActiveSortOrder : "Bill_Creation_Date:asc"} className={classes["select-button-height"]} id="sortOrder" onChange={(e) => changeSortOrder({ target: { id: "sortOrder", value: e } })}>
                                    <Select.Option value="Bill_Creation_Date:asc">Bill Generated. (asc)</Select.Option>
                                    <Select.Option value="Challan_Number:asc">Challan No. (asc)</Select.Option>
                                    <Select.Option value="Date_of_Unloading:asc">Date Unloading (asc)</Select.Option>
                                </Select>
                            </Form.Item>
                        </Form>
                    </Grid>
                    <Grid item lg={6} md={3} sm={6} xs={6}>
                        <UIButton
                            onClick={showDrawer}
                            variant="outlined"
                            startIcon={<FilterOutlined />}
                            className={classes["filter-new-entry"]}
                        >
                            Filters
                        </UIButton>
                        <Drawer title="Filters" onClose={onClose} open={open}>
                            <Form layout="vertical" form={filterForm} onFinish={filterResults}>
                                <Form.Item label="Date of Unloading" name="Date">
                                    <RangePicker style={{ width: "100%" }} picker="date" onChange={handleRangePicker} format={dateFormat} defaultValue={[startDateOfUnloadingFilterForm, endDateOfUnloadingFilterForm]} />
                                </Form.Item>
                                <Form.Item label="Challan No" name="Challan_Number">
                                    <Input
                                        type="number"
                                        min={0}
                                        max={999999}
                                        step={1}
                                        placeholder="Enter Challan No"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Thermal Plant Book No." name="Thermal_Plant_Book_no">
                                    <Input
                                        placeholder="Enter Thermal Plant Book No."
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <Form.Item label="Kanta Slip No. UL" name="Kanta_Slip_No">
                                    <Input
                                        placeholder="Enter Kanta Slip No. UL"
                                        addonBefore={<SearchOutlined />}
                                    />
                                </Form.Item>
                                <div className={classes["search-button-area"]}>
                                    <UIButton size="medium" variant="outlined" color="error" onClick={resetFilterForm}>
                                        Reset
                                    </UIButton>
                                    <UIButton
                                        size="medium"
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes["submit-button"]}
                                    >
                                        Apply
                                    </UIButton>
                                </div>
                            </Form>
                        </Drawer>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid className={classes["client-bill-number-wrapper"]} container>
                    <Grid className={classes["client-bill-wrappper"]}>
                        <Typography variant="h6" color="#000" fontWeight="bold">
                            Bill Date
                        </Typography>
                        <Form>
                            <DatePicker style={{ width: "100%" }} onChange={(e) => changeBillDate({ target: { id: "BllDate", value: e } })} value={dayjs(billDate)} format={dateFormat} maxDate={dayjs(currentDate)} />
                        </Form>
                    </Grid>
                    <Grid className={classes["client-bill-number"]}>
                        <Typography variant="h6" color="#000" fontWeight="bold">
                            Bill Number
                        </Typography>
                        <Typography
                            variant="body"
                            color="#353535"
                            className={classes["hr-client-box"]}
                        >
                            {billNo}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>

            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["pending-client-bill"]}
            >
                <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                    container
                    spacing={0}
                    className={classes["headerMarginTop"]}
                >
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-first"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBTripCount}
                                        alt="Total count"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {totalTripCount}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Trip Count
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-second"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBStartDate}
                                        alt="Starting Date (UL)"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {minUnloadingDate}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Starting Date (UL)
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-third"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBLastDate}
                                        alt="End Date (UL)"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {maxUnloadDate}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        End Date (UL)
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                    <Grid item lg={3} md={2} xs={1}>
                        <Typography
                            variant="body1"
                            className={`${classes["total-items"]} ${classes["total-item-fourth"]}`}
                            fontSize={14}
                        >
                            <div className={classes["header-grid-flex"]}>
                                <div>
                                    <img
                                        src={CBNetWeight}
                                        alt="Net Weight (UL)"
                                        className={classes["header-grid-image"]}
                                    />
                                </div>
                                <div>
                                    <Typography
                                        variant="body"
                                        color="white"
                                        className={classes["header-text-fontSize"]}
                                    >
                                        {totalNetWeight}
                                    </Typography>
                                    <br></br>
                                    <Typography variant="body" color="white" className={classes['box-label']}>
                                        Net Weight (UL)
                                    </Typography>
                                </div>
                            </div>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            <>
                {
                    showPagination === true && billRecords?.meta?.pagination?.total > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${billRecords?.meta?.pagination?.total} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={billRecords?.meta?.pagination?.total}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>
            <Form>
                <Table
                    className={classes["pending-client-bill"]}
                    rowSelection={rowSelection}
                    columns={pendingBillsTablecolumns}
                    dataSource={pendingBillsTabledata}
                    rowKey={(record) => record?.key}
                    bordered={true}
                    scroll={{ y: 800 }}
                    loading={{ indicator: <div><Spin /></div>, spinning: showTableSpinner }}
                    pagination={false}
                />
            </Form>
            <>
                {
                    showPagination === true && billRecords?.meta?.pagination?.total > 0 ? (
                        <div className={classes["pagination-box"]}>
                            <Pagination
                                current={currentPagePagination}
                                showSizeChanger={false}
                                defaultCurrent={currentPage}
                                defaultPageSize={currentPageSizePagination}
                                showTotal={(total) => `Total ${billRecords?.meta?.pagination?.total} items`}
                                responsive
                                pageSize={currentPageSizePagination}
                                onChange={changePageNumber}
                                total={billRecords?.meta?.pagination?.total}
                            />
                        </div>
                    ) : (
                        ""
                    )
                }
            </>

            <div className={classes["submit-button-area"]}>
                <UIButton
                    variant="outlined"
                    className={classes["submit-outline-button"]}
                    onClick={exportClientPendingBills}
                    disabled={isExportButtonDisabled}
                >
                    Export
                </UIButton>
                <UIButton
                    variant="contained"
                    color="primary"
                    type="submit"
                    className={classes["submit-button"]}
                    onClick={redirectToGeneratePage}
                >
                    Generate Bill
                </UIButton>
            </div>
        </>
    );
};
