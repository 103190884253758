const constantsArr = {
    'AGENCY_TYPES': [
        {
            label: 'Loading',
            value: 'Loading'
        },
        {
            label: 'Unloading',
            value: 'Unloading'
        },
        {
            label: 'Transportation',
            value: 'Transportation'
        }
    ],
    'AGENCY_RATES_BLOCK_KEYS': [
        'Loading_Rate',
        'Unloading_Rates',
        'Transportation_Rate'
    ],
    'DEFAULT_PAGINATION_SIZE': 12,
    'PAGINATION_CURRENT_PAGE': 1,
    'MAX_UPLOAD_SIZE_MB': 2,
    'ALLOWED_MIME_TYPES': [
        'image/png',
        'image/jpeg',
        'image/svg+xml',
        'image/bmp',
        'image/x-png',
        'application/pdf'
    ],
    'MAX_UPLOAD_IMAGES': 25,
    'MAX_IMPORT_EXCEL_FILE_SIZE_MB': 8,
    'BILL_GST_OPTIONS': [
        {
            label: 0,
            value: 0
        },
        {
            label: 5,
            value: 5
        },
        {
            label: 12,
            value: 12
        },
        {
            label: 18,
            value: 18
        }
    ],
    'GLOBAL_DATE_FORMAT': 'DD-MM-YYYY',
    'GST_RATES': [
        {
            value: "0",
            label: "0%",
        },
        {
            value: "5",
            label: "5%",
        },
        {
            value: "12",
            label: "12%",
        },
        {
            value: "18",
            label: "18%",
        },
    ],
    'LESS_TDS_RATES': [
        {
            value: "1",
            label: "1%",
        },
        {
            value: "2",
            label: "2%",
        }
    ],
    'DEFAULT_PAGINATION_SIZE_BILLS_TABLE': 100000,
    'DEFAULT_PAGINATION_SIZE_BILLS_TABLE_LAYOUT': 100,
    'EXPORT_EXCEL_EXTENSION': 'xlsx',
    'GLOBAL_DATE_FORMAT_DAYJS': 'YYYY-MM-DD',
    'AGENCY_RATE_TYPES': [
        {
            'label': 'Per (MT)',
            'value': 'per_mt'
        },
        {
            'label': 'Per (Unit)',
            'value': 'per_unit'
        }
    ],
    'LONG_LABELS_VEHICLE_INFO_TABLE' : [
        'vehicleManufacturerName',
        'vehicleInsuranceCompanyName',
        'vehicleInsurancePolicyNumber',
        'vehicleInsuranceUpto',
        'regAuthority',
        'presentAddress',
        'permanentAddress'
    ]
}

export default constantsArr