import React from "react";
import { Typography, Grid } from "@mui/material";
import classes from "./PendingHardCopyBD.module.scss";

export const PendingHardCopyBD = ({ widgetContentInfoPendingHardCopy }) => {

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["allbill-padding"]}
            >
                <Grid lg={12} md={12} sm={12} xs={12} container spacing={2}>
                    {/* left side */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* pink bars */}
                        <div className={classes["flex-wrapper-colors-first"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Bill Amount
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumBillAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>

                        <div className={classes["flex-wrapper-colors-first"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                GST
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumGST.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>

                        <div className={classes["flex-wrapper-colors-first"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Total
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumTotal.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        {/* pink bars */}

                        {/* yellow bars */}
                        <div
                            className={classes["flex-wrapper-colors-second"]}
                            style={{ marginTop: "15px" }}
                        >
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Hsd Deduction
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumHSDAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        <div className={classes["flex-wrapper-colors-second"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Tds Deduction
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumTdsAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        <div className={classes["flex-wrapper-colors-second"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Other Deduction
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumOtherDeductionAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        {/* yellow bars */}
                    </Grid>
                    {/* right side */}
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        {/* green bars */}
                        <div className={classes["flex-wrapper-colors-third"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Billed Payable
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumTotalPayableAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        {/* green bars */}

                        {/* brown bars */}
                        <div className={classes["flex-wrapper-colors-fourth"]} style={{ marginTop: "5px" }}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                With Held GST
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumWithHeldAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        {/* brown bars */}

                        {/* orange bars */}
                        <div className={classes["flex-wrapper-colors-fivth"]} style={{ marginTop: "5px" }}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Already Paid
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.sumAlreadyPaidAmount.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        <div className={classes["flex-wrapper-colors-fivth"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Billed Balance
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.totalBilledBalance.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        {/* orange bars */}

                        {/* cyan bars */}
                        <div className={classes["flex-wrapper-colors-sixth"]} style={{ marginTop: "5px" }}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Unbilled Balance
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.totalUnBilledBalance.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        <div className={classes["flex-wrapper-colors-sixth"]}>
                            <Typography
                                variant="body1"
                                color="#353535"
                                className={classes["bill-amount-bg-color"]}
                            >
                                Eff Balance
                            </Typography>
                            <Typography
                                variant="body1"
                                color="#353535"
                                fontWeight="bold"
                                className={classes["bill-amount-bg-color-opacity"]}
                            >
                                &#x20b9; {widgetContentInfoPendingHardCopy?.data?.attributes?.totalEffectiveBalance.toLocaleString('en-in') ?? 0}
                            </Typography>
                        </div>
                        {/* cyan bars */}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
