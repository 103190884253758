import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage/LoginPage";
import { SampleDataPage } from "./pages/SampleDataPage/SampleDataPage";
import { LoadingData } from "./components/SamplePage/LoadingData/LoadingData";
import { UnloadingData } from "./components/SamplePage/UnloadingData/UnloadingData";
import { CreateLoading } from "./components/SamplePage/LoadingData/CreateLoading/CreateLoading";
import { ViewDataPage } from "./components/SamplePage/LoadingData/ViewDataPage/ViewDataPage";
import { AgencyData } from "./components/SamplePage/AgencyData/AgencyData";
import ViewAgencyData from "./components/SamplePage/AgencyData/ViewAgencyData";
import { ViewUnloadData } from "./components/SamplePage/UnloadingData/ViewUnloadData/ViewUnloadData";
import { VehicleData } from "./components/SamplePage/VehicleData/VehicleData";
import { ViewVehicleData } from "./components/SamplePage/VehicleData/viewVehicleData/ViewVehicleData";
import { CreateVehicleData } from "./components/SamplePage/VehicleData/CreateVehicleData/CreateVehicleData";
import CreateAgency from "./components/SamplePage/AgencyData/CreateAgency/CreateAgency";
import { CreateUnloadedData } from "./components/SamplePage/UnloadingData/CreateUnloadedData/CreateUnloadedData";
import { CreateHsdDetails } from "./components/SamplePage/HSDDetails/CreateHsdDetails/CreateHsdDetails";
import { HSDDetailsData } from "./components/SamplePage/HSDDetails/HSDDetailsData";
import EditAgency from "./components/SamplePage/AgencyData/EditAgency/EditAgency";
import { PetrolPumpData } from "./components/SamplePage/PetrolPumpData/PetrolPumpData";
import { CreatePetrolPump } from "./components/SamplePage/PetrolPumpData/CreatePetrolPump/CreatePetrolPump";
import { ViewPetrolPumpData } from "./components/SamplePage/PetrolPumpData/ViewPetrolPumpData/ViewPetrolPumpData";
import ViewHSDDetailsData from "./components/SamplePage/HSDDetails/ViewHSDDetailsData";
import EditHsdDetails from "./components/SamplePage/HSDDetails/EditHsdDetails/EditHsdDetails";
import { EditPetrolPump } from "./components/SamplePage/PetrolPumpData/EditPetrolPump/EditPetrolPump";
import { EditVehicleData } from "./components/SamplePage/VehicleData/EditVehicleData/EditVehicleData";
import { EditLoadingData } from "./components/SamplePage/LoadingData/EditLoadingData/EditLoadingData";
import { EditUnloadedData } from "./components/SamplePage/UnloadingData/EditUnloadedData/EditUnloadedData";
import { ChangePassword } from "./components/ChangePassword/ChangePassword";
import { ProfileSettings } from "./components/ProfileSettings/ProfileSettings";
import { ExcavatorData } from "./components/SamplePage/Excavator/ExcavatorData";
import { CreateExcavator } from "./components/SamplePage/Excavator/CreateExcavator/CreateExcavator";
import ViewExcavatorData from "./components/SamplePage/Excavator/ViewExcavatorData";
import { EditExcavator } from "./components/SamplePage/Excavator/EditExcavator/EditExcavator";
import { PendingLoadingBills } from "./components/Bills/Loading/PendingLoadingBills/PendingLoadingBills";
import { GeneratedLoadingBills } from "./components/Bills/Loading/GeneratedLoadingBills/GeneratedLoadingBills";
import { GenerateBill } from "./components/Bills/Loading/GenerateBill/GenerateBill";
import { EditLoadingBills } from "./components/Bills/Loading/EditLoadingBills/EditLoadingBills";
import { PendingTransportBills } from "./components/Bills/Transport/PendingTransportBills/PendingTransportBills";
import { GenerateTBill } from "./components/Bills/Transport/GenerateTBill/GenerateTBill";
import { GeneratedTransportBills } from "./components/Bills/Transport/GeneratedTransportBills/GeneratedTransportBills";
import { EditTransportBills } from "./components/Bills/Transport/EditTransportBills/EditTransportBills";
import { PendingUnloadingBills } from "./components/Bills/Unloading/PendingUnloadingBills/PendingUnloadingBills";
import { GeneratedUnloadingBills } from "./components/Bills/Unloading/GeneratedUnloadingBills/GeneratedUnloadingBills";
import { EditUnloadingBills } from "./components/Bills/Unloading/EditUnloadingBills/EditUnloadingBills";
import { VehicleDataReport } from "./components/SamplePage/VehicleData/VehicleDataReport/VehicleDataReport";
import { FinalCalculationBills } from "./components/Bills/Loading/FinalCalculationBIlls/FinalCalculationBills";
import { TransportFinalCalculation } from "./components/Bills/Transport/TransportFinalCalculation/TransportFinalCalculation";
import { ViewTransactionSummary } from "./components/Bills/Transport/ViewTransactionSummary/ViewTransactionSummary";
import { ViewLoadingTransactionSummary } from "./components/Bills/Loading/ViewTransactionSummary/ViewLoadingTransactionSummary";
import { ViewUnloadingTransactionSummary } from "./components/Bills/Unloading/ViewUnloadingTransactionSummary/ViewUnloadingTransactionSummary";
import { PendingClientBilling } from "./components/Bills/ClientBilling/PendingClientBilling/PendingClientBilling";
import { GenerateTEditBill } from "./components/Bills/Transport/GenerateTEditBill/GenerateTEditBill";
import { TransportEditBillFinalCalculation } from "./components/Bills/Transport/TransportEditBillFinalCalculation/TransportEditBillFinalCalculation";
import { PendingClientBillingGenerate } from "./components/Bills/ClientBilling/PendingClientBillingGenerate/PendingClientBillingGenerate";
import { ClientBillFinalCalculation } from "./components/Bills/ClientBilling/ClientBillFinalCalculation/ClientBillFinalCalculation";
import { GeneratedClientBill } from "./components/Bills/ClientBilling/GeneratedClientBill/GeneratedClientBill";
import { ViewTransactionClientBillingSummary } from "./components/Bills/ClientBilling/ViewTransactionClientBillingSummary/ViewTransactionClientBillingSummary";
import { EditPendingClientBilling } from "./components/Bills/ClientBilling/EditPendingClientBilling/EditPendingClientBilling";
import { EditPendingClientBillingGenerate } from "./components/Bills/ClientBilling/EditPendingClientBillingGenerate/EditPendingClientBillingGenerate";
import { EditClientBillFinalCalculation } from "./components/Bills/ClientBilling/EditClientBillFinalCalculation/EditClientBillFinalCalculation";
import { GenerateLoadingEditBill } from "./components/Bills/Loading/GenerateLoadingEditBill/GenerateLoadingEditBill";
import { LoadingEditBillFinalCalculation } from "./components/Bills/Loading/LoadingEditBillFinalCalculation/LoadingEditBillFinalCalculation";
import { FinalCalculationBillsUnloading } from "./components/Bills/Unloading/FinalCalculationBillsUnloading/FinalCalculationBillsUnloading";
import { GenerateUnloadingBill } from "./components/Bills/Unloading/GenerateUnloadingBill/GenerateUnloadingBill";
import { GenerateUnloadingEditBill } from "./components/Bills/Unloading/GenerateUnloadingEditBill/GenerateUnloadingEditBill";
import { UnloadingEditBillFinalCalculation } from "./components/Bills/Unloading/UnloadingEditBillFinalCalculation/UnloadingEditBillFinalCalculation";
import { Dashboard } from "./components/Dashboard/Dashboard";
import { PendingPetrolPumpBill } from "./components/Bills/PetrolPump/PendingPetrolPumpBill/PendingPetrolPumpBill";
import { GeneratePetrolPumpBill } from "./components/Bills/PetrolPump/GeneratePetrolPumpBill/GeneratePetrolPumpBill";
import { FinalCalculationPetrolPumpBill } from "./components/Bills/PetrolPump/FinalCalculationPetrolPumpBill/FinalCalculationPetrolPumpBill";
import { EditPendingPetrolPumpBill } from "./components/Bills/PetrolPump/EditPendingPetrolPumpBill/EditPendingPetrolPumpBill";
import { EditGeneratePetrolPumpBill } from "./components/Bills/PetrolPump/EditGeneratePetrolPumpBill/EditGeneratePetrolPumpBill";
import { EditFinalCalulationPetrolPumpBill } from "./components/Bills/PetrolPump/EditFinalCalculationPetrolPumpBill/EditFinalCalulationPetrolPumpBill";
import { GeneratedPetrolPumpBill } from "./components/Bills/PetrolPump/GeneratedPetrolPumpBill/GeneratedPetrolPumpBill";
import { PetrolPumpViewTransactionSummary } from "./components/Bills/PetrolPump/PetrolPumpViewTransactionSummary/PetrolPumpViewTransactionSummary";
import { Summary } from "./components/Reports/Summary/Summary";
import { Payment } from "./components/Reports/Payment/Payment";

function App() {
    return (
        <>
            <Router>
                <Routes>
                    <Route path="/" element={<LoginPage />} />
                    <Route element={<SampleDataPage />}>
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/changepassword/:id" element={<ChangePassword />} />
                        <Route path="/profilesettings/:id" element={<ProfileSettings />} />
                        <Route path="/loadings" element={<LoadingData />} />
                        <Route path="/loadings/create" element={<CreateLoading />} />
                        <Route path="/loadings/view/:id" element={<ViewDataPage />} />
                        <Route path="/loadings/edit/:id" element={<EditLoadingData />} />
                        <Route path="/unloadings" element={<UnloadingData />} />
                        <Route path="/unloadings/view/:id" element={<ViewUnloadData />} />
                        <Route path="/unloadings/create/:id" element={<CreateUnloadedData />} />
                        <Route path="/unloadings/edit/:id" element={<EditUnloadedData />} />
                        <Route path="/agencies" element={<AgencyData />} />
                        <Route path="/agencies/:id" element={<ViewAgencyData />} />
                        <Route path="/agencies/create" element={<CreateAgency />} />
                        <Route path="/agencies/edit/:id" element={<EditAgency />} />
                        <Route path="/vehicles" element={<VehicleData />} />
                        <Route path="/vehicles/view/:id" element={<ViewVehicleData />} />
                        <Route path="/vehicles/create" element={<CreateVehicleData />} />
                        <Route path="/vehicles/edit/:id" element={<EditVehicleData />} />
                        <Route path="/vehicles/vehicle-report" element={<VehicleDataReport />} />
                        <Route path="/hsd-details" element={<HSDDetailsData />} />
                        <Route path="/hsd-details/create/loading/:id" element={<CreateHsdDetails />} />
                        <Route path="/hsd-details/:id" element={<ViewHSDDetailsData />} />
                        <Route path="/hsd-details/edit/:id" element={<EditHsdDetails />} />
                        <Route path="/petrol-pumps" element={<PetrolPumpData />} />
                        <Route path="/petrol-pumps/create" element={<CreatePetrolPump />} />
                        <Route path="/petrol-pumps/view/:id" element={<ViewPetrolPumpData />} />
                        <Route path="/petrol-pumps/edit/:id" element={<EditPetrolPump />} />
                        <Route path="/excavators" element={<ExcavatorData />} />
                        <Route path="/excavators/create" element={<CreateExcavator />} />
                        <Route path="/excavators/view/:id" element={<ViewExcavatorData />} />
                        <Route path="/excavators/edit/:id" element={<EditExcavator />} />
                        <Route path="/bills/loading/pending-loading-bills" element={<PendingLoadingBills />} />
                        <Route path="/bills/loading/generate-bill" element={<GenerateBill />} />
                        <Route path="/bills/loading/edit-loading-bill/:id" element={<EditLoadingBills />} />
                        <Route path="/bills/loading/edit-loading-bill-generate/:id" element={<GenerateLoadingEditBill />} />
                        <Route path="/bills/loading/edit-loading-bill-final-calculation/:id" element={<LoadingEditBillFinalCalculation />} />
                        <Route path="/bills/loading/final-calculation" element={<FinalCalculationBills />} />
                        <Route path="/bills/loading/view-transaction-summary/:id" element={<ViewLoadingTransactionSummary />} />
                        <Route path="/bills/loading/generated-loading-bills" element={<GeneratedLoadingBills />} />

                        <Route path="/bills/transport/pending-transport-bills" element={<PendingTransportBills />} />
                        <Route path="/bills/transport/generate-bill" element={<GenerateTBill />} />
                        <Route path="/bills/transport/edit-transport-bill/:id" element={<EditTransportBills />} />
                        <Route path="/bills/transport/edit-transport-bill-generate/:id" element={<GenerateTEditBill />} />
                        <Route path="/bills/transport/edit-transport-bill-final-calculation/:id" element={<TransportEditBillFinalCalculation />} />
                        <Route path="/bills/transport/generated-transport-bills" element={<GeneratedTransportBills />} />
                        <Route path="/bills/transport/view-transaction-summary/:id" element={<ViewTransactionSummary />} />
                        <Route path="/bills/transport/final-calculation" element={<TransportFinalCalculation />} />

                        <Route path="/bills/unloading/pending-unloading-bills" element={<PendingUnloadingBills />} />
                        <Route path="/bills/unloading/generate-bill" element={<GenerateUnloadingBill />} />
                        <Route path="/bills/unloading/edit-unloading-bill/:id" element={<EditUnloadingBills />} />
                        <Route path="/bills/unloading/edit-unloading-bill-generate/:id" element={<GenerateUnloadingEditBill />} />
                        <Route path="/bills/unloading/edit-unloading-bill-final-calculation/:id" element={<UnloadingEditBillFinalCalculation />} />
                        <Route path="/bills/unloading/generated-unloading-bills" element={<GeneratedUnloadingBills />} />
                        <Route path="/bills/unloading/view-transaction-summary/:id" element={<ViewUnloadingTransactionSummary />} />
                        <Route path="/bills/unloading/final-calculation" element={<FinalCalculationBillsUnloading />} />

                        <Route path="/bills/client-bill/pending-client-bill" element={<PendingClientBilling />} />
                        <Route path="/bills/client-bill/generate" element={<PendingClientBillingGenerate />} />
                        <Route path="/bills/client-bill/final-calculation" element={<ClientBillFinalCalculation />} />
                        <Route path="/bills/client-bill/generated-client-bill" element={<GeneratedClientBill />} />
                        <Route path="/bills/client-bill/view-summary-bill/:id" element={<ViewTransactionClientBillingSummary />} />
                        <Route path="/bills/client-bill/edit/:id" element={<EditPendingClientBilling />} />
                        <Route path="/bills/client-bill/edit-generate/:id" element={<EditPendingClientBillingGenerate />} />
                        <Route path="/bills/client-bill/edit-final-calculation/:id" element={<EditClientBillFinalCalculation />} />

                        <Route path="/reports/summary" element={<Summary />} />
                        <Route path="/reports/payment" element={<Payment />} />
                          
                        <Route path="/bills/petrol-pump/pending-petrol-pump-bill" element={<PendingPetrolPumpBill />} />
                        <Route path="/bills/petrol-pump/generate-petrol-pump-bill" element={<GeneratePetrolPumpBill />} />
                        <Route path="/bills/petrol-pump/final-calculation" element={<FinalCalculationPetrolPumpBill />} />
                        <Route path="/bills/petrol-pump/edit-pending-petrol-pump-bill" element={<EditPendingPetrolPumpBill />} />
                        <Route path="/bills/petrol-pump/edit-generate-petrol-pump-bill" element={<EditGeneratePetrolPumpBill />} />
                        <Route path="/bills/petrol-pump/edit-final-calculation-bill" element={<EditFinalCalulationPetrolPumpBill />} />
                        <Route path="/bills/petrol-pump/generated-petrol-pump-bill" element={<GeneratedPetrolPumpBill />}  />
                        <Route path="/bills/petrol-pump/view-transaction-summary" element={<PetrolPumpViewTransactionSummary />} />
                    </Route>
                </Routes>
            </Router>
        </>
    );
}

export default App;
