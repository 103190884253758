import React, { useState, useEffect } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import { ArrowLeftOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import classes from '../CreateExcavator/CreateExcavator.module.scss'
import { DatePicker, Form, Input, Select, notification, Upload, Modal } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import constantsArr from "../../../../constants/agency-constant";
import arrConstants from "../../../../constants/global-constants";
import dayjs from "dayjs";
import moment from "moment";
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { toolbarPlugin } from '@react-pdf-viewer/toolbar'

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

export const CreateExcavator = () => {
    const navigate = useNavigate();
    const [hsdForm] = Form.useForm();
    const [petrolPumpsDropDownValues, setPetrolPumpsDropDownValues] = useState([]);
    const [petrolPumpsLists, setPetrolPumpsLists] = useState([]);
    const [loadingAgencyLists, setloadingAgencyLists] = useState([]);
    const [agencyDataDown, setagencyDataDown] = useState([]);
    const sessionGlobalValues = AppHelper.decryptText(sessionStorage.getItem("globalValues"));
    const dateFormat = "DD-MM-YYYY"
    const currentDateObj = new Date()
    const currentDate = `${currentDateObj.getDate()}-${(currentDateObj.getMonth() + 2).toString().padStart(2, 0)}-${currentDateObj.getFullYear()}`
    const [isSubmittingResponse, setIsSubmittingResponse] = useState(false);
    const maxNoOfUploadsAllowed = arrConstants.MAX_UPLOAD_IMAGES
    const [fileList, setFileList] = useState([]);
    const [uploadedFileList, setUploadedFileList] = useState([]);
    const allowedMimeTypes = arrConstants.ALLOWED_MIME_TYPES
    const [viewPdf, setViewPdf] = useState(null);
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const [previewTitle, setPreviewTitle] = useState("");
    const API_URL = process.env.REACT_APP_API_DOMAIN || "";
    const maxUploadSizeAllowed = constantsArr.MAX_UPLOAD_SIZE_MB * 1024 * 1024

    const toolbarPluginInstance = toolbarPlugin();
    const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;
    const transform = (slot) => ({
        ...slot,
        // These slots will be empty
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        CurrentPageInput: () => <></>,
        NumberOfPages: () => <></>,
        MoreActionsPopover: () => <></>,
        GoToFirstPage: () => <></>,
        GoToFirstPageMenuItem: () => <></>,
        GoToNextPage: () => <></>,
        GoToNextPageMenuItem: () => <></>,
        GoToPreviousPage: () => <></>,
        GoToPreviousPageMenuItem: () => <></>,
        GoToLastPage: () => <></>,
        GoToLastPageMenuItem: () => <></>,
        SwitchTheme: () => <></>,
        SwitchThemeMenuItem: () => <></>,
        Print: () => <></>,
        PrintMenuItem: () => <></>,
        Open: () => <></>,
        OpenMenuItem: () => <></>,
        ShowProperties: () => <></>,
        ShowPropertiesMenuItem: () => <></>,
    });

    const onFinish = async (values) => {
        setIsSubmittingResponse(true);
        values.Vehicle_Number = "EXCAVATOR";
        values.Owner = "EXCAVATOR";

        try {
            // if (uploadedFileList.length === 0) {
            //     notification.error({
            //         message: "Error",
            //         description: "Please upload images of unloading",
            //         duration: 3,
            //     });
            //     setIsSubmittingResponse(false);
            //     return false
            // }
            let filesJson = JSON.stringify(uploadedFileList)
            const uploadImageResponse = await AppHelper.savePictureData(filesJson)
                const globalTpProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
                values['Project'] = globalTpProjectValues.projectGlobal
                values['Petrol_Pumps'] = [values.Petrol_Pumps]
                const agency = loadingAgencyLists.find((item) => item?.attributes?.Company_name === values?.agency_id && item?.attributes?.Project?.data?.id === globalTpProjectValues.projectGlobal)
                values['agency_id'] = agency?.id ?? null
                values['Date'] = dayjs(values?.Date).format("YYYY-MM-DD")
                values['Uploaded_Pictures'] = { 'id': uploadImageResponse?.data?.id ?? "" }
                const response = await AppHelper.postExcavator(values);
                if (response?.error) {
                    notification.error({
                        message: "Error",
                        description: response?.error?.message,
                        duration: 1.5,
                    });
                } else {
                    notification.success({
                        message: "Success",
                        description: "Excavator details created successfully.",
                        duration: 1.5,
                    });
                    navigate("/excavators?sort=updatedAt:desc");
                }
        } catch (error) {
            console.log("Error creating HSD Detail", error);

            // Optionally, you can show an error message
        }
        setIsSubmittingResponse(false)
    }    

    const hsdExcavatorDetails = (e) => {
        const selectedDate = ((hsdForm.getFieldValue("Date")) ? dayjs(hsdForm.getFieldValue("Date"), "DD-MM-YYYY").format("YYYY-MM-DD") : null);
        if (!selectedDate || selectedDate === "") {
            notification.error({
                message: "Error",
                description: "Please select date first to fetch the petrol pump rate",
                duration: 3,
            });
            hsdForm.setFieldsValue({
                Petrol_Pumps: null
            })
            return false
        }
        const hsdDetailsById = petrolPumpsLists.find((obj) => obj.id === e);
        const petrolPumpRates = hsdDetailsById?.attributes?.Rate;
        const sortPetrolPumpRates = AppHelper.makePetrolPumpRatesDescendingOrder(petrolPumpRates)

        // find rate by petrol pump and date
        const petrolPumpRate = AppHelper.findPetrolPumpRatebyDateAndPetrolPumpValue(selectedDate, sortPetrolPumpRates)
        hsdForm.setFieldsValue({
            Rate: petrolPumpRate,
        });
        const hsdLtr = hsdForm.getFieldValue('HSD_LTR') ?? 0;
        displayHSDAmt(hsdLtr, petrolPumpRate)
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const backToHSDDetailsList = (e) => {
        e.preventDefault();
        navigate("/excavators");
    };

    const populateAgencyDropDown = (e) => {
        hsdForm.setFieldsValue({
            agency_id: null
        })
        setagencyDataDown(AppHelper.getAgencyDropDown(loadingAgencyLists, e, sessionGlobalValues));
    }

    const handleChange = (e) => {
        const newValue = (!e.target.value || e.target.value === "") ? 0 : e.target.value;
        const numericValue = parseFloat(newValue);
        const rateInr = hsdForm.getFieldValue('Rate') ?? 0;
        displayHSDAmt(numericValue, rateInr)
    }

    const handleChangeDate = (e) => {
        hsdForm.setFieldsValue({
            Petrol_Pumps: null,
            Rate: null
        })
        setPetrolPumpsDropDownValues([]);
        setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpNameDropDown(petrolPumpsLists));
    }

    const displayHSDAmt = (hsdLtr, rateInr) => {
        const hsdAmt = isNaN(hsdLtr) || isNaN(rateInr) ? 0 : ((parseFloat(hsdLtr) * parseFloat(rateInr)).toFixed(2)).toString();
        hsdForm.setFieldsValue({
            HSD_AMOUNT: hsdAmt
        })
    }

    const disableDates = (currentDate) => {
        return currentDate > moment().endOf('day')
    }

    const handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if (file.type === 'application/pdf') {
            // Only set viewPdf if the file is a c/PDF
            setViewPdf(file.url || file.preview);
            setPreviewOpen(true);
            if (!viewPdf) {
                setPreviewImage(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        } else {
            setPreviewImage(file.url || file.preview);
            setPreviewOpen(true);
            if (!previewImage) {
                setViewPdf(null)
            }
            setPreviewTitle(
                file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
            );
        }
    };


    const customRequest = async ({ file, onSuccess, onError }) => {
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            return false
        }
        const response = await AppHelper.uploadImages(file);
        if (response?.error) {
            onError(new Error("Upload failed"));
        } else {
            onSuccess();
            let imageResponse = response[0]
            Object.entries(imageResponse).forEach(([key, value]) => {
                if (key === 'url') {
                    if (uploadefFileType === 'application/pdf') {
                        // Do not set viewPdf here
                    }
                    imageResponse[key] = API_URL + value
                    imageResponse['uid'] = file?.uid
                }
            })
            uploadedFileList.push({ ...imageResponse });
        }
    };

    const beforeUpload = (file) => {
        // check correct file type
        const uploadefFileType = file?.type ?? ""
        if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
            notification.error({
                message: "Error",
                description: "The uploaded file is invalid; only files with the following extensions are accepted: jpg, jpeg, svg, bmp, png and pdf.",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
        // check for maximum size can upload
        let uploadedSize = file?.size ?? 0
        uploadedSize = parseInt(uploadedSize)
        if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
            notification.error({
                message: "Error",
                description: "The uploaded file exceeds the maximum allowed image size; please ensure that the file size does not exceed 2 MB",
                duration: 5
            })
            return false || Upload.LIST_IGNORE
        }
    }


    const handleChangePicture = async (values) => {
        const fileStatus = values?.file?.status
        if (fileStatus !== 'removed') {
            const uploadefFileType = values?.file?.type ?? ""
            if (!uploadefFileType || !allowedMimeTypes.includes(uploadefFileType)) {
                return false || Upload.LIST_IGNORE
            } else {
                // check for maximum size can upload
                let uploadedSize = values?.file?.size ?? 0
                uploadedSize = parseInt(uploadedSize)
                if (uploadedSize > parseInt(maxUploadSizeAllowed)) {
                    return false || Upload.LIST_IGNORE
                } else {
                    if (values?.file?.type === 'application/pdf') {
                        const getUploadedFile = values?.fileList.find((item) => item?.uid === values?.file?.uid)
                        let fileReader = new FileReader();
                        // Onload of file read the file content
                        fileReader.onload = function (fileLoadedEvent) {
                            // file = fileLoadedEvent.target.result;
                            // Print data in console
                            getUploadedFile.thumbUrl = fileLoadedEvent.target.result
                        };
                        // Convert data to base64
                        fileReader.readAsDataURL(values?.file?.originFileObj);
                    }
                }
            }
        } else {
            // remove from uploaded file array
            const uploadedFileListUpdated = uploadedFileList.filter((item) => item?.uid !== values?.file?.uid)
            setUploadedFileList(uploadedFileListUpdated)
        }
        setFileList(values?.fileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const handleCancel = () => setPreviewOpen(false);

    useEffect(() => {
        document.title = "Create Excavator"
        AppHelper.getPetrolPumps().then((response) => {
            setPetrolPumpsLists(response);
            setPetrolPumpsDropDownValues(AppHelper.getPetrolPumpNameDropDown(response));
        });

        AppHelper.getAgenciesByProject(sessionGlobalValues?.projectGlobal).then((response) => {
            setloadingAgencyLists(response);
        });
        hsdForm.setFieldsValue({
            Vehicle_Number: "EXCAVATOR",
            Owner: "EXCAVATOR",
            HSD_LTR: 0
        })
    }, []);

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["create-excavator-padding"]}
            >
                <Grid item lg={10} md={9} xs={12}>
                    <Typography
                        variant="h1"
                        fontSize="1.5rem"
                        fontWeight="bold"
                        className="title-un-loading-text"
                    >
                        Create Excavator
                    </Typography>
                </Grid>

                <Grid item lg={2} md={3} xs={12} container className={classes['action-button']}>
                    <UIButton
                        size="small"
                        variant="contained"
                        onClick={backToHSDDetailsList} className={classes['add-new-entry']}
                    >
                        <ArrowLeftOutlined />
                        &nbsp;Excavators
                    </UIButton>
                </Grid>
            </Grid>

            <div className={classes["marginTop20"]}>
                <Form layout="vertical" form={hsdForm} onFinish={onFinish}>
                    <Grid
                        container
                        spacing={1}
                        direction="row"
                        justify="center"
                        alignItems="center"
                        alignContent="center"
                        wrap="wrap"
                    >
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item name="Vehicle_Number" label="Vehicle No.">
                                <Input readOnly disabled className={classes["disabled-input"]} />
                            </Form.Item>
                            <Form.Item name="Agency_Type" label="Type of agency" rules={
                                [
                                    {
                                        required: true,
                                        message: "Please select Type of agency"
                                    }
                                ]
                            }>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={constantsArr.AGENCY_TYPES}
                                    placeholder="Select"
                                    onChange={(e) => populateAgencyDropDown(e)}
                                />
                            </Form.Item>

                            <Form.Item name="agency_id" label="Agency Name"
                                rules={[{
                                    required: true,
                                    message: "Please enter the Agency name."
                                }]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={agencyDataDown}
                                    placeholder="Select"
                                />
                            </Form.Item>

                            <Form.Item name="DO_No" label="D/O No."
                                rules={
                                    [
                                        {
                                            required: true,
                                            message: "Please enter D/O No."
                                        }
                                    ]
                                }>
                                <Input />
                            </Form.Item>

                            <Form.Item name="HSD_LTR" label="HSD LTR" rules={[
                                {
                                    required: true,
                                    message: "Please enter the HSD Litre."
                                }
                            ]}>
                                <Input type='number' step="any" min={0} onKeyDown={AppHelper.preventMinus} onChange={handleChange} />
                            </Form.Item>
                        </Grid>
                        <Grid item lg={6} md={6} xs={12}>
                            <Form.Item name="Owner" label="Owner">
                                <Input readOnly disabled className={classes["disabled-input"]} />
                            </Form.Item>

                            <Form.Item
                                name="Date"
                                label="Date"
                                rules={[{ required: true, message: "Please select the date" }]}
                            >
                                {/* Add onChange handler to trigger hsdExcavatorDetails on date change */}
                                <DatePicker
                                    style={{ width: "100%" }}
                                    maxDate={dayjs(currentDate, dateFormat)}
                                    format={dateFormat}
                                    disabledDate={disableDates}
                                    onChange={handleChangeDate}
                                />
                            </Form.Item>

                            <Form.Item name="Petrol_Pumps" label="Petrol Pump Name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please select petrol pump"
                                    }
                                ]}>
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    filterOption={filterOption}
                                    options={petrolPumpsDropDownValues}
                                    placeholder="Select"
                                    onChange={(e) => hsdExcavatorDetails(e)}
                                />
                            </Form.Item>

                            <Form.Item name="Rate" label="Rate">
                                <Input readOnly type="number" disabled className={classes["disabled-input"]} />
                            </Form.Item>

                            <Form.Item name="HSD_AMOUNT" label="HSD Amount">
                                <Input type="number" readOnly disabled className={classes["disabled-input"]} />
                            </Form.Item>
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Form.Item name="Remarks" label="Remarks">
                            <Input style={{ width: "50%" }} />
                        </Form.Item>
                    </Grid>

                    <Grid item>
                        <Upload
                                customRequest={customRequest}
                                multiple
                                listType="picture-card"
                                fileList={fileList}
                                onPreview={handlePreview}
                                onChange={handleChangePicture}
                                name="Unloading_Images"
                                withCredentials={true}
                                beforeUpload={beforeUpload}
                                accept="image/png, image/jpeg, image/svg+xml, image/bmp, image/x-png, application/pdf"
                            >
                                {fileList.length >= maxNoOfUploadsAllowed ? null : uploadButton}
                            </Upload>
                            <Modal
                                open={previewOpen}
                                title={previewTitle}
                                footer={null}
                                onCancel={handleCancel}
                                width={800}
                                styles={{ body: { height: '500px', overflowY: 'scroll', maxHeight: '500px' } }}
                            >
                                <div
                                    className="rpv-core__viewer"
                                    style={{
                                        border: '1px solid rgba(0, 0, 0, 0.3)',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '100%',
                                    }}
                                >
                                    {viewPdf && (
                                        <>
                                            <div
                                                style={{
                                                    alignItems: 'center',
                                                    backgroundColor: '#eeeeee',
                                                    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
                                                    display: 'flex',
                                                    padding: '0.25rem',
                                                }}
                                            >
                                                <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
                                            </div>
                                            <>
                                                <div
                                                    style={{
                                                        flex: 1,
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Worker workerUrl={`https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.js`}>
                                                        <Viewer fileUrl={viewPdf} plugins={[toolbarPluginInstance]} />
                                                    </Worker>
                                                </div>
                                            </>
                                        </>
                                    )}
                                    {previewImage && ( // Render image if not viewing PDF
                                        <img
                                            alt="example"
                                            style={{
                                                width: "100%",
                                            }}
                                            src={previewImage}
                                        />
                                    )}
                                </div>
                            </Modal>
                    </Grid>
                    <div className={classes["submit-button-area"]}>
                        <UIButton variant="contained" color="primary" type="submit" className={classes['submit-button']} disabled={isSubmittingResponse}>
                            Save
                        </UIButton>
                        <UIButton
                            variant="outlined"
                            color="error"
                            onClick={backToHSDDetailsList}
                        >
                            Cancel
                        </UIButton>
                    </div>
                </Form>
            </div>
        </>
    );
};
