import React from 'react'
import { Login } from '../../components/Login/Login'

export const LoginPage = () => {
  return (
    <div>
        <Login />
    </div>
  )
}
