import React, { useEffect, useState } from "react";
import { Typography, Grid, Button as UIButton } from "@mui/material";
import classes from "./EditClientBillFinalCalculation.module.scss";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Input, notification } from "antd";
import AppHelper from "../../../../helpers/AppHelper";
import dayjs from "dayjs";
import { BreadCrumbClientBilling } from "../BreadCrumbClientBilling/BreadCrumbClientBilling";
import exceljs from 'exceljs'
import FileSaver from 'file-saver'
import constantsArr from "../../../../constants/global-constants";

export const EditClientBillFinalCalculation = () => {
    const navigate = new useNavigate();
    const params = useParams()
    const activeButton = 'edit_client_bill_final_calculation'; // Set the default active button
    const globalTPProjectValues = AppHelper.decryptText(sessionStorage.getItem('globalValues'))
    const [finalForm] = Form.useForm()
    const [totalPayableAmount, setTotalPayableAmount] = useState(0)
    const [cumAmount, setCUMAmount] = useState(0)
    const [diableSubmitButton, setDisableSubmitButton] = useState(false)
    const selectedLoadingIds = AppHelper.decryptText(sessionStorage.getItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`))
    const billDataStorage = AppHelper.decryptText(sessionStorage.getItem(`billDataStorage_${params?.id}`))
    const dateFormat = constantsArr.GLOBAL_DATE_FORMAT;

    const onChange = (e) => {
        if (e.target.id === 'CUM') {
            // calculate gst on payable amount
            const value = e.target.value ? parseFloat(e.target.value) : 0
            setCUMAmount(parseFloat(value).toFixed(2))
        }
    };

    /**
     * Function to generate final bill with calculation with validation
     */
    const generateBillWithFinalCalculation = async (formValues) => {
        setDisableSubmitButton(true)
        const billPendingIds = makeSelectedIdsToBillSubmitFormat(selectedLoadingIds)
        const sortedBilledLoadingIds = billPendingIds.sort((first, second) => first?.loading - second?.loading)
        const finalBillData = {
            "trip_count": billDataStorage?.trip_count ?? 0,
            "unloading_starting_date": dayjs(billDataStorage?.start_unloading_date, 'DD-MM-YYYY').format('YYYY-MM-DD') ?? 0,
            "unloading_end_date": dayjs(billDataStorage?.end_unloading_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            "bill_no": formValues?.Bill_No ?? '',
            "bill_date": dayjs(billDataStorage?.bill_date).format('YYYY-MM-DD'),
            "net_weight": billDataStorage?.net_weight ?? 0,
            "cum": parseFloat(cumAmount ?? 0).toFixed(2),
            "total_net_weight": (parseFloat(totalPayableAmount)).toFixed(2),
            "is_bill_final_settled": false,
            "Project": globalTPProjectValues?.projectGlobal,
            "loading_ids": sortedBilledLoadingIds
        }
        const response = await AppHelper.updateClientBill(params?.id, finalBillData)
        if (response?.code === "ERR_NETWORK") {
            notification.error({
                message: "Network Error",
                description: response?.error?.message,
                duration: 3,
            });
            setDisableSubmitButton(false)
        } else if (response?.error) {
            notification.error({
                message: "Error",
                description: response?.error?.message,
                duration: 3,
            });
            setDisableSubmitButton(false)
        } else {
            // export functionality
            const exportResponse = await AppHelper.getClientBillDetails(response?.id)
            if (exportResponse?.error) {
                notification.error({
                    message: "Error",
                    description: exportResponse?.error?.message,
                    duration: 3
                })
            } else {
                // export xlsx code goes here
                const workBook = new exceljs.Workbook()
                const workSheetPendingBills = workBook.addWorksheet("Vehicle List", {
                    views: [{ ySplit: 1, state: 'frozen' }]
                })
                workSheetPendingBills.columns = [
                    { header: 'S.NO.', key: "sl_no", width: 8 },
                    { header: 'Date of Loading', key: "Date_of_Loading", width: 20 },
                    { header: 'Loading Agency', key: "Loading_Agency", width: 20 },
                    { header: 'Date of UL', key: "Date_of_Unloading", width: 20 },
                    { header: 'Unloading Agency', key: "Unloading_Agency", width: 20 },
                    { header: 'Vehicle Number.', key: "Vehicle_Number", width: 15 },
                    { header: 'Challan No.', key: "Challan_Number", width: 15 },
                    { header: 'D/O No', key: "DO_No", width: 15 },
                    { header: 'HSD (LTR)', key: "HSD_LTR", width: 15 },
                    { header: 'Transporter Agency', key: "Transporter_Agency", width: 20 },
                    { header: 'Thermal Plant Book No', key: "Thermal_Plant_Book_no", width: 20 },
                    { header: 'Net Wt. UL (MT)', key: "Net_Weight", width: 20 },
                    { header: 'Gross weight (MT)', key: "Gross_Weight", width: 20 },
                    { header: 'Tare weight (MT)', key: "Tare_Weight", width: 15 },
                    { header: 'Kanta Slip No. UL', key: "Kanta_Slip_No", width: 25 },
                    { header: 'Unloading Location', key: "Unloading_Location", width: 20 },
                ]
                exportResponse?.loading_ids.forEach((billData, key) => {
                    billData.loading.sl_no = key + 1
                    billData.loading.Unloading_Location = billData?.loading?.Unloading?.Unloading_Location
                    billData.loading.Net_Weight = ((billData?.loading?.Unloading?.Net_Weight && billData?.loading?.Unloading?.Net_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Net_Weight / 1000).toFixed(2)) : 0)
                    billData.loading.Kanta_Slip_No = billData?.loading?.Unloading?.Kanta_Slip_No
                    billData.loading.Transporter_Agency = billData?.loading?.Transporter_Agency?.Company_name
                    billData.loading.Unloading_Agency = billData?.loading?.Unloading?.Unloading_Agency?.Company_name
                    billData.loading.HSD_LTR = billData?.loading?.hsd_detail?.HSD_LTR
                    billData.loading.Tare_Weight = ((billData?.loading?.Unloading?.Tare_Weight && billData?.loading?.Unloading?.Tare_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Tare_Weight / 1000).toFixed(2)) : 0)
                    billData.loading.Gross_Weight = ((billData?.loading?.Unloading?.Gross_Weight && billData?.loading?.Unloading?.Gross_Weight !== "") ? parseFloat((billData?.loading?.Unloading?.Gross_Weight / 1000).toFixed(2)) : 0)
                    billData.loading.Vehicle_Number = billData?.loading?.Vehicle_Number?.Vehicle_Number
                    billData.loading.Loading_Agency = billData?.loading?.Loading_Agency?.Company_name
                    billData.loading.Date_of_Unloading = ((billData?.loading?.Unloading?.Date_of_Unloading !== "" && billData?.loading?.Unloading?.Date_of_Unloading !== '-') ? dayjs(billData?.loading?.Unloading?.Date_of_Unloading).format(dateFormat) : '')
                    billData.loading.Date_of_Loading = ((billData?.loading?.Date_of_Loading !== "" && billData?.loading?.Date_of_Loading !== '-') ? dayjs(billData?.loading?.Date_of_Loading).format(dateFormat) : '')
                    billData.loading.Thermal_Plant_Book_no = ((billData?.loading?.Unloading?.Thermal_Plant_Book_no !== "" && billData?.loading?.Unloading?.Thermal_Plant_Book_no !== '-') ? billData?.loading?.Unloading?.Thermal_Plant_Book_no : '')
                    workSheetPendingBills.addRow(billData?.loading)
                })

                // preapare sheet for global counts

                const workSheetPendingBillGlobalCounts = workBook.addWorksheet("Transaction Summary")
                // prepare sheet columns / headers
                workSheetPendingBillGlobalCounts.columns = [
                    { header: 'TP', key: "first_value", width: 25 },
                    { header: exportResponse?.Project?.Thermal_Plant?.Name, key: "second_value", width: 25 },
                ]

                // prepare sheet rows to display
                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Project", second_value: exportResponse?.Project?.Name_of_project ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Bill Date", second_value: exportResponse?.bill_date ? dayjs(exportResponse?.bill_date).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Bill No.", second_value: exportResponse?.bill_no ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Trip Count", second_value: exportResponse?.trip_count ?? 0 }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Starting Date (UL)", second_value: exportResponse?.unloading_starting_date ? dayjs(exportResponse?.unloading_starting_date).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "End Date (UL)", second_value: exportResponse?.unloading_end_date ? dayjs(exportResponse?.unloading_end_date).format(dateFormat) : '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "Net Weight (UL)", second_value: exportResponse?.total_net_weight ?? '' }
                )

                workSheetPendingBillGlobalCounts.addRow(
                    { first_value: "CUM", second_value: exportResponse?.cum ?? 0 }
                )


                // set header to export as excel file
                try {
                    const buffer = await workBook.xlsx.writeBuffer()
                    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    const excelExtension = constantsArr.EXPORT_EXCEL_EXTENSION
                    const blob = new Blob([buffer], { type: fileType })
                    FileSaver.saveAs(blob, `client_bill_save_export_data_${dayjs().format(dateFormat)}.${excelExtension}`)
                } catch (error) {
                    console.log(error, 'error');
                    notification.error({
                        message: "Error",
                        description: exportResponse?.error?.message,
                        duration: 3
                    })
                }
            }


            notification.success({
                message: "Success",
                description: "Client bill updated successfully",
                duration: 3,
            });

            // clear session storage items related to bills
            sessionStorage.removeItem(`billDataStorage_${params?.id}`)
            sessionStorage.removeItem(`edit_client_pending_bills_selected_loading_ids_${params?.id}`)
            sessionStorage.removeItem(`edit_client_pending_bill_generate_screen_active_sort_order_${params?.id}`)
            sessionStorage.removeItem(`selected_bill_date_${params?.id}`)
            setDisableSubmitButton(false)
            navigate("/bills/client-bill/generated-client-bill");
        }
    }

    const makeSelectedIdsToBillSubmitFormat = (loadingIds) => {
        if (loadingIds.length === 0) return []
        let finalIdsArray = []
        for (let index = 0; index < loadingIds.length; index++) {
            finalIdsArray.push(
                {
                    "id": index,
                    "loading": loadingIds[index]
                }
            )
        }

        return finalIdsArray
    }

    const backToGenerateBill = () => {
        navigate(`/bills/client-bill/edit-generate/${params?.id}`)
    }

    useEffect(() => {
        AppHelper.getClientBillDetails(params?.id, false).then(response => {
            finalForm.setFieldsValue({
                Bill_No: response?.bill_no,
                CUM: response?.cum
            })
            setTotalPayableAmount(billDataStorage?.net_weight ? parseFloat(billDataStorage?.net_weight) : 0)
            setCUMAmount(response?.cum)
        }).catch(err => {
            notification.error({
                message: err.message,
                duration: 3
            })
        })

        document.title = "Client Bill Final Calculation"
    }, [])

    return (
        <>
            <Grid
                container
                spacing={1}
                direction="row"
                justify="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                className={classes["transport-final-calculation"]}
            >
                <Grid item lg={10} md={8} sm={8} xs={12}>
                    <BreadCrumbClientBilling
                        activeButton={activeButton}
                        page="clientBillEdit"
                        billId={params?.id}
                    />
                </Grid>
                <Grid item lg={2} md={4} sm={4} xs={12} className={classes['right-align']}>
                    <UIButton
                        variant="contained"
                        className={classes["back-new-entry"]}
                        startIcon={<ArrowLeftOutlined />}
                        onClick={backToGenerateBill}
                    >
                        Generate Bills
                    </UIButton>
                </Grid>
            </Grid>
            <Form layout="vertical" form={finalForm} onFinish={generateBillWithFinalCalculation}>
                <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center"
                    alignContent="center"
                    wrap="wrap"
                    className={classes["transport-final-calculation-padding"]}
                >
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid className={classes["final-track-first"]}>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-second"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Trip Count :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {billDataStorage?.trip_count ?? 0}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-third"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Start date of Unloading:
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {billDataStorage?.start_unloading_date}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        End date of Unloading :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {billDataStorage?.end_unloading_date}
                                    </Typography>
                                </Grid>
                            </Grid><Grid
                                container
                                spacing={0}
                                className={classes["work-type-object-fourth"]}
                            >
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["normal-para"]}>
                                        Billing Date :
                                    </Typography>
                                </Grid>
                                <Grid item lg={6} md={6} xs={6}>
                                    <Typography variant="body1" className={classes["bold-para"]}>
                                        {dayjs(billDataStorage?.bill_date).format("DD-MM-YYYY")}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6} md={12} xs={12}>
                        <Grid className={classes["final-track-second"]}>
                            <Grid className={classes["billing-prefix-form"]}>
                                <Grid container spacing={0}>
                                    <Grid container item lg={6} md={6} xs={12}>
                                        <Form.Item label="Bill No." name="Bill_No" style={{ width: '95%' }} rules={
                                            [
                                                {
                                                    required: true,
                                                    message: 'Please enter bill no'
                                                }
                                            ]
                                        }>
                                            <Input className={classes["input-field"]} />
                                        </Form.Item>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={12}>
                                        <Form.Item label="CUM" name="CUM">
                                            <Input type="number" step={0.01} min={0} className={classes["input-field"]} onChange={onChange} onKeyPress={AppHelper.preventNegativeValueForCUM} />
                                        </Form.Item>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid className={classes["total-amount-wrapper"]}>
                                <Grid container spacing={0}>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <Typography variant="h5" color="#fff">
                                            Net Weight (MT)
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={6} md={6} xs={6}>
                                        <Typography variant="h4" color="#fff" fontWeight="bold" className={classes["amount-bold"]}>
                                            {(parseFloat(totalPayableAmount)).toLocaleString('en-IN')}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={12} md={12} xs={12}>
                        <div className={classes["submit-button-area"]}>
                            <UIButton
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={diableSubmitButton}
                                className={classes["submit-button"]}
                            >
                                Save and Export
                            </UIButton>
                        </div>
                    </Grid>
                </Grid>
            </Form>
        </>
    );
};
